import React, { FC, ReactNode, Suspense, useEffect, useState } from 'react';

import { getWritingDir } from '@rbilabs/intl';
import { isNil } from 'lodash';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Route, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import { isFalse } from 'utils';

import { AppHeader } from 'components/app-header';
import { BottomServiceMode } from 'components/bottom-service-mode';
import { BottomSheetRefillDrinks } from 'components/bottom-sheet-refill-drinks';
import { ErrorNetwork } from 'components/error-network';
import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import { Footer } from 'components/footer';
import { ForceUpdateModal } from 'components/force-update-modal';
import { LayoutBottomContainer } from 'components/layout/layout-bottom-container';
import { LoadingAnimation } from 'components/loading-animation';
import { MobileWebNavigationBar } from 'components/mobile-web-navigation-bar';
import { ModalBeeperWrapper } from 'components/modal-beeper';
import { ModalLockersPickupWrapper } from 'components/modal-lockers-pickup';
import { Routes } from 'components/routes';
import { ScrollView } from 'components/scroll-view';
import { SetLoading } from 'components/set-loading';
import { ToastContainer } from 'components/toast';
import { TopServiceModeNotification } from 'components/top-service-mode-notification';
import { View } from 'components/view';
import {
  useFeatureAccountFormQuery,
  useFeatureAccountQuery,
  useFeatureMenuQuery,
} from 'generated/sanity-graphql';
import { useAuthRedirects } from 'hooks/auth/use-auth-redirects';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import { useBatteryLevelEvent } from 'hooks/use-battery-level-event';
import { useEffectOnce } from 'hooks/use-effect-once';
import { useEvaluateLdFlags } from 'hooks/use-evaluate-ld-flags';
import { useFeatureInvitationCode } from 'hooks/use-feature-invitation-code';
import { useFeatureMemberships } from 'hooks/use-feature-memberships';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useForceWaitingPaymentRedirects } from 'hooks/use-force-waiting-payment-redirects/use-force-waiting-payment-redirects';
import { useForter } from 'hooks/use-forter';
import { useGeoData } from 'hooks/use-geo-data';
import { useIosAtt } from 'hooks/use-ios-att';
import { useLogPageView } from 'hooks/use-log-page-view';
import { useTrendingData } from 'hooks/use-trending-data';
import { AboutApp } from 'pages/about-app';
import { LoyaltyNotAvailableAtDeliveryMode } from 'pages/loyalty/loyalty-not-available-at-delivery-mode';
import { LoyaltyNotAvailableAtThisRestaurant } from 'pages/loyalty/loyalty-not-available-at-this-restaurant';
import { LoyaltyOptInModal } from 'pages/loyalty/loyalty-opt-in-modal';
import { useBluecodeContext } from 'state/bluecode';
import { useFeaturesContext } from 'state/features';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { FeatureFlagOverridesModal } from 'state/launchdarkly/feature-flag-overrides-modal';
import { useLocationContext } from 'state/location';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOrderContext } from 'state/order';
import { useScrollContext } from 'state/scroll';
import { usePageManagerContext } from 'state/static-page-manager';
import { isProduction } from 'utils/environment';
import { getIosAppTrackingStatus } from 'utils/get-ios-app-tracking-status';
import { Status } from 'utils/get-ios-app-tracking-status/types';
import {
  askPermissionOnCritialRoute,
  getCurrentVersion,
  getShouldReloadApp,
} from 'utils/live-updates';
import * as location from 'utils/location';
import { logger } from 'utils/logger';
import { enablePush } from 'utils/permissions';
import { routes } from 'utils/routing';

import { brandRoutes, fullPageBrandRoutes } from './brand-routes';
import { CookieBanner } from './cookie-banner-container';
import { FabContainer } from './fab-container/fab-container';
import { useAlternateLinks } from './use-alternate-links';
import { useScrollTop } from './use-scroll-top';
import { LazyRoute, lazyWithFallback } from './util';

const Cart: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/cart')).Cart,
}));
const MainMenu: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/main-menu')).MainMenu,
}));
const MenuContent: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/menu-content')).MenuContent,
}));
const Home: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/home')).Home,
}));
const Account: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account')).Account,
}));
const CommPreferences: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/comm-preferences')).CommPreferences,
}));
const AccountInfo: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-info')).AccountInfo,
}));
const AccountDelete: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-delete')).AccountDelete,
}));
const AccountRequestInfo: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-request-info')).AccountRequestInfo,
}));
const PaymentMethods: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/payment-methods')).PaymentMethods,
}));
const Diagnostics: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/diagnostics')).Diagnostics,
}));
const AccountOrders: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-orders')).AccountOrders,
}));
const Offers: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/offers')).OffersPage,
}));
const LoyaltyOffers: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-offers')).LoyaltyOffers,
}));
const LoyaltyRewards: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-rewards')).LoyaltyRewardsMemo,
}));
const LoyaltyDashboard: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-dashboard')).LoyaltyDashboard,
}));
const LoyaltySurprise: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-surprise')).LoyaltySurprise,
}));
const LoyaltyInRestaurantRedemption: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-in-restaurant-redemption'))
    .LoyaltyInRestaurantRedemption,
}));
const LoyaltyClaimPoints: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-claim-points')).LoyaltyClaimPoints,
}));
const LoyaltyLinkCard: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/loyalty/loyalty-link-card')).LoyaltyLinkCard,
}));
const SignupHome: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/authentication/sign-up-home')).SignupHome,
}));
const SignupUnifiedHome: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/authentication/sign-up-unified')).SignUpUnified,
}));
const AuthChallengeScreenJwt: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/authentication/auth-email-jwt')).AuthJwtChallengeScreen,
}));
const ConfirmJwt: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/authentication/confirm-jwt')).ConfirmJwt,
}));
const SignOut: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/authentication/sign-out')).SignOut,
}));
const StoreLocator: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/store-locator')).StoreLocator,
}));
const OrderConfirmation: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/order-confirmation/order-confirmation')).OrderConfirmation,
}));
const OrderProcessing: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/order-processing/order-processing')).OrderProcessing,
}));

const OrderPayment: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('payments')).PaymentPage,
}));

const CateringBirthday: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/catering-birthday')).CateringBirthdaySection,
}));
const About: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/about')).AboutPage,
}));
const StaticPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/static')).StaticPageContainer,
}));
const NotFound: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/not-found')).NotFound,
}));
const SupportPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/support')).SupportPage,
}));
const SupportV2CategoriesPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/support-v2/support-v2-categories-page')).SupportV2CategoriesPage,
}));
const SupportV2CategoryPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/support-v2/support-v2-category-page')).SupportV2CategoryPage,
}));
const SupportV2FormPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/support-v2/support-v2-form-page')).SupportV2FormPage,
}));
const SupportV2OrdersListPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/support-v2/support-v2-orders-list-page')).SupportV2OrdersListPage,
}));
const ReportOrderIssuePage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/report-order-issue')).ReportOrderIssuePage,
}));
const ReportOrderNeverArrivedPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/report-order-never-arrived')).ReportOrderNeverArrivedPage,
}));
const ReportOrderItemsMissingPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/report-order-items-missing')).ReportOrderItemsMissingPage,
}));
const History = lazyWithFallback(async () => ({
  default: (await import('pages/account/history')).History,
}));
const FavoriteEditorPage = lazyWithFallback(async () => ({
  default: (await import('pages/favorite-editor')).FavoriteEditor,
}));
const LoadingPage = lazyWithFallback(async () => ({
  default: (await import('pages/loading')).LoadingPage,
}));
const AccountSettings: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-settings')).AccountSettings,
}));
const SettingsLocation: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/account-settings/settings-location')).SettingsLocation,
}));
const InvitationCode: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/invitation-code')).InvitationCode,
}));
const Memberships: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/memberships')).Memberships,
}));
const MembershipDetailsPage: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/membership-details')).MembershipDetails,
}));
const BlueLightCard: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/account/blue-light-card')).BlueLightCard,
}));
const BkpayRedirect: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/bkpay')).BkPay,
}));
const WaitingPayment: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/cart/payment/order-payment/waiting-payment')).WaitingPaymentPage,
}));
const CartAddPaymentMethod: LazyRoute = lazyWithFallback(async () => ({
  default: (await import('pages/cart/payment/payment-method')).PaymentMethodPage,
}));

const AppContainer = styled(View)`
  height: 100%;
  width: 100%;
  flex: 1;
`;

const LayoutContainerScrollView = styled(ScrollView)`
  background: ${Styles.color.background};
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  &:focus {
    inset-block-start: 0.2rem;
    inset-inline-start: 0.2rem;
    opacity: 1;
  }
`;

const LayoutMain = styled.main`
  height: 100%;
`;

const ContentContainer = styled(View)`
  background: ${Styles.color.background};
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const ContentLoadingContainer = styled(View)`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${Styles.color.background};
`;

const RouterWrapperContainer = styled(View)`
  background: ${Styles.color.background};
  flex-grow: 1;
  height: 100%;
`;

const RouterWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return <RouterWrapperContainer>{children}</RouterWrapperContainer>;
};

const ContentRouting = () => {
  const { clearServerOrder } = useOrderContext(); // @todo type order context

  const { formatMessage } = useIntl();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { claimPointsEnabled, loyaltySurpriseOffersEnabled } = useLoyaltyContext();

  const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);

  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const enableCommunicationPreferences = !isFalse(enableCommPrefFlag);
  const isUnifiedSignInEnabled = useFlag(LaunchDarklyFlag.ENABLE_UNIFIED_SIGN_IN);

  const enableGuestAndMultiStepSupportForm = useFlag(
    LaunchDarklyFlag.ENABLE_GUEST_AND_MULTI_STEP_SUPPORT_PAGE
  );

  const { isBluecodeEnabled } = useBluecodeContext();

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;

  const forceAuthOnHomepageFlag = useFlag(LaunchDarklyFlag.FORCE_HOMEPAGE_AUTH);
  const isBaseRoute = useMatch(routes.base);

  const forceAuthOnHomepage = forceAuthOnHomepageFlag && enableSignUp && isBaseRoute;

  const enableOffersStaticPage = useFlag(LaunchDarklyFlag.ENABLE_OFFERS_STATIC_PAGE);

  const enableAccountSettings = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_SETTINGS);
  const enableGeolocation = useFlag(LaunchDarklyFlag.ENABLE_SETTINGS_GEOLOCATION);

  const enableCateringBirthday = useFlag(LaunchDarklyFlag.ENABLE_CATERING_BIRTHDAY);

  const { enableInvitationCode } = useFeatureInvitationCode();

  const { enableMemberships } = useFeatureMemberships();

  useAuthRedirects({ skip: !forceAuthOnHomepage });
  useForceWaitingPaymentRedirects();

  useEffect(() => {
    // reset the server order when a user navigate into the menu no matter what
    clearServerOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const SignUpComponent = isUnifiedSignInEnabled ? <SignupUnifiedHome /> : <SignupHome />;

  return (
    <>
      {/* Base Routes */}
      <ContentContainer>
        <RouterWrapper>
          <Routes>
            <Route path={'*'} element={<StaticPage />} />
            <Route noIndex path={routes.notFound404} element={<NotFound />} />
            <Route noIndex path={routes.confirmJwt} element={<ConfirmJwt />} />
            {loyaltyEnabled && (
              <Route
                path={`${formatMessage({ id: 'routes.loyaltyDashboard' })}`}
                element={<LoyaltyDashboard />}
              />
            )}
            {loyaltySurpriseOffersEnabled && (
              <Route
                noIndex
                path={`${formatMessage({ id: 'routes.loyaltyHome' })}/${formatMessage({
                  id: 'surprise',
                })}`}
                element={<LoyaltySurprise />}
              />
            )}
            <Route
              noIndex
              path={formatMessage({ id: 'routes.redemptionInRestaurant' })}
              element={<LoyaltyInRestaurantRedemption />}
            />
            <Route
              noIndex
              path={formatMessage({ id: 'routes.redeem-is-not-available-at-this-restaurant' })}
              element={<LoyaltyNotAvailableAtThisRestaurant />}
            />
            <Route
              noIndex
              path={formatMessage({ id: 'routes.redeem-is-not-available-at-delivery-mode' })}
              element={<LoyaltyNotAvailableAtDeliveryMode />}
            />
            <Route
              noIndex
              path={formatMessage({ id: 'routes.linkPhysicalCard' })}
              element={<LoyaltyLinkCard />}
            />
            {claimPointsEnabled && (
              <Route
                noIndex
                path={`${formatMessage({ id: 'routes.claimPointsBase' })}/*`}
                element={<LoyaltyClaimPoints />}
              />
            )}
            {enableSignUp && (
              <>
                <Route path={routes.signUp} element={SignUpComponent} />
                <Route path={routes.signIn} element={SignUpComponent} />
                <Route noIndex path={routes.confirmOtp} element={SignUpComponent} />
                <Route path={routes.createAccount} element={SignUpComponent} />
                <Route noIndex path={routes.account} element={<Account />} />
              </>
            )}
            <Route noIndex path={routes.authChallengeJwt} element={<AuthChallengeScreenJwt />} />
            <Route noIndex path={routes.signOut} element={<SignOut />} />
            <Route path={routes.menu} element={<MainMenu />} />
            <Route path={routes.browseMenu} element={<MainMenu />} />

            {/*
                MenuContent handles the following routes:

                /menu/favorites
                /menu/recent-items
                /menu/section-:id
                /menu/picker-:id
                /menu/combo-:id
                /menu/item-:id
                /menu/* (Item Not Found)

              */}
            <Route path={`${routes.menu}/:id`} element={<MenuContent />} />
            <Route path={`${routes.browseMenu}/:id`} element={<MenuContent />} />

            <Route path={routes.base} element={<Home />} />
            {!enableOffersStaticPage && <Route path={`${routes.offers}/*`} element={<Offers />} />}
            <Route
              path={formatMessage({ id: 'routes.loyaltyHome' })}
              element={<LoyaltyDashboard />}
            />

            <Route
              path={formatMessage({ id: 'routes.loyaltyOfferList' })}
              element={<LoyaltyOffers />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyOfferList' })}/*`}
              element={<LoyaltyOffers />}
            />
            <Route
              path={formatMessage({ id: 'routes.loyaltyRewardList' })}
              element={<LoyaltyRewards />}
            />
            <Route
              path={`${formatMessage({ id: 'routes.loyaltyRewardList' })}/*`}
              element={<LoyaltyRewards />}
            />

            <Route noIndex path={routes.info} element={<AccountInfo />} />
            <Route
              noIndex
              path={`${formatMessage({ id: 'routes.rewardsHistory' })}`}
              element={<History />}
            />
            {enableCommunicationPreferences && (
              <Route noIndex path={routes.communication} element={<CommPreferences />} />
            )}
            <Route noIndex path={`${routes.orders}/*`} element={<AccountOrders />} />
            {enableAccountDeletePage && (
              <Route
                noIndex
                path={formatMessage({ id: 'routes.accountDelete' })}
                element={<AccountDelete />}
              />
            )}
            {enableRequestMyInformation && (
              <Route
                noIndex
                path={formatMessage({ id: 'routes.accountRequestInfo' })}
                element={<AccountRequestInfo />}
              />
            )}
            <Route noIndex path={`${routes.payment}/*`} element={<PaymentMethods />} />
            <Route path={routes.about} element={<About />} />
            <Route noIndex path={routes.aboutApp} element={<AboutApp />} />
            <Route noIndex path={`${routes.diagnostics}/*`} element={<Diagnostics />} />
            {enableOrderSupport && (
              <Route
                noIndex
                path={`${routes.reportOrderIssue}/:orderId`}
                element={<ReportOrderIssuePage />}
              />
            )}
            {enableOrderSupport && (
              <Route
                noIndex
                path={`${routes.missingOrder}/:orderId`}
                element={<ReportOrderNeverArrivedPage />}
              />
            )}
            {enableOrderSupport && (
              <Route
                noIndex
                path={`${routes.missingItems}/:orderId`}
                element={<ReportOrderItemsMissingPage />}
              />
            )}
            {enableInvitationCode && (
              <Route noIndex path={`${routes.invitationCode}`} element={<InvitationCode />} />
            )}
            {enableGuestAndMultiStepSupportForm ? (
              <>
                {/* // general support requests */}
                <Route path={routes.supportForm} element={<SupportV2FormPage />} />
                <Route path={routes.supportOrders} element={<SupportV2OrdersListPage />} />
                <Route path={routes.supportCategories} element={<SupportV2CategoriesPage />} />
                <Route path={routes.supportCategory} element={<SupportV2CategoryPage />} />
                <Route path={routes.support} element={<SupportV2CategoriesPage />} />
              </>
            ) : (
              <Route path={routes.support} element={<SupportPage />} />
            )}

            <Route noIndex path={routes.favoriteEditor} element={<FavoriteEditorPage />} />

            {/* We only want brand-specific routes to be available for those brands */}
            {/* `brandRoutes` can be thought of a Map of routes (string) to Components (React component) */}
            {(Object.entries(brandRoutes) as Array<[keyof typeof brandRoutes, LazyRoute]>).map(
              ([path, Component]) => (
                <Route
                  noIndex
                  path={formatMessage({ id: path })}
                  element={<Component key={path} />}
                  key={path}
                />
              )
            )}

            {enableAccountSettings && (
              <Route noIndex path={routes.settings} element={<AccountSettings />} />
            )}

            {enableMemberships && (
              <>
                <Route
                  noIndex
                  path={formatMessage({ id: 'routes.memberships' })}
                  element={<Memberships />}
                />
                <Route
                  noIndex
                  path={formatMessage({ id: 'routes.membershipDetails' })}
                  element={<MembershipDetailsPage />}
                />
                <Route
                  noIndex
                  path={formatMessage({ id: 'routes.blueLightCard' })}
                  element={<BlueLightCard />}
                />
              </>
            )}

            {enableGeolocation && (
              <Route noIndex path={routes.settingsLocation} element={<SettingsLocation />} />
            )}
            {enableCateringBirthday && <Route path={routes.cateringBirthday} />}
            {isBluecodeEnabled && (
              <>
                <Route noIndex path={routes.bkPay} element={<BkpayRedirect />} />
                <Route noIndex path={routes.bkPayPaymentRequest} element={<Home />} />
              </>
            )}
          </Routes>
          <Footer />
        </RouterWrapper>
      </ContentContainer>
    </>
  );
};

export const Layout = () => {
  const {
    location: { pathname },
  } = useLocationContext();
  const {
    featureAccountId = '',
    featureIdsLoading,
    featureAccountFormId = '',
    featureMenuId = '',
  } = useFeaturesContext();
  const { routesLoading } = usePageManagerContext();
  const { scrollRef, scrollTo } = useScrollContext();
  const [IOSTrackingStatus, setIOSTrackingStatus] = useState<null | string>(null);
  const { loading: isFeatureAccountLoading } = useFeatureAccountQuery({
    variables: { featureAccountId },
    skip: !featureAccountId || pathname !== routes.communication || pathname !== routes.orders,
  });
  const { featureNavigationLoading: isFeatureNavigationLoading } = useFeatureNavigation();
  const { loading: isTrendingContentLoading } = useTrendingData();
  const { loading: isMainMenuContentLoading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId || pathname !== routes.menu,
  });

  const { loading: isSignUpContentLoading } = useFeatureAccountFormQuery({
    variables: { featureAccountFormId },
    skip: !featureAccountFormId || pathname !== routes.signUp || pathname !== routes.signIn,
    fetchPolicy: 'cache-and-network',
  });

  const topServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const bottomServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableCateringBirthday = useFlag(LaunchDarklyFlag.ENABLE_CATERING_BIRTHDAY);
  const enableTopServiceMode = topServiceModeFlag && !bottomServiceModeFlag;
  const enableBottomServiceMode = bottomServiceModeFlag && !topServiceModeFlag;
  const permissionToUpdate = askPermissionOnCritialRoute() && getShouldReloadApp();

  useBatteryLevelEvent();

  useEffect(() => {
    scrollTo({ x: 0, y: 0, animated: false });
  }, [scrollTo, pathname]);

  async function trackingStatus() {
    const iosstatus = await getIosAppTrackingStatus();
    setIOSTrackingStatus(iosstatus);
  }

  useEffectOnce(() => {
    // Prompt users to enable push notifications, the prompt is displayed only once
    enablePush();
    trackingStatus();
  });

  useEffectOnce(() => {
    getCurrentVersion().then(versionData => {
      if (versionData) {
        logger.setContext({
          appflowBuildId: versionData.appflowBuildId,
          appVersionCode: versionData.build,
          appVersionName: versionData.version,
        });
      }
    });
  });

  /* TODO: figure out how to get the loading status
   *       without double-triggering the Sanity call
   * CONTEXT:
   *  this code still fires off the Sanity query even
   *    though it is already fired from useFeatureHomePage hook
   *  setting skip: true means that loading always comes back false
   *  not passing anything means we still hit Sanity, but it returns
   *    an error that id is required
   *  💭 maybe moving feature home page data to context so that we
   *    have the data and loading state available multiple places?
   */
  const { featureHomePageLoading: isFeatureHomePageLoading } = useFeatureHomePage();
  const { routesLoading: isStaticRoutesLoading } = usePageManagerContext();

  const { featureInvitationCodeLoading } = useFeatureInvitationCode();

  useForter();
  useLogPageView();
  useScrollTop();
  useGeoData();

  const { language } = useLocale();
  const { formatMessage } = useIntl();

  const [canonicalUrl] = location.get('href').split('?');
  const canonicalLink = [{ rel: 'canonical', href: canonicalUrl }];
  const alternateLinks = useAlternateLinks();

  const { requestIosAttPermissions } = useIosAtt();

  let content;

  useResetStoreResetCartResetServiceModeTimeout();
  useEvaluateLdFlags();

  useEffect(requestIosAttPermissions, []);

  const showFeatureFlagOverridesModal = !isProduction || process.env.NODE_ENV === 'development';

  // Temporary solution, the goal is to make object for each pathname
  const getLoadingAriaLabel = (path: string) => {
    if (path === routes.menu) {
      return formatMessage({ id: 'loadingRestaurantMenu' });
    }
    return formatMessage({ id: 'loading' });
  };

  const blockingLoadingStates = (path: string) => {
    // The ultimate goal here is to preload what the critical pages will use,
    // without holding up the user waiting for content that isn't needed on the page.
    // As we extend this pattern, more routes should be added to this switch statement.
    // This should become the only "full page spinner" in the app, so we know it when we see it.
    switch (path) {
      case routes.base:
        return [featureIdsLoading, isFeatureHomePageLoading];
      case routes.offers:
        return [featureIdsLoading];
      case routes.about:
        return [featureIdsLoading, isFeatureNavigationLoading];
      case routes.orders:
      case routes.communication:
        return [featureIdsLoading, isFeatureAccountLoading];
      case routes.menu:
        return [featureIdsLoading, isMainMenuContentLoading];
      case routes.signUp:
        return [featureIdsLoading, isSignUpContentLoading];
      case routes.trending:
        return [featureIdsLoading, isTrendingContentLoading];
      case routes.invitationCode:
        return [featureIdsLoading, featureInvitationCodeLoading];
      case routes.cateringBirthday:
      default:
        return [routesLoading, isStaticRoutesLoading];
    }
  };

  if (blockingLoadingStates(pathname).some(queryIsLoading => !!queryIsLoading)) {
    content = (
      <ContentLoadingContainer aria-label={getLoadingAriaLabel(pathname)}>
        <LoadingAnimation fillColor={Styles.color.primary} />
      </ContentLoadingContainer>
    );
  } else {
    content = (
      <LayoutContainerScrollView data-testid="main-layout" ref={scrollRef}>
        <LayoutMain id="main">
          <Suspense fallback={<SetLoading />}>
            <Routes>
              {/* Full page routes */}
              {enableCateringBirthday && (
                <Route noIndex path={routes.cateringBirthday} element={<CateringBirthday />} />
              )}
              <Route noIndex path={routes.waitingPayment} element={<WaitingPayment />} />
              <Route
                noIndex
                path={`${routes.cart}/*`}
                element={<Cart permissionToUpdate={permissionToUpdate} />}
              />
              <Route
                noIndex
                path={`${routes.orderConfirmation}/:rbiOrderId`}
                element={<OrderConfirmation />}
              />
              <Route
                noIndex
                path={`${routes.orderProcessing}/:rbiOrderId`}
                element={<OrderProcessing />}
              />
              <Route
                noIndex
                path={`${routes.orderPayment}/:rbiOrderId?`}
                element={<OrderPayment />}
              />
              <Route path={`${routes.storeLocator}/*`} element={<StoreLocator />} />
              <Route
                path={`${formatMessage({ id: 'routes.storeLocator' })}/*`}
                element={<StoreLocator />}
              />
              <Route
                noIndex
                path={routes.cartAddPaymentMethod}
                element={<CartAddPaymentMethod />}
              />
              <Route noIndex path={routes.loading} element={<LoadingPage />} />
              {(
                Object.entries(fullPageBrandRoutes) as Array<
                  [keyof typeof fullPageBrandRoutes, LazyRoute]
                >
              ).map(([path, Component]) => (
                <Route
                  path={formatMessage({ id: path })}
                  element={<Component key={path} />}
                  key={path}
                />
              ))}
              {/* Regular routes (with normal nav, header, footer, etc) */}
              <Route path="*" element={<ContentRouting />} />
            </Routes>
          </Suspense>
        </LayoutMain>
      </LayoutContainerScrollView>
    );
  }

  return (
    <>
      <Helmet link={[...canonicalLink, ...alternateLinks]}>
        <html lang={language} dir={getWritingDir(language)} />
      </Helmet>

      <AppContainer>
        <AppHeader />
        {content}
        <LoyaltyOptInModal />
        <ModalBeeperWrapper />
        <ModalLockersPickupWrapper />

        <View data-testid="footer">
          <FabContainer />
          <ToastContainer />
          <CookieBanner hasUserDeniedTracking={IOSTrackingStatus === Status.DENIED} />
          {pathname === routes.base && <BottomSheetRefillDrinks />}
          {enableTopServiceMode && <TopServiceModeNotification />}

          <LayoutBottomContainer>
            {enableBottomServiceMode && <BottomServiceMode />}
            <MobileWebNavigationBar />
          </LayoutBottomContainer>
        </View>
        <ForceUpdateModal />
        {showFeatureFlagOverridesModal && <FeatureFlagOverridesModal />}
      </AppContainer>
      <ErrorNetwork />
    </>
  );
};
