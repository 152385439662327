import React from 'react';

import { isNil } from 'lodash';
import { useLocation } from 'react-router-dom';

import { IHeaderProps } from 'components/app-header/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { ServiceModeAddress } from './service-mode-address';
import { SubheaderContainer } from './styled';
import { SubheaderLanguageSelector } from './subheader-language-selector';
import { shouldShowServiceModeHeader } from './utils';

export const AppSubheader: React.FC<IHeaderProps> = ({ serviceMode, userAddress }) => {
  const hideServiceModeFlag = useFlag(LaunchDarklyFlag.HIDE_SERVICE_MODE);
  const location = useLocation();
  // determines whether service mode is shown using current window location and service mode
  const routeRequiresServiceMode = shouldShowServiceModeHeader(location.pathname, serviceMode);
  const hideServiceMode =
    (isNil(hideServiceModeFlag) && !routeRequiresServiceMode) || !routeRequiresServiceMode
      ? true
      : hideServiceModeFlag;

  const enableBottomServiceFlag = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableBottomService = isNil(enableBottomServiceFlag) ? false : enableBottomServiceFlag;

  const enableTopServiceModeFlag = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const enableTopServiceMode = isNil(enableTopServiceModeFlag) ? false : enableTopServiceModeFlag;

  const disableLocalizationFlag = useFlag(LaunchDarklyFlag.DISABLE_LOCALIZATION);
  const disableLocalization = isNil(disableLocalizationFlag) ? true : disableLocalizationFlag;

  const isEmpty = hideServiceMode && disableLocalization;

  if (enableBottomService || enableTopServiceMode || isEmpty) {
    return null;
  }

  return (
    <SubheaderContainer data-testid="app-subheader-container">
      {!hideServiceMode && serviceMode && (
        <ServiceModeAddress serviceMode={serviceMode} userAddress={userAddress} />
      )}
      <SubheaderLanguageSelector />
    </SubheaderContainer>
  );
};
