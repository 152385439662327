import React from 'react';

export const replacePlaceholderWithComponent = (
  text: string,
  placeholder: string,
  component: JSX.Element
) => {
  const parts = text.split(placeholder);
  const componentParts = parts.reduce((acc, part) => [...acc, part, component], []).slice(0, -1);

  return <>{componentParts}</>;
};
