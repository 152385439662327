import { ISOs } from 'utils/form/constants';

type ISOString = keyof typeof ISOs;

type AdministrativeAreasByCountry = {
  [key in ISOString]?: { [key: string]: string };
};

export const administrativeAreasByCountry: AdministrativeAreasByCountry = {
  [ISOs.US]: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FM: 'Federated States of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  [ISOs.CA]: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
  },
  [ISOs.ES]: {
    AN: 'Andalucía',
    AR: 'Aragón',
    AS: 'Asturias',
    CN: 'Canarias',
    CB: 'Cantabria',
    CM: 'Castilla-La Mancha',
    CL: 'Castilla y León',
    CT: 'Catalunya',
    EX: 'Extremadura',
    GA: 'Galicia',
    IB: 'Illes Balears',
    RI: 'La Rioja',
    MD: 'Madrid',
    MC: 'Murcia',
    NC: 'Navarra',
    PV: 'País Vasco',
    VC: 'Valenciana',
  },
  [ISOs.PT]: {
    AC: 'Açores',
    AV: 'Aveiro',
    BE: 'Beja',
    BR: 'Braga',
    BA: 'Bragança',
    CB: 'Castelo Branco',
    CO: 'Coimbra',
    EV: 'Évora',
    FA: 'Faro',
    GU: 'Guarda',
    LE: 'Leiria',
    LI: 'Lisboa',
    MA: 'Madeira',
    PA: 'Portalegre',
    PO: 'Porto',
    SA: 'Santarém',
    SE: 'Setúbal',
    VC: 'Viana do Castelo',
    VR: 'Vila Real',
    VI: 'Viseu',
  },
};

export const administrativeAreasByCountryExtended: AdministrativeAreasByCountry = {
  [ISOs.PT]: {
    Açores: 'AC',
    Aveiro: 'AV',
    Beja: 'BE',
    Braga: 'BR',
    Bragança: 'BA',
    'Castelo Branco': 'CB',
    Coimbra: 'CO',
    Évora: 'EV',
    Faro: 'FA',
    Guarda: 'GU',
    Leiria: 'LE',
    Lisboa: 'LI',
    Madeira: 'MA',
    Portalegre: 'PA',
    Porto: 'PO',
    Santarém: 'SA',
    Setúbal: 'SE',
    'Viana do Castelo': 'VC',
    'Vila Real': 'VR',
    Viseu: 'VI',
  },
};
