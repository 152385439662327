import React, { useRef, useState } from 'react';

import { PrimaryButton, Switch, TextInput } from '@rbilabs/components-library';
import { createPortal } from 'react-dom';

import { BooleanFlags, LaunchDarklyFlag } from 'utils/launchdarkly';

import { useLDContext } from '..';

import {
  CloseIcon,
  Container,
  FlagButton,
  FlagLabel,
  Header,
  Heading,
  List,
  ListItem,
  Popover,
  RemoveButton,
} from './styled';
import { useDrag } from './use-drag';

const SEARCH_FLAG_MIN_LENGTH = 3;

const CloseButton = ({ onClick }: { onClick: VoidFunction }) => (
  <RemoveButton onClick={onClick}>
    <CloseIcon icon="close" color="text-disabled" />
  </RemoveButton>
);

export const FeatureFlagOverridesModal = () => {
  const { flags, flagOverrides, addFlagOverride, removeFlagOverride } = useLDContext();
  const [displayModal, setDisplayModal] = useState(false);
  const [searchFlag, setSearchFlag] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { position, handleMouseDown } = useDrag(containerRef);

  const handleAddFlagOverride = (flag: string, value: boolean) => {
    addFlagOverride(flag as BooleanFlags, value);
    setSearchFlag('');
  };

  const filteredFlags = Object.values(LaunchDarklyFlag).filter(
    (flag: BooleanFlags) => flag.includes(searchFlag) && typeof flags[flag] === 'boolean'
  );

  return createPortal(
    displayModal ? (
      <Container position={position} ref={containerRef}>
        <Popover>
          <Header onMouseDown={handleMouseDown}>
            <Heading>Feature Flag Overrides</Heading>
            <CloseButton onClick={() => setDisplayModal(false)} />
          </Header>

          <TextInput
            label="Search flag"
            onChange={e => setSearchFlag(e.target.value)}
            value={searchFlag}
          />

          {searchFlag.length >= SEARCH_FLAG_MIN_LENGTH ? (
            <List>
              {filteredFlags.map((flag: BooleanFlags) => (
                <ListItem key={flag}>
                  <FlagLabel>{flag}</FlagLabel>
                  <PrimaryButton
                    size="small"
                    onClick={() => handleAddFlagOverride(flag, flags[flag] ?? false)}
                  >
                    Override
                  </PrimaryButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <List>
              {Object.keys(flagOverrides).map((flag: BooleanFlags) => (
                <ListItem key={flag}>
                  <FlagLabel>{flag}</FlagLabel>
                  <Switch
                    label=""
                    checked={flagOverrides[flag]}
                    onClick={() => addFlagOverride(flag, !flagOverrides[flag])}
                  />
                  <CloseButton onClick={() => removeFlagOverride(flag)} />
                </ListItem>
              ))}
            </List>
          )}
        </Popover>
      </Container>
    ) : (
      <FlagButton onClick={() => setDisplayModal(true)}>⚑</FlagButton>
    ),
    document.body
  );
};
