import styled from 'styled-components';

import { StyledSwitchButton } from './switch.styled';
import { SwitchLabelProps } from './types';

export const SwitchLabel = styled.label<SwitchLabelProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font: ${(p) => p.theme.token('text-style-list-item')};
  color: ${(p) => p.theme.token('text-default')};
  &:hover,
  &:focus {
    ${StyledSwitchButton} {
      box-shadow: ${(p) => {
        if (p.disabled) {
          return 'none';
        }
        return p.theme.token('box-shadow-pattern');
      }};
    }
  }
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    cursor: not-allowed;
  }
`;
