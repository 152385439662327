import React from 'react';

import { ExternalLink } from 'components/external-link';
import { ITextWidgetWithUrlFragment } from 'generated/sanity-graphql';

import { P } from './styled';
import { replacePlaceholderWithComponent } from './utils';

type ITextWidgetWithUrlProps = ITextWidgetWithUrlFragment;

const PLACEHOLDER_VALUE = '{url}';

export const TextWithUrl: React.FC<ITextWidgetWithUrlProps> = ({ text, textUrl, url }) => {
  const link = <ExternalLink href={url?.locale ?? ''}>{textUrl?.locale}</ExternalLink>;
  const textWithPlaceholderReplaced = replacePlaceholderWithComponent(
    text?.locale ?? '',
    PLACEHOLDER_VALUE,
    link
  );

  return <P>{textWithPlaceholderReplaced}</P>;
};
