import { IntlShape } from 'react-intl';

import { ServiceMode } from 'state/service-mode/types';

export enum ServiceModeCategory {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
}

export const getLocalizedServiceModeCategory = (
  serviceMode: ServiceMode,
  formatMessage: IntlShape['formatMessage']
): string => {
  return serviceMode === ServiceMode.DELIVERY
    ? formatMessage({ id: 'delivery' })
    : formatMessage({ id: 'pickUp' });
};
