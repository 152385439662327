import { textTransform, zIndex } from '../common';
import { ITheme } from '../types';

import { color } from './color';
import { fontFace } from './font-face';
import { fontFamily } from './font-family';
import { fontSize } from './font-size';
import { fontWeight } from './font-weight';
import { icon } from './icon';
import { letterSpacing } from './letter-spacing';
import { lineHeight } from './line-height';

export const theme: ITheme = {
  fontFace,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  textTransform,
  zIndex,
  icon,
  color,
  components: {
    Hero: {
      defaultProps: {
        disableButtonReverse: false,
      },
    },
  },
};
