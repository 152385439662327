import { PermissionState } from './types';

export class GeolocationError extends Error {
  code: PermissionState;

  constructor(message: string, code: PermissionState) {
    super(message);
    this.name = 'GeolocationError';
    this.code = code;
  }
}
