import * as React from 'react';
import { useMemo } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import { IWidgetElement, IWidgetKey, IWidgetMap, hasComponentKey } from './types';

export const useWidgetComponents = <TWidgetKeys extends string>(
  widgetMap: IWidgetMap<TWidgetKeys>,
  widgetKeys: Maybe<ReadonlyArray<Maybe<IWidgetKey>>>
): IWidgetElement[] => {
  const widgetComponents = useMemo(() => {
    if (!widgetKeys?.length) {
      return [];
    }

    // Maps Sanity widget key to widget component
    const createWidgetComponents = (keys: ReadonlyArray<Maybe<IWidgetKey>>) =>
      keys.reduce((acc: IWidgetElement[], widget, idx) => {
        if (hasComponentKey<IWidgetKey>(widget)) {
          const { componentKey, _key, ...props } = widget;
          const Component = componentKey && widgetMap[componentKey as TWidgetKeys];
          if (Component) {
            const id = `${_key || idx}`;
            acc.push({
              element: <Component key={id} {...(props as any)} />,
              id,
            });
          }
        }

        return acc;
      }, []);

    return createWidgetComponents(widgetKeys);
  }, [widgetKeys, widgetMap]);

  return widgetComponents;
};
