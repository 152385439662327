import * as React from 'react';
import { FC, useCallback, useState } from 'react';

import { ISanityBlockContent, ISanityImage } from '@rbi-ctg/menu';
import { MarketingCardGroup } from 'components/features/components/marketing-card-group';
import { MarketingBasicTile } from 'components/marketing-basic-tile';
import {
  HorizontalPosition,
  MarketingTile,
  ModalPayloadType,
  OnShowModalType,
  TileSize,
} from 'components/marketing-tile';
import { SanityBlockRenderer } from 'components/sanity-block-renderer';
import {
  IMarketingTile,
  IMarketingTileBasicFragment,
  IMarketingTileFragment,
  IMarketingTileGroup,
} from 'generated/sanity-graphql';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useMediaQuery } from 'hooks/use-media-query';
import { useBluecodeContext } from 'state/bluecode';
import { FixLocaleTypes, RemoveKeys } from 'types/sanity';
import { getWeekDay } from 'utils/dateTime';

import { linkSerializers } from '../terms/terms-link';

import { MarketingTileList } from './marketing-tile-list';
import {
  LeftContainer,
  QuadTileContainer,
  RightContainer,
  Section,
  StyledBasicTile,
} from './styled';
import { IMarketingTileGroupProps, MarketingTileGroupType } from './types';

type TAlignmentValue = 'left' | 'center' | 'right';

const getAlignment = (val: TAlignmentValue) => {
  return HorizontalPosition[val.toUpperCase() as Uppercase<TAlignmentValue>];
};

export const Tile = ({
  tile,
  size,
  buttonPosition,
  onShowTermsModal,
  hasBorder,
  additionalPadding,
}: {
  tile: IMarketingTileFragment;
  size: TileSize;
  buttonPosition: HorizontalPosition;
  onShowTermsModal?: OnShowModalType;
  hasBorder?: boolean;
  additionalPadding?: string;
}) => {
  const backgroundImage = tile.backgroundImage?.locale?.app;
  const backgroundImageDescription = tile.backgroundImage?.locale?.imageDescription;
  const buttonText = tile.buttonAction?.actionText?.locale;
  const buttonUrl = tile.buttonAction?.actionUrl?.locale || tile.buttonAction?.route;
  const termsText = tile.termsText?.localeRaw;
  const termsButtonText = tile.termsButton?.actionText?.locale;
  const termsButtonUrl = tile.termsButton?.actionUrl?.locale;
  const ruleSet = tile.ruleSet;

  /**
   * Based on Sanity `ruleSet` field for the MarketingTile` component,
   * this function will return a boolean value indicating if the Tile should
   * be displayed or not based on the current day of the week and/or time.
   * If no rules are set, the Tile will always be displayed.
   * @returns {boolean} - true if the Tile should be displayed, false otherwise
   */
  const tileShouldBeDisplayed = () => {
    if (!ruleSet) {
      return true;
    }

    const now = new Date();
    const currentDayOfWeek = getWeekDay(now);

    let isActiveForDayOfWeek = true;
    if (ruleSet.dayOfWeek && ruleSet.dayOfWeek[currentDayOfWeek] != null) {
      isActiveForDayOfWeek = !!ruleSet.dayOfWeek[currentDayOfWeek];
    }

    const getTimeParts = (time: string) => {
      const [hours, minutes, seconds] = time.split(':');
      const hoursInt = Number.parseInt(hours, 10);
      const minutesInt = Number.parseInt(minutes, 10);
      const secondsInt = Number.parseInt(seconds, 10);
      return [hoursInt, minutesInt, secondsInt];
    };

    let isActiveForTimeRange = true;
    if (ruleSet.betweenTimes && ruleSet.betweenTimes.startTime && ruleSet.betweenTimes.endTime) {
      const [startHour, startMinutes, startSeconds] = getTimeParts(ruleSet.betweenTimes.startTime);
      const [endHour, endMinutes, endSeconds] = getTimeParts(ruleSet.betweenTimes.endTime);

      const startTimeMs = new Date().setHours(startHour, startMinutes, startSeconds);
      const endTimeMs = new Date().setHours(endHour, endMinutes, endSeconds);
      const nowMs = now.getTime();

      isActiveForTimeRange = startTimeMs <= nowMs && nowMs <= endTimeMs;
    }

    return isActiveForDayOfWeek && isActiveForTimeRange;
  };

  if (tileShouldBeDisplayed()) {
    return (
      <MarketingTile
        id={tile._id}
        tileSize={size}
        backgroundImage={backgroundImage}
        backgroundImageDescription={backgroundImageDescription}
        buttonText={buttonText || ''}
        buttonUrl={buttonUrl || ''}
        termsText={termsText || ''}
        termsButtonText={termsButtonText || undefined}
        termsButtonUrl={termsButtonUrl || undefined}
        onShowTermsModal={onShowTermsModal}
        textPosition={getAlignment((tile.textAlignment as TAlignmentValue) || 'right')}
        hasLockedAspectRatio={tile.lockedAspectRatio || undefined}
        textColor={tile.textColor || undefined}
        buttonPosition={buttonPosition}
        hasBorder={hasBorder}
        additionalPadding={additionalPadding}
      />
    );
  }

  return null;
};

export interface ITermModalComponentProps {
  headingText?: string;
  contentBlock?: ISanityBlockContent[];
  modalAppearanceEventMessage: string;
  onDismiss: VoidFunction;
}

/**
 * MarketingTileGroup purpose
 */
export const MarketingTileGroup: FC<IMarketingTileGroupProps> = ({ item, className }) => {
  const isMobile = useMediaQuery('mobile');

  const [pendingData, setPendingData] = useState<ModalPayloadType>({ content: [], heading: '' });
  const [TermsModal, openTermsModal] = useDialogModal({
    modalAppearanceEventMessage: 'Terms and Conditions',
  });

  const { userHasBluecodeAccount } = useBluecodeContext();

  const hideBluecodeMarketingTile = useCallback(
    (tile: IMarketingTile): boolean => {
      const hasBluecodeRule = tile.ruleSet?.bkSpecificTargeting;

      if (!hasBluecodeRule) {
        return false;
      }

      const bluecodeRule = tile.ruleSet?.BKPayAudience;

      const hideBluecodeBannerAccountLinked = bluecodeRule === 'linked' && !userHasBluecodeAccount;
      const hideBluecodeBannerAccountNotLinked =
        bluecodeRule === 'unlinked' && userHasBluecodeAccount;

      if (hideBluecodeBannerAccountLinked || hideBluecodeBannerAccountNotLinked) {
        return true;
      }

      return false;
    },
    [userHasBluecodeAccount]
  );

  const onShowTermsModal: OnShowModalType = data => {
    openTermsModal(data);
    setPendingData(data);
  };

  // TODO(MarketingTiles) Remove Marketing Tiles after migration
  let itemTiles: IMarketingTileGroup['tiles'] = null;
  if ('tiles' in item) {
    itemTiles = item?.tiles;
  }
  let cardsContent;
  if ('Cards' in item) {
    cardsContent = <MarketingCardGroup onShowTermsModal={onShowTermsModal} doc={item} />;
  }

  if (itemTiles || cardsContent) {
    return (
      <div className={className}>
        <TermsModal
          heading={pendingData.heading}
          body={
            <SanityBlockRenderer content={pendingData?.content} serializers={linkSerializers} />
          }
        />
        {/* Marketing Card */}
        {cardsContent}
        {itemTiles?.map((tile, idx) => {
          if (!tile) {
            return null;
          }
          switch (tile.__typename) {
            case MarketingTileGroupType.MARKETING_TILE: {
              const key = `MarketingTile_${tile._id}`;
              // Always half size on mobile
              const size = isMobile ? TileSize.HALF : TileSize.FULL;
              const buttonPosition = getAlignment(
                (tile?.buttonAlignment as TAlignmentValue) || 'left'
              );

              if (hideBluecodeMarketingTile(tile)) {
                return null;
              }

              return (
                <Section key={key}>
                  <Tile
                    buttonPosition={buttonPosition}
                    size={size}
                    onShowTermsModal={onShowTermsModal}
                    tile={tile as IMarketingTileFragment}
                  />
                </Section>
              );
            }
            case MarketingTileGroupType.MARKETING_TILE_BASIC: {
              const basicTile = tile as IMarketingTileBasicFragment;
              return (
                <StyledBasicTile
                  key={`MarketingTileBasic_${basicTile._id}`}
                  description={basicTile.marketingBasicTileDescription?.localeRaw}
                  image={basicTile.marketingBasicTileImage?.locale?.app as ISanityImage}
                  title={basicTile.marketingBasicTileTitle?.locale || ''}
                />
              );
            }
            case MarketingTileGroupType.MARKETING_TILE_PAIR: {
              const leftKey = `MarketingTile_left_${tile.left?._id || idx}`;
              const rightKey = `MarketingTile_right_${tile.right?._id || idx}`;
              const key = `MarketingTilePair_${leftKey}_${rightKey}`;
              // Always half size on when it's a pair
              const halfSize = TileSize.HALF;
              const leftButtonPosition = getAlignment(
                (tile.left?.buttonAlignment as TAlignmentValue) || 'left'
              );
              const rightButtonPosition = getAlignment(
                (tile.right?.buttonAlignment as TAlignmentValue) || 'left'
              );
              const left = tile.left && (
                <Tile
                  buttonPosition={leftButtonPosition}
                  onShowTermsModal={onShowTermsModal}
                  size={halfSize}
                  tile={tile.left as IMarketingTileFragment}
                />
              );
              const right = tile.right && (
                <Tile
                  buttonPosition={rightButtonPosition}
                  onShowTermsModal={onShowTermsModal}
                  size={halfSize}
                  tile={tile.right as IMarketingTileFragment}
                />
              );
              // On mobile the tiles should always be stacked
              if (isMobile) {
                return (
                  <>
                    <Section key={leftKey}>{left}</Section>
                    <Section key={rightKey}>{right}</Section>
                  </>
                );
              }
              // On desktop they are side by side
              return (
                <Section key={key}>
                  <LeftContainer>{left}</LeftContainer>
                  <RightContainer>{right}</RightContainer>
                </Section>
              );
            }
            case MarketingTileGroupType.MARKETING_TILE_QUAD: {
              const basicTiles = (Object.keys(tile) as (keyof RemoveKeys<typeof tile>)[])?.map(
                key => {
                  const basicTile = tile[key] as FixLocaleTypes<(typeof tile)[typeof key]>;
                  return (
                    // @ts-ignore
                    key !== '__typename' &&
                    basicTile && (
                      <MarketingBasicTile
                        key={key}
                        description={basicTile.marketingBasicTileDescription?.localeRaw}
                        image={basicTile.marketingBasicTileImage?.locale?.app as ISanityImage}
                        title={basicTile.marketingBasicTileTitle?.locale || ''}
                      />
                    )
                  );
                }
              );
              return basicTiles?.length ? (
                <QuadTileContainer>{basicTiles}</QuadTileContainer>
              ) : null;
            }
            case MarketingTileGroupType.MARKETING_TILE_LIST: {
              return (
                <MarketingTileList
                  key={`MarketingTileQuad_TileList_${tile._key}`}
                  tiles={tile?.tiles || []}
                />
              );
            }

            default:
              return null;
          }
        })}
      </div>
    );
  }
  return null;
};
