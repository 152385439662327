import {
  EventHandler,
  InvalidEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';

type InvalidEventHandler<T> = EventHandler<InvalidEvent<T>>;

/**
 * Detect the validity of an input or select as determined by native APIs
 *
 * "Native APIs" include things such as the `required` or `pattern` attributes
 * or formats enforced by input[type=email], etc.
 */
export const useNativeInvalidDetector = <
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement = HTMLInputElement,
>(
  localRef: MutableRefObject<T | null>,
  onInvalid?: InvalidEventHandler<T>,
) => {
  const [isNativeInvalid, setIsNativeInvalid] = useState(false);

  // Resets validity back to correct value after renders (most importantly,
  // those triggered by input events that reset the validation state)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isNativeInvalid && localRef.current && localRef.current.validity.valid) {
      setIsNativeInvalid(false);
    }

    // ** Why no dependency array on the useEffect? **
    // The linter would prefer I have a dependency array of `[isNativeInvalid,
    // localRef]`, which would limit the frequency with which the effect would be
    // called, causing it to not work properly. I want it to run on every render
    // so it can detect the validity of the input returning to "valid" (HTML only
    // specifies an `onInvalid` event, with no corresponding `onValid` event, so
    // this approach was the most reliable to detect it becoming valid again).

    // I could add extra values to the dependency array, like the value of the field
    // or its validation-related props, but ultimately, the only purpose of a
    // dependency array here would be [to
    // optimize](https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects),
    // and we do not need the optimization. The value of
    // `localRef.current.validity.valid` is not going to change often enough to
    // present the risk of a performance problem.
  });

  const handleInvalid = useCallback<InvalidEventHandler<T>>(
    (event) => {
      setIsNativeInvalid(true);

      if (typeof onInvalid === 'function') {
        onInvalid(event);
      }
    },
    [onInvalid],
  );

  return { handleInvalid, isNativeInvalid };
};
