import React from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import { ExcludeNil } from '@rbi-ctg/frontend';
import { INavigationTabFragment, INavigationTabsFragment } from 'generated/sanity-graphql';
import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';
import { relativeRoutes, routes } from 'utils/routing';

import { SanityNavMobileTab } from './sanity-nav-mobile-tab';
import { NavigationMobileTab } from './types';

export const checkFlagEnabled = (
  flags: LaunchDarklyFlagsObject,
  flagKey: Maybe<string>
): boolean => {
  return Boolean(flagKey && flags?.[flagKey as keyof LaunchDarklyFlagsObject]);
};

const alternateTabIsValid = (
  altMobileTab: INavigationTabFragment['launchDarklyAlternateMobileTab']
) => {
  return Boolean(
    altMobileTab?.enabled &&
      altMobileTab?.tabIcon?.locale &&
      altMobileTab?.tabIconActive?.locale &&
      altMobileTab?.tabLink?.link &&
      altMobileTab?.tabIconActive?.locale
  );
};

export const generateNavigationMobileTabs = (
  sanityTabs: ExcludeNil<INavigationTabsFragment['navigationTabs']>,
  LDFlags: LaunchDarklyFlagsObject,
  isLocationInRecentItemsRoute: boolean = false
): NavigationMobileTab[] => {
  const isRecentItemsLinkInSanityTabs = sanityTabs.some(
    tab => tab?.tabLink?.link?.locale === `${routes.menu}/${relativeRoutes.recentItems}`
  );
  return sanityTabs.reduce<NavigationMobileTab[]>((tabs, sanityNavMobileTab) => {
    const hideNavLinkLdFlag = sanityNavMobileTab?.hideLinkBasedOnLdFlag?.launchDarklyFlag;
    const shouldHideNavLink = checkFlagEnabled(LDFlags, hideNavLinkLdFlag);

    if (sanityNavMobileTab && !shouldHideNavLink) {
      const altSanityNavMobileTab = sanityNavMobileTab?.launchDarklyAlternateMobileTab;
      const LDFlagDependency = altSanityNavMobileTab?.launchDarklyFlagDependency?.launchDarklyFlag;
      const shouldShowAlternateLdNavLink =
        checkFlagEnabled(LDFlags, LDFlagDependency) && alternateTabIsValid(altSanityNavMobileTab);

      const alternateTabContents =
        shouldShowAlternateLdNavLink && altSanityNavMobileTab
          ? altSanityNavMobileTab
          : sanityNavMobileTab;

      const tabLink = alternateTabContents?.tabLink?.link?.locale || '';
      const forceExactMatch =
        tabLink === routes.menu && isLocationInRecentItemsRoute && isRecentItemsLinkInSanityTabs;
      tabs.push({
        selectedHrefs: [tabLink],
        Component: () => (
          <SanityNavMobileTab {...alternateTabContents} forceExactMatch={forceExactMatch} />
        ),
      });
    }
    return tabs;
  }, []);
};
