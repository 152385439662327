import { ActionButtonVariants } from 'components/action-button';

export const themeBase = {
  textTransform: Styles.textTransform.headlines,
  curbside: {
    height: '2.15rem',
    width: '2.55rem',
  },
  delivery: {
    icon: 'delivery',
    height: '1.75rem',
    width: '1.75rem',
  },
  pickup: {
    icon: 'restaurant',
    height: '1.75rem',
    width: '1.75rem',
  },
  iconContainer: {
    color: 'icon-default',
  },
  text: {
    color: 'text-link-default',
  },
  bottomServiceWrapper: {
    color: 'text-link-default',
  },
  underline: {
    color: 'text-link-default',
  },
  badge: {
    color: Styles.color.black,
  },
  buttonIcon: 'cart',
  defaultButtonVariant: ActionButtonVariants.PRIMARY,
  inProgressVariant: ActionButtonVariants.INVERSE,
} as const;
