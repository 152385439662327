import { IDeliveryAddress } from 'generated/rbi-graphql';
import { ICommitOrderOptions } from 'hooks/commit-order';
import { ICommitOrderMutationDeliveryInput } from 'remote/queries/order';
import { UserDetails } from 'state/auth/hooks/types';
import { ServiceMode } from 'state/order';
import { logger } from 'utils/logger';

export const buildCommitDeliveryInput = (
  order: ICommitOrderOptions['order'],
  user: UserDetails | null
): ICommitOrderMutationDeliveryInput | null => {
  if (order.serviceMode !== ServiceMode.DELIVERY) {
    return null;
  }

  const deliveryAddress = order.deliveryAddress as IDeliveryAddress | undefined;

  if (deliveryAddress && (!deliveryAddress.latitude || !deliveryAddress.longitude)) {
    logger.error({
      message: 'missing delivery address lat/lng in buildCommitDeliveryInput',
      order,
      bypassSampling: true,
    });
  }

  return {
    selectedPreOrderTimeSlot: order.selectedPreOrderTimeSlot,
    instructions: order.deliveryInstructions,
    tipCents: order.tipAmount,
    pushNotification: true,
    dropoff: {
      ...order.deliveryAddress,
      phoneNumber: order.orderPhoneNumber || user?.details?.phoneNumber || '',
    },
  };
};
