// Onetrust cookie categories and consent groups are standard in onetrust to to the following:
// C0001 - Strictly Necessary
// C0002 - Performance Cookies
// C0003 - Functional Cookies
// C0004 - Targeting Cookies
// C0005 - Social Media Cookies

export type ConsentGroups = Record<OneTrustConsentCategories, number>;

export enum OneTrustGlobalConsentState {
  OPT_OUT = 'Opt-Out',
  OPT_IN = 'Opt-In',
}

export enum OneTrustConsentCategories {
  C0001 = 'C0001',
  C0002 = 'C0002',
  C0003 = 'C0003',
  C0004 = 'C0004',
}

export enum ConsentAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export type ConsentGroupCategory =
  | 'strictly_necessary'
  | 'performance'
  | 'functional'
  | 'targeting';
