// NOTE: The following components are used by both TextArea and TextInput. If
// modifying only one of the fields, make sure the other is not broken.

import styled, { css } from 'styled-components';

import { autofillDetectableStyle } from '../../hooks';
import { calcCSSUnitSides } from '../../utils';

import { TextFieldState } from './types';

export const TextFieldWrapper = styled.div`
  /* Prevents the label and shadow from overlapping with elements above */
  margin-block-start: 8px;

  margin-block-end: 24px;
  width: 100%;
  max-width: 100%;

  --height-minus-border: calc(
    ${(p) => p.theme.token('height-input')} -
      ${(p) => calcCSSUnitSides(p.theme.token('border-width-input')).topAndBottom}
  );
  --padding-right-input: ${(p) => calcCSSUnitSides(p.theme.token('padding-input')).right};
  --width-icon: ${(p) => p.theme.token('width-icon')};
`;

export const TextFieldInner = styled.div<TextFieldState>`
  position: relative;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  border-radius: ${(p) => p.theme.token('border-radius-input')};
  border-color: ${(p) => p.theme.token('border-color-input')};
  border-style: solid;
  border-width: ${(p) => p.theme.token('border-width-input')};
  transition: border 300ms;

  ${(p) =>
    !p.$disabled &&
    !p.$hasError &&
    css`
      &:hover {
        border-color: ${p.theme.token('border-color-hover')};
      }
    `}
  &:focus-within {
    box-shadow: ${(p) => p.theme.token('box-shadow-pattern')};
  }

  ${(p) =>
    p.$disabled &&
    css`
      border-color: ${p.theme.token('border-color-disabled')};
    `}

  ${(p) =>
    p.$hasError &&
    css`
      border-color: ${p.theme.token('border-color-error')};
    `}
`;

const iconMargin = '12px';
const getInputRightPadding = (iconCount: number | undefined = 0) =>
  iconCount === 0
    ? 'var(--padding-right-input)'
    : `calc(${iconMargin} + (${iconMargin} + var(--width-icon)) * ${iconCount})`;

const labelLeftOffset = '12px';

export const TextFieldLabel = styled.label<TextFieldState>`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: ${labelLeftOffset};
  font: ${(p) => p.theme.token('text-style-form-label-default')};
  line-height: var(--height-minus-border);
  background: transparent;
  border-radius: 4px;
  padding: 0 4px;
  transition: top 300ms, font 300ms, background 300ms, color 300ms, max-width 300ms;
  color: ${({ $hasError, $hasValue, $focused, theme }) => {
    if ($hasError) {
      return theme.token('text-error');
    }
    if ($hasValue || $focused) {
      return theme.token('text-default');
    }
    return theme.token('text-placeholder');
  }};
  pointer-events: none;
  user-select: none;

  /* Account for the input width and the presence of icons to avoid long label
    text going outside of the input or overlapping with icons  */
  max-width: calc(100% - ${labelLeftOffset} - ${(p) => getInputRightPadding(p.$iconCount)});
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p) =>
    p.$disabled &&
    css`
      color: ${p.theme.token('text-disabled')};
      cursor: not-allowed;
    `}

  ${(p) =>
    (p.$focused || p.$autofilled || p.$isDate || p.$hasValue) &&
    css`
      background: #fff;
      font: ${p.theme.token('text-style-form-label-value')};
      inset-block-start: -10px;
      padding: 4px;
      /* No longer need to account for overlapping with icons when the label appears at
              the top */
      max-width: calc(100% - 12px - 12px);

      ${p.$hasError &&
      css`
        color: ${p.theme.token('text-error')};
      `}
    `}

  ${(p) =>
    p.$autofilled &&
    css`
      /* Fixes an issue in Firefox where autofilled fields overlap the label */
      z-index: 1;
    `}
`;

export const StyledTextField = styled.input<TextFieldState & { $iconCount: number }>`
  display: block;
  padding: ${(p) => p.theme.token('padding-input')};
  margin: 0;
  font: ${(p) => p.theme.token('text-style-form-input')};
  height: var(--height-minus-border);
  line-height: var(--height-minus-border);
  border: none;
  border-radius: ${(p) => p.theme.token('border-radius-input')};
  background: ${(p) => p.theme.token('background-input')};
  color: ${(p) => p.theme.token('text-default')};
  appearance: none;
  width: 100%;

  ${(p) =>
    p.$iconCount > 0 &&
    css`
      padding-inline-end: ${getInputRightPadding(p.$iconCount)};

      /* Hides native password reveal button on input[type=password] fields in
              Edge, but only when we've replaced it with our own icon */

      &::-ms-reveal {
        display: none;
      }
    `}

  ${(p) =>
    p.$disabled &&
    css`
      color: ${p.theme.token('text-disabled')};
      cursor: not-allowed;
    `}

  ${autofillDetectableStyle};

  &:invalid {
    /* Reset browser-native styling for invalid fields */
    box-shadow: none;
  }

  /* Hides native clear button on input[type=search] fields */

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }
`;

export const IconStyles = styled.div`
  position: absolute;
  line-height: 0;
  inset-block-start: calc(
    ${iconMargin} - ${(p) => calcCSSUnitSides(p.theme.token('border-width-input')).top}
  );
  inset-inline-end: ${iconMargin};
  display: flex;

  /* Disable pointer events on this presentation-only container, and reenable
    them for the children */
  pointer-events: none;

  & > * {
    pointer-events: auto;
    margin-inline-start: ${iconMargin};
  }

  svg {
    height: ${(p) => p.theme.token('height-icon')};
    width: ${(p) => p.theme.token('width-icon')};
  }
`;
