import { LocalStorage, StorageKeys } from 'utils/local-storage';

type FlagOverrides = Record<string, boolean>;

export const getOverrides = (): FlagOverrides =>
  LocalStorage.getItem(StorageKeys.FEATURE_FLAG_OVERRIDES) ?? {};

export const saveOverrides = (flagOverrides: FlagOverrides) => {
  LocalStorage.setItem(StorageKeys.FEATURE_FLAG_OVERRIDES, flagOverrides);
};

export const removeOverrides = () => {
  LocalStorage.removeItem(StorageKeys.FEATURE_FLAG_OVERRIDES);
};
