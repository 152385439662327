import { AnimationEventHandler, useCallback, useState } from 'react';

import { css, keyframes } from 'styled-components';

// Empty animation keyframes used to detect change in autofill state.
// Their values need to be different, or styled-components gives them
// the same class name.
const onAutoFillStart = keyframes`from {/**/} to {/**/}`;
const onAutoFillCancel = keyframes`from {/* end */} to {/**/}`;

/**
 * Required for use of useAutofillDetector
 */
export const autofillDetectableStyle = css`
  /* Webkit browsers */
  &:-webkit-autofill {
    animation: ${onAutoFillStart} 50000s infinite;
  }
  &:not(:-webkit-autofill) {
    animation: ${onAutoFillCancel} 50000s infinite;
  }
  /* Firefox */
  &:autofill {
    animation: ${onAutoFillStart} 50000s infinite;
  }
  &:not(:autofill) {
    animation: ${onAutoFillCancel} 50000s infinite;
  }
`;

/**
 * Use animation to detect autofill start and stop
 *
 * Requires autofillDetectableStyle on the style of the element in question
 * @link https://stackoverflow.com/a/41530164
 */
export const useAutofillDetector = (onAnimationStart?: AnimationEventHandler) => {
  const [isAutofilled, setIsAutofilled] = useState(false);

  const handleAnimationStart = useCallback<AnimationEventHandler>(
    (event) => {
      if (event.animationName === onAutoFillStart.getName() && !isAutofilled) {
        setIsAutofilled(true);
      }
      if (event.animationName === onAutoFillCancel.getName() && isAutofilled) {
        setIsAutofilled(false);
      }

      if (typeof onAnimationStart === 'function') {
        onAnimationStart(event);
      }
    },
    [isAutofilled, onAnimationStart],
  );

  return { handleAnimationStart, isAutofilled };
};
