// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { CurrencyCode } from '@rbilabs/intl';

import { CdpAdapterBase } from '../cdp-adapter-base';
import { EventTypes, ProductActionTypes } from '../constants';
import {
  ICdpAdapter,
  ICdpCallback,
  ICdpIdentityAdapter,
  ICdpIdentityApiData,
  ICdpProduct,
} from '../types';

class MParticleIdentityAdapterWeb implements ICdpIdentityAdapter {
  static modify(identityApiData: ICdpIdentityApiData, callback: ICdpCallback) {
    return window.mParticle.Identity.modify(identityApiData, callback);
  }
}

class MParticleAdapterWeb extends CdpAdapterBase implements ICdpAdapter {
  createProduct(name: string, sku: string, price: number, quantity: number) {
    return window.mParticle.eCommerce.createProduct(name, sku, price, quantity);
  }

  isInitialize() {
    return window?.mParticle.isInitialized() ?? false;
  }

  logEvent(
    name: string,
    eventTypes: EventTypes,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    if (this.skipEvent(name, attributes, customFlags)) {
      return;
    }
    this.updatePrevEvent(name, attributes, customFlags);

    if (customFlags) {
      window.mParticle.logEvent(name, eventTypes, attributes, customFlags);
    } else {
      window.mParticle.logEvent(name, eventTypes, attributes);
    }
  }

  logProductAction(
    type: ProductActionTypes,
    products: ICdpProduct[],
    attrs?: object | null,
    flags?: object | null,
    transactionalAttrs?: object,
    currencyCode?: string
  ) {
    window.mParticle.eCommerce.setCurrencyCode(currencyCode || CurrencyCode.USD);
    window.mParticle.eCommerce.logProductAction(type, products, attrs, flags, transactionalAttrs);
  }

  getDeviceId() {
    return window.mParticle?.getDeviceId?.();
  }

  getSession(): Promise<{ sessionId: string }> {
    const sessionId = window.mParticle.sessionManager.getSession();
    return Promise.resolve({ sessionId });
  }

  logout() {
    return window?.mParticle?.Identity?.logout();
  }

  setOptOut(optOut: boolean) {
    window.mParticle.setOptOut(optOut);
  }

  Identity = MParticleIdentityAdapterWeb;
}

const MParticleAdapter = new MParticleAdapterWeb();

export { MParticleAdapter };
