import { datadogRum } from '@datadog/browser-rum';

import { useBranchContext } from 'state/branch';
import { useCdpContext } from 'state/cdp';
import { CdpProviderTypes } from 'state/cdp/types';
import * as datadog from 'utils/datadog';
import { LaunchDarklyFlag, LaunchDarklyHelper, getExtraContext } from 'utils/launchdarkly';

const config: Partial<Record<CdpProviderTypes, string>> = {
  [CdpProviderTypes.MParticle]: 'mParticle',
  [CdpProviderTypes.Amplitude]: 'amplitude',
};

export const useInitializeThirdPartyServices = () => {
  const { init } = useCdpContext();
  const { initBranch } = useBranchContext();

  const initServicesWithCookies = () => {
    const allFlags = LaunchDarklyHelper.getInstance().launchDarkly.allFlags();
    const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);
    if (!datadogRum.getInitConfiguration()) {
      const sampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE] || 0;
      const replaySampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_REPLAY_SAMPLE_RATE] || 0;
      datadog.initRum(getExtraContext(), sampleRate, replaySampleRate);
    }
    const cdpProvider = flattenedFlags[LaunchDarklyFlag.CDP_PROVIDER] as CdpProviderTypes;
    const provider = config[cdpProvider] ?? config[CdpProviderTypes.MParticle];

    if (!window?.[provider as any]) {
      init();
    }

    if (!window.branch) {
      initBranch();
    }
  };

  return { initServicesWithCookies };
};
