import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash';
import { useIntl } from 'react-intl';

import { IBaseProps, Nullable, SetState } from '@rbi-ctg/frontend';
import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { usePrevious } from 'hooks/use-previous';
import { useCdpContext } from 'state/cdp';
import { serializePickupMode, serializeServiceMode } from 'state/cdp/utils';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useUIContext } from 'state/ui';
import { LocalStorage, StorageKeys } from 'utils/local-storage';

import { ServiceMode, ServiceModeStatus } from './types';

export { ServiceMode };
export type { ServiceModeStatus };

export const findServiceModeInUrl = (search: string): ServiceMode | null => {
  const urlParams = new URLSearchParams(search);
  const serviceMode = urlParams.get('service-mode') as keyof typeof ServiceMode;
  return (serviceMode && ServiceMode[serviceMode]) ?? null;
};

export const findTableIdInUrl = (search: string): string | null => {
  const urlParams = new URLSearchParams(search);
  return urlParams.get('table-id');
};

const preloadedSvcMode = (search: string): ServiceMode | null => {
  const serviceMode = findServiceModeInUrl(search);
  if (serviceMode) {
    return serviceMode;
  }
  const preloaded = LocalStorage.getItem(StorageKeys.SERVICE_MODE);
  return preloaded ? preloaded.serviceMode : null;
};

const persistServiceMode = (serviceMode: Nullable<ServiceMode>) => {
  LocalStorage.setItem(StorageKeys.SERVICE_MODE, { serviceMode });
};

export interface IServiceModeCtx {
  serviceMode: Nullable<ServiceMode>;
  setServiceMode: SetState<ServiceMode | null>;
  setDeliverySurchargeFee: SetState<number | null>;
  isDelivery: boolean;
  isTakeout: boolean;
  isEatIn: boolean;
  isCurbside: boolean;
  isTableService: boolean;
}

export const ServiceModeContext = createContext<IServiceModeCtx>({
  serviceMode: null,
  setServiceMode: noop,
  setDeliverySurchargeFee: noop,
  isDelivery: false,
  isTakeout: false,
  isEatIn: false,
  isCurbside: false,
  isTableService: false,
});

export const useServiceModeContext = () => useContext(ServiceModeContext);

export const ServiceModeProvider = ({ children }: IBaseProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const { location } = useLocationContext();

  const preloaded = useMemo(() => preloadedSvcMode(location?.search ?? ''), [location]);
  const [serviceMode, setServiceMode] = useState(preloaded);
  const previousServiceMode = usePrevious(serviceMode);
  const enableDeliveryOnly = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY_ONLY);
  const enableDeliveryFeeSurchargeModal = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_INCREASED_DELIVERY_FEES_MODAL)
  );

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const isTakeout = serviceMode === ServiceMode.TAKEOUT;
  const isEatIn = serviceMode === ServiceMode.EAT_IN;
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;
  const isTableService = serviceMode === ServiceMode.TABLE_SERVICE;
  const { updateServiceMode } = useLDContext();
  const { updateUniversalAttributes, updateUserAttributes } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();

  const serviceModeInUrl = findServiceModeInUrl(location?.search ?? '');
  const tableIdInUrl = findTableIdInUrl(location?.search ?? '');

  const [deliverySurchargeFee, setDeliverySurchargeFee, clearDeliverySurchargeFee] =
    useLocalStorageState<number | null>({
      key: StorageKeys.DELIVERY_SURCHARGE_FEE,
      defaultReturnValue: null,
    });

  const acceptDeliverySurchargeFee = useCallback(() => {
    clearDeliverySurchargeFee();
  }, [clearDeliverySurchargeFee]);

  const [DeliveryFeeSurchargeDialog, openDeliveryFeeSurcharge] = useDialogModal({
    onConfirm: acceptDeliverySurchargeFee,
    allowDismiss: false,
    modalAppearanceEventMessage: 'Delivery Surcharge',
  });
  const showDeliveryFeeSurchargeModal = enableDeliveryFeeSurchargeModal && !!deliverySurchargeFee;

  // the delivery fee surcharge modal is displayed when the url has changed
  // because after the service mode selection happens the user is redirected
  useEffectOnUrlChange(() => {
    if (showDeliveryFeeSurchargeModal) {
      openDeliveryFeeSurcharge();
    }
  });

  useEffect(() => {
    persistServiceMode(serviceMode);
    if (previousServiceMode !== serviceMode) {
      // Updates service/pickup modes for all mParticle events/page views
      updateUniversalAttributes({
        'Service Mode': serializeServiceMode(serviceMode),
        'Pickup Mode': serializePickupMode(serviceMode),
      });
      updateUserAttributes(
        {
          'Pickup Mode': serializePickupMode(serviceMode),
          'Service Mode': serializeServiceMode(serviceMode),
        },
        {},
        sendUpdateUserAttributesEvent
      );
    }
    // update LaunchDarkly
    updateServiceMode(serviceMode);
  }, [serviceMode, previousServiceMode, updateServiceMode, updateUniversalAttributes]);

  useEffect(() => {
    // If the service mode isn't delivery and the only service mode available is delivery, wipe out the current service mode
    if (enableDeliveryOnly && !isDelivery) {
      setServiceMode(null);
    }
  }, [enableDeliveryOnly, isDelivery, serviceMode]); // Needed to be checked every time serviceMode is being changed

  useEffect(() => {
    if (serviceModeInUrl && serviceModeInUrl !== serviceMode) {
      setServiceMode(serviceModeInUrl);
    }
  }, [serviceMode, serviceModeInUrl]);

  useEffect(() => {
    if (isTableService && tableIdInUrl) {
      LocalStorage.setItem(StorageKeys.TABLE_NUMBER, tableIdInUrl);
    }
  }, [isTableService, tableIdInUrl]);

  return (
    <ServiceModeContext.Provider
      value={{
        serviceMode,
        setServiceMode,
        setDeliverySurchargeFee,
        isDelivery,
        isTakeout,
        isEatIn,
        isCurbside,
        isTableService,
      }}
    >
      {enableDeliveryFeeSurchargeModal && deliverySurchargeFee && (
        <DeliveryFeeSurchargeDialog
          heading={formatMessage({ id: 'deliverySurcharge' })}
          body={formatMessage(
            { id: 'deliverySurchargeMessage' },
            {
              feeSurcharge: currencyFormatter(deliverySurchargeFee),
            }
          )}
        />
      )}

      {children}
    </ServiceModeContext.Provider>
  );
};
