import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

import { theme } from './theme';

const MutedText = styled.div`
  text-align: center;
  color: ${theme.mutedTextColor};
  font-size: 0.8rem;
`;

export const MinimumMessage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const { isDelivery, checkoutDeliveryPriceMinimum } = useOrderContext();

  const isDeliveryMinEnabled = useMemo(
    () => checkoutDeliveryPriceMinimum > 0,
    [checkoutDeliveryPriceMinimum]
  );

  if (isDelivery && isDeliveryMinEnabled) {
    return (
      <MutedText>
        {formatMessage(
          { id: 'orderMinimumWarningX' },
          {
            min: formatCurrencyForLocale(checkoutDeliveryPriceMinimum),
          }
        )}
      </MutedText>
    );
  }
  return null;
};
