import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { TLocalizationKey } from 'types/i18n';

import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconSnapchat,
  IconTiktok,
  IconTwitter,
  IconYoutube,
} from './icons';
import { SocialIcon } from './social-icon';
import { SocialIconsContainer } from './styled';
import { ISocialCompProps, TSocialIconUrlKeys, TSocialIconsProps } from './types';

const socialComponents: Record<TSocialIconUrlKeys, React.ElementType> = {
  facebookUrl: IconFacebook,
  instagramUrl: IconInstagram,
  tiktokUrl: IconTiktok,
  twitterUrl: IconTwitter,
  youtubeUrl: IconYoutube,
  linkedinUrl: IconLinkedin,
  snapchatUrl: IconSnapchat,
};

const socialIds: Record<TSocialIconUrlKeys, TLocalizationKey> = {
  facebookUrl: 'followUsOnFacebook',
  instagramUrl: 'followUsOnInstagram',
  tiktokUrl: 'followUsOnTiktok',
  twitterUrl: 'followUsOnTwitter',
  youtubeUrl: 'followUsOnYoutube',
  linkedinUrl: 'followUsOnLinkedIn',
  snapchatUrl: 'followUsOnSnapchat',
};

export const SocialComp: React.FC<ISocialCompProps> = props => {
  const { formatMessage } = useIntl();
  const SocialComponent = socialComponents[props.item];
  const SocialId = socialIds[props.item];
  return (
    <SocialIcon href={props.href}>
      <VisuallyHidden>{formatMessage({ id: SocialId })}</VisuallyHidden>
      <SocialComponent width={25} aria-hidden="true" />
    </SocialIcon>
  );
};
export const SocialIcons: React.FC<TSocialIconsProps> = props => {
  return (
    <SocialIconsContainer data-testid="social-icons">
      {(Object.keys(props) as TSocialIconUrlKeys[]).map((item, i) => {
        if (!props[item] || props[item] === '') {
          return null;
        }
        return <SocialComp key={`social_${i}`} item={item} href={props[item]} />;
      })}
    </SocialIconsContainer>
  );
};
