import { useEffect, useRef } from 'react';

import { useCdpContext } from 'state/cdp';
import { logFilteredLDFlags } from 'state/cdp/utils';
import { useLDContext } from 'state/launchdarkly';

import { useAbTestFlags } from './use-get-ab-test-flags';

export const useEvaluateLdFlags = () => {
  const logFlagsEvaluatedEvent = useRef(false);
  const prevFlags = useRef({});
  const { logEvent, providerName } = useCdpContext();
  const { flags: abTestFlags, loading: loadingAbTestFlags } = useAbTestFlags();
  const { flags: rawLdFlags, ldUser } = useLDContext();

  // logs FLAGS_EVALUATED event
  useEffect(() => {
    if (!loadingAbTestFlags && abTestFlags) {
      logFilteredLDFlags(
        providerName,
        prevFlags,
        logFlagsEvaluatedEvent,
        logEvent,
        abTestFlags,
        ldUser
      );
    }
  }, [loadingAbTestFlags, abTestFlags, rawLdFlags, providerName, logEvent, ldUser]);
};
