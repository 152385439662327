import { isBefore, startOfDay } from 'date-fns';
import isString from 'lodash/isString';

import { MenuObjectTypes } from 'enums/menu';
import { IOffersFragment } from 'generated/graphql-gateway';
import { IOfferDiscount } from 'generated/sanity-graphql';
import { RBIEnv, env } from 'utils/environment';

import { LoyaltyAppliedOffer, LoyaltyOffer, RuleType } from './types';

type OffersMap = Record<string, LoyaltyOffer>;

export const isDiscountLoyaltyOffer = (offer: LoyaltyOffer) =>
  offer?.incentives?.every(benefit => benefit?._type === MenuObjectTypes.OFFER_DISCOUNT);

export const isDiscountOffer = (offer: LoyaltyOffer) => isDiscountLoyaltyOffer(offer);

export const isCartDiscountOffer = (offer: LoyaltyOffer) => {
  const discountIncentive = offer?.incentives?.[0] as IOfferDiscount;
  return isDiscountLoyaltyOffer(offer) && !discountIncentive.discountProduct;
};

export const isSupportOffer = (offer: IOffersFragment) =>
  Boolean(
    offer?.metadata?.some(
      entry =>
        entry?.key?.toLocaleLowerCase() === 'source' &&
        isString(entry?.value) &&
        entry.value.toUpperCase() === 'SUPPORT'
    )
  );

export const isAppliedOfferSwap = (offer: LoyaltyAppliedOffer): boolean => !!offer.swap;

export const getCanStackOfferCheck =
  <T>(getOffer: (element: T) => LoyaltyOffer | LoyaltyAppliedOffer | null) =>
  (offer: LoyaltyOffer, list: T[], standardOffersLimit?: number): boolean => {
    return (
      list.length === 0 ||
      list.every((element: T) => {
        const offerItem = getOffer(element);

        // If offer explicitly set to not be stackable return
        if (offer.isStackable === false) {
          return false;
        }

        // the stackable offer check is for offers only
        return offerItem
          ? !!(offerItem as LoyaltyAppliedOffer)?.swap || standardOffersLimit! > list.length
          : true;
      })
    );
  };

export const getCmsOffersMapByLoyaltyId = (offers: LoyaltyOffer[] | null): OffersMap => {
  return (offers || []).reduce<OffersMap>((acc, offer) => {
    if (offer?.loyaltyEngineId) {
      acc[offer.loyaltyEngineId] = offer;
    }

    return acc;
  }, {});
};

export const getCmsOffersMapByCmsId = (offers: LoyaltyOffer[] | null): OffersMap => {
  return (offers || []).reduce<OffersMap>((acc, offer) => {
    if (offer?._id) {
      acc[offer._id] = offer;
    }

    return acc;
  }, {});
};

export const filterRedeemableUpsellOffers = (offer: LoyaltyOffer, userOffers: LoyaltyOffer[]) => {
  const { upsellOptions } = offer;
  const userOfferIds = userOffers.map(offer => offer.loyaltyEngineId);

  if (!upsellOptions) {
    return [];
  }

  return upsellOptions.filter(option => {
    return userOfferIds.includes(option?.loyaltyEngineId ?? '');
  }) as LoyaltyOffer[];
};

export const isUpcomingOffer = (offer: LoyaltyOffer) => {
  const isUpcomingOfferChecked = Boolean(offer.isUpcomingOffer);

  const today = Date.now();

  let startDate = '';
  (offer.rules ?? []).forEach(rule => {
    if (rule?.__typename === RuleType.LoyaltyBetweenDates) {
      startDate = rule.startDate ?? '';
    }
  });

  // The offer is still upcoming if the startDate of the "Loyalty Between-Dates" rule
  // is not yet reached
  const isStillUpcomingOffer = isBefore(startOfDay(today), startOfDay(new Date(startDate)));

  return isUpcomingOfferChecked && isStillUpcomingOffer;
};

export const isAppliedAtCheckout = (appliedOffer: LoyaltyAppliedOffer[]) =>
  appliedOffer.some(offer => offer.isAppliedAtCheckout);

export const isTestOnly = (incentive: any) => !!incentive?.testOnly && env() === RBIEnv.PROD;
