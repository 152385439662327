import { IntlShape } from 'react-intl';

import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';

export const SERVICE_MODES = [
  ServiceMode.DELIVERY,
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
  ServiceMode.TABLE_SERVICE,
];

export const PICKUP_SERVICE_MODES = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.EAT_IN,
  ServiceMode.TAKEOUT,
  ServiceMode.CURBSIDE,
];

export const isDelivery = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode === ServiceMode.DELIVERY;

export const isPickup = (serviceMode: ServiceMode | null): serviceMode is ServiceMode.DELIVERY =>
  serviceMode ? PICKUP_SERVICE_MODES.includes(serviceMode) : false;

export const getLocalizedServiceMode = (
  serviceMode: ServiceMode,
  formatMessage: IntlShape['formatMessage']
): string => {
  switch (serviceMode) {
    case ServiceMode.DRIVE_THRU:
      return formatMessage({ id: 'driveThru' });
    case ServiceMode.EAT_IN:
      return formatMessage({ id: 'dineIn' });
    case ServiceMode.TAKEOUT:
      return formatMessage({ id: 'takeOut' });
    case ServiceMode.CURBSIDE:
      return formatMessage({ id: 'curbside' });
    case ServiceMode.DELIVERY:
      return formatMessage({ id: 'delivery' });
    case ServiceMode.TABLE_SERVICE:
      return formatMessage({ id: 'tableService' });
    default:
      return '';
  }
};

export const isServiceModeAvailable =
  (serviceModeStatus: ServiceModeStatus) =>
  (serviceMode: ServiceMode): boolean => {
    const status = serviceModeStatus[serviceMode];

    if (!status) {
      return false;
    }

    return status.available && !status.disabled;
  };
