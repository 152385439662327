import { Icon } from '@rbilabs/components-library';
import styled from 'styled-components';

import { Position } from './use-drag';

export const List = styled.div`
  /* stylelint-disable plugin/logical-properties */
  width: 100%;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const FlagLabel = styled.div`
  white-space: wrap;
  flex: 1;
`;

export const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const Container = styled.div<{ position: Position }>`
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 10000000;
  ${Styles.desktop} {
    bottom: ${props => props.position.y}px;
    right: ${props => props.position.x}px;
  }
`;

export const Popover = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100vw;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  overflow: hidden;
  ${Styles.desktop} {
    width: 480px;
    height: 640px;
  }
`;

export const Header = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: move;
`;

export const Heading = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;

export const FlagButton = styled.div`
  position: fixed;
  bottom: 64px;
  padding: 10px;
  font-size: 1.5rem;
  right: 0;
  color: white;
  background-color: red;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;
`;
