import { IFeatureMenuQuery, useFeatureMenuQuery } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IUseFeatureMenuValue {
  menuId: string;
  featureMenu: IFeatureMenuQuery['FeatureMenu'];
  featureMenuLoading: boolean;
}

export const useFeatureMenu = (): IUseFeatureMenuValue => {
  const featureMenuId = useFlag(LaunchDarklyFlag.AB_TESTING_MENU_PAGE);

  const { data, loading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId,
  });

  const featureMenu = data?.FeatureMenu ?? null;
  const { defaultMenu } = featureMenu || {};
  const featureMenuLoading = loading;

  const menuId: string = defaultMenu?._id || '';

  return {
    menuId,
    featureMenu,
    featureMenuLoading,
  };
};
