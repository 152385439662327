import { useMemo } from 'react';

import { IBaseItem, ISanityItem } from '@rbi-ctg/menu';
import { ServiceMode } from 'generated/rbi-graphql';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useImagesByChannels } from 'hooks/use-images-by-channels';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';

import { existsInEntries } from './utils';

interface IUseFeatureUpsellValue {
  items: IBaseItem[];
  loading: boolean;
}

export const useMenuFeatureUpsell = (): IUseFeatureUpsellValue => {
  const { changeImageByChannel } = useImagesByChannels();
  const { serviceMode } = useServiceModeContext();
  const { filterForAvailability } = useMenuContext();
  const { cartEntries } = useOrderContext();

  const { featureMenu, featureMenuLoading } = useFeatureMenu();

  const upsellType = useMemo(() => {
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        return 'upsellItemsAtCheckoutDelivery';
      default:
        return 'upsellItemsAtCheckoutRestaurant';
    }
  }, [serviceMode]);

  const filteredItems = useMemo(() => {
    const _filtered = (featureMenu?.[upsellType] || []).filter(
      item =>
        item &&
        // Check if doesn't exists in the cart
        !existsInEntries(item as unknown as IBaseItem, cartEntries) &&
        // Check if is available
        Boolean(filterForAvailability(item as unknown as ISanityItem))
    );

    return changeImageByChannel(_filtered);
  }, [cartEntries, featureMenu, filterForAvailability, upsellType, changeImageByChannel]);

  return {
    items: filteredItems as unknown as IBaseItem[],
    loading: featureMenuLoading,
  };
};
