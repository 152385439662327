import { waitForElm } from 'utils/wait-for-element';

import { ITrackedComponent, ITrackedPage } from './types';

export * from './constants';

/**
 * This utils function is used to track how long certain elements of the page
 * are taking to load.
 *
 * The page needs to be first initialized with a unique identifier.
 * You can then track specific components on that page to see how long they take to load.
 * You can export all events tracked with the performanceReport method.
 */
const trackedPages: ITrackedPage = {};
const trackedComponents: ITrackedComponent = {};

export const initializePagePerformance = (pageName: string): void => {
  if (!trackedPages[pageName]) {
    trackedPages[pageName] = {
      startTime: performance.now(),
      endTime: null,
      events: {},
    };
  }
};

export const performanceReport = (pageName: string) => {
  const currPage = trackedPages[pageName];

  // If page is not initialized do nothing
  if (!currPage) {
    return;
  }

  return {
    [`${normalizeString(pageName)}_page_load_time`]: currPage.endTime
      ? currPage.endTime - currPage.startTime
      : undefined,
    ...currPage.events,
  };
};

export const trackComponent = async (
  pageName: string,
  componentName: string,
  componentSelector?: string,
  callback?: VoidFunction
) => {
  const currPage = trackedPages[pageName];

  // If page is not initialized do nothing
  if (!currPage) {
    return;
  }

  // If the component is already tracked don't do anything
  if (trackedComponents[pageName]?.[componentName]) {
    return;
  }

  trackedComponents[pageName] = {};
  trackedComponents[pageName][componentName] = true;

  // If selector is provided we wait until the element is visible on the page
  if (componentSelector) {
    await waitForElm(componentSelector);
  }

  requestAnimationFrame(() => {
    const loadTime = performance.now() - currPage.startTime;

    // Add the event to the list
    currPage.events[
      `${normalizeString(pageName)}_page_${normalizeString(componentName)}_load_time`
    ] = loadTime;

    if (callback) {
      callback();
    }
  });
};

export const pageLoaded = (pageName: string) => {
  const currPage = trackedPages[pageName];

  // If page is not initialized do nothing
  if (!currPage) {
    return;
  }

  currPage.endTime = performance.now();
};

export const pageUnloaded = (pageName: string) => {
  const currPage = trackedPages[pageName];

  // If page is not initialized do nothing
  if (!currPage) {
    return;
  }

  delete trackedPages[pageName];
  delete trackedComponents[pageName];
};

/**
 * Some features in Datadog, such as creating facets for measurements,
 * do not allow special characters. To enhance clarity, we will
 * substitute all non-alphabetical characters with underscores.
 */
const normalizeString = (string: string) => {
  let normalizedString: string = string.replace(/[^a-zA-Z]/g, '_');
  // Use replace method with regex to replace multiple underscores with a single underscore
  normalizedString = normalizedString.replace(/_+/g, '_');

  return normalizedString;
};
