import {
  Children,
  ComponentType,
  MouseEvent,
  cloneElement,
  forwardRef,
  isValidElement,
  useMemo,
} from 'react';

import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import {
  useAutofillDetector,
  useFocusDetector,
  useForwardedRefLocally,
  useIdCounter,
  useNativeInvalidDetector,
} from '../../hooks';
import { IAutoScroll } from '../../types/auto-scroll';
import { combineAttributeStrings, getRequiredLabel } from '../../utils/internal';
import noop from '../../utils/noop';
import { InputMessage } from '../input-message';
import {
  IconStyles,
  TextFieldInner,
  TextFieldLabel,
  TextFieldWrapper,
} from '../text-fieldish/text-fieldish.styled';
import { TextFieldState } from '../text-fieldish/types';

import { StyledTextInput } from './text-input.styled';
import { ITextInputProps } from './types';

/**
 * A component for entering simple text information such as names, addresses,
 * search queries, email addresses, passwords or urls
 */
const TextInput = forwardRef<HTMLInputElement, ITextInputProps & IAutoScroll>(
  (
    {
      // Custom props
      'data-testid': testId,
      disableScreenReaderAlert,
      errorMessage,
      footer = null,
      forceErrorState,
      hintMessage,
      icon,
      label,
      labelId,

      // Native input element props
      'aria-describedby': ariaDescribedBy,
      'aria-label': ariaLabel,
      autoComplete,
      autoFocus,
      className,
      disabled,
      id,
      inputMode,
      max,
      maxLength,
      min,
      name,
      onAnimationStart,
      onBlur,
      onChange,
      onFocus = noop,
      onInvalid,
      pattern,
      readOnly,
      required,
      style,
      type = 'text',
      value,

      // Masked input props
      mask,
      guide,
      placeholderChar,
      autoScrollIntoView = true,
      autoScrollIntoViewAlignBlock = 'nearest',
      autoScrollIntoViewAlignInline = 'nearest',
    },
    forwardedRef,
  ) => {
    const defaultInputId = useIdCounter('floating-label-input');
    const messageId = useIdCounter('message');
    const inputId = id ?? defaultInputId;

    const genId = (suffix: string) => (testId ? `${testId}-${suffix}` : undefined);
    const wrapperTestId = genId('wrapper');
    const labelTestId = genId('label');
    const messageTestId = genId('message');

    const { localRef, refForProps } = useForwardedRefLocally<HTMLInputElement>(forwardedRef);
    const { isNativeInvalid, handleInvalid } = useNativeInvalidDetector(localRef, onInvalid);
    const { isAutofilled, handleAnimationStart } = useAutofillDetector(onAnimationStart);
    const { handleBlur, handleFocus, isFocused } = useFocusDetector({
      onFocus,
      onBlur,
      autoScrollIntoView,
      autoScrollIntoViewAlignBlock,
      autoScrollIntoViewAlignInline,
    });

    const stateAttrs: { [k in keyof TextFieldState]-?: TextFieldState[k] } = {
      $disabled: disabled || !!readOnly,
      $focused: isFocused,
      $autofilled: isAutofilled,
      $hasError: forceErrorState || !!errorMessage || isNativeInvalid,
      $hasValue: !!value,
      $isDate: type === 'date',
      $iconCount: Children.count(icon),
    };

    const maskProps = useMemo(
      () =>
        mask
          ? {
              render: (internalRef: (n: HTMLInputElement) => void, props: object) => {
                // react-text-mask manages its own ref via this render method
                // in order to reference this input and allow react-text-mask
                // to reference it as well, this function assigns our own ref,
                // then passes the component along to react-text-mask's ref callback
                // because the ref being passed from parent (through forwardRef)
                // is lost during this exchange, so we assign innerInput to our ref
                const refCallback = (innerInput: HTMLInputElement) => {
                  refForProps(innerInput);
                  internalRef(innerInput);
                };

                return <input ref={refCallback} {...props} />;
              },
              guide,
              placeholderChar,
            }
          : { ref: refForProps },
      [guide, mask, placeholderChar, refForProps],
    );

    return (
      <TextFieldWrapper className={className} data-testid={wrapperTestId}>
        <TextFieldInner {...stateAttrs}>
          <TextFieldLabel {...stateAttrs} id={labelId} htmlFor={inputId} data-testid={labelTestId}>
            {getRequiredLabel(label, required)}
          </TextFieldLabel>

          <StyledTextInput
            {...stateAttrs}
            {...maskProps}
            aria-describedby={combineAttributeStrings(
              hintMessage || errorMessage ? messageId : undefined,
              ariaDescribedBy,
            )}
            aria-invalid={stateAttrs.$hasError || undefined}
            aria-label={ariaLabel}
            as={mask ? (MaskedInput as ComponentType<MaskedInputProps>) : 'input'}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            data-private
            data-testid={testId}
            disabled={disabled}
            id={inputId}
            inputMode={inputMode}
            mask={mask}
            max={max}
            maxLength={maxLength}
            min={min}
            name={name}
            onAnimationStart={handleAnimationStart}
            onBlur={handleBlur}
            onChange={onChange}
            onFocus={handleFocus}
            onInvalid={handleInvalid}
            pattern={pattern}
            readOnly={readOnly}
            required={required}
            style={style}
            type={type}
            value={value}
          />

          {icon && (
            <IconStyles
              // Prevent clicks to icons within from stealing focus from the
              // input, but still allow keyboard focus
              onMouseDownCapture={(event: MouseEvent) => event.preventDefault()}
              onMouseUpCapture={(event: MouseEvent) => {
                event.preventDefault();

                // Gives focus to the text input after clicking a button. The
                // preventDefault from the mouseDown will prevent icons from
                // stealing focus from the input, but this ensures that the text
                // input ends up with focus even if it didn't start with it
                localRef.current?.focus();
              }}
            >
              {Children.map(icon, (el) => (isValidElement(el) ? cloneElement(el, stateAttrs) : el))}
            </IconStyles>
          )}
        </TextFieldInner>

        {footer}

        {(errorMessage || hintMessage) && (
          <InputMessage
            id={messageId}
            disableScreenReaderAlert={disableScreenReaderAlert}
            data-testid={messageTestId}
            hasError={!!errorMessage}
            disabled={stateAttrs.$disabled}
          >
            {errorMessage || hintMessage}
          </InputMessage>
        )}
      </TextFieldWrapper>
    );
  },
);

export default TextInput;
