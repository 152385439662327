import { RefObject, useCallback, useRef, useState } from 'react';

export interface Position {
  x: number;
  y: number;
}

export const useDrag = (
  elementRef: RefObject<HTMLElement>,
  initialPosition: Position = { x: 20, y: 20 }
) => {
  const [position, setPosition] = useState<Position>(initialPosition);
  const draggingRef = useRef(false);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!elementRef.current) {
        return;
      }

      draggingRef.current = true;

      const element = elementRef.current;
      const elementRect = element.getBoundingClientRect();

      // Calculate initial mouse position relative to the element
      const startX = e.clientX;
      const startY = e.clientY;
      const startRight = window.innerWidth - elementRect.right;
      const startBottom = window.innerHeight - elementRect.bottom;

      const handleMouseMove = (e: MouseEvent) => {
        if (!draggingRef.current || !element) {
          return;
        }

        // Calculate the distance moved
        const deltaX = e.clientX - startX;
        const deltaY = e.clientY - startY;

        // Update position (remember we're positioning from the right/bottom)
        const newX = startRight - deltaX;
        const newY = startBottom - deltaY;

        const maxX = window.innerWidth - element.offsetWidth;
        const maxY = window.innerHeight - element.offsetHeight;

        setPosition({
          x: Math.max(0, Math.min(maxX, newX)),
          y: Math.max(0, Math.min(maxY, newY)),
        });
      };

      const handleMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        draggingRef.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [elementRef]
  );

  return { position, isDragging: draggingRef.current, handleMouseDown };
};
