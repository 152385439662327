import * as React from 'react';
import { FC, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { Currency } from 'components/currency';
import { FEE_TYPE } from 'pages/cart/your-cart/totals/constants';
import { filterFees } from 'pages/cart/your-cart/totals/utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isHistoricalMigratedOrder } from 'state/order/utils';
import { useUIContext } from 'state/ui';
import { TLocalizationKey } from 'types/i18n';
import {
  calculateFeeValue,
  computeDeliveryFee,
  computeOtherDiscountAmount,
} from 'utils/cart/helper';

import { getRewardsDiscount } from './get-rewards-discount';
import { FeeDeliveryItem, ReceiptDetail, RewardsDiscountDetail } from './receipt-details.styled';
import { IOrderFooterProps } from './types';

const geographicalFeeLabelIdMap: Record<string, TLocalizationKey> = { CO: 'geographicalFeeCO' };

export const ReceiptDetails: FC<IOrderFooterProps> = ({ order }) => {
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const { delivery } = order;
  const { subTotalCents, taxCents, totalCents } = order.cart;
  const { formatMessage } = useIntl();

  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);
  const enableHideSubTotalLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_SUBTOTAL_LINE);
  const enableDiscountsOnOrderReceipt = useFlag(LaunchDarklyFlag.ENABLE_DISCOUNTS_ON_ORDER_RECEIPT);
  const hideTipAmountCheckout = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const enableFeesUi = useFlag(LaunchDarklyFlag.ENABLE_FEES_UI);
  const enableHideServiceFees = useFlag(LaunchDarklyFlag.ENABLE_HIDE_SERVICE_FEES_BY_RESTAURANT);

  const feeCents = delivery?.feeCents || 0;
  const feeDiscountCents = delivery?.feeDiscountCents || 0;
  const serviceFeeCents = delivery?.serviceFeeCents || 0;
  const serviceFeeDiscountCents = delivery?.serviceFeeDiscountCents || 0;
  const smallCartFeeCents = delivery?.smallCartFeeCents || 0;
  const geographicalFeeCents = delivery?.geographicalFeeCents || 0;
  const discounts = order.cart.discounts ?? [];
  const dropoffState = delivery?.dropoff?.state;

  // Use a custom label for geographical delivery fees as required
  const geographicalFeeLabelId =
    dropoffState && geographicalFeeLabelIdMap.hasOwnProperty(dropoffState)
      ? geographicalFeeLabelIdMap[dropoffState]
      : 'geographicalFee';

  const deliveryFeeCents = enableFeesUi
    ? calculateFeeValue(feeCents, feeDiscountCents)
    : computeDeliveryFee({
        feeCents,
        feeDiscountCents,
        serviceFeeCents,
        smallCartFeeCents,
        geographicalFeeCents,
      });
  const isFreeDelivery = deliveryFeeCents === 0;
  const rewardsDiscountCents = useMemo(
    () => getRewardsDiscount({ discounts, enableDiscountsOnOrderReceipt }),
    [discounts, enableDiscountsOnOrderReceipt]
  );

  const otherDiscountAmount = computeOtherDiscountAmount(discounts);

  const feesList = filterFees(order.cart.fees);

  const Total = (
    <ReceiptDetail $total>
      <p>{formatMessage({ id: 'total' })}</p>
      <p>{currencyFormatter(totalCents)}</p>
    </ReceiptDetail>
  );

  // For migrated orders, only show the total
  if (isHistoricalMigratedOrder(order)) {
    return Total;
  }

  return (
    <>
      {order.delivery && (
        <>
          {!enableHideSubTotalLine && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'subtotal' })}</p>
              <p>{currencyFormatter(subTotalCents)}</p>
            </ReceiptDetail>
          )}

          {(serviceFeeCents > 0 || enableFeesUi) && !enableHideServiceFees && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'serviceFee' })}</p>
              <p>
                {currencyFormatter(calculateFeeValue(serviceFeeCents, serviceFeeDiscountCents))}
              </p>
            </ReceiptDetail>
          )}

          <ReceiptDetail>
            <p>{formatMessage({ id: 'deliveryFee' })}</p>
            {isFreeDelivery && !enableFeesUi ? (
              <FeeDeliveryItem>
                {formatMessage({ id: 'free' })}
                &nbsp;
                <s>
                  <Currency amount={feeDiscountCents} />
                </s>
              </FeeDeliveryItem>
            ) : (
              <p>{currencyFormatter(deliveryFeeCents)}</p>
            )}
          </ReceiptDetail>
          {geographicalFeeCents > 0 && (
            <ReceiptDetail>
              <p>{formatMessage({ id: geographicalFeeLabelId })}</p>
              <p>{currencyFormatter(geographicalFeeCents)}</p>
            </ReceiptDetail>
          )}

          {smallCartFeeCents > 0 && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'smallCartFee' })}</p>
              <p>{currencyFormatter(smallCartFeeCents)}</p>
            </ReceiptDetail>
          )}
          {!hideTipAmountCheckout && (
            <ReceiptDetail>
              <p>{formatMessage({ id: 'tip' })}</p>
              <p>{currencyFormatter(order.delivery.tipCents)}</p>
            </ReceiptDetail>
          )}
        </>
      )}

      {enableDiscountsOnOrderReceipt && !!otherDiscountAmount && (
        <RewardsDiscountDetail>
          <p>{formatMessage({ id: 'otherDiscounts' })}</p>
          <p>{` - ${currencyFormatter(otherDiscountAmount)}`}</p>
        </RewardsDiscountDetail>
      )}

      {!!rewardsDiscountCents && (
        <RewardsDiscountDetail>
          <p>{formatMessage({ id: 'rewardsDiscount' })}</p>
          <p>{` - ${currencyFormatter(rewardsDiscountCents)}`}</p>
        </RewardsDiscountDetail>
      )}

      {!enableHideTaxLine && (
        <ReceiptDetail>
          <p>{formatMessage({ id: 'tax' })}</p>
          <p>{currencyFormatter(taxCents)}</p>
        </ReceiptDetail>
      )}
      {feesList.map(feeItem => (
        <ReceiptDetail key={feeItem.name}>
          <p>{formatMessage({ id: FEE_TYPE[feeItem.name] })}</p>
          <p>{currencyFormatter(feeItem.totalCents)}</p>
        </ReceiptDetail>
      ))}
      {Total}
    </>
  );
};
