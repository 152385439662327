import { DefaultTheme, useTheme } from 'styled-components';

type Falsy = false | 0 | '' | null | undefined;

/**
 * Assembles strings from potentially falsey parts for use in aria-describedby, className, etc.
 */
export const combineAttributeStrings = (...args: (string | Falsy)[]) => {
  const filtered = args.filter((a): a is string => !!a);

  return filtered.length !== 0 ? filtered.join(' ') : undefined;
};

/**
 * Assembles a string with an asterisks postfix when required is true.
 */
export const getRequiredLabel = (label: string, required?: boolean) => {
  if (label.endsWith('*')) {
    return label;
  }

  return combineAttributeStrings(label, required ? '*' : undefined);
};

/** Provides default values from theme config for certain component props */
export const useDefaultPropsFromTheme = <
  ComponentName extends keyof DefaultTheme['components'],
  DefaultProps extends DefaultTheme['components'][ComponentName]['defaultProps'],
>(
  componentName: ComponentName,
  props: {
    [k in keyof DefaultProps]: DefaultProps[k] | undefined;
  },
): DefaultProps => {
  const theme = useTheme();

  return Object.entries(theme.components[componentName].defaultProps).reduce(
    (acc, [key, defaultValue]) => {
      if (acc[key] === undefined) {
        acc[key] = defaultValue;
      }
      return acc;
    },
    { ...props } as DefaultProps,
  );
};
