import { WatchQueryFetchPolicy } from '@apollo/client';

import { useGetUserOrdersQuery as useGetUserOrdersQueryFulfillment } from 'generated/graphql-gateway';
import {
  IGetUserOrdersQueryVariables,
  IUserOrderFragment,
  RbiOrderStatus,
  useGetUserOrdersQuery,
} from 'generated/rbi-graphql';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export const UserOrdersDefaultLimit = 5;

export const UserOrdersDefaultVariables = {
  orderStatuses: [
    RbiOrderStatus.INSERT_SUCCESSFUL,
    RbiOrderStatus.UPDATE_SUCCESSFUL,
    RbiOrderStatus.UPDATE_ERROR, // the order could still go successfully, we need to show it.
    RbiOrderStatus.REFUND_SUCCESSFUL,
    RbiOrderStatus.REFUND_ERROR,
    RbiOrderStatus.REFUND_REQUESTED,
  ],
  shouldFilterByRegion: true,
};

const UserOrdersDefaultFulfillmentVariables = {
  orderStatuses: [
    RbiOrderStatus.INSERT_SUCCESSFUL,
    RbiOrderStatus.UPDATE_SUCCESSFUL,
    RbiOrderStatus.UPDATE_ERROR, // the order could still go successfully, we need to show it.
    RbiOrderStatus.REFUND_SUCCESSFUL,
    RbiOrderStatus.REFUND_ERROR,
    RbiOrderStatus.REFUND_REQUESTED,
  ],
  shouldFilterByRegion: true,
};

export const useUserOrders = ({
  fetchPolicy = 'cache-and-network',
  pollInterval,
  variables,
  skipQuery,
}: {
  variables?: IGetUserOrdersQueryVariables;
  pollInterval?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  skipQuery?: boolean;
}) => {
  const { isAuthenticated } = useAuthContext();
  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS
  );
  const useGetUserOrders = isFulfillmentServiceEnabled
    ? useGetUserOrdersQueryFulfillment
    : useGetUserOrdersQuery;
  const getUserOrdersVariables = isFulfillmentServiceEnabled
    ? { ...UserOrdersDefaultFulfillmentVariables, ...variables }
    : { ...UserOrdersDefaultVariables, ...variables };

  const result = useGetUserOrders({
    variables: {
      ...getUserOrdersVariables,
    },
    pollInterval,
    context: { shouldNotBatch: true },
    fetchPolicy,
    skip: skipQuery || !isAuthenticated(),
  });

  const resumeAfter = result?.data?.userOrders?.resumeAfter;
  const count = result?.data?.userOrders?.count || 0;
  const orders = (result?.data?.userOrders?.orders || ([] as unknown)) as IUserOrderFragment[];

  return {
    ...result,
    orders,
    count,
    resumeAfter,
  };
};
