import { MutableRefObject, useRef } from 'react';

/**
 * Lets us get a copy of the value of a forwardedRef
 *
 * Refs passed to a React component can take a couple different forms:
 * 1. An object with a `current` property containing the value
 * 2. A callback that is called with the value
 *
 * This function lets us reliably access the same value that is passed to the
 * forwarded ref by wrapping its assignment in our own callback function, and
 * passing that to the React component
 */
export const useForwardedRefLocally = <RefValueType>(
  forwardedRef:
    | ((instance: RefValueType | null) => void)
    | MutableRefObject<RefValueType | null>
    | null,
) => {
  const localRef = useRef<RefValueType | null>(null);

  return {
    localRef,
    refForProps: (element: RefValueType) => {
      if (typeof forwardedRef === 'function') {
        forwardedRef(element);
      } else if (forwardedRef) {
        forwardedRef.current = element;
      }

      localRef.current = element;
    },
  };
};
