import { FocusEventHandler, useCallback, useState } from 'react';

import { IAutoScroll } from '../types/auto-scroll';

interface IUseInputHandlesProps<ElementType> {
  onBlur?: FocusEventHandler<ElementType>;
  onFocus?: FocusEventHandler<ElementType>;
}

export function useFocusDetector<ElementType = Element>({
  onBlur,
  onFocus,
  autoScrollIntoView,
  autoScrollIntoViewAlignBlock,
  autoScrollIntoViewAlignInline,
}: IUseInputHandlesProps<ElementType> & IAutoScroll) {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = useCallback<FocusEventHandler<ElementType>>(
    (event) => {
      setIsFocused(false);

      onBlur?.(event);
    },
    [onBlur],
  );

  const handleFocus = useCallback<FocusEventHandler<ElementType>>(
    (event) => {
      setIsFocused(true);
      const target = event.target;

      if (autoScrollIntoView && target instanceof HTMLElement) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: autoScrollIntoViewAlignBlock,
          inline: autoScrollIntoViewAlignInline,
        });
      }
      onFocus?.(event);
    },
    [onFocus, autoScrollIntoView, autoScrollIntoViewAlignBlock, autoScrollIntoViewAlignInline],
  );

  return {
    handleBlur,
    handleFocus,
    isFocused,
  };
}
