interface SideValues {
  top: string;
  right: string;
  bottom: string;
  left: string;
  topAndBottom: string;
  leftAndRight: string;
}

/**
 * Class used to return side values, unable to be used itself as a value in
 * styled-components (if a plain object was used instead of this class, it
 * would be interpreted as a styled-components CSSObject)
 */
class MustSelectASideResult {
  top: string;
  right: string;
  bottom: string;
  left: string;
  topAndBottom: string;
  leftAndRight: string;
  constructor({ top, right, bottom, left, topAndBottom, leftAndRight }: SideValues) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
    this.topAndBottom = topAndBottom;
    this.leftAndRight = leftAndRight;
  }
}

/**
 * Parses CSS units to get per-side sizes
 *
 * CSS's unit shorthand allows many different patterns for specifying sizes.
 * For example, the padding values of `4px 12px`, `4px 12px 4px`, and
 * `4px 12px 4px 12px` are all equivalent. This makes sizes derived from sizes
 * defined elsewhere (design tokens, etc) very difficult to calculate.
 * This utility simplifies the extraction of per-side sizes for those
 * calculations.
 */
export const calcCSSUnitSides = (valueString: string) => {
  if (!valueString.trim()) {
    // Make sure this utility is being used on an appropriate unit value
    if (process.env.NODE_ENV === 'development') {
      throw new Error('Empty unit string');
    }
    return new MustSelectASideResult({
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      topAndBottom: '0px',
      leftAndRight: '0px',
    });
  }

  let values = valueString
    .split(' ')
    // Filter out any extra values from whitespace in the unit value
    .filter((value) => value.trim() !== '')
    .map((value) => {
      const trimmed = value.trim();

      // Make sure this utility is being used on an appropriate unit value
      if (
        process.env.NODE_ENV === 'development' &&
        trimmed !== '0' &&
        // Check against a short list of known units
        !trimmed.match(/.+(px|em|rem|ch|pt|vh|vw|%)$/)
      ) {
        throw new Error(
          `unexpected value "${valueString}" found in design token value.` +
            'The token needs to correspond to a value that can be broken ' +
            'down into four sides (e.g., padding, margin, border-width)',
        );
      }
      // Make sure no zero values go without a unit, which breaks css's `calc()`
      return value.trim() === '0' ? '0px' : value.trim();
    });

  if (values.length === 3) {
    values = [values[0], values[1], values[2], values[1]];
  } else if (values.length === 2) {
    values = [values[0], values[1], values[0], values[1]];
  } else if (values.length === 1) {
    values = [values[0], values[0], values[0], values[0]];
  }

  return new MustSelectASideResult({
    top: values[0],
    right: values[1],
    bottom: values[2],
    left: values[3],

    // Provide summed values for convenience, which can be nested in another calc
    // e.g.,
    // `height: calc(
    //   ${p => p.theme.token('height-input')} -
    //     ${p => calcCSSUnitSides(p.theme.token('border-width-input')).topAndBottom}
    // )`
    topAndBottom: `calc(${values[0]} + ${values[2]})`,
    leftAndRight: `calc(${values[3]} + ${values[1]})`,
  });
};
