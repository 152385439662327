import styled from 'styled-components';

import { normalizedTranslate } from '../../utils';

export const StyledSwitchButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 50px;
  width: 50px;
  height: 24px;
  position: relative;
  background: ${(p) => p.theme.token('background-switch-track')};
  vertical-align: middle;

  ${(p) => p.disabled && { background: p.theme.token('background-switch-track-disabled') }}
  &:hover,
  &:focus {
    outline: none;
    box-shadow: ${(p) => (p.disabled ? 'none' : p.theme.token('box-shadow-pattern'))};
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: ${(p) => p.theme.token('background-switch-thumb')};
    height: 18px;
    width: 18px;
    inset-block-start: 3px;
    inset-inline-start: 3px;
    transition: 0.2s ease-out transform;
  }
  &[aria-checked='true'] {
    background: ${(p) => {
      return p.disabled
        ? p.theme.token('background-switch-track-disabled')
        : p.theme.token('background-active');
    }};
    &::after {
      transform: ${normalizedTranslate('25px')};
    }
  }
`;

export const StyledButtonAndLabelWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  ${StyledSwitchButton} {
    justify-self: end;
  }
`;
