// This AmplitudeAdapter is used as the adapter layer to talk to the Amplitude CDP web SDK

import * as amplitude from '@amplitude/analytics-browser';

import { logger } from 'utils/logger';

import { CdpAdapterBase } from '../cdp-adapter-base';
import { CustomEventNames, EventTypes } from '../constants';
import { ICdpAdapter, ICdpUserIdentity, UpdatedUserAttributes, ValidPropertyType } from '../types';

class AmplitudeAdapterWeb extends CdpAdapterBase implements ICdpAdapter {
  logEvent(
    name: string,
    eventType: EventTypes,
    attributes?: Record<string, unknown>,
    customFlags?: Record<string, unknown>
  ) {
    if (this.skipEvent(name, attributes, customFlags)) {
      return;
    }
    this.updatePrevEvent(name, attributes, customFlags);
    let eventProperties = {
      type: eventType,
      ...attributes,
      ...customFlags,
    };

    try {
      amplitude.track(name, eventProperties);
    } catch (error) {
      logger.error({ message: 'Amplitude CDP - track event not found', error });
    }
  }

  async identify(
    email: string,
    customerId: string,
    userAttributes: Record<string, any>,
    callback: (response: { status: string }) => void
  ) {
    const identifyEvent = new amplitude.Identify();
    amplitude.setUserId(customerId);
    identifyEvent.set('customerid', customerId);
    identifyEvent.set('email', email);
    Object.keys(userAttributes).forEach(key => {
      identifyEvent.set(key, userAttributes[key]);
    });
    try {
      await amplitude.identify(identifyEvent);
      callback({ status: 'ok' });
    } catch (error) {
      logger.error({ message: 'Amplitude CDP - identify not found', error });
    }
  }

  async logout(callback: (response: { status: string }) => void) {
    try {
      await amplitude.reset();
      callback({ status: 'ok' });
    } catch (error) {
      callback({ status: 'error' });
      logger.error({ message: 'Amplitude CDP - logout not found', error });
    }
  }

  updateUserIdentity({ email, other3, customerid }: ICdpUserIdentity) {
    amplitude.setUserId(customerid);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('customerid', customerid);
    identifyEvent.set('email', email || '');
    identifyEvent.set('other3', other3 || '');
    amplitude.identify(identifyEvent);
  }

  getDeviceId() {
    return amplitude?.getDeviceId?.() ?? '';
  }

  getSession(): Promise<{ sessionId: string }> {
    const sessionId = amplitude?.getSessionId?.();
    return Promise.resolve({ sessionId: sessionId?.toString() ?? '' });
  }

  setOptOut(optOut: boolean) {
    amplitude.setOptOut(optOut);
  }

  updateUserAttributes(userAttributes: UpdatedUserAttributes) {
    const identifyEvent = new amplitude.Identify();
    Object.keys(userAttributes).forEach(key => {
      identifyEvent.set(key, (userAttributes as Record<string, ValidPropertyType>)[key]);
    });
    amplitude.identify(identifyEvent);
    this.logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, userAttributes);
  }

  Identity = AmplitudeAdapterWeb;
}

export const AmplitudeAdapter = new AmplitudeAdapterWeb();
