import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

/**
 * Opens the link in a new tab.
 */
export const ExternalLink: React.FC<React.AnchorHTMLAttributes<{}>> = ({ children, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children}
      <VisuallyHidden>{formatMessage({ id: 'linkOpensInNewTab' })}</VisuallyHidden>
    </a>
  );
};
