import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { format, isAfter, isBefore, parse, parseISO } from 'date-fns';
import { FormattedExecutionResult } from 'graphql';
import { IntlShape, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { IServerOrder } from '@rbi-ctg/menu';
import { IStore } from '@rbi-ctg/store';
import { StoreCard as StoreCardComponent } from 'components/store-card';
import { ThreeDsChallengeModal } from 'components/three-ds-challenge-modal';
import { PaymentStatus } from 'generated/graphql-gateway';
import { AdyenPaymentType, CartPaymentCardType, PaymentMethodBrand } from 'generated/rbi-graphql';
import { ICommitOrderInput, useCommitOrder } from 'hooks/commit-order';
import { useDialogModal } from 'hooks/use-dialog-modal';
import { useEffectOnUpdates } from 'hooks/use-effect-on-updates';
import { ThreeDsMethod } from 'hooks/use-threeDS';
import { ICommitOrderMutationResponse } from 'remote/queries/order';
import { useDayPartContext } from 'state/day-part';
import { IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import { OrderStatus, useOrderContext } from 'state/order';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';
import { usePaymentContext } from 'state/payment';
import { CASH_ACCOUNT_IDENTIFIER, FREE_ORDER_ACCOUNT_IDENTIFIER } from 'state/payment/constants';
import { getGraphQLPaymentErrorType } from 'state/payment/hooks/errors';
import {
  GraphQLPaymentErrorType,
  ThreeDSDataPayment,
  ThreeDSType,
} from 'state/payment/hooks/types';
import { IPaymentDetails, IPaymentMethod } from 'state/payment/types';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { ServiceMode } from 'state/service-mode';
import { useFetchStore } from 'state/store/hooks';
import { getConfigValue } from 'utils/environment';
import {
  GraphQLErrorCodes,
  getErrorDialogForGraphQLCode,
  parseGraphQLErrorCodes,
} from 'utils/errors';
import * as location from 'utils/location';
import {
  IAdyenPaymentState,
  IFraudPreventionValues,
  IPaycometState,
  IVrPaymentState,
  getFraudPreventionValues,
  splitExpiry,
} from 'utils/payment';
import { handleRedirectPayment } from 'utils/payment/redirect-handler';
import { readableCloseHourToday } from 'utils/restaurant';
import { DAYPART_FORMAT, TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';
import { routes } from 'utils/routing';
import { getThreeDSError } from 'utils/threeDS';

import { CardButton } from './card-button';
import { CheckoutIcon } from './checkout-icon';
import { LogoContainer, NarrowSection } from './common';
import {
  APPROX_MINUTES_AWAY_TRANSLATION_ID,
  TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID,
  TAP_IM_CLOSE_TRANSLATION_ID,
} from './constants';
import { RedirectForm } from './redirect-form';
import { Heading } from './styled';
import { theme } from './theme';

type onCommitType = (
  input: ICommitOrderInput
) => Promise<FormattedExecutionResult<ICommitOrderMutationResponse>>;

type onCommitErrorType = (error: any) => void;

type ThreeDSState = ThreeDSDataPayment & { challengeResponse?: string };

interface ICardFooter {
  onCommit: () => Promise<void>;
  isLoading: boolean;
  restaurant: IStore;
  orderStatus: OrderStatus | null;
  shouldShowImCloseText: boolean;
}

const noTopBorderRadius = `
  & > div {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

const StoreCard = styled(StoreCardComponent)<{ $confirmed: boolean }>`
  border-inline-end: none !important;
  ${({ $confirmed }) => !$confirmed && noTopBorderRadius}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Pickup = styled.span`
  color: ${Styles.color.accent} !important;
`;

const PickupInstructions = styled.h3`
  text-align: center;
  color: ${theme.pickupInstructionsColor};
  font-weight: ${Styles.fontWeight.normal};
  ${Styles.mobile} {
    font-size: 1rem;
  }
`;

const handleCommitOrder = (
  onCommit: onCommitType,
  onCommitError: onCommitErrorType,
  payment: {
    clearPaymentValues(): void;
    isAdyen: boolean;
    isVrPayment: boolean;
    paymentValues: IPaymentDetails;
    threeDS: ThreeDSState | null;
    isPaycomet: boolean;
    paymentMethods: IPaymentMethod[];
    redirectResult: string | undefined;
  },
  serviceMode: ServiceMode,
  skipCoolingPeriod?: boolean
) => {
  const {
    paymentValues,
    clearPaymentValues,
    isAdyen,
    isVrPayment,
    isPaycomet,
    threeDS,
    paymentMethods,
    redirectResult,
  } = payment;
  const {
    accountIdentifier,
    encryptedCN = '',
    encryptedEM = '',
    encryptedEY = '',
    encryptedSC = '',
    creditType,
    fdAccessToken,
    fdNonce,
    fraudPreventionValues = {} as IFraudPreventionValues,
    applePayDetails,
    googlePayDetails,
  } = paymentValues;

  let commitInput;

  if (accountIdentifier === FREE_ORDER_ACCOUNT_IDENTIFIER) {
    commitInput = {
      creditType: CASH_ACCOUNT_IDENTIFIER,
      order: { serviceMode },
      skipCoolingPeriod,
    };

    return onCommit(commitInput).then(() => {
      clearPaymentValues();
    });
  }

  // check for cash payments before isAdyen
  if (accountIdentifier === CASH_ACCOUNT_IDENTIFIER) {
    commitInput = {
      creditType: CASH_ACCOUNT_IDENTIFIER,
      order: { serviceMode },
      skipCoolingPeriod,
      payment: {
        cashPayment: true,
        fullName: fraudPreventionValues.fullName,
      },
    };

    return onCommit(commitInput).then(() => {
      clearPaymentValues();
    });
  } else if (isAdyen) {
    const baseCommitInput = {
      creditType,
      payment: {
        fullName: fraudPreventionValues.fullName,
      },
      order: { serviceMode },
      skipCoolingPeriod,
    };

    const isDigitalPay = !!applePayDetails || !!googlePayDetails;

    if (isDigitalPay) {
      commitInput = {
        ...baseCommitInput,
        payment: {
          ...baseCommitInput.payment,
          adyenInput: {
            paymentType: applePayDetails ? AdyenPaymentType.APPLE_PAY : AdyenPaymentType.GOOGLE_PAY,
          },
        },
      };
    }
    // recurring pay
    else if (accountIdentifier) {
      commitInput = {
        ...baseCommitInput,
        payment: {
          ...baseCommitInput.payment,
          adyenInput: {
            accountIdentifier,
            redirectDetails: redirectResult
              ? JSON.stringify({ type: 'scheme', redirectResult })
              : undefined,
            returnUrl: location.get('href'),
            browserInfo: (paymentValues.state as IAdyenPaymentState).browserInfo,
          },
        },
      };
      return onCommit(commitInput)
        .then(() => {
          clearPaymentValues();
        })
        .catch(onCommitError);
    }
    // encrypted pay
    else {
      if ((paymentValues.state as IAdyenPaymentState).blikCode) {
        commitInput = {
          creditType: CartPaymentCardType.BLIK,
          order: baseCommitInput.order,
          payment: {
            adyenInput: {
              savePaymentMethod: false,
              browserInfo: (paymentValues.state as IAdyenPaymentState).browserInfo,
            },
            blikCode: (paymentValues.state as IAdyenPaymentState).blikCode,
            fullName: fraudPreventionValues.fullName,
          },
          skipCoolingPeriod: baseCommitInput.skipCoolingPeriod,
        };
      } else {
        commitInput = {
          ...baseCommitInput,
          payment: {
            ...baseCommitInput.payment,
            adyenInput: {
              encryptedPayload: {
                encryptedCN,
                encryptedEM,
                encryptedEY,
                encryptedSC,
              },
              savePaymentMethod: paymentValues.saveCard,
              redirectDetails: redirectResult
                ? JSON.stringify({ type: 'scheme', redirectResult })
                : undefined,
              returnUrl: location.get('href'),
              browserInfo: (paymentValues.state as IAdyenPaymentState).browserInfo,
            },
            billingAddress: fraudPreventionValues.billingAddress,
          },
        };
      }

      return onCommit(commitInput)
        .then(() => {
          clearPaymentValues();
        })
        .catch(onCommitError);
    }
  } else if (isVrPayment) {
    if (!paymentValues.state) {
      throw new Error('State missing from payment values, cannot process VR Payments sale');
    }

    return onCommit(
      createCommitInputFromVrPaymentState({
        creditType,
        serviceMode,
        skipCoolingPeriod,
        vrPaymentState: paymentValues.state as IVrPaymentState,
      })
    ).then(() => {
      clearPaymentValues();
    });
  } else if (isPaycomet) {
    if (!paymentValues.state) {
      throw new Error('State missing from payment values, cannot process Paycomet Payments sale');
    }

    let paymentMethodBrand: PaymentMethodBrand | undefined;

    if (paymentValues.accountIdentifier) {
      const paymentAccount = paymentMethods.find(method => {
        return method.accountIdentifier === paymentValues.accountIdentifier;
      });
      if (paymentAccount?.paymentMethodBrand) {
        paymentMethodBrand =
          PaymentMethodBrand[paymentAccount?.paymentMethodBrand as keyof typeof PaymentMethodBrand];
      }
    } else {
      paymentMethodBrand = (paymentValues.state as IPaycometState).paymentMethodBrand;
    }

    return onCommit(
      createCommitInputFromPaycometState({
        creditType,
        serviceMode,
        paycometPaymentState: paymentValues.state as IPaycometState,
        accountIdentifier,
        threeDS,
        paymentMethodBrand,
      })
    )
      .then(() => {
        clearPaymentValues();
      })
      .catch(onCommitError);
  }

  const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');

  return onCommit({
    applePayDetails,
    creditType,
    fdAccountId: accountIdentifier,
    fullName: fraudPreventionValues.fullName,
    order: { serviceMode },
    payment: {
      billingAddress: fraudPreventionValues.billingAddress,
      ccMetadata: {
        ccBin: fraudPreventionValues.ccBin,
        ccExpiryMonth: expiryMonth,
        ccExpiryYear: expiryYear,
        ccLast4: fraudPreventionValues.ccLast4,
      },
      firstDataInput: {
        accountIdentifier,
        fdAccessToken,
        fdNonce,
      },
      fullName: fraudPreventionValues.fullName,
    },
    googlePayDetails,
    skipCoolingPeriod: threeDS ? true : skipCoolingPeriod,
    threeDSDetails: threeDS
      ? {
          saleId: threeDS.saleId!,
          threeDSOptions: {
            challengeResponse: threeDS.challengeResponse,
            transactionId: threeDS.transactionId!,
          },
        }
      : undefined,
  })
    .then(() => {
      clearPaymentValues();
    })
    .catch(onCommitError);
};

const CardFooter: React.FC<ICardFooter> = ({
  onCommit,
  isLoading,
  restaurant,
  orderStatus,
  shouldShowImCloseText,
}) => {
  const { formatMessage } = useIntl();
  const hasCheckoutError = useRef(false);
  const navigate = useNavigate();
  const onConfirmAlert = useCallback(() => {
    if (hasCheckoutError.current) {
      navigate(routes.cart);

      return;
    }
  }, [navigate]);
  const [AlertDialog, openAlert, alertMessage] = useDialogModal({
    onConfirm: onConfirmAlert,
    modalAppearanceEventMessage: 'Alert: Payment Failed',
  });

  useEffectOnUpdates(() => {
    if (orderStatus === OrderStatus.INSERT_ERROR) {
      // @todo - we need to evaluate the best path
      // when a user payment succeeds but insert fails
      openAlert({
        body: formatMessage({ id: 'couldntProcessPayment' }),
        buttonLabel: formatMessage({ id: 'retry' }),
        heading: formatMessage({ id: 'paymentFailed' }),
      });
    }
  }, [orderStatus]);

  const imClose = {
    enabled: shouldShowImCloseText,
    text: formatMessage({ id: 'imClose' }),
    approxMinutesAway: formatMessage({ id: APPROX_MINUTES_AWAY_TRANSLATION_ID }),
  };

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId: restaurant.number,
  });

  return (
    <ButtonContainer>
      <Box margin={'1rem 0 0'} width="100%" minWidth="auto">
        <CardButton
          isLoading={isLoading}
          commitOrder={onCommit}
          imHereText={formatMessage({ id: 'imHere' })}
          imClose={imClose}
          storeUnavailable={!isRestaurantPosOnline}
        />
      </Box>
      <AlertDialog {...(alertMessage || {})} />
    </ButtonContainer>
  );
};

interface IHoursText {
  serverOrder: IServerOrder;
  store: IStore;
}
interface IGetHoursText {
  dayParts: readonly IValidDayPart[];
  formatMessage: IntlShape['formatMessage'];
  hours: Pick<IStore, 'driveThruHours' | 'diningRoomHours'>;
  orderedAt: Date;
  serviceMode: ServiceMode;
}

export const getHoursText = ({
  dayParts,
  formatMessage,
  hours,
  orderedAt,
  serviceMode,
}: IGetHoursText) => {
  const timeFormat = getConfigValue('timeFormat') || TWELVE_HOUR_TIME_PARSE_FORMAT;

  const { startTime, endTime } =
    dayParts.find(dayPart => dayPart.key === DAY_PART_SELECTIONS.BREAKFAST_KEY) || {};
  const breakfastStartTime = startTime ? parse(startTime, DAYPART_FORMAT, new Date()) : null;
  const breakfastEndTime = endTime ? parse(endTime, DAYPART_FORMAT, new Date()) : null;

  const isBreakfastOrder =
    breakfastStartTime && breakfastEndTime
      ? isAfter(orderedAt, breakfastStartTime) && isBefore(orderedAt, breakfastEndTime)
      : false;

  const serviceModeHours =
    serviceMode === ServiceMode.DRIVE_THRU ? hours.driveThruHours : hours.diningRoomHours;

  const pickupTime =
    isBreakfastOrder && breakfastEndTime
      ? format(breakfastEndTime, timeFormat)
      : readableCloseHourToday(serviceModeHours);

  return formatMessage(
    { id: 'pickUpBefore' },
    {
      pickupTime,
      pickup: str => <Pickup>{str}</Pickup>,
    }
  );
};

const HoursText: React.FC<IHoursText> = ({ serverOrder, store }) => {
  const { dayParts } = useDayPartContext();
  const { formatMessage } = useIntl();

  return (
    <>
      {getHoursText({
        orderedAt: parseISO(serverOrder.createdAt),
        hours: {
          diningRoomHours: store.diningRoomHours,
          driveThruHours: store.driveThruHours,
        },
        serviceMode: serverOrder.cart.serviceMode,
        dayParts,
        formatMessage,
      })}
    </>
  );
};

const createCommitInputFromVrPaymentState = ({
  vrPaymentState,
  creditType,
  serviceMode,
  skipCoolingPeriod,
}: {
  creditType?: string;
  serviceMode: ServiceMode;
  skipCoolingPeriod?: boolean;
  vrPaymentState: IVrPaymentState;
}): ICommitOrderInput => {
  const baseCommitInput = {
    creditType,
    order: { serviceMode },
    skipCoolingPeriod,
  };

  if (vrPaymentState.card) {
    return {
      ...baseCommitInput,
      payment: {
        fullName: vrPaymentState.nameOnCard,
        ccMetadata: {
          ccBin: vrPaymentState.card.bin,
          ccExpiryMonth: vrPaymentState.card.expiryMonth,
          ccExpiryYear: vrPaymentState.card.expiryYear,
          ccLast4: vrPaymentState.card.last4,
        },
        vrPaymentInput: {
          merchantAccount: vrPaymentState.merchantAccount,
          pspReference: vrPaymentState.pspReference,
          storePaymentMethod: vrPaymentState.saveCard,
          storedPaymentMethodId: vrPaymentState.cardNumber || undefined,
        },
      },
    };
  }

  if (vrPaymentState.paypal) {
    return {
      ...baseCommitInput,
      creditType: CartPaymentCardType.PAYPAL,
      payment: {
        fullName: vrPaymentState.nameOnCard,
        ccMetadata: undefined,
        vrPaymentInput: {
          merchantAccount: vrPaymentState.merchantAccount,
          pspReference: '', // not available in "registered" flow
          storePaymentMethod: false,
          storedPaymentMethodId: vrPaymentState.cardNumber || undefined,
        },
      },
    };
  }

  throw new Error('VR payments missing card and paypal');
};

export function handleThreeDSError(
  error: any,
  onThreeDSError: (data: ThreeDSDataPayment | null) => void
): void {
  const threeDSError = getThreeDSError(error);
  if (threeDSError) {
    onThreeDSError(threeDSError);
    return;
  }
  onThreeDSError(null);
  throw error;
}

const createCommitInputFromPaycometState = ({
  paycometPaymentState,
  creditType,
  serviceMode,
  accountIdentifier,
  threeDS,
  paymentMethodBrand,
}: {
  paycometPaymentState: IPaycometState;
  creditType?: string;
  serviceMode: ServiceMode;
  accountIdentifier?: string;
  threeDS?: any;
  paymentMethodBrand?: PaymentMethodBrand;
}): ICommitOrderInput => {
  const order = { serviceMode };

  // recurring pay
  if (accountIdentifier) {
    return {
      creditType,
      order,
      skipCoolingPeriod: true,
      payment: {
        fullName: paycometPaymentState.nameOnCard,
        paycometInput: {
          storedPaymentMethodId: accountIdentifier,
        },
        paymentMethodBrand,
      },
      threeDSDetails: threeDS
        ? {
            saleId: threeDS.saleId!,
            threeDSOptions: {
              challengeResponse: threeDS.challengeResponse,
              transactionId: threeDS.transactionId!,
            },
          }
        : undefined,
    };
  }

  if (paycometPaymentState.isPayLink) {
    return {
      creditType: CartPaymentCardType.PAYPAL,
      order,
      payment: {
        fullName: paycometPaymentState.nameOnCard || '',
        ccMetadata: undefined,
        paycometInput: {
          pspReference: paycometPaymentState.pspReference || '',
          storePaymentMethod: paycometPaymentState.saveCard || false,
          storedPaymentMethodId: paycometPaymentState.cardNumber || undefined,
        },
      },
      skipCoolingPeriod: true,
    };
  }

  return {
    creditType,
    order,
    skipCoolingPeriod: true,
    payment: {
      fullName: paycometPaymentState.nameOnCard || '',
      ccMetadata: {
        ccBin: paycometPaymentState?.card?.bin || '',
        ccExpiryMonth: paycometPaymentState?.card?.expiryMonth || '',
        ccExpiryYear: paycometPaymentState?.card?.expiryYear || '',
        ccLast4: paycometPaymentState?.card?.last4 || '',
      },
      paymentMethodBrand,
      paycometInput: {
        pspReference: paycometPaymentState.pspReference || '',
        storePaymentMethod: paycometPaymentState.saveCard || false,
        storedPaymentMethodId: paycometPaymentState.cardNumber || undefined,
      },
    },
  };
};

const verifyPaymentValuesToCommit = (payment: {
  isAdyen: boolean;
  isVrPayment: boolean;
  paymentValues: IPaymentDetails;
  threeDS: ThreeDSState | null;
  isPaycomet: boolean;
  paymentMethods: IPaymentMethod[];
  redirectResult?: string;
}): boolean => {
  const { isAdyen, redirectResult, paymentValues } = payment;

  const isAdyenRequiredInputAvailable =
    paymentValues.encryptedCN &&
    paymentValues.encryptedEM &&
    paymentValues.encryptedEY &&
    paymentValues.encryptedSC;

  if (isAdyen && !isAdyenRequiredInputAvailable) {
    return false;
  }

  // redirectResult is currently only Adyen specific concept
  if (redirectResult && !isAdyen) {
    return false;
  }

  return true;
};

export const PickupCard = ({
  confirmed = false,
  serverOrder,
  newLayout = false,
  onOrderRefunded,
}: {
  confirmed?: boolean;
  serverOrder: IServerOrder;
  newLayout?: boolean;
  onOrderRefunded: VoidFunction;
}) => {
  const { emptyCart, onCommitSuccess, serviceMode } = useOrderContext();
  const navigate = useNavigate();
  const [threeDS, setThreeDS] = useState<ThreeDSState | null>(null);
  const threeDSChallengeFinished = useRef(false);
  const isRedirectResultSubmitted = useRef(false);
  const [searchParams] = useSearchParams();
  const { pickupDate } = useOrderTimedFire({ serverOrder });

  const { commitOrder, loading, orderStatus, commitResult } = useCommitOrder({
    rbiOrderId: serverOrder.rbiOrderId,
  });

  const { data: store, loading: loadingStore } = useFetchStore({
    storeNumber: serverOrder.cart?.storeDetails?.storeNumber,
  });

  const {
    storePaymentValues,
    clearPaymentValues,
    redirectResult,
    isAdyen,
    isVrPayment,
    paymentValues,
    isPaycomet,
    paymentMethods,
    setRedirectResult,
    isPending,
    setIsPending,
    redirectData,
    setRedirectData,
  } = usePaymentContext();

  const onCommitError = useCallback(
    (err: ApolloError) => {
      if (getGraphQLPaymentErrorType(err) === GraphQLPaymentErrorType.REDIRECT) {
        const redirectErrorsData = handleRedirectPayment(err);

        if (redirectErrorsData) {
          setRedirectData(redirectErrorsData);
          isRedirectResultSubmitted.current = false;
          return;
        }
      }

      if (getGraphQLPaymentErrorType(err) === GraphQLPaymentErrorType.AWAIT) {
        const paymentState = paymentValues.state as IAdyenPaymentState | undefined;
        storePaymentValues({
          encryptedCN: paymentState?.cardNumber,
          encryptedEM: paymentState?.encryptedExpiryMonth,
          encryptedEY: paymentState?.encryptedExpiryYear,
          encryptedSC: paymentState?.cvv ?? '',
          fdAccessToken: '',
          fdNonce: '',
          creditType: paymentState?.cardType ?? '',
          fraudPreventionValues: paymentState ? getFraudPreventionValues(paymentState) : undefined,
          saveCard: paymentState?.saveCard,
          state: paymentState,
        });

        setIsPending(true);
        return;
      }

      handleThreeDSError(err, setThreeDS);
    },
    [setIsPending, storePaymentValues, paymentValues.state, setRedirectData]
  );

  const onCommitOrderSkipCooldown = useCallback(async () => {
    const payment = {
      clearPaymentValues,
      isAdyen,
      isVrPayment,
      paymentValues,
      threeDS,
      isPaycomet,
      paymentMethods,
      redirectResult,
    };

    if (!verifyPaymentValuesToCommit(payment)) {
      return;
    }

    if (redirectResult) {
      isRedirectResultSubmitted.current = true;
    }

    handleCommitOrder(commitOrder, onCommitError, payment, serviceMode, true);
    onCommitSuccess(serverOrder);
    return emptyCart();
  }, [
    clearPaymentValues,
    isAdyen,
    isVrPayment,
    paymentValues,
    threeDS,
    isPaycomet,
    paymentMethods,
    redirectResult,
    commitOrder,
    onCommitError,
    serviceMode,
    onCommitSuccess,
    serverOrder,
    emptyCart,
  ]);

  const onCommitOrder = useCallback(() => {
    const payment = {
      clearPaymentValues,
      isAdyen,
      isVrPayment,
      paymentValues,
      threeDS,
      isPaycomet,
      paymentMethods,
      redirectResult,
    };

    let skipCoolingPeriod = true;
    if (payment.isAdyen && serverOrder?.cart?.payment?.cardType === 'BLIK') {
      skipCoolingPeriod =
        serverOrder.status === OrderStatus.PAYMENT_SUCCESSFUL ||
        serverOrder.paymentStatus === PaymentStatus.PAYMENT_SUCCESSFUL;
    }

    handleCommitOrder(commitOrder, onCommitError, payment, serviceMode, skipCoolingPeriod);
    onCommitSuccess(serverOrder);
    return emptyCart();
  }, [
    clearPaymentValues,
    commitOrder,
    emptyCart,
    isAdyen,
    isPaycomet,
    isVrPayment,
    onCommitError,
    onCommitSuccess,
    paymentMethods,
    paymentValues,
    redirectResult,
    serverOrder,
    serviceMode,
    threeDS,
  ]);

  const onCancelModal = () => navigate(routes.orders);
  const set3DSChallengeResponse = (cRes: string) => {
    threeDSChallengeFinished.current = true;
    setThreeDS({ ...threeDS, challengeResponse: cRes } as ThreeDSState);
  };
  const [OrderCooldownErrorDialog, openOrderCooldownErrorDialog] = useDialogModal({
    onConfirm: onCommitOrderSkipCooldown,
    onCancel: onCancelModal,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation Modal: Store Location',
  });

  const { formatMessage } = useIntl();
  const redirectResultFromUrl = searchParams.get('redirectResult');

  const isRedirectPaymentResuming =
    !!redirectResult &&
    redirectResult === redirectResultFromUrl &&
    !isRedirectResultSubmitted.current;

  const isOrderPaid =
    serverOrder.status === OrderStatus.PAYMENT_SUCCESSFUL ||
    serverOrder.paymentStatus === PaymentStatus.PAYMENT_SUCCESSFUL;
  const isPendingPaymentResuming = isOrderPaid && isPending && serviceMode !== ServiceMode.TAKEOUT;
  const isPaymentResuming = isRedirectPaymentResuming || isPendingPaymentResuming;

  const orderIsLoading = loading || orderStatus === OrderStatus.INSERT_REQUESTED;
  const enableImCloseDisplay = useFlag(LaunchDarklyFlag.ENABLE_IM_CLOSE_DISPLAY);

  const shouldShowImCloseText = enableImCloseDisplay && serviceMode !== ServiceMode.CURBSIDE;

  const screenReaderButtonName = shouldShowImCloseText ? 'imClose' : 'imHere';

  const isCashPayment = paymentValues.accountIdentifier === CASH_ACCOUNT_IDENTIFIER;
  useEffect(() => {
    if (!commitResult.error || getThreeDSError(commitResult.error)) {
      return;
    }

    const [parsedGqlError] = parseGraphQLErrorCodes(commitResult.error);
    if (!parsedGqlError) {
      return;
    }

    const mappedErrorCode = getErrorDialogForGraphQLCode(parsedGqlError, formatMessage);
    const isCommitOrderCoolingPeriodError =
      parsedGqlError.errorCode === GraphQLErrorCodes.COMMIT_ORDER_COOLING_PERIOD;

    if (mappedErrorCode && isCommitOrderCoolingPeriodError) {
      const { message, title = '' } = mappedErrorCode;
      openOrderCooldownErrorDialog({ message, title });
    }
  }, [commitResult, formatMessage, openOrderCooldownErrorDialog]);

  useEffect(() => {
    if (threeDSChallengeFinished.current) {
      threeDSChallengeFinished.current = false;
      onCommitOrderSkipCooldown();
    }
  }, [onCommitOrderSkipCooldown]);

  useEffect(() => {
    if (isPaymentResuming && !confirmed) {
      onCommitOrderSkipCooldown();
    }
    setRedirectResult(redirectResultFromUrl ?? '');
  }, [
    onCommitOrderSkipCooldown,
    setRedirectResult,
    isPaymentResuming,
    redirectResultFromUrl,
    paymentValues,
    confirmed,
  ]);

  if (
    confirmed ||
    serverOrder.status === OrderStatus.PAYMENT_SUCCESSFUL ||
    serverOrder.paymentStatus === PaymentStatus.PAYMENT_SUCCESSFUL
  ) {
    setIsPending(false);
  }

  return (
    <>
      <div data-testid="pickup-card">
        <VisuallyHidden role="alert">
          {formatMessage(
            { id: 'pickUpConfirmationScreenReaderInstructions' },
            {
              screenReaderButtonName,
            }
          )}
        </VisuallyHidden>
        {!confirmed && isPending && (
          <>
            <NarrowSection>
              <LogoContainer>
                <CheckoutIcon aria-hidden />
              </LogoContainer>

              <Heading>{formatMessage({ id: 'savedOrder' })}</Heading>
              <PickupInstructions>{formatMessage({ id: 'isPendingBlik' })}</PickupInstructions>
            </NarrowSection>
          </>
        )}

        {!confirmed && !isPending && (
          <>
            <NarrowSection>
              <LogoContainer>
                <CheckoutIcon aria-hidden />
              </LogoContainer>

              <Heading>{formatMessage({ id: 'savedOrder' })}</Heading>
              <PickupInstructions>
                {formatMessage({
                  id: isCashPayment
                    ? shouldShowImCloseText
                      ? TAP_IM_CLOSE_CASH_PAYMENT_TRANSLATION_ID
                      : 'tapImHereCashPayment'
                    : shouldShowImCloseText
                      ? TAP_IM_CLOSE_TRANSLATION_ID
                      : 'tapImHere',
                })}
              </PickupInstructions>
            </NarrowSection>
          </>
        )}

        {!loadingStore && store && (
          <StoreCard
            isListView={true}
            restaurant={store}
            $confirmed={confirmed}
            pickupDate={pickupDate}
            HoursText={() => <HoursText serverOrder={serverOrder} store={store} />}
            Footer={({ restaurant }) =>
              confirmed ? null : (
                <CardFooter
                  isLoading={orderIsLoading || isPaymentResuming || isPending}
                  onCommit={onCommitOrder}
                  orderStatus={orderStatus}
                  restaurant={restaurant}
                  shouldShowImCloseText={shouldShowImCloseText}
                />
              )
            }
            newLayout={newLayout}
            onOrderRefunded={onOrderRefunded}
          />
        )}
      </div>
      <OrderCooldownErrorDialog
        confirmLabel={formatMessage({ id: 'placeOrder' })}
        cancelLabel={formatMessage({ id: 'recentOrders' })}
      />
      {threeDS && threeDS.type === ThreeDSType.METHOD && threeDS.iframeContent && (
        <ThreeDsMethod
          iframeContent={threeDS.iframeContent}
          onComplete={onCommitOrderSkipCooldown}
        />
      )}
      {threeDS &&
        threeDS.type === ThreeDSType.CHALLENGE &&
        threeDS.acsUrl &&
        threeDS.challengeRequestToken && (
          <ThreeDsChallengeModal
            acsUrl={threeDS.acsUrl}
            challengeRequestToken={threeDS.challengeRequestToken}
            onChallengeSuccess={set3DSChallengeResponse}
            headerIdMessage={'paymentVerification'}
          />
        )}
      {redirectData && <RedirectForm data={redirectData} />}
    </>
  );
};

export const PickupCardMemo = memo(PickupCard);
