import { TimedFireGenerateOptions } from 'state/launchdarkly/variations';
import { Days, getDateForHours, nextCloseDay, nextOpenDay } from 'utils/restaurant';

import { MINUTE_IN_MS, OPTION_NOW } from './constants';
import {
  ICalculateNextAvailableOptions,
  IOptionSettings,
  TCloseHoursKey,
  TOpenHoursKey,
} from './types';

/**
 * Calculates nearest 5-minute time slot in the future
 **/
export const getNearestTimeSlot = (): number => {
  const dateObj = new Date();
  let currentMinutes = dateObj.getMinutes();
  while (currentMinutes % 5) {
    currentMinutes++;
  }
  dateObj.setMinutes(currentMinutes);
  return dateObj.getTime();
};

/**
 * Translates fireOrderIn, which is measured in seconds, to the nearest selectable option
 **/
export const deriveDefaultOptionFromValue = (
  value: number,
  options: IOptionSettings[]
): IOptionSettings | null => {
  if (!options?.length) {
    return null;
  }
  return options.reduce((a, b) => {
    return Math.abs(a.inSeconds - value) < Math.abs(b.inSeconds - value) ? a : b;
  });
};

/**
 * Dynamically generates an array of options for time picker
 * based on a time interval an offset.
 **/
export const generateOptions = ({
  timeInterval,
  length,
  offset = 0,
  startNow = false,
}: TimedFireGenerateOptions): IOptionSettings[] => {
  // indexPadding is used to modify the index to perform the correct calculation.
  // Defaults to 1 when neither {offset} nor {startNow} are set.
  let indexPadding = 1;

  if (startNow && offset) {
    // When both {startNow} and {offset} are set, then the 1st option is {OPTION_NOW},
    // the 2nd option is {offset}, and the 3rd option is {timeInterval + offset}.
    indexPadding = -1;
  } else if (startNow || offset) {
    // When either {startNow} or {offset} are set, the 1st option is {OPTION_NOW|offset}
    // and the 2nd option is {timeInterval + offset}.
    indexPadding = 0;
  }

  return Array.from({ length }, (a, i) => {
    if (startNow && i === 0) {
      return OPTION_NOW;
    }

    const minutes = (i + indexPadding) * timeInterval + offset;

    return {
      id: `${minutes}min`,
      inMinutes: minutes,
      inSeconds: minutes * 60,
      labelId: 'prepareInLabel',
      ariaLabel: 'prepareInMinLabel',
    };
  });
};

/**
 * Dynamically calculate an array of options for time picker when delivery is closed
 **/
export const calculateNextAvailableOptions = ({
  deliveryOptions,
  deliveryHours,
  now = new Date(),
  openingMinutesOffset = 45,
}: ICalculateNextAvailableOptions): IOptionSettings[] => {
  const { timeInterval } = deliveryOptions;

  // Check if restaurant hasn't open yet or if
  // it's closed already to ensure we get the right hours
  const todayOpenString = `${Days[now.getDay() % 7]}Open` as TOpenHoursKey;
  const todayCloseString = `${Days[now.getDay() % 7]}Close` as TCloseHoursKey;
  const todayOpenOurs = getDateForHours(deliveryHours[todayOpenString]);
  const todayCloseOurs = getDateForHours(deliveryHours[todayCloseString]);

  const tomorrowOpenHours = getDateForHours(nextOpenDay(deliveryHours));
  const tomorrowCloseHours = getDateForHours(nextCloseDay(deliveryHours));

  // Account for the different day as getDateForHours uses 'now' to create the date.
  tomorrowOpenHours.setDate(tomorrowOpenHours.getDate() + 1);
  tomorrowCloseHours.setDate(tomorrowCloseHours.getDate() + 1);

  const isClosedToday = now.getTime() > todayCloseOurs.getTime();
  const open = isClosedToday ? tomorrowOpenHours : todayOpenOurs;
  const close = isClosedToday ? tomorrowCloseHours : todayCloseOurs;

  // Calculate the time to open
  const openTime = open.getTime() - now.getTime();

  // Calculate the props needed for the generateOptions function
  const offset = Math.ceil(openTime / MINUTE_IN_MS) + openingMinutesOffset;
  const length = Math.round((close.getTime() - open.getTime()) / MINUTE_IN_MS / timeInterval);

  return generateOptions({ offset, length, timeInterval });
};
