import { ISOs as IsoCountryCode } from 'utils/form/constants';
import { RBIPlatform as Platform } from 'utils/environment/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
}

/** A First Data or Adyen account (payment method) will have either credit or prepaid but not both */
export interface IAccount {
  readonly __typename?: 'Account';
  /** Common PSP payment method identifier and Adyen account identifier. */
  readonly accountIdentifier: Maybe<Scalars['String']['output']>;
  /** Used for making cc direct payments with chase (TH) */
  readonly chaseProfileId: Maybe<Scalars['String']['output']>;
  readonly credit: Maybe<ICreditCard>;
  /** First Data account ID */
  readonly fdAccountId: Maybe<Scalars['String']['output']>;
  /** Orbital account stored payment method id */
  readonly orbitalIdentifier: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<PaymentMethodBrand>;
  readonly paypal: Maybe<Scalars['Boolean']['output']>;
  readonly paypalIdentifier: Maybe<Scalars['String']['output']>;
  readonly prepaid: Maybe<IGiftCard>;
}

export interface IAccountListResponse {
  readonly __typename?: 'AccountListResponse';
  readonly accounts: Maybe<ReadonlyArray<Maybe<IAccount>>>;
  readonly count: Scalars['Int']['output'];
  readonly defaultAccount: Maybe<IAccount>;
  readonly paymentMethodBrand: Maybe<PaymentMethodBrand>;
  readonly paymentProcessor: Maybe<PaymentProcessor>;
}

/** Add a First Data account (payment method) for the current user */
export interface IAddAccountInput {
  /** billingAddress associated to card */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /** First 6 digits of credit card */
  readonly ccBin: Scalars['String']['input'];
  /** cc token from tokenex required for chase direct pay enrollment */
  readonly ccToken?: InputMaybe<Scalars['String']['input']>;
  /** chase id from TH endpoint to add to DynamoDB */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /** cc security code */
  readonly cvv?: InputMaybe<Scalars['String']['input']>;
  /** Access token which was used to request nonce */
  readonly fdAccessToken: Scalars['String']['input'];
  /** Nonce provided by FD linking to the new payment method */
  readonly fdNonce: Scalars['String']['input'];
  /** Name On Card */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /** Make this new account the user's default account */
  readonly makeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Session ID as set by the FE. This should be derived from mParticle. */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** threeDSOptions to resume account creation */
  readonly threeDSOptions?: InputMaybe<IThreeDsOptions>;
}

/** Add Adyen (payment method) for the current user */
export interface IAddAdyenAccountInput {
  /** Encrypted Payment Payload */
  readonly encryptedPayload: IAdyenEncryptedInfo;
}

/** Add payment method for the current user */
export interface IAddCreditAccountInput {
  readonly adyenInput?: InputMaybe<IAddAdyenAccountInput>;
  /** billingAddress associated to card */
  readonly billingAddress: IBillingAddressInput;
  readonly firstDataInput?: InputMaybe<IAddAccountInput>;
  readonly firstpayInput?: InputMaybe<IAddFirstpayAccountInput>;
  /** Name on credit card */
  readonly fullName: Scalars['String']['input'];
  /** (TH only) Make this new account the user's default account */
  readonly makeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orbitalInput?: InputMaybe<IAddOrbitalAccountInput>;
}

/** Add Firstpay (payment method) for the current user */
export interface IAddFirstpayAccountInput {
  readonly bin: Scalars['String']['input'];
  readonly cardBrand: Scalars['String']['input'];
  readonly expiryMonth: Scalars['String']['input'];
  readonly expiryYear: Scalars['String']['input'];
  readonly last4: Scalars['String']['input'];
  readonly storedPaymentMethodId: Scalars['String']['input'];
}

/** Add a gift account (payment method) for the current user */
export interface IAddGiftAccountInput {
  /** Details from Apple Pay for funding. */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /**
   * Vaulted first data Account ID (credit) used to fund gift card.
   * Either this or applePayDetails is required.
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Amount to fund to gift card account in cents */
  readonly fundValue: Scalars['Int']['input'];
  /** Details from Goole Pay for funding. */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Session ID as set by the FE.
   *
   * Note that the "sessionId" field is deprecated and will be ignored.
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
}

/** Add Orbital (payment method) for the current user */
export interface IAddOrbitalAccountInput {
  readonly bin: Scalars['String']['input'];
  readonly cardBrand: Scalars['String']['input'];
  readonly encryptedCardNum: Scalars['String']['input'];
  readonly encryptedCvv: Scalars['String']['input'];
  readonly expiryMonth: Scalars['String']['input'];
  readonly expiryYear: Scalars['String']['input'];
  readonly pieFormat: Scalars['String']['input'];
  readonly pieIntegrityCheck: Scalars['String']['input'];
  readonly pieKeyID: Scalars['String']['input'];
  readonly pieMode: Scalars['String']['input'];
  readonly piePhaseId: Scalars['String']['input'];
  readonly pieSubscriberId: Scalars['String']['input'];
}

export interface IAdditionalTimeSlot {
  readonly __typename?: 'AdditionalTimeSlot';
  /** Type AdditionalTimeSlot */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Close additional hours */
  readonly close: Maybe<Scalars['String']['output']>;
  /** Open additional hours */
  readonly open: Maybe<Scalars['String']['output']>;
}

/** Browser information needed for Adyen 3DS flow */
export interface IAdyenBrowserInfoInput {
  readonly acceptHeader: Scalars['String']['input'];
  readonly colorDepth: Scalars['Int']['input'];
  readonly javaEnabled: Scalars['Boolean']['input'];
  readonly language: Scalars['String']['input'];
  readonly screenHeight: Scalars['Int']['input'];
  readonly screenWidth: Scalars['Int']['input'];
  readonly timeZoneOffset: Scalars['Int']['input'];
  readonly userAgent: Scalars['String']['input'];
}

export interface IAdyenEncryptedInfo {
  /** Encrypted credit card number */
  readonly encryptedCN: Scalars['String']['input'];
  /** Encrypted credit card expiry month */
  readonly encryptedEM: Scalars['String']['input'];
  /** Encrypted credit card expiry year */
  readonly encryptedEY: Scalars['String']['input'];
  /** Encrypted credit card security code */
  readonly encryptedSC: Scalars['String']['input'];
}

export interface IAdyenPayment {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or
   * encrypted payload is required.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Browser information needed for Adyen 3DS flow */
  readonly browserInfo?: InputMaybe<IAdyenBrowserInfoInput>;
  /** Encrypted Payment Payload. If accountIdentifier is not passed this will be used. */
  readonly encryptedPayload?: InputMaybe<IAdyenEncryptedInfo>;
  /** Denotes the payment type */
  readonly paymentType?: InputMaybe<AdyenPaymentType>;
  /** A JSON string of details containing data required to resume payments */
  readonly redirectDetails?: InputMaybe<Scalars['String']['input']>;
  /** URL to return to after redirection flow */
  readonly returnUrl?: InputMaybe<Scalars['String']['input']>;
  /** When true, payment details will be saved as a recurring payment method in Adyen */
  readonly savePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum AdyenPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  TOKENIZED_CREDIT_CARD = 'TOKENIZED_CREDIT_CARD',
}

export interface IApiMetadata {
  readonly __typename?: 'ApiMetadata';
  /** Pong! Simple health check. */
  readonly ping: Scalars['String']['output'];
}

/** Details from Apple Pay used for Apple Pay funding. */
export interface IApplePay {
  /** Data of the payment method used for Apple Pay. */
  readonly PaymentMethodData?: InputMaybe<IPaymentMethodData>;
  /** Application data as passed through from Apple Pay */
  readonly applicationData: Scalars['String']['input'];
  readonly billingAddress: IBillingAddressInput;
  /** part of the billing address from apple pay */
  readonly country: CountryCode;
  readonly data: Scalars['String']['input'];
  readonly decryptAlias: Scalars['String']['input'];
  readonly ephemeralPublicKey: Scalars['String']['input'];
  readonly formatted: Scalars['String']['input'];
  /** bundle all apple pay details into an encrypted token. */
  readonly paymentData?: InputMaybe<Scalars['String']['input']>;
  readonly primary: Scalars['Boolean']['input'];
  readonly publicKeyHash: Scalars['String']['input'];
  /** Signature as passed through from Apple Pay */
  readonly signature: Scalars['String']['input'];
  readonly transactionId: Scalars['String']['input'];
  /** type of the billing address set in apple pay e.g. work, home, etc */
  readonly type: Scalars['String']['input'];
  readonly version: Scalars['String']['input'];
}

export interface IAppliedOffer {
  readonly cartId?: InputMaybe<Scalars['ID']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId?: InputMaybe<Scalars['ID']['input']>;
  readonly swap?: InputMaybe<ISwapInput>;
  readonly type?: InputMaybe<OfferType>;
}

export interface IAppliedReward {
  readonly cartId: Scalars['ID']['input'];
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly rewardId: Scalars['ID']['input'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId?: InputMaybe<Scalars['ID']['input']>;
  readonly timesApplied: Scalars['Int']['input'];
}

export interface IAttemptLoginOtpResponse {
  readonly __typename?: 'AttemptLoginOTPResponse';
  /** OTP information. Present if user found and signin with otp started */
  readonly otp: Maybe<ICreateOtp>;
  /** Indicates if the user was found or not */
  readonly result: AttemptLoginOtpResult;
}

export enum AttemptLoginOtpResult {
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
}

/** A Prepaid balance response (payment method) */
export interface IBalance {
  readonly __typename?: 'Balance';
  /** gift card last 4 digits */
  readonly alias: Scalars['String']['output'];
  /** dollar amount gift card begins with */
  readonly beginBalance: Scalars['Int']['output'];
  readonly currencyCode: Scalars['String']['output'];
  /** current gift card balance in cents */
  readonly currentBalance: Scalars['Int']['output'];
  readonly expiryMonth: Scalars['String']['output'];
  readonly expiryYear: Scalars['String']['output'];
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
}

export interface IBillingAddress {
  readonly __typename?: 'BillingAddress';
  /** Country */
  readonly country: Maybe<Scalars['String']['output']>;
  /** City */
  readonly locality: Scalars['String']['output'];
  /** Zip Code */
  readonly postalCode: Scalars['String']['output'];
  /** State */
  readonly region: Scalars['String']['output'];
  /** Street Address */
  readonly streetAddress: Scalars['String']['output'];
  /** Apt or Unit Number */
  readonly unitNumber: Maybe<Scalars['String']['output']>;
}

export interface IBillingAddressInput {
  /** Country */
  readonly country?: InputMaybe<Scalars['String']['input']>;
  /** City */
  readonly locality?: InputMaybe<Scalars['String']['input']>;
  /** Zip Code */
  readonly postalCode: Scalars['String']['input'];
  /** State */
  readonly region?: InputMaybe<Scalars['String']['input']>;
  /** Street Address */
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  /** Apt or Unit Number */
  readonly unitNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IBrameEventAttributes {
  readonly loyaltyId: Scalars['ID']['input'];
  readonly userEmail: Scalars['String']['input'];
  readonly userId: Scalars['ID']['input'];
}

export enum Brand {
  BK = 'BK',
  FHS = 'FHS',
  LTW = 'LTW',
  PLK = 'PLK',
  TH = 'TH',
}

export interface IBrazeUserPhone {
  readonly __typename?: 'BrazeUserPhone';
  readonly phone: Maybe<Scalars['String']['output']>;
}

/** needed for generate vr payment vault link */
export interface IBrowserInfoInput {
  readonly colorDepth: Scalars['Int']['input'];
  readonly screenHeight: Scalars['Int']['input'];
  readonly screenWidth: Scalars['Int']['input'];
  readonly timeZoneOffset: Scalars['Int']['input'];
}

export interface ICancelOrderInput {
  readonly rbiOrderId: Scalars['ID']['input'];
}

export interface ICart {
  readonly __typename?: 'Cart';
  readonly appliedOffers: Maybe<ReadonlyArray<Maybe<ICartAppliedOffer>>>;
  readonly brand: Brand;
  readonly cartEntries: Maybe<ReadonlyArray<ICartEntries>>;
  readonly cartVersion: Maybe<Scalars['Int']['output']>;
  readonly customerLocale: Maybe<Scalars['String']['output']>;
  readonly customerName: Scalars['String']['output'];
  readonly deviceOS: Maybe<Scalars['String']['output']>;
  readonly discounts: Maybe<ReadonlyArray<Maybe<IDiscount>>>;
  readonly donations: Maybe<ReadonlyArray<Maybe<IDonation>>>;
  readonly fees: Maybe<ReadonlyArray<Maybe<IFees>>>;
  readonly guestDetails: Maybe<IGuestDetails>;
  readonly guestId: Maybe<Scalars['String']['output']>;
  /**
   * (Deprecated) Used for CBA Offers
   * @deprecated CBA Offers were deprecated
   */
  readonly offersFeedback: Maybe<ReadonlyArray<Maybe<ICouponUserOffersFeedbackEntry>>>;
  readonly payment: Maybe<IPayment>;
  readonly platform: Maybe<Platform>;
  readonly posVendor: PosVendor;
  /**
   * ISO Timestamp from POS in local timezone e.g 2019-07-08T23:02:17.203Z
   * @deprecated Only for internal use
   */
  readonly processedAt: Maybe<Scalars['String']['output']>;
  /**
   * Cart entries for reordering.
   * This is usually the same as cartEntries except for when there are swaps.
   */
  readonly reorderCartEntries: Maybe<ReadonlyArray<ICartEntries>>;
  readonly requestedAmountCents: Scalars['Int']['output'];
  readonly rewardsApplied: Maybe<ReadonlyArray<Maybe<ICartAppliedReward>>>;
  readonly serviceMode: ServiceMode;
  readonly storeAddress: IStoreAddress;
  readonly storeDetails: Maybe<IStoreDetails>;
  readonly storeId: Scalars['String']['output'];
  readonly storePosId: Maybe<Scalars['String']['output']>;
  readonly subTotalCents: Maybe<Scalars['Int']['output']>;
  /** @deprecated Not needed anymore since it is included in AppliedOffer */
  readonly swaps: Maybe<ReadonlyArray<Maybe<ICartSwap>>>;
  readonly taxCents: Maybe<Scalars['Int']['output']>;
  readonly ticketNumber: Maybe<Scalars['String']['output']>;
  readonly totalCents: Maybe<Scalars['Int']['output']>;
  readonly unavailableCartEntries: Maybe<ReadonlyArray<Maybe<IUnavailableCartEntry>>>;
  readonly userDetails: Maybe<IUserDetails>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly vatNumber: Maybe<Scalars['String']['output']>;
  readonly wasLoyaltyEarned: Maybe<Scalars['Boolean']['output']>;
}

export interface ICartAppliedOffer {
  readonly __typename?: 'CartAppliedOffer';
  readonly cartId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['ID']['output'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId: Maybe<Scalars['ID']['output']>;
  readonly swap: Maybe<ICartSwap>;
  readonly type: OfferType;
}

/** AppliedReward is duplicated here to avoid breaking changes as above definition serves as an input. */
export interface ICartAppliedReward {
  readonly __typename?: 'CartAppliedReward';
  readonly cartId: Scalars['ID']['output'];
  readonly price: Maybe<Scalars['Int']['output']>;
  readonly rewardId: Scalars['ID']['output'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId: Maybe<Scalars['ID']['output']>;
  readonly timesApplied: Scalars['Int']['output'];
}

export interface ICartEntries {
  readonly __typename?: 'CartEntries';
  readonly children: Maybe<ReadonlyArray<Maybe<ICartEntries>>>;
  readonly image: Maybe<Scalars['String']['output']>;
  readonly isDonation: Maybe<Scalars['Boolean']['output']>;
  readonly isExtra: Maybe<Scalars['Boolean']['output']>;
  readonly isInMenu: Maybe<Scalars['Boolean']['output']>;
  readonly lineId: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly offerVendorConfigs: Maybe<IVendorConfigs>;
  readonly pickerSelections: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<IPlu>;
  readonly price: Maybe<Scalars['Int']['output']>;
  readonly quantity: Scalars['Int']['output'];
  readonly redeemable: Maybe<Scalars['Boolean']['output']>;
  readonly sanityId: Scalars['String']['output'];
  readonly type: CartEntryType;
  readonly url: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface ICartEntryInput {
  readonly children?: InputMaybe<ReadonlyArray<ICartEntryInput>>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly isDonation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isExtra?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lineId: Scalars['String']['input'];
  readonly markerItemPlu?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly offerVendorConfigs?: InputMaybe<IVendorConfigsInput>;
  readonly pickerSelections?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<IPluInput>;
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly productHierarchy?: InputMaybe<IProductHierarchyInput>;
  readonly quantity: Scalars['Int']['input'];
  readonly sanityId: Scalars['String']['input'];
  readonly type: CartEntryType;
  readonly url?: InputMaybe<Scalars['String']['input']>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsInput>;
}

export enum CartEntryType {
  COMBO = 'Combo',
  COMBOSLOT = 'ComboSlot',
  ITEM = 'Item',
  ITEMOPTION = 'ItemOption',
  ITEMOPTIONMODIFIER = 'ItemOptionModifier',
  OFFER = 'Offer',
  OFFERCOMBO = 'OfferCombo',
  OFFERDISCOUNT = 'OfferDiscount',
  OFFERITEM = 'OfferItem',
  REWARD = 'Reward',
}

export enum CartOfferState {
  ACTIVE = 'ACTIVE',
  CONSUMED = 'CONSUMED',
  INACTIVE = 'INACTIVE',
}

export enum CartPaymentCardType {
  AMEX = 'AMEX',
  APPLEPAYLINK = 'APPLEPAYLINK',
  APPLE_PAY = 'APPLE_PAY',
  BIZUM = 'BIZUM',
  BLIK = 'BLIK',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  DINERS = 'DINERS',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  GIFT_CARD = 'GIFT_CARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  IDEAL = 'IDEAL',
  JCB = 'JCB',
  MADA = 'MADA',
  MAESTRO = 'MAESTRO',
  MASTERCARD = 'MASTERCARD',
  MBWAY = 'MBWAY',
  PAYPAL = 'PAYPAL',
  TWINT = 'TWINT',
  VISA = 'VISA',
  WAYLET = 'WAYLET',
}

export enum CartPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  BLIK = 'BLIK',
  CASH = 'CASH',
  CREDIT_ANONYMOUS = 'CREDIT_ANONYMOUS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  MBWAY = 'MBWAY',
  PAYPAL = 'PAYPAL',
  TWINT = 'TWINT',
  VAULTED_ACCOUNT = 'VAULTED_ACCOUNT',
}

export interface ICartSwap {
  readonly __typename?: 'CartSwap';
  readonly cartId: Scalars['String']['output'];
  readonly from: Scalars['String']['output'];
  readonly swapType: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
}

export interface ICatering {
  readonly __typename?: 'Catering';
  readonly declineReason: Maybe<CateringDeclineReasons>;
  readonly notes: Maybe<Scalars['String']['output']>;
  readonly pickupTime: Scalars['String']['output'];
}

export enum CateringDecision {
  ACCEPT = 'Accept',
  DECLINE = 'Decline',
}

export enum CateringDeclineReasons {
  CATERING_ORDER_TIME_OUT = 'CATERING_ORDER_TIME_OUT',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVENTORY = 'INVENTORY',
  STAFFING = 'STAFFING',
  TIMING = 'TIMING',
}

export interface ICateringInfoInput {
  /**
   * Pickup Time - in ISO format.
   * Only ISO-formatted datetime values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly pickupTime: Scalars['String']['input'];
}

export interface ICognitoCredentials {
  readonly __typename?: 'CognitoCredentials';
  readonly challengeCode: Scalars['String']['output'];
  readonly email: Maybe<Scalars['String']['output']>;
  readonly sessionId: Scalars['String']['output'];
  /** Data corresponding to user requesting credentials */
  readonly user: IUser;
}

export interface ICommitDeliveryInput {
  /**
   * drop off location for delivery (user's address)
   * this can be marked as required when launch darkly
   * flag is no longer in use
   *
   * @deprecated(reason: "needs to be named dropoff")
   */
  readonly dropOff?: InputMaybe<IDeliveryWaypointInput>;
  readonly dropoff?: InputMaybe<IDeliveryWaypointInput>;
  readonly instructions?: InputMaybe<Scalars['String']['input']>;
  /** boolean flag to mark notification setting */
  readonly pushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time selected for scheduled delivery */
  readonly selectedPreOrderTimeSlot?: InputMaybe<IPreOrderTimeSlotInput>;
  readonly tipCents?: InputMaybe<Scalars['Int']['input']>;
}

export interface ICommitOrderInput {
  /** The Additional details provided to pass Additional Info from FE to Partners API. */
  readonly additionalDetails?: InputMaybe<Scalars['String']['input']>;
  /**
   * Details from Apple Pay for payment.
   * @deprecated(reason: "Use payment object")
   */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /**
   * Billing address Comes in the following format
   * billingStreetAddress, billingApt, billingCity, billingState, billingZip
   * @deprecated(reason: "Use payment object")
   */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @deprecated(reason: "Use payment object")
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /**
   * Credit card 2 digit expiry month
   * @deprecated(reason: "Use payment object")
   */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /**
   * Credit card 2 digit expiry year
   * @deprecated(reason: "Use payment object")
   */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @deprecated(reason: "Use payment object")
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /**
   * chase id from TH endpoint to trigger direct payment
   * @deprecated(reason: "Use payment object")
   */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX)
   * @deprecated(reason: "Use payment object")
   */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
  /** Device Session Id for Checkout.com risk assessment */
  readonly deviceSessionId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Valid access token for accessing First Data API
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * The First Data payment method ID which must be associated to the user
   * making the request.
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce.
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Takes in a time in seconds to delay the order commit */
  readonly fireOrderIn?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Customer full name
   * @deprecated(reason: "Use payment object")
   */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Details from Google Pay for payment.
   * @deprecated(reason: "Use payment object")
   */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /** The email address provided for Guest Order purposes. */
  readonly guestEmail?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   * @deprecated(reason: "Use payment object")
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** Contains information to submit payment for this order */
  readonly payment?: InputMaybe<IPaymentInput>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['ID']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Flag that indicates that the commit order request should only be saved to be used by the backend commit order */
  readonly saveCommitOrderOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Unique Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   *
   * @deprecated(reason: "Use kountSessionId on payment object")
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** Store email address */
  readonly storeEmail?: InputMaybe<Scalars['String']['input']>;
  /** Store ID, derived from MDM. This value will be used for LD restrictions. */
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  /** Details from 3DS transactions, relates to properties needed to resume sale transactions. */
  readonly threeDSDetails?: InputMaybe<IResumeThreeDSaleTransaction>;
}

export interface ICommunicationPreference {
  readonly __typename?: 'CommunicationPreference';
  /** Database id of the preference (i.e. language) */
  readonly id: Maybe<Scalars['String']['output']>;
  /** Value the preference is set to */
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface ICommunicationPreferenceInput {
  readonly asBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  readonly asNumber?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
}

export interface IConfirmCateringOrderInput {
  /** Catering JWT containing catering order info */
  readonly cateringJwt: Scalars['String']['input'];
  /** Accept or Decline Order */
  readonly decision: CateringDecision;
  /** Decline Reason */
  readonly declineReason?: InputMaybe<CateringDeclineReasons>;
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Connection-Types
 *
 * A connection as defined by the GraphQL Cursor Connections Specifications
 *
 * Provides totalCount if there is an efficient way to determine the total
 * pre-paginated results.
 *
 * Provides an array of nodes for consumers that do not need each node's cursor.
 */
export interface IConnection {
  readonly edges: ReadonlyArray<IEdge>;
  readonly nodes: ReadonlyArray<INode>;
  readonly pageInfo: IPageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

/**
 * Coordinates is used by restaurants search to filter
 * by a radius and the current user's coordinates
 */
export interface ICoordinates {
  readonly searchRadius?: InputMaybe<Scalars['Float']['input']>;
  readonly userLat?: InputMaybe<Scalars['Float']['input']>;
  readonly userLng?: InputMaybe<Scalars['Float']['input']>;
}

export enum CountryCode {
  AE = 'AE',
  AQ = 'AQ',
  AR = 'AR',
  AT = 'AT',
  AU = 'AU',
  BH = 'BH',
  BR = 'BR',
  CA = 'CA',
  CH = 'CH',
  CL = 'CL',
  CZ = 'CZ',
  DE = 'DE',
  EG = 'EG',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IE = 'IE',
  KR = 'KR',
  KW = 'KW',
  MA = 'MA',
  MX = 'MX',
  MY = 'MY',
  NL = 'NL',
  NZ = 'NZ',
  OM = 'OM',
  PH = 'PH',
  PL = 'PL',
  PR = 'PR',
  PT = 'PT',
  QA = 'QA',
  RO = 'RO',
  SA = 'SA',
  SG = 'SG',
  TW = 'TW',
  US = 'US',
  ZA = 'ZA',
}

/** To be removed after old clients update */
export interface ICouponEvaluationFeedback {
  readonly __typename?: 'CouponEvaluationFeedback';
  /** @deprecated CBA Offers were deprecated */
  readonly code: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly condition: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly expiresOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly message: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemableForSeconds: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemableInSeconds: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetIndex: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetType: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly sanityId: Maybe<Scalars['String']['output']>;
}

/** To be removed after old clients update */
export interface ICouponRedemptionEligibility {
  readonly __typename?: 'CouponRedemptionEligibility';
  /** @deprecated CBA Offers were deprecated */
  readonly evaluationFeedback: Maybe<ReadonlyArray<Maybe<ICouponEvaluationFeedback>>>;
  /** @deprecated CBA Offers were deprecated */
  readonly expiresOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isRedeemable: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isValid: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemedOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly validationErrors: Maybe<ReadonlyArray<Maybe<ICouponValidationError>>>;
}

/** To be removed after old clients update */
export interface ICouponUserOffersFeedbackEntry {
  readonly __typename?: 'CouponUserOffersFeedbackEntry';
  /** @deprecated CBA Offers were deprecated */
  readonly cartEntry: Maybe<ICartEntries>;
  /** @deprecated CBA Offers were deprecated */
  readonly couponId: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isLoyalty: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerDetails: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerState: Maybe<CartOfferState>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerVariables: Maybe<ReadonlyArray<Maybe<IOfferVariable>>>;
  /** @deprecated CBA Offers were deprecated */
  readonly rank: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redemptionEligibility: Maybe<ICouponRedemptionEligibility>;
  /** @deprecated CBA Offers were deprecated */
  readonly tokenId: Maybe<Scalars['String']['output']>;
}

/** To be removed after old clients update */
export interface ICouponValidationError {
  readonly __typename?: 'CouponValidationError';
  /** @deprecated CBA Offers were deprecated */
  readonly code: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly message: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetType: Maybe<Scalars['String']['output']>;
}

export interface ICreateOtp {
  readonly __typename?: 'CreateOTP';
  /**
   * The maximum validation attempts allowed for the created OTP
   * @deprecated Use maxValidateAttempts
   */
  readonly maxLoginAttempts: Scalars['Int']['output'];
  /** The maximum validation attempts allowed for the created OTP */
  readonly maxValidateAttempts: Scalars['Int']['output'];
  /** Whether the login has been performed in US and requires redirection */
  readonly redirectToUS: Maybe<Scalars['Boolean']['output']>;
  /** The time-to-live for the one-time password, in seconds */
  readonly ttl: Scalars['Int']['output'];
}

export interface ICreateOtpInput {
  /**
   * The email of the user requesting a One-Time Password. Only one of either
   * email or phoneNumber can be provided. One or the other is required.
   * @todo - only email is implemented at this time
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number of the user requesting a One-Time Password. Only one of either
   * email or phoneNumber can be provided. One or the other is required.
   * @todo - not implemented
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Requestor's platform */
  readonly platform: Platform;
  /**
   * A unique identifier to associate with the one-time password, to be provided on
   * validation attempts
   */
  readonly sessionId: Scalars['ID']['input'];
}

/** create paycomet pre-authorization */
export interface ICreatePaycometPreAuthorizationInput {
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  readonly cardHolderName: Scalars['String']['input'];
  readonly dateMonth: Scalars['String']['input'];
  readonly dateYear: Scalars['String']['input'];
  readonly ipOrigin: Scalars['String']['input'];
  readonly paytpvToken?: InputMaybe<Scalars['String']['input']>;
  readonly rbiOrderId: Scalars['String']['input'];
  readonly saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  readonly userPaymentDetails?: InputMaybe<IUserPaymentDetails>;
}

export interface ICreditCard {
  readonly __typename?: 'CreditCard';
  /** Credit card last 4 digits */
  readonly alias: Scalars['String']['output'];
  readonly billingAddress: Maybe<IBillingAddress>;
  /** Card Provider */
  readonly cardType: Scalars['String']['output'];
  /** Two digit expiry month */
  readonly expiryMonth: Scalars['String']['output'];
  /** Two digit expiry year */
  readonly expiryYear: Scalars['String']['output'];
  readonly fullName: Scalars['String']['output'];
  /**
   * First Data credit card token.
   *
   * Should be a numeric string, whose last 4 digits match the credit card alias.
   */
  readonly panToken: Maybe<Scalars['String']['output']>;
}

export interface ICreditCardMetadataInput {
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry month */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry year */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /** The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX) */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
}

/**  USD/840 CAD/124 */
export interface ICurrencyCode {
  readonly __typename?: 'CurrencyCode';
  readonly code: Scalars['String']['output'];
  readonly number: Scalars['Int']['output'];
}

export interface ICustomerFacingAddress {
  readonly __typename?: 'CustomerFacingAddress';
  readonly locale: Maybe<Scalars['String']['output']>;
}

export interface ICybersourceCaptureContext {
  readonly __typename?: 'CybersourceCaptureContext';
  readonly context: Scalars['String']['output'];
}

export interface ICybersourceCaptureContextInput {
  readonly operation: CybersourcePaymentInitiationOperation;
  readonly targetOrigin: Scalars['String']['input'];
}

export interface ICybersourcePaSetup {
  readonly __typename?: 'CybersourcePaSetup';
  readonly accessToken: Scalars['String']['output'];
  readonly deviceDataCollectionUrl: Scalars['String']['output'];
  readonly rbiOrderId: Scalars['String']['output'];
  readonly referenceId: Scalars['String']['output'];
}

export interface ICybersourcePaSetupInput {
  readonly operation: CybersourcePaymentInitiationOperation;
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  readonly rbiOrderId: Scalars['String']['input'];
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export enum CybersourcePaymentInitiationOperation {
  CAPTURE_CONTEXT = 'CAPTURE_CONTEXT',
  PA_SETUP = 'PA_SETUP',
}

export interface IDay {
  readonly __typename?: 'Day';
  readonly close: Maybe<Scalars['String']['output']>;
  readonly open: Maybe<Scalars['String']['output']>;
}

export interface IDayPart {
  readonly __typename?: 'DayPart';
  readonly displayName: Maybe<Scalars['String']['output']>;
  readonly hours: Maybe<IOperatingHours>;
  readonly key: Maybe<Scalars['String']['output']>;
}

/** Delete an account (payment method) from the current user */
export interface IDeleteAccountInput {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * Use this field instead.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Account ID for account to be deleted */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
}

export interface IDelivery {
  readonly __typename?: 'Delivery';
  readonly assignedFleetName: Maybe<Scalars['String']['output']>;
  readonly baseDeliveryFeeCents: Maybe<Scalars['Int']['output']>;
  readonly cancellation: Maybe<IDeliveryCancellation>;
  readonly customerId: Maybe<Scalars['String']['output']>;
  readonly deliverySurchargeFeeCents: Maybe<Scalars['Int']['output']>;
  readonly driver: Maybe<IDeliveryDriver>;
  readonly dropoff: IDeliveryWaypoint;
  readonly estimateId: Maybe<Scalars['String']['output']>;
  readonly expiration: Maybe<Scalars['String']['output']>;
  readonly feeCents: Scalars['Int']['output'];
  readonly feeDiscountCents: Maybe<Scalars['Int']['output']>;
  readonly fleetId: Maybe<Scalars['String']['output']>;
  readonly geographicalFeeCents: Maybe<Scalars['Int']['output']>;
  readonly instructions: Maybe<Scalars['String']['output']>;
  readonly pickup: IDeliveryWaypoint;
  readonly pincode: Maybe<Scalars['String']['output']>;
  readonly preOrderTimeSlots: Maybe<ReadonlyArray<Maybe<IPreOrderTimeSlot>>>;
  readonly quoteId: Maybe<Scalars['String']['output']>;
  readonly quotedFeeCents: Maybe<Scalars['Int']['output']>;
  readonly serviceFeeCents: Maybe<Scalars['Int']['output']>;
  readonly serviceFeeDiscountCents: Maybe<Scalars['Int']['output']>;
  readonly shareUuid: Maybe<Scalars['String']['output']>;
  readonly smallCartFeeCents: Maybe<Scalars['Int']['output']>;
  readonly status: DeliveryStatus;
  readonly task: Maybe<IDeliveryTaskDetails>;
  readonly tipCents: Maybe<Scalars['Int']['output']>;
  readonly unavailabilityReason: Maybe<Scalars['String']['output']>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly vendorProperties: Maybe<IVendor>;
}

export interface IDeliveryAddress {
  readonly __typename?: 'DeliveryAddress';
  /** Address Line limited to 150 chars */
  readonly addressLine1: Scalars['String']['output'];
  /** Address Line 2 limited to 150 chars */
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  /** Alias to identify an address e.g home, office etc */
  readonly alias: Maybe<Scalars['String']['output']>;
  /** city name limited to 150 chars */
  readonly city: Scalars['String']['output'];
  /** isoCountryCode */
  readonly country: Maybe<IsoCountryCode>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  /** 10 digit phone number spaces don't matter */
  readonly phoneNumber: Scalars['String']['output'];
  readonly route: Maybe<Scalars['String']['output']>;
  /** state abbreviation FL, GA, etc */
  readonly state: Maybe<Scalars['String']['output']>;
  readonly streetNumber: Maybe<Scalars['String']['output']>;
  /** zipCode 5-10 digits no spaces */
  readonly zip: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryCancellation {
  readonly __typename?: 'DeliveryCancellation';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly details: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['Int']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryDriver {
  readonly __typename?: 'DeliveryDriver';
  readonly location: Maybe<ILocation>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly profileImageUrl: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryOrderAmountLimit {
  readonly __typename?: 'DeliveryOrderAmountLimit';
  readonly deliveryOrderAmountLimit: Maybe<Scalars['Float']['output']>;
  readonly deliveryOrderAmountLimitEnabled: Maybe<Scalars['Boolean']['output']>;
  readonly deliveryOrderRepeatedFailureLimitation: Maybe<Scalars['Boolean']['output']>;
  readonly firstDeliveryOrder: Maybe<Scalars['Float']['output']>;
  readonly firstDeliveryOrderEnabled: Maybe<Scalars['Boolean']['output']>;
}

export interface IDeliveryRestaurant {
  readonly __typename?: 'DeliveryRestaurant';
  /** Restaurant delivery surcharge fee cents. */
  readonly deliverySurchargeFeeCents: Maybe<Scalars['Int']['output']>;
  /** Next available delivery time for store */
  readonly nextEarliestOpen: Maybe<Scalars['String']['output']>;
  /** Pre order timeslots. */
  readonly preOrderTimeSlots: Maybe<ReadonlyArray<Maybe<IPreOrderTimeSlot>>>;
  /** Status of delivery order */
  readonly quote: Maybe<DeliveryStatus>;
  /** the quoteId related to the current delivery restaurant */
  readonly quoteId: Maybe<Scalars['String']['output']>;
  /** Extended restaurant data containing all fields from Sanity + Dynamo */
  readonly restaurant: Maybe<IRestaurantNode>;
  /** Status of delivery store */
  readonly storeStatus: DeliveryStoreStatus;
  /** Reason for the store being unavailable, if any */
  readonly unavailabilityReason: Maybe<Scalars['String']['output']>;
}

export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_NOT_REQUIRED = 'QUOTE_NOT_REQUIRED',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
}

export enum DeliveryStoreStatus {
  CLOSED = 'CLOSED',
  NO_DELIVERY = 'NO_DELIVERY',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
}

export interface IDeliveryTaskDetails {
  readonly __typename?: 'DeliveryTaskDetails';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
  readonly uuid: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryWaypoint {
  readonly __typename?: 'DeliveryWaypoint';
  readonly addressLine1: Scalars['String']['output'];
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  readonly afterTime: Maybe<Scalars['String']['output']>;
  readonly arrivalTime: Maybe<Scalars['String']['output']>;
  readonly beforeTime: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly country: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly phoneNumber: Scalars['String']['output'];
  readonly shareUuid: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['String']['output']>;
  readonly trackingUrl: Maybe<Scalars['String']['output']>;
  readonly zip: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryWaypointInput {
  readonly addressLine1: Scalars['String']['input'];
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly latitude?: InputMaybe<Scalars['Float']['input']>;
  readonly longitude?: InputMaybe<Scalars['Float']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
  readonly route?: InputMaybe<Scalars['String']['input']>;
  /** flag to determine wether or not the waypoint should be saved */
  readonly shouldSave?: InputMaybe<Scalars['Boolean']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface IDiscount {
  readonly __typename?: 'Discount';
  readonly name: Maybe<DiscountTypes>;
  /** values are represented as positive integers in cents */
  readonly value: Maybe<Scalars['Int']['output']>;
}

export enum DiscountTypes {
  ALL_DISCOUNTS = 'ALL_DISCOUNTS',
  COMBO_AND_OFFER_DISCOUNTS = 'COMBO_AND_OFFER_DISCOUNTS',
  REWARDS_DISCOUNTS = 'REWARDS_DISCOUNTS',
}

export interface IDonation {
  readonly __typename?: 'Donation';
  readonly id: Maybe<Scalars['String']['output']>;
  /** Name of donation */
  readonly name: Maybe<Scalars['String']['output']>;
  /** Total are represented as positive integers in cents */
  readonly totalCents: Maybe<Scalars['Int']['output']>;
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Edge-Types
 *
 * An edge provides an association between a node and it's associated cursor.
 */
export interface IEdge {
  readonly cursor: Scalars['String']['output'];
  readonly node: INode;
}

/** All details necessary to encrypt CC information for First Data */
export interface IEncryptionDetails {
  readonly __typename?: 'EncryptionDetails';
  /**
   * Encryption algorithm.
   *
   * You should be able to ignore this value, as we only support 'RSA/NONE/PKCS1Padding'
   */
  readonly algorithm: Maybe<Scalars['String']['output']>;
  /** Access token for accessing First Data API. Limited TTL. */
  readonly fdAccessToken: Scalars['String']['output'];
  /** RBI specific API key for accessing First Data */
  readonly fdApiKey: Scalars['String']['output'];
  /** First data customer ID. Derived from provided authentication header. */
  readonly fdCustomerId: Maybe<Scalars['String']['output']>;
  /** Public key for performing RSA encryption on specific First Data requests */
  readonly fdPublicKey: Scalars['String']['output'];
  /** Time until public key expires (milliseconds?) */
  readonly fdPublicKeyExpiresInSeconds: Scalars['Int']['output'];
  /** ISO datetime for when public key was issued */
  readonly fdPublicKeyIssuesOn: Scalars['String']['output'];
  /** First data tokenizationUrl. */
  readonly fdtokenizationUrl: Maybe<Scalars['String']['output']>;
}

export interface IEventDetails {
  /**
   * eventAttributes sent to mParticle
   * now only the one of Brame event is added, can add more to support other events
   */
  readonly eventAttributes: IBrameEventAttributes;
  readonly eventName: Scalars['String']['input'];
}

export interface IEvertecInitiateSessionInput {
  readonly callbackUrl: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly rbiOrderId: Scalars['String']['input'];
  readonly totalAmount: Scalars['Float']['input'];
}

export interface IEvertecInitiateSessionResponse {
  readonly __typename?: 'EvertecInitiateSessionResponse';
  readonly processUrl: Maybe<Scalars['String']['output']>;
}

export interface IExchangeOtpCodeForCredentialsInput {
  /** The code associated with the user's current OTP, for validation */
  readonly code: Scalars['String']['input'];
  /**
   * The email of the user requesting a One-Time Password. Optional, one of either
   * email or phoneNumber must always be provided
   * @todo - only email is implemented at this time
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number of the user logging in with a One-Time Password. Optional, one of either
   * email or phoneNumber must always be provided.
   * @todo - not implemented
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId: Scalars['ID']['input'];
}

export interface IFavorite {
  readonly __typename?: 'Favorite';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly entries: Maybe<ReadonlyArray<Maybe<ICartEntries>>>;
  readonly favoriteId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly ref: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteInput {
  readonly entries?: InputMaybe<ReadonlyArray<InputMaybe<ICartEntryInput>>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly ref?: InputMaybe<Scalars['String']['input']>;
}

export interface IFavoriteList {
  readonly __typename?: 'FavoriteList';
  readonly count: Maybe<Scalars['Int']['output']>;
  readonly favorites: Maybe<ReadonlyArray<Maybe<IFavorite>>>;
  readonly resumeAfter: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteOffer {
  readonly __typename?: 'FavoriteOffer';
  /** Favorite Offer ID */
  readonly id: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteOfferInput {
  readonly id?: InputMaybe<Scalars['String']['input']>;
}

export interface IFavoriteStore {
  readonly __typename?: 'FavoriteStore';
  /** Favorite Store ID */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /** Favorite Store Number */
  readonly storeNumber: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteStoreInput {
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly storeNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IFavoriteStoreSignUpInput {
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly storeNumber: Scalars['String']['input'];
}

export interface IFees {
  readonly __typename?: 'Fees';
  readonly name: Maybe<Scalars['String']['output']>;
  readonly priceCents: Maybe<Scalars['Int']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
  readonly totalCents: Maybe<Scalars['Int']['output']>;
}

export enum FilterRestaurantType {
  FAVORITE = 'FAVORITE',
  NEARBY = 'NEARBY',
  RECENT = 'RECENT',
}

export interface IFirstDataHostedPageLink {
  readonly __typename?: 'FirstDataHostedPageLink';
  readonly callbackUrl: Scalars['String']['output'];
  readonly chargetotal: Scalars['Float']['output'];
  readonly checkoutoption: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly customParam_storeId: Scalars['String']['output'];
  readonly hashExtended: Scalars['String']['output'];
  readonly hash_algorithm: Scalars['String']['output'];
  readonly host: Scalars['String']['output'];
  readonly language: Scalars['String']['output'];
  readonly merchantTransactionId: Scalars['String']['output'];
  readonly parentUri: Scalars['String']['output'];
  readonly paymentMethod: Scalars['String']['output'];
  readonly responseFailURL: Scalars['String']['output'];
  readonly responseSuccessURL: Scalars['String']['output'];
  readonly storename: Scalars['String']['output'];
  readonly timezone: Scalars['String']['output'];
  readonly txndatetime: Scalars['String']['output'];
  readonly txntype: Scalars['String']['output'];
}

export interface IFirstDataPayment {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or fdNonce
   * and fdAccessToken should be passed.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Valid access token for accessing First Data API */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of customer received from First Data */
  readonly fdCustomerId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce. If accountIdentifier is not passed this must
   * be passed.
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IFirstpayCheckoutLink {
  readonly __typename?: 'FirstpayCheckoutLink';
  readonly link: Scalars['String']['output'];
}

export interface IFirstpayPayment {
  /** Merchant Account */
  readonly merchantAccount?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IFirstpayVaultedLink {
  readonly __typename?: 'FirstpayVaultedLink';
  readonly link: Scalars['String']['output'];
}

export interface IFlavorFlowItem {
  readonly __typename?: 'FlavorFlowItem';
  /** The item id of recommendation item */
  readonly id: Scalars['String']['output'];
}

export interface IFlavorFlowItemInput {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly sublevelItems?: InputMaybe<ReadonlyArray<InputMaybe<IFlavorFlowSubItem>>>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
}

export interface IFlavorFlowItemResult {
  readonly __typename?: 'FlavorFlowItemResult';
  readonly recommendationToken: Maybe<Scalars['String']['output']>;
  readonly recommender: Maybe<Scalars['String']['output']>;
  readonly results: Maybe<ReadonlyArray<Maybe<IFlavorFlowItem>>>;
}

export interface IFlavorFlowSubItem {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export enum FlavorFlowType {
  ADD_TO_CART = 'ADD_TO_CART',
  CHECKOUT = 'CHECKOUT',
}

export interface IFulfillmentDetails {
  readonly __typename?: 'FulfillmentDetails';
  readonly pickupPin: Maybe<Scalars['String']['output']>;
  readonly pickupReady: Maybe<Scalars['Boolean']['output']>;
  readonly pickupType: Maybe<FulfillmentPickupType>;
}

export enum FulfillmentPickupType {
  BEEPER = 'BEEPER',
  LOCKER = 'LOCKER',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** generate ideal link */
export interface IGenerateIdealCheckoutLinkInput {
  readonly returnUrl: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['String']['input'];
}

/** generate vr payment PayPalFast link */
export interface IGenerateVrPaymentPayPalFastLinkInput {
  readonly deviceToken: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['String']['input'];
  readonly response: Scalars['String']['input'];
}

/** generate vr payment vault link */
export interface IGenerateVrPaymentVaultLinkInput {
  readonly accountIdentifier: Scalars['String']['input'];
  readonly browserInfo: IBrowserInfoInput;
  readonly rbiOrderId: Scalars['String']['input'];
}

/** A First Data gift card account (prepaid payment method) */
export interface IGiftAccount {
  readonly __typename?: 'GiftAccount';
  /** First Data account ID */
  readonly fdAccountId: Scalars['String']['output'];
  readonly prepaid: IGiftCard;
}

/** First Data prepaid account */
export interface IGiftCard {
  readonly __typename?: 'GiftCard';
  /** last 4 digits of prepaid gift card number */
  readonly alias: Scalars['String']['output'];
  readonly cardNumber: Scalars['String']['output'];
  /** amount card was loaded with (cents) upon creation */
  readonly currentBalance: Maybe<Scalars['Int']['output']>;
  /** Two digit expiry month */
  readonly expiryMonth: Scalars['String']['output'];
  /** Two digit expiry year */
  readonly expiryYear: Scalars['String']['output'];
  readonly feFormattedCurrentBalance: Maybe<Scalars['Int']['output']>;
  /** Use to generate barcode */
  readonly shuffledCardNumber: Scalars['String']['output'];
}

/** Details from Google Pay used for Google Pay funding. */
export interface IGooglePay {
  /** Data of the payment method used for Google Pay. */
  readonly PaymentMethodData?: InputMaybe<IPaymentMethodData>;
  /** Billing address from Google Pay */
  readonly billingAddress: IBillingAddressInput;
  /** Country Code from user account */
  readonly country: CountryCode;
  /** Signature message passed through from Google Pay */
  readonly data: Scalars['String']['input'];
  /** Formatted billing address from Google Pay */
  readonly formatted: Scalars['String']['input'];
  /** Encrypted token including signature and signature message as passed through from Google Pay. */
  readonly paymentData?: InputMaybe<Scalars['String']['input']>;
  readonly primary: Scalars['Boolean']['input'];
  /** Signature as passed through from Google Pay */
  readonly signature: Scalars['String']['input'];
  readonly type: Scalars['String']['input'];
  readonly version: Scalars['String']['input'];
}

export interface IGuestDetails {
  readonly __typename?: 'GuestDetails';
  /** User's ISO Country Code (e.g. USA, CAN) */
  readonly isoCountryCode: Maybe<Scalars['String']['output']>;
}

export enum ITicketStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export interface IIdealCheckoutLink {
  readonly __typename?: 'IdealCheckoutLink';
  readonly action: IRedirectAction;
  readonly pspReference: Maybe<Scalars['String']['output']>;
  readonly result: Scalars['String']['output'];
}

export interface IInitiatePaymentConnectionInput {
  readonly userAgent: Scalars['String']['input'];
  readonly userIp: Scalars['String']['input'];
}

export interface IInitiatePaymentInput {
  readonly connection?: InputMaybe<IInitiatePaymentConnectionInput>;
  readonly cybersourceCaptureContext?: InputMaybe<ICybersourceCaptureContextInput>;
  readonly cybersourcePaSetup?: InputMaybe<ICybersourcePaSetupInput>;
  readonly evertecInitiateSession?: InputMaybe<IEvertecInitiateSessionInput>;
  readonly paycometCaptureContext?: InputMaybe<IPaycometCaptureContextInput>;
  readonly paymentMethodType: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
}

export interface IInitiatePaymentResponse {
  readonly __typename?: 'InitiatePaymentResponse';
  readonly cybersourceCaptureContext: Maybe<ICybersourceCaptureContext>;
  readonly cybersourcePaSetup: Maybe<ICybersourcePaSetup>;
  readonly evertecInitiateSession: Maybe<IEvertecInitiateSessionResponse>;
  readonly paycometCaptureContext: Maybe<IPaycometCaptureContextResponse>;
}

export interface IInstagramImage {
  readonly __typename?: 'InstagramImage';
  readonly media_type: MediaType;
  /** An instagram image */
  readonly media_url: Scalars['String']['output'];
  readonly timestamp: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
}

export interface IIntegration {
  readonly __typename?: 'Integration';
  readonly _key: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
  readonly isPartnerRestaurant: Maybe<Scalars['Boolean']['output']>;
  readonly partnerGroup: Maybe<IPartnerGroup>;
}

export interface IInvitationCode {
  readonly __typename?: 'InvitationCode';
  /** invitation code */
  readonly code: Maybe<Scalars['String']['output']>;
  /** User Cognito id */
  readonly cognitoId: Scalars['ID']['output'];
  /** User loyalty id */
  readonly loyaltyId: Scalars['String']['output'];
}

export { IsoCountryCode };

export interface ILocation {
  readonly __typename?: 'Location';
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
}

export interface ILoyaltyTransaction {
  readonly __typename?: 'LoyaltyTransaction';
  /** Bonus points earned. */
  readonly bonusPointsEarned: Scalars['Int']['output'];
  readonly id: Scalars['String']['output'];
  /** Points balance after this transaction. */
  readonly pointsBalance: Scalars['Int']['output'];
  /** Points earned. */
  readonly pointsEarned: Scalars['Int']['output'];
  /** Points used. */
  readonly pointsUsed: Scalars['Int']['output'];
  /** Number of rewards used. */
  readonly rewardsUsed: Scalars['Int']['output'];
}

export interface IMParticleEventAttributes {
  /**
   * User's device time in local time - 24 hr time
   * Example: 19:41:45
   */
  readonly device_time?: InputMaybe<Scalars['String']['input']>;
  /** MParticle device ID */
  readonly mp_deviceid?: InputMaybe<Scalars['String']['input']>;
}

export interface IMeInput {
  /** Should the unique items count extras added in the cart */
  readonly shouldIncludeExtras?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Should the unique items check for modifier differences
   * Example: Should a Medium Coffee with Cream and Sugar
   * be considered distinctly different from a
   * Medium Coffee with Cream and NO Sugar
   * Includes quantity as well.
   * Example: Medium Coffee with 1 Cream and 1 Sugar
   * is different than a Medium Coffee with 2 Cream and 1 Sugar
   */
  readonly shouldUniqueByModifiers?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum MediaType {
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

/** A Prepaid Merge two gift cards response */
export interface IMerge {
  readonly __typename?: 'Merge';
  /** dollar amount destination gift card begins with */
  readonly beginBalance: Scalars['Int']['output'];
  /** destination card currency code */
  readonly currencyCode: Scalars['String']['output'];
  /** new balance of destination gift card after merge in dollars */
  readonly currentBalance: Scalars['Int']['output'];
  /** card number of destination gift card */
  readonly destinationCardNumber: Scalars['String']['output'];
  readonly fdAccountId: Maybe<Scalars['String']['output']>;
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
  /** card number of source gift card that was deleted */
  readonly sourceCardNumber: Scalars['String']['output'];
  readonly transactionId: Scalars['String']['output'];
}

export interface IMissingItem {
  /** Line Id of item */
  readonly lineId: Scalars['String']['input'];
  /** Quantity of items requested for refund */
  readonly refundQuantity: Scalars['Int']['input'];
  /** Total quantity of items in a Combo or OfferCombo */
  readonly totalQuantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface IMissingOrderRefundDetails {
  readonly __typename?: 'MissingOrderRefundDetails';
  /** Amount refunded in cents */
  readonly approvedAmount: Scalars['Int']['output'];
  /** Date time when refund was issued */
  readonly transactionDateTime: Scalars['String']['output'];
}

export interface IMissingOrderResolution {
  readonly __typename?: 'MissingOrderResolution';
  /** Refund details */
  readonly refund: Maybe<IMissingOrderRefundDetails>;
  /** Zendesk support ticket id */
  readonly ticket: Maybe<Scalars['String']['output']>;
}

export interface IMutation {
  readonly __typename?: 'Mutation';
  /**
   * Add a Ayden or First Data account (credit card) for a customer.
   * Can throw MaxCreditCardsExistError.
   */
  readonly addCreditAccount: IAccount;
  /**
   * Add a gift account (gift card) for a customer using a vaulted fd account.
   * Can throw MaxGiftCardsExistError.
   */
  readonly addGiftAccount: IGiftAccount;
  /** Tries to associate an invitation code to a user with Loyalty */
  readonly associateInvitationCodeToLoyaltyUser: IInvitationCode;
  /** Attempt user login with OTP. */
  readonly attemptLoginOTP: IAttemptLoginOtpResponse;
  /** Tries to enroll a user in Loyalty */
  readonly autoEnrollUserInLoyalty: IUser;
  /**
   * Cancel an order using the store's PoS.
   *
   * This endpoint _must_ be called after committing the order.
   */
  readonly cancelOrder: IOrder;
  /**
   * Collect payments and commit the order to the PoS.
   *
   * If the service mode in the previously priced order was DRIVE_THRU or DELIVERY,
   * then the PoS will immediately start cooking the order. Else the PoS
   * will not cook the order until the customer arrives.
   *
   * (Please note that despite the name this is not a "transactional" flow)
   */
  readonly commitOrder: IOrder;
  /**
   * Store Member will choose wether to fulfill or accept the order at this point
   * Decline: will refund and send appropriate emails to user and store
   * Accept: will send a user and confirmation emails that the store will process
   * the order
   * @deprecated(reason: "Not needed anymore")
   */
  readonly confirmCateringOrder: IOrder;
  /** Create a favorite */
  readonly createFavorite: IFavorite;
  /** Request a jwt to auth for a given vendor */
  readonly createLoginForVendor: Maybe<Scalars['String']['output']>;
  /** Request a one-time password for the given user */
  readonly createLoginOTP: ICreateOtp;
  /** Create a loyalty user record associated with a given user */
  readonly createLoyaltyUser: IUser;
  /**
   * Request a one-time password for the given user
   * @deprecated Use createLoginOTP
   */
  readonly createOTP: ICreateOtp;
  /** Create Paycomet Pre-authorization */
  readonly createPaycometPreAuthorization: IPaycometCreatePreAuthorization;
  /** Create a support case */
  readonly createSupportCase: Maybe<ISupportCase>;
  /** Delete a customer's account */
  readonly deleteAccount: Maybe<Scalars['Boolean']['output']>;
  /** Delete a favorite */
  readonly deleteFavorite: IFavorite;
  /** Delete current user */
  readonly deleteMe: Maybe<Scalars['Boolean']['output']>;
  /** Fetch public key & access token for encrypting CC values. */
  readonly encryptionDetails: IEncryptionDetails;
  /**
   * Validates a previously-created OTP code and then exchanges the
   * valid code for Cognito credentials
   */
  readonly exchangeOTPCodeForCognitoCredentials: ICognitoCredentials;
  /** Generate a FirstData hosted page link (Initially for TWINT payment) */
  readonly generateFirstDataHostedPageLink: IFirstDataHostedPageLink;
  /** Generate a Firstpay checkout link */
  readonly generateFirstpayCheckoutLink: IFirstpayCheckoutLink;
  /** Generate a Firstpay vaulted link */
  readonly generateFirstpayVaultedLink: IFirstpayVaultedLink;
  /** Generate a Ideal link */
  readonly generateIdealCheckoutLink: IIdealCheckoutLink;
  /** Generate a Paycomet checkout link */
  readonly generatePaycometCheckoutLink: IPaycometCheckoutLink;
  /** Generate a PayMark checkout link */
  readonly generatePaymarkCheckoutLink: IPayMarkCheckoutLink;
  /** Generate a Vr Payment checkout link */
  readonly generateVrPaymentCheckoutLink: IVrPaymentCheckoutLink;
  /** Generate a Vr Payment PayPalFast link */
  readonly generateVrPaymentPayPalFastLink: IVrPaymentPayPalFastLink;
  /** Generate a Vr Payment vault link */
  readonly generateVrPaymentVaultLink: IVrPaymentVaultLink;
  /** Initiate a payment. */
  readonly initiatePayment: IInitiatePaymentResponse;
  /** Log PAYMENT_FLOW_STARTED DataDog metric. */
  readonly logPaymentFlowStartedEvent: Scalars['Boolean']['output'];
  /** Get paycomet card info */
  readonly paycometCardInfo: IPaycometCardInfoResponse;
  /**
   * Collect payments and send emails to user and the store for further acknowledgement
   * and fulfillment of this order
   * @deprecated(reason: "Not needed anymore")
   */
  readonly placeCateringOrder: IOrder;
  /** Get balance for prepaid account */
  readonly prepaidsBalance: IBalance;
  /** Transfer balance of prepaid gift card into one and delete it */
  readonly prepaidsMerge: IMerge;
  /** Reload a prepaid gift card account */
  readonly prepaidsReload: IReload;
  /**
   * Price an order using the store's PoS.
   *
   * This endpoint _must_ be called previous to finalizing the order.
   */
  readonly priceOrder: IOrder;
  readonly putFlavorFlowEvents: Maybe<Scalars['Boolean']['output']>;
  /** Kicks off email with all data we have on the user */
  readonly requestMyInfo: Maybe<Scalars['Boolean']['output']>;
  /** Re-sends the current OTP code for the given user email/phone number */
  readonly resendCurrentLoginOTP: ICreateOtp;
  /** Re-sends the verification email to the current user */
  readonly resendVerificationEmail: Maybe<Scalars['Boolean']['output']>;
  /** Re-sends the verification email to the current user */
  readonly sendPhoneVerification: Maybe<Scalars['Boolean']['output']>;
  /** Kicks off part one of Login Flow */
  readonly signInJWT: Maybe<Scalars['Boolean']['output']>;
  /** Sign up user with cognito returns a JWT */
  readonly signUp: Maybe<Scalars['String']['output']>;
  /** Track mParticle event via BE */
  readonly trackEventViaBE: Maybe<Scalars['Boolean']['output']>;
  /** Update a favorite */
  readonly updateFavorite: IFavorite;
  /** Update current user */
  readonly updateMe: IUser;
  /**
   * Update an order using the store's PoS.
   *
   * This endpoint _must_ be called after committing the order.
   */
  readonly updateOrder: IOrder;
  /**
   * Update the order data to set the commit order request body
   *
   * This endpoint _must_ be called if the backend commit order is enabled.
   */
  readonly updateOrderCommitRequest: IOrder;
  /**
   * User reports order or items in a order that are missing
   * @deprecated Use userRequestsRefund
   */
  readonly userReportsMissingOrder: IMissingOrderResolution;
  /** User requests a refund for an order or items that are missing or wrong. */
  readonly userRequestsRefund: IRefundRequestResponse;
  /** Validate the RBI generated JWT and exchange for cognito credentials */
  readonly validateAuthJwt: ICognitoCredentials;
  /** Runs all the validations required prior to order payment. */
  readonly validateCommitOrder: Maybe<IOrder>;
  /** Verifies the phone number for the given user */
  readonly verifyPhoneNumber: Maybe<Scalars['Boolean']['output']>;
  /** Void a sale, issuing a full refund to the original payment method */
  readonly voidSale: Maybe<ISale>;
}

export interface IMutationAddCreditAccountArgs {
  input: IAddCreditAccountInput;
}

export interface IMutationAddGiftAccountArgs {
  input: IAddGiftAccountInput;
}

export interface IMutationAssociateInvitationCodeToLoyaltyUserArgs {
  loyaltyId: Scalars['String']['input'];
}

export interface IMutationAttemptLoginOtpArgs {
  input: ICreateOtpInput;
}

export interface IMutationCancelOrderArgs {
  input: ICancelOrderInput;
}

export interface IMutationCommitOrderArgs {
  delivery?: InputMaybe<ICommitDeliveryInput>;
  input: ICommitOrderInput;
  skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IMutationConfirmCateringOrderArgs {
  input: IConfirmCateringOrderInput;
}

export interface IMutationCreateFavoriteArgs {
  input: IFavoriteInput;
}

export interface IMutationCreateLoginForVendorArgs {
  vendor: Scalars['String']['input'];
}

export interface IMutationCreateLoginOtpArgs {
  input: ICreateOtpInput;
}

export interface IMutationCreateLoyaltyUserArgs {
  cognitoId: Scalars['String']['input'];
}

export interface IMutationCreateOtpArgs {
  input: ICreateOtpInput;
}

export interface IMutationCreatePaycometPreAuthorizationArgs {
  input: ICreatePaycometPreAuthorizationInput;
}

export interface IMutationCreateSupportCaseArgs {
  caseInfo: ISupportCaseInput;
}

export interface IMutationDeleteAccountArgs {
  input: IDeleteAccountInput;
}

export interface IMutationDeleteFavoriteArgs {
  favoriteId: Scalars['String']['input'];
}

export interface IMutationExchangeOtpCodeForCognitoCredentialsArgs {
  input: IExchangeOtpCodeForCredentialsInput;
}

export interface IMutationGenerateFirstDataHostedPageLinkArgs {
  callbackUrl: Scalars['String']['input'];
  parentUri: Scalars['String']['input'];
  rbiOrderId: Scalars['String']['input'];
  typePayment: Scalars['String']['input'];
}

export interface IMutationGenerateFirstpayCheckoutLinkArgs {
  rbiOrderId: Scalars['String']['input'];
}

export interface IMutationGenerateFirstpayVaultedLinkArgs {
  storeId?: InputMaybe<Scalars['String']['input']>;
}

export interface IMutationGenerateIdealCheckoutLinkArgs {
  input: IGenerateIdealCheckoutLinkInput;
}

export interface IMutationGeneratePaycometCheckoutLinkArgs {
  rbiOrderId: Scalars['String']['input'];
  typePayment: Scalars['String']['input'];
}

export interface IMutationGeneratePaymarkCheckoutLinkArgs {
  rbiOrderId: Scalars['String']['input'];
}

export interface IMutationGenerateVrPaymentCheckoutLinkArgs {
  guestEmail?: InputMaybe<Scalars['String']['input']>;
  rbiOrderId: Scalars['String']['input'];
}

export interface IMutationGenerateVrPaymentPayPalFastLinkArgs {
  input: IGenerateVrPaymentPayPalFastLinkInput;
}

export interface IMutationGenerateVrPaymentVaultLinkArgs {
  input: IGenerateVrPaymentVaultLinkInput;
}

export interface IMutationInitiatePaymentArgs {
  input: IInitiatePaymentInput;
}

export interface IMutationPaycometCardInfoArgs {
  input: IPaycometCardInfoInput;
}

export interface IMutationPlaceCateringOrderArgs {
  input: IPlaceCateringOrderInput;
}

export interface IMutationPrepaidsBalanceArgs {
  cardNumber: Scalars['String']['input'];
  feCountryCode?: InputMaybe<IsoCountryCode>;
}

export interface IMutationPrepaidsMergeArgs {
  input: IPrepaidsMergeInput;
}

export interface IMutationPrepaidsReloadArgs {
  input: IPrepaidsReloadInput;
}

export interface IMutationPriceOrderArgs {
  delivery?: InputMaybe<IPriceDeliveryInput>;
  input: IPriceOrderInput;
}

export interface IMutationPutFlavorFlowEventsArgs {
  eventType: FlavorFlowType;
  productDetails: ReadonlyArray<InputMaybe<IFlavorFlowItemInput>>;
  serviceMode: ServiceMode;
  storeId: Scalars['String']['input'];
}

export interface IMutationResendCurrentLoginOtpArgs {
  input: IResendOtpInput;
}

export interface IMutationResendVerificationEmailArgs {
  input: IResendVerificationEmailInput;
}

export interface IMutationSendPhoneVerificationArgs {
  input: IResendPhoneVerificationInput;
}

export interface IMutationSignInJwtArgs {
  userInfo: ISignInUserInput;
}

export interface IMutationSignUpArgs {
  userInfo: ISignUpUserInput;
}

export interface IMutationTrackEventViaBeArgs {
  input: ITrackEventViaBeInput;
}

export interface IMutationUpdateFavoriteArgs {
  favoriteId: Scalars['String']['input'];
  input: IFavoriteInput;
}

export interface IMutationUpdateMeArgs {
  details: IUpdateUserDetailsInput;
}

export interface IMutationUpdateOrderArgs {
  input: IUpdateOrderInput;
}

export interface IMutationUpdateOrderCommitRequestArgs {
  input: IUpdateOrderCommitRequestInput;
}

export interface IMutationUserReportsMissingOrderArgs {
  input: IUserReportsMissingOrderInput;
}

export interface IMutationUserRequestsRefundArgs {
  input: IUserRequestsRefundInput;
}

export interface IMutationValidateAuthJwtArgs {
  authInput: IValidateAuthJwtInput;
}

export interface IMutationValidateCommitOrderArgs {
  input: IValidateCommitOrderInput;
}

export interface IMutationVerifyPhoneNumberArgs {
  input: IVerifyPhoneNumberInput;
}

export interface IMutationVoidSaleArgs {
  fdSaleId: Scalars['String']['input'];
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Node
 *
 * Interface that is implemented by an application data node.
 */
export interface INode {
  readonly id: Maybe<Scalars['ID']['output']>;
}

export enum OfferType {
  GLOBAL = 'GLOBAL',
  PERSONALIZED = 'PERSONALIZED',
}

/** To be removed after old clients update */
export interface IOfferVariable {
  readonly __typename?: 'OfferVariable';
  /** @deprecated CBA Offers were deprecated */
  readonly key: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly type: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly value: Maybe<Scalars['String']['output']>;
}

/**
 * Operating hours for a restaurant in local time formatted
 * as "YYYY-MM-DD hh:mm:ss" (the date component should be ignored)
 */
export interface IOperatingHours {
  readonly __typename?: 'OperatingHours';
  /** Friday Additional Hours */
  readonly friAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Friday operating hours close */
  readonly friClose: Maybe<Scalars['String']['output']>;
  /** Friday operating hours open */
  readonly friOpen: Maybe<Scalars['String']['output']>;
  /** Monday Additional Hours */
  readonly monAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Monday operating hours close */
  readonly monClose: Maybe<Scalars['String']['output']>;
  /** Monday operating hours open */
  readonly monOpen: Maybe<Scalars['String']['output']>;
  /** Saturday Additional Hours */
  readonly satAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Saturday operating hours close */
  readonly satClose: Maybe<Scalars['String']['output']>;
  /** Saturday operating hours open */
  readonly satOpen: Maybe<Scalars['String']['output']>;
  /** Sunday Additional Hours */
  readonly sunAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Sunday operating hours close */
  readonly sunClose: Maybe<Scalars['String']['output']>;
  /** Sunday operating hours open */
  readonly sunOpen: Maybe<Scalars['String']['output']>;
  /** Thursday Additional Hours */
  readonly thrAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Thursday operating hours close */
  readonly thrClose: Maybe<Scalars['String']['output']>;
  /** Thursday operating hours open */
  readonly thrOpen: Maybe<Scalars['String']['output']>;
  /** Tuesday Additional Hours */
  readonly tueAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Tuesday operating hours close */
  readonly tueClose: Maybe<Scalars['String']['output']>;
  /** Tuesday operating hours open */
  readonly tueOpen: Maybe<Scalars['String']['output']>;
  /** Wednesday Additional Hours */
  readonly wedAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Wednesday operating hours close */
  readonly wedClose: Maybe<Scalars['String']['output']>;
  /** Wednesday operating hours open */
  readonly wedOpen: Maybe<Scalars['String']['output']>;
}

export enum OperationalStatus {
  CLOSED = 'CLOSED',
  CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
  OPEN = 'OPEN',
  PROJECTED = 'PROJECTED',
}

export interface IOrbitalPayment {
  /**
   * Represents a saved funding source. (i.e. orbitalIdentifier or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or
   * encrypted payload is required.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Encrypted Payment Payload. If accountIdentifier is not passed this will be used. */
  readonly encryptedPayload?: InputMaybe<IAddOrbitalAccountInput>;
  /** When true, payment details will be saved as a recurring payment method in Adyen */
  readonly savePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IOrder {
  readonly __typename?: 'Order';
  readonly cart: ICart;
  readonly catering: Maybe<ICatering>;
  readonly createdAt: Scalars['String']['output'];
  readonly delivery: Maybe<IDelivery>;
  readonly earliestPickupTimeInSeconds: Maybe<Scalars['String']['output']>;
  readonly fireOrderIn: Maybe<Scalars['Int']['output']>;
  readonly fulfillmentDetails: Maybe<IFulfillmentDetails>;
  /** Same value as rbiOrderId, used for Apollo Cache */
  readonly id: Maybe<Scalars['String']['output']>;
  readonly loyaltyTransaction: Maybe<ILoyaltyTransaction>;
  readonly order: Maybe<IRbiOrder>;
  readonly orderErrors: Maybe<IOrderErrors>;
  readonly orderNumberFormatted: Maybe<Scalars['String']['output']>;
  readonly pk: Scalars['String']['output'];
  readonly posOrderId: Maybe<Scalars['String']['output']>;
  readonly posVendor: PosVendor;
  readonly rbiOrderId: Scalars['String']['output'];
  readonly revision: Scalars['Int']['output'];
  readonly sk: Scalars['String']['output'];
  readonly status: RbiOrderStatus;
  readonly updatedAt: Scalars['String']['output'];
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly userSortKey: Scalars['String']['output'];
}

export interface IOrderErrors {
  readonly __typename?: 'OrderErrors';
  readonly orderInjectionErrors: IOrderInjectionErrors;
  readonly posErrors: Maybe<ReadonlyArray<Maybe<IRbiError>>>;
}

export interface IOrderInjectionErrors {
  readonly __typename?: 'OrderInjectionErrors';
  readonly isConfigurationError: Scalars['Boolean']['output'];
  readonly isMenuError: Scalars['Boolean']['output'];
  readonly isStoreAvailabilityError: Scalars['Boolean']['output'];
  readonly isTimeoutError: Scalars['Boolean']['output'];
  readonly isUnknownError: Scalars['Boolean']['output'];
}

export interface IOrderListResponse {
  readonly __typename?: 'OrderListResponse';
  readonly count: Scalars['Int']['output'];
  readonly orders: Maybe<ReadonlyArray<Maybe<IOrder>>>;
  readonly resumeAfter: Maybe<Scalars['String']['output']>;
}

export enum OrderingStatus {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  LIVE = 'live',
  PROD = 'prod',
  TEMPORARILY_UNAVAILABLE = 'temporarily_unavailable',
  TEMPORARY_UNAVAILABLE = 'temporary_unavailable',
}

/**
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 *
 * Page information to determine if there are more results, and what node,
 * determined by it's cursor, is at the start and end of the page returned.
 */
export interface IPageInfo {
  readonly __typename?: 'PageInfo';
  readonly endCursor: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor: Maybe<Scalars['String']['output']>;
}

export interface IParentChildPlu {
  readonly __typename?: 'ParentChildPlu';
  readonly childPlu: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
}

export interface IParentChildPluInput {
  readonly childPlu?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<Scalars['String']['input']>;
}

export interface IPartnerGroup {
  readonly __typename?: 'PartnerGroup';
  readonly name: Maybe<Scalars['String']['output']>;
  readonly posIntegration: Maybe<IPosIntegration>;
}

export interface IPayMarkCheckoutLink {
  readonly __typename?: 'PayMarkCheckoutLink';
  readonly link: Scalars['String']['output'];
}

export interface IPayMarkPayment {
  /** Merchant Account */
  readonly merchantAccount?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IPaycometCaptureContextInput {
  readonly amount: Scalars['Int']['input'];
  readonly cellphone: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['String']['input'];
}

export interface IPaycometCaptureContextResponse {
  readonly __typename?: 'PaycometCaptureContextResponse';
  readonly amount: Maybe<Scalars['Int']['output']>;
  readonly challengeUrl: Maybe<Scalars['String']['output']>;
  readonly currency: Maybe<Scalars['String']['output']>;
  readonly errorCode: Scalars['Int']['output'];
  readonly methodId: Maybe<Scalars['Int']['output']>;
  readonly order: Maybe<Scalars['String']['output']>;
  readonly paymentRequestTimeLimit: Maybe<Scalars['String']['output']>;
}

/** get paycomet card info */
export interface IPaycometCardInfoInput {
  readonly paytpvToken: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['String']['input'];
  readonly storeId: Scalars['String']['input'];
}

export interface IPaycometCardInfoResponse {
  readonly __typename?: 'PaycometCardInfoResponse';
  readonly cardInfo: IPaycometGetCardInfo;
  readonly merchantAccount: Scalars['String']['output'];
  readonly pspReference: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export interface IPaycometCheckoutLink {
  readonly __typename?: 'PaycometCheckoutLink';
  readonly iframeUrl: Maybe<Scalars['String']['output']>;
  readonly jetId: Maybe<Scalars['String']['output']>;
  /** @deprecated New two fields were created */
  readonly link: Scalars['String']['output'];
}

export interface IPaycometCreatePreAuthorization {
  readonly __typename?: 'PaycometCreatePreAuthorization';
  readonly merchantAccount: Scalars['String']['output'];
  readonly paymentMethod: IPaycometPaymentMethod;
  readonly pspReference: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export interface IPaycometGetCardInfo {
  readonly __typename?: 'PaycometGetCardInfo';
  readonly bin: Scalars['String']['output'];
  readonly brand: Scalars['String']['output'];
  readonly expiryMonth: Scalars['String']['output'];
  readonly expiryYear: Scalars['String']['output'];
  readonly last4: Scalars['String']['output'];
  readonly paytpvToken: Scalars['String']['output'];
  readonly storedPaymentMethodId: Scalars['String']['output'];
}

export interface IPaycometPayment {
  /** Merchant Account */
  readonly merchantAccount?: InputMaybe<Scalars['String']['input']>;
  /** Order Description (the first item name of the cart) */
  readonly orderDescription?: InputMaybe<Scalars['String']['input']>;
  /** Payment Type */
  readonly paymentType?: InputMaybe<Scalars['String']['input']>;
  /** PaytpvToken */
  readonly paytpvToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Should update credit card billing address */
  readonly shouldUpdateBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** User Payment details */
  readonly userPaymentDetails?: InputMaybe<IUserPaymentDetails>;
}

export interface IPaycometPaymentMethod {
  readonly __typename?: 'PaycometPaymentMethod';
  readonly bin: Scalars['String']['output'];
  readonly brand: Scalars['String']['output'];
  readonly cardholderName: Scalars['String']['output'];
  readonly expiryMonth: Scalars['String']['output'];
  readonly expiryYear: Scalars['String']['output'];
  readonly last4: Scalars['String']['output'];
  readonly source: Scalars['String']['output'];
  readonly storePaymentMethod: Maybe<Scalars['Boolean']['output']>;
  readonly storedPaymentMethodId: Scalars['String']['output'];
  readonly url3ds: Scalars['String']['output'];
}

export interface IPayment {
  readonly __typename?: 'Payment';
  readonly approvalNumber: Maybe<Scalars['String']['output']>;
  readonly cardType: Maybe<Scalars['String']['output']>;
  readonly ccLast4: Maybe<Scalars['String']['output']>;
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
  readonly fdSaleId: Scalars['String']['output'];
  readonly panToken: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<PaymentMethodBrand>;
  readonly paymentRequestTimeLimit: Maybe<Scalars['String']['output']>;
  readonly paymentType: Maybe<CartPaymentType>;
}

export enum PaymentFlowType {
  ONETIME = 'ONETIME',
  ONETIME_PRE_AUTHORIZATION = 'ONETIME_PRE_AUTHORIZATION',
  PAID_ON_REDIRECTING = 'PAID_ON_REDIRECTING',
  PAYMENT_LINK = 'PAYMENT_LINK',
  PAYPAL = 'PAYPAL',
  VAULTED = 'VAULTED',
  VAULTED_PRE_AUTHORIZATION = 'VAULTED_PRE_AUTHORIZATION',
}

export interface IPaymentInput {
  /** Adyen payment data */
  readonly adyenInput?: InputMaybe<IAdyenPayment>;
  /** Details from Apple Pay for payment. */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /** Billing address associated to card */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /** Blik (Payment Method specific to Poland) Account Identifier. */
  readonly blikCode?: InputMaybe<Scalars['String']['input']>;
  /** Denotes a cash payment */
  readonly cashPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Contains supplemental data for CC transactions */
  readonly ccMetadata?: InputMaybe<ICreditCardMetadataInput>;
  /** chase id from TH endpoint to trigger direct payment */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /** Device Session Id for Checkout.com risk assessment */
  readonly deviceSessionId?: InputMaybe<Scalars['String']['input']>;
  /** First Data payment data */
  readonly firstDataInput?: InputMaybe<IFirstDataPayment>;
  /** Firstpay Payment data */
  readonly firstPayInput?: InputMaybe<IFirstpayPayment>;
  /** Name on credit card */
  readonly fullName: Scalars['String']['input'];
  /** Details from Google Pay for payment. */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /** ID of the iDEAL bank that issues the payment */
  readonly idealIssuer?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** Orbital Payment data */
  readonly orbitalInput?: InputMaybe<IOrbitalPayment>;
  /** PayMark Payment data */
  readonly payMarkInput?: InputMaybe<IPayMarkPayment>;
  /** Paycomet Payment data */
  readonly paycometInput?: InputMaybe<IPaycometPayment>;
  /** Payment flow type (vaulted, onetime, paypal...) */
  readonly paymentFlowType?: InputMaybe<PaymentFlowType>;
  /** Payment Method Brand */
  readonly paymentMethodBrand?: InputMaybe<PaymentMethodBrand>;
  /** The time limit allowed to complete the payment process, represented as a timestamp. */
  readonly paymentRequestTimeLimit?: InputMaybe<Scalars['Int']['input']>;
  /** VR Payment data */
  readonly vrPaymentInput?: InputMaybe<IVrPayment>;
  /** Worldpay Payment data */
  readonly worldpayInput?: InputMaybe<IWorldpayPayment>;
}

export enum PaymentMethodBrand {
  APPLEPAYLINK = 'APPLEPAYLINK',
  BIZUM = 'BIZUM',
  CHEQUE_GOURMET = 'CHEQUE_GOURMET',
  CHEQUE_GOURMET_VOUCHER = 'CHEQUE_GOURMET_VOUCHER',
  MAESTRO = 'MAESTRO',
  PAYMENT_ON_DELIVERY_CARD = 'PAYMENT_ON_DELIVERY_CARD',
  SODEXO = 'SODEXO',
  SODEXO_VOUCHER = 'SODEXO_VOUCHER',
  /** @deprecated Use TICKET_RESTAURANT_CARD */
  TICKET_RESTAURANT = 'TICKET_RESTAURANT',
  TICKET_RESTAURANT_CARD = 'TICKET_RESTAURANT_CARD',
  TICKET_RESTAURANT_VOUCHER = 'TICKET_RESTAURANT_VOUCHER',
  TWINT = 'TWINT',
  WAYLET = 'WAYLET',
}

export interface IPaymentMethodData {
  /** Card display name with last 4 digits of the credit card */
  readonly displayName?: InputMaybe<Scalars['String']['input']>;
  /** Card type used for digital pay */
  readonly paymentMethodType?: InputMaybe<Scalars['String']['input']>;
  /** Payment type used for digital pay */
  readonly paymentType?: InputMaybe<Scalars['String']['input']>;
}

export interface IPaymentMethods {
  readonly __typename?: 'PaymentMethods';
  readonly isOnlinePayment: Maybe<Scalars['Boolean']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['Boolean']['output']>;
}

export enum PaymentProcessor {
  ADYEN = 'ADYEN',
  CHASE = 'CHASE',
  CHECKOUTDOTCOM = 'CHECKOUTDOTCOM',
  CYBERSOURCE = 'CYBERSOURCE',
  EVERTEC = 'EVERTEC',
  FIRSTDATA = 'FIRSTDATA',
  FIRSTPAY = 'FIRSTPAY',
  ORBITAL = 'ORBITAL',
  PAYCOMET = 'PAYCOMET',
  PAYMARK = 'PAYMARK',
  VRPAYMENT = 'VRPAYMENT',
  WORLDPAY = 'WORLDPAY',
}

export interface IPhysicalAddress {
  readonly __typename?: 'PhysicalAddress';
  readonly address1: Maybe<Scalars['String']['output']>;
  readonly address2: Maybe<Scalars['String']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly stateProvince: Maybe<Scalars['String']['output']>;
  readonly stateProvinceShort: Maybe<Scalars['String']['output']>;
}

export interface IPlaceCateringOrderInput {
  /**
   * Billing address Comes in the following format
   * billingStreetAddress, billingApt, billingCity, billingState, billingZip
   */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /**
   * Catering Information
   * contains Datetime for the catering
   */
  readonly cateringInfo: ICateringInfoInput;
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry month */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry year */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /** The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX) */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
  /** Valid access token for accessing First Data API */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * The First Data payment method ID which must be associated to the user
   * making the request.
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce.
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Customer full name */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['ID']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   *
   * Note that the "sessionId" field is deprecated and will be ignored.
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** Store email address */
  readonly storeEmail?: InputMaybe<Scalars['String']['input']>;
  /** Details from 3DS transactions, relates to properties needed to resume sale transactions. */
  readonly threeDSDetails?: InputMaybe<IResumeThreeDSaleTransaction>;
}

export interface IPlaceToPayCheckoutLink {
  readonly __typename?: 'PlaceToPayCheckoutLink';
  readonly link: Scalars['String']['output'];
}

export { Platform };

export interface IPlu {
  readonly __typename?: 'Plu';
  readonly connector: Maybe<Scalars['String']['output']>;
  readonly ncr: Maybe<Scalars['String']['output']>;
  readonly ncrDelivery: Maybe<Scalars['String']['output']>;
  readonly oheics: Maybe<Scalars['String']['output']>;
  readonly oheicsDelivery: Maybe<Scalars['String']['output']>;
  readonly partner: Maybe<Scalars['String']['output']>;
  readonly partnerDelivery: Maybe<Scalars['String']['output']>;
  readonly productNumber: Maybe<Scalars['String']['output']>;
  readonly productNumberDelivery: Maybe<Scalars['String']['output']>;
  readonly qst: Maybe<Scalars['String']['output']>;
  readonly qstDelivery: Maybe<Scalars['String']['output']>;
  readonly sicom: Maybe<Scalars['String']['output']>;
  readonly sicomDelivery: Maybe<Scalars['String']['output']>;
  readonly simplyDelivery: Maybe<Scalars['String']['output']>;
  readonly simplyDeliveryDelivery: Maybe<Scalars['String']['output']>;
  readonly toshibaLoyalty: Maybe<Scalars['String']['output']>;
}

export interface IPluData {
  readonly __typename?: 'PluData';
  readonly deliveryPlus: Maybe<Scalars['String']['output']>;
  readonly plus: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export interface IPluInput {
  readonly connector?: InputMaybe<Scalars['String']['input']>;
  readonly ncr?: InputMaybe<Scalars['String']['input']>;
  readonly ncrDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly oheics?: InputMaybe<Scalars['String']['input']>;
  readonly oheicsDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly partner?: InputMaybe<Scalars['String']['input']>;
  readonly partnerDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly productNumber?: InputMaybe<Scalars['String']['input']>;
  readonly qst?: InputMaybe<Scalars['String']['input']>;
  readonly qstDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly rpos?: InputMaybe<Scalars['String']['input']>;
  readonly rposDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly sicom?: InputMaybe<Scalars['String']['input']>;
  readonly sicomDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly simplyDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly simplyDeliveryDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly toshibaLoyalty?: InputMaybe<Scalars['String']['input']>;
}

export interface IPosIntegration {
  readonly __typename?: 'PosIntegration';
  readonly _ref: Maybe<Scalars['String']['output']>;
  readonly _type: Maybe<Scalars['String']['output']>;
}

export enum PosVendor {
  CARROLS = 'CARROLS',
  CONNECTOR = 'CONNECTOR',
  NCR = 'NCR',
  OHEICS = 'OHEICS',
  PARTNER = 'PARTNER',
  PRODUCT_NUMBER = 'PRODUCT_NUMBER',
  QDI = 'QDI',
  QST = 'QST',
  RPOS = 'RPOS',
  SICOM = 'SICOM',
  SIMPLY_DELIVERY = 'SIMPLY_DELIVERY',
  TABLET = 'TABLET',
  TOSHIBA_LOYALTY = 'TOSHIBA_LOYALTY',
}

export interface IPreOrderTimeSlot {
  readonly __typename?: 'PreOrderTimeSlot';
  readonly end: Scalars['String']['output'];
  readonly start: Scalars['String']['output'];
}

export interface IPreOrderTimeSlotInput {
  readonly end: Scalars['String']['input'];
  readonly start: Scalars['String']['input'];
}

/** A transaction for a prepaid gift card */
export interface IPrepaidTransaction {
  readonly __typename?: 'PrepaidTransaction';
  /** First Data account ID */
  readonly amount: Scalars['Int']['output'];
  readonly currencyCode: ICurrencyCode;
  readonly info: Scalars['String']['output'];
  readonly transactionTime: Scalars['String']['output'];
}

/** Merge two prepaid gift cards into one. Source gets deleted. */
export interface IPrepaidsMergeInput {
  /** Destination first data gift card Account ID gets balance from source */
  readonly destinationFdAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Number of physical Gift Card which is the funding source (card being deactivated) */
  readonly sourceCardNumber: Scalars['String']['input'];
  /** Pin number for physical gift card which is the funding source (card being deactivated) */
  readonly sourcePin?: InputMaybe<Scalars['String']['input']>;
}

/** Reload a prepaid gift card (payment method) */
export interface IPrepaidsReloadInput {
  /**
   * Details from Apple Pay for funding. Only one of fdAccountId or
   * applePayDetails or googlePayDetails should be provided.
   */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /** Country of currency to reload card */
  readonly countryCode?: InputMaybe<CountryCode>;
  /**
   * Vaulted first data Account ID (credit) used to fund gift card.
   * Either this or applePayDetails is required.
   * Only one of fdAccountId or
   * applePayDetails or googlePayDetails should be provided.
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Amount to fund to gift card account in cents */
  readonly fundValue: Scalars['Int']['input'];
  /**
   * Details from Goole Pay for funding. Only one of fdAccountId or
   * applePayDetails or googlePayDetails should be provided.
   */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** First Data Account ID of prepaid gift card to be reloaded */
  readonly prepaidFdAccountId: Scalars['String']['input'];
  /**
   * Session ID as set by the FE.
   *
   * Note that the "sessionId" field is deprecated and will be ignored.
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
}

export interface IPriceDeliveryInput {
  readonly dropoff: IDeliveryWaypointInput;
  readonly instructions?: InputMaybe<Scalars['String']['input']>;
  readonly quoteId?: InputMaybe<Scalars['String']['input']>;
  readonly tipCents?: InputMaybe<Scalars['Int']['input']>;
}

export interface IPriceOrderInput {
  /** Offers applied to cart */
  readonly appliedOffers?: InputMaybe<ReadonlyArray<InputMaybe<IAppliedOffer>>>;
  /** The brand of the store. */
  readonly brand: Brand;
  /** The order cart entries to be injected into the POS. */
  readonly cartEntries?: InputMaybe<ReadonlyArray<ICartEntryInput>>;
  /** The cart shape version. */
  readonly cartVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The locale of the customer. */
  readonly customerLocale?: InputMaybe<Scalars['String']['input']>;
  /**
   * The name of the customer. If provided, this will
   * supersede the user's name on this order.
   */
  readonly customerName?: InputMaybe<Scalars['String']['input']>;
  /**
   * (Deprecated) Tim Hortons user's loyalty card barcode to apply reward/visit
   * @deprecated(reason: "TH Loyalty was deprecated")
   */
  readonly loyaltyBarcode?: InputMaybe<Scalars['String']['input']>;
  /** payment method - for cash orders */
  readonly paymentMethod?: InputMaybe<CartPaymentCardType>;
  /** The Platform */
  readonly platform?: InputMaybe<Platform>;
  /** The POS Vendor */
  readonly posVendor: PosVendor;
  /** quoteId associated to */
  readonly quotedId?: InputMaybe<Scalars['String']['input']>;
  /**
   * (Deprecated) Tim Hortons flag for reward redemption, if true must provide loyaltyBarcode
   * @deprecated(reason: "TH Loyalty was deprecated")
   */
  readonly redeemReward?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Requested sale amount in cents (USD) */
  readonly requestedAmountCents: Scalars['Int']['input'];
  /** Reward ids being applied to cart */
  readonly rewardsApplied?: InputMaybe<ReadonlyArray<InputMaybe<IAppliedReward>>>;
  /** How the order will be executed. */
  readonly serviceMode: ServiceMode;
  /** Store Address, derived from MDM. */
  readonly storeAddress: IStoreAddressInput;
  /** Store ID, derived from MDM. This value will be validated within First Data. */
  readonly storeId: Scalars['String']['input'];
  /** The internal Store ID. */
  readonly storePosId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Cart Item swaps being applied to cart
   * @deprecated(reason: "Not needed anymore since it is included in AppliedOffer")
   */
  readonly swaps?: InputMaybe<ReadonlyArray<InputMaybe<ISwapInput>>>;
  /** vat number on the restaurant document for BK UK */
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IProductHierarchyInput {
  readonly L1?: InputMaybe<Scalars['String']['input']>;
  readonly L2?: InputMaybe<Scalars['String']['input']>;
  readonly L3?: InputMaybe<Scalars['String']['input']>;
  readonly L4?: InputMaybe<Scalars['String']['input']>;
  readonly L5?: InputMaybe<Scalars['String']['input']>;
}

export enum ProviderType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export interface IQuantityPlu {
  readonly __typename?: 'QuantityPlu';
  readonly plu: Maybe<Scalars['String']['output']>;
  readonly qualifier: Maybe<Scalars['String']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
}

export interface IQuantityPluInput {
  readonly plu?: InputMaybe<Scalars['String']['input']>;
  readonly qualifier?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface IQuery {
  readonly __typename?: 'Query';
  /** List all flags tagged as AB_Testing */
  readonly abTestFlags: ReadonlyArray<Scalars['String']['output']>;
  /** API Metadata */
  readonly api: Maybe<IApiMetadata>;
  /**
   * Query an order by providing a catering JWT for the current order
   * @deprecated(reason: "Not needed anymore")
   */
  readonly cateringOrder: Maybe<IOrder>;
  /** Query if customer initiated refunds is enabled for an order. */
  readonly checkRefundEligibility: Maybe<IRefundEligibilityResponse>;
  /** Obtain a quote to deliver an order. */
  readonly deliveryQuote: IDelivery;
  /** Returns an available delivery restaurant based on vendor quote given provided address and search radius. */
  readonly deliveryRestaurant: IDeliveryRestaurant;
  /** Get instagram feed image urls. Input is number of images to be fetched */
  readonly getImageFeed: ReadonlyArray<IInstagramImage>;
  /** Get refill drink QR Code hash */
  readonly getRefillDrinkQRCode: IRefillDrinkQrCodeInfo;
  /** Query all flavor flows */
  readonly listFlavorFlowItems: Maybe<IFlavorFlowItemResult>;
  readonly me: IUser;
  /** Query an order by order id for the current user. */
  readonly order: Maybe<IOrder>;
  /** Query an order's history by order id */
  readonly orderRevisions: IOrderListResponse;
  readonly pluData: Maybe<IPluData>;
  /** Return transaction history for prepaid gift card account from FD */
  readonly prepaidsTransactions: Maybe<ITransactionListResponse>;
  /** Returns a restaurant entity given store id */
  readonly restaurant: IRestaurant;
  /**
   * Returns a list of restaurants based on provided filters.
   * @todo: Return result with pagination metadata
   */
  readonly restaurants: Maybe<IRestaurantsConnection>;
  /** Check if the registration is complete */
  readonly signUpComplete: Maybe<Scalars['Boolean']['output']>;
  /** Query orders from unique stores for current user. */
  readonly uniqueStores: IOrderListResponse;
  /** Fetch all accounts for current customer (limit 100) */
  readonly userAccounts: Maybe<IAccountListResponse>;
  /** Get a user favorite */
  readonly userFavorite: IFavorite;
  /** List of user favorites */
  readonly userFavorites: IFavoriteList;
  readonly userGeo: IUserGeo;
  /**
   * Query all orders for current user.
   *
   * Note that the "userId" field is deprecated and will be ignored.
   */
  readonly userOrders: IOrderListResponse;
  /** Get user phone from braze */
  readonly userPhoneFromBraze: Maybe<IBrazeUserPhone>;
  /** Get user most recent delivery addresses */
  readonly userRecentAddresses: Maybe<IUserRecentAddresses>;
}

export interface IQueryCateringOrderArgs {
  cateringJwt: Scalars['String']['input'];
}

export interface IQueryCheckRefundEligibilityArgs {
  rbiOrderId: Scalars['ID']['input'];
}

export interface IQueryDeliveryQuoteArgs {
  dropoff: IDeliveryWaypointInput;
  pickup: IDeliveryWaypointInput;
  requestedAmountCents?: InputMaybe<Scalars['Int']['input']>;
  serviceMode?: InputMaybe<ServiceMode>;
}

export interface IQueryDeliveryRestaurantArgs {
  dropoff: IDeliveryWaypointInput;
  platform?: InputMaybe<Platform>;
  searchRadius: Scalars['Float']['input'];
}

export interface IQueryGetImageFeedArgs {
  input?: InputMaybe<Scalars['Int']['input']>;
}

export interface IQueryGetRefillDrinkQrCodeArgs {
  orderUpdateAt: Scalars['String']['input'];
  storeNumber: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
}

export interface IQueryListFlavorFlowItemsArgs {
  productDetails: ReadonlyArray<InputMaybe<IFlavorFlowItemInput>>;
  serviceMode: ServiceMode;
  storeId: Scalars['String']['input'];
}

export interface IQueryMeArgs {
  customInput?: InputMaybe<IMeInput>;
  numUniquePurchasedItems?: InputMaybe<Scalars['Int']['input']>;
}

export interface IQueryOrderArgs {
  handleCommitError?: InputMaybe<Scalars['Boolean']['input']>;
  mParticleEventAttributes?: InputMaybe<IMParticleEventAttributes>;
  rbiOrderId: Scalars['ID']['input'];
}

export interface IQueryOrderRevisionsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  rbiOrderId: Scalars['ID']['input'];
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  revision?: InputMaybe<Scalars['String']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IQueryPluDataArgs {
  storeId: Scalars['String']['input'];
}

export interface IQueryPrepaidsTransactionsArgs {
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  fdAccountId?: InputMaybe<Scalars['String']['input']>;
}

export interface IQueryRestaurantArgs {
  localDateTime?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  storeNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IQueryRestaurantsArgs {
  input?: InputMaybe<IRestaurantsInput>;
}

export interface IQuerySignUpCompleteArgs {
  jwt: Scalars['String']['input'];
}

export interface IQueryUniqueStoresArgs {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus>>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IQueryUserAccountsArgs {
  feCountryCode?: InputMaybe<IsoCountryCode>;
}

export interface IQueryUserFavoriteArgs {
  favoriteId: Scalars['String']['input'];
}

export interface IQueryUserFavoritesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IQueryUserOrdersArgs {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus>>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  serviceModes?: InputMaybe<ReadonlyArray<InputMaybe<ServiceMode>>>;
  shouldFilterByRegion?: InputMaybe<Scalars['Boolean']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IQueryUserPhoneFromBrazeArgs {
  brazeId?: InputMaybe<Scalars['String']['input']>;
}

export interface IQueryUserRecentAddressesArgs {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}

export interface IRbiError {
  readonly __typename?: 'RbiError';
  readonly errorCode: Maybe<Scalars['String']['output']>;
  readonly errorName: Maybe<Scalars['String']['output']>;
  readonly message: Maybe<Scalars['String']['output']>;
  readonly rbiErrorCode: Maybe<Scalars['String']['output']>;
  readonly rbiErrorDomain: Maybe<RbiErrorDomain>;
}

export enum RbiErrorDomain {
  PAYMENTS = 'PAYMENTS',
  POS = 'POS',
}

export interface IRbiOrder {
  readonly __typename?: 'RbiOrder';
  readonly refund: Maybe<IRefund>;
}

export enum RbiOrderStatus {
  CATERING_DECLINED = 'CATERING_DECLINED',
  CATERING_ERROR = 'CATERING_ERROR',
  CATERING_PLACED = 'CATERING_PLACED',
  CATERING_SUCCESSFUL = 'CATERING_SUCCESSFUL',
  COMMIT_ERROR = 'COMMIT_ERROR',
  INSERT_ERROR = 'INSERT_ERROR',
  INSERT_REQUESTED = 'INSERT_REQUESTED',
  INSERT_SUCCESSFUL = 'INSERT_SUCCESSFUL',
  KOUNT_DENIAL = 'KOUNT_DENIAL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PRICE_ERROR = 'PRICE_ERROR',
  PRICE_REQUESTED = 'PRICE_REQUESTED',
  PRICE_SUCCESSFUL = 'PRICE_SUCCESSFUL',
  QUEUED = 'QUEUED',
  QUEUE_ERROR = 'QUEUE_ERROR',
  REFUND_ERROR = 'REFUND_ERROR',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_SUCCESSFUL = 'UPDATE_SUCCESSFUL',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export interface IRedirectAction {
  readonly __typename?: 'RedirectAction';
  readonly method: Scalars['String']['output'];
  readonly paymentData: Scalars['String']['output'];
  readonly paymentMethodType: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
}

export interface IRefillDrinkQrCodeInfo {
  readonly __typename?: 'RefillDrinkQRCodeInfo';
  /** An refill drink QR Code info */
  readonly qrCodeInfo: Maybe<Scalars['String']['output']>;
}

export interface IRefund {
  readonly __typename?: 'Refund';
  /** Reason picked from a predefined dropdown list */
  readonly primaryReason: RefundReason;
  /** Items that were refunded. Undefined if order was fully refunded. */
  readonly refundedItems: Maybe<ReadonlyArray<Maybe<IRefundedItem>>>;
  /** Optional reason either added by a support agent or an user via the support form */
  readonly secondaryReason: Maybe<Scalars['String']['output']>;
  readonly subtotal: Scalars['String']['output'];
  readonly tax: Scalars['String']['output'];
  /** Date time when refund was issued */
  readonly transactionDateTime: Scalars['String']['output'];
}

export interface IRefundEligibilityResponse {
  readonly __typename?: 'RefundEligibilityResponse';
  readonly eligible: Scalars['Boolean']['output'];
  readonly ineligibilityReason: Maybe<RefundIneligibilityReason>;
}

export enum RefundIneligibilityReason {
  ALREADY_REFUNDED = 'ALREADY_REFUNDED',
  DISABLED = 'DISABLED',
  INVALID_ORDER_STATUS = 'INVALID_ORDER_STATUS',
  INVALID_PAYMENT_TYPE = 'INVALID_PAYMENT_TYPE',
  INVALID_USER_ID = 'INVALID_USER_ID',
  ORDER_BEING_DELIVERED = 'ORDER_BEING_DELIVERED',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  OVER_ALLOWED_REFUND_AMOUNT = 'OVER_ALLOWED_REFUND_AMOUNT',
  REFUNDED_MAX_TIMES = 'REFUNDED_MAX_TIMES',
}

export enum RefundReason {
  CATERING_ORDER_DECLINED = 'CATERING_ORDER_DECLINED',
  CATERING_ORDER_ERROR = 'CATERING_ORDER_ERROR',
  CATERING_ORDER_TIME_OUT = 'CATERING_ORDER_TIME_OUT',
  CUSTOMER_OVERCHARGED = 'CUSTOMER_OVERCHARGED',
  CUSTOMER_REQUESTED_CANCEL_ORDER = 'CUSTOMER_REQUESTED_CANCEL_ORDER',
  FOOD_QUALITY_ISSUE = 'FOOD_QUALITY_ISSUE',
  INCORRECT_CUSTOMIZATION = 'INCORRECT_CUSTOMIZATION',
  ITEMS_OUT_OF_STOCK = 'ITEMS_OUT_OF_STOCK',
  MISSING_OR_INCORRECT_ITEM = 'MISSING_OR_INCORRECT_ITEM',
  MOBILE_ORDERING_TECHNICAL_ISSUE = 'MOBILE_ORDERING_TECHNICAL_ISSUE',
  ORDER_ARRIVED_LATE = 'ORDER_ARRIVED_LATE',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_COMMIT_ERROR = 'ORDER_COMMIT_ERROR',
  ORDER_NEVER_ARRIVED = 'ORDER_NEVER_ARRIVED',
  ORDER_WAS_DAMAGED = 'ORDER_WAS_DAMAGED',
  OTHER_ADD_COMMENT = 'OTHER_ADD_COMMENT',
  RECEIVED_INCORRECT_ORDER = 'RECEIVED_INCORRECT_ORDER',
  RESTAURANT_CLOSED = 'RESTAURANT_CLOSED',
  RESTAURANT_COULDNT_FIND_ORDER = 'RESTAURANT_COULDNT_FIND_ORDER',
}

export interface IRefundRequestResponse {
  readonly __typename?: 'RefundRequestResponse';
  /** Requested refund amount in cents */
  readonly refundRequestAmountCents: Scalars['Int']['output'];
  /** Date time when refund was requested */
  readonly requestDateTime: Maybe<Scalars['String']['output']>;
}

export interface IRefundedItem {
  readonly __typename?: 'RefundedItem';
  /** Name of item refunded */
  readonly name: Maybe<Scalars['String']['output']>;
  /** Plu of item refunded */
  readonly plu: Maybe<Scalars['String']['output']>;
  /** Amount refunded in dollars for item */
  readonly refundAmount: Maybe<Scalars['String']['output']>;
  /** Quantity of item refunded */
  readonly refundQuantity: Maybe<Scalars['String']['output']>;
  /** SanityId of item refunded */
  readonly sanityId: Maybe<Scalars['String']['output']>;
  /** Type of item refunded */
  readonly type: Maybe<CartEntryType>;
}

/** A Prepaid Reload response (payment method) */
export interface IReload {
  readonly __typename?: 'Reload';
  /** dollar amount gift card begins with */
  readonly beginBalance: Scalars['Int']['output'];
  readonly currencyCode: Scalars['String']['output'];
  /** current gift card balance in dollars */
  readonly currentBalance: Scalars['Int']['output'];
  /** First Data account id of gift card */
  readonly fdAccountId: Scalars['String']['output'];
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
  readonly transactionId: Scalars['String']['output'];
}

export interface IRequiredAcceptanceAgreementInfo {
  readonly __typename?: 'RequiredAcceptanceAgreementInfo';
  /** Acceptance Agreement ID */
  readonly id: Maybe<Scalars['String']['output']>;
  /** Updated Date */
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export interface IRequiredAcceptanceAgreementInfoInput {
  readonly id: Scalars['String']['input'];
  readonly updatedAt: Scalars['String']['input'];
}

export interface IResendOtpInput {
  /**
   * User email. Only one of email or phone number should be provided
   * Example foobar@example.com
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * User phone number. Only one of email or phone number should be provided
   * Example 4258675309
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Requestor's platform */
  readonly platform: Platform;
}

export interface IRestaurant {
  readonly __typename?: 'Restaurant';
  /**
   * Boolean value for whether or not the restaurant is available
   * This is determined by the restaurant's heartbeat data stored in sanity
   */
  readonly available: Scalars['Boolean']['output'];
  /** Curbside operating hours */
  readonly curbsideHours: Maybe<IOperatingHours>;
  /** Day parts from sanity */
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayPart>>>;
  /** Delivery operating hours */
  readonly deliveryHours: Maybe<IOperatingHours>;
  /** Dining room operating hours */
  readonly diningRoomHours: Maybe<IOperatingHours>;
  /** Drive through operating hours */
  readonly driveThruHours: Maybe<IOperatingHours>;
  /**
   * DEPRECATED
   * Last record heartbeat timestamp on this restaurant
   * @deprecated Only for internal use
   */
  readonly lastHeartbeatTimestamp: Maybe<Scalars['String']['output']>;
  /** Pos Vendor associated with restaurant */
  readonly posVendor: Maybe<Scalars['String']['output']>;
  /**
   * The MDM number identifier. This is the `number` property on the sanity store obj
   * this is NOT the `_id` property
   */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /** Restaurant Vat Number for BK UK */
  readonly vatNumber: Maybe<Scalars['String']['output']>;
  /** Current Estimated Wait Time in the Restaurant */
  readonly waitTime: Maybe<IWaitTime>;
}

export interface IRestaurantEdge extends IEdge {
  readonly __typename?: 'RestaurantEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: IRestaurantNode;
}

/** Extended restaurant data containing all fields from Sanity + Dynamo */
export interface IRestaurantNode extends INode {
  readonly __typename?: 'RestaurantNode';
  /** While pagination spec requires an id, Sanity and Frontend use _id */
  readonly _id: Maybe<Scalars['String']['output']>;
  readonly chaseMerchantId: Maybe<Scalars['String']['output']>;
  readonly curbsideHours: Maybe<IOperatingHours>;
  readonly customerFacingAddress: Maybe<ICustomerFacingAddress>;
  readonly cybersourceTransactingId: Maybe<Scalars['String']['output']>;
  readonly deliveryHours: Maybe<IOperatingHours>;
  readonly deliveryOrderAmountLimit: Maybe<IDeliveryOrderAmountLimit>;
  readonly diningRoomHours: Maybe<IOperatingHours>;
  readonly distanceInMiles: Maybe<Scalars['Int']['output']>;
  readonly drinkStationType: Maybe<Scalars['String']['output']>;
  readonly driveThruHours: Maybe<IOperatingHours>;
  readonly driveThruLaneType: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly environment: Maybe<RestaurantStatus>;
  readonly franchiseGroupId: Maybe<Scalars['Int']['output']>;
  readonly franchiseGroupName: Maybe<Scalars['String']['output']>;
  readonly frontCounterClosed: Maybe<Scalars['Boolean']['output']>;
  readonly hasBreakfast: Maybe<Scalars['Boolean']['output']>;
  readonly hasBurgersForBreakfast: Maybe<Scalars['Boolean']['output']>;
  readonly hasCatering: Maybe<Scalars['Boolean']['output']>;
  readonly hasCurbside: Maybe<Scalars['Boolean']['output']>;
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  readonly hasDineIn: Maybe<Scalars['Boolean']['output']>;
  readonly hasDriveThru: Maybe<Scalars['Boolean']['output']>;
  readonly hasLateNightMenu: Maybe<Scalars['Boolean']['output']>;
  readonly hasLoyalty: Maybe<Scalars['Boolean']['output']>;
  readonly hasMobileOrdering: Maybe<Scalars['Boolean']['output']>;
  readonly hasParking: Maybe<Scalars['Boolean']['output']>;
  readonly hasPlayground: Maybe<Scalars['Boolean']['output']>;
  readonly hasTableService: Maybe<Scalars['Boolean']['output']>;
  readonly hasTakeOut: Maybe<Scalars['Boolean']['output']>;
  readonly hasWifi: Maybe<Scalars['Boolean']['output']>;
  readonly hideClickAndCollectOrdering: Maybe<Scalars['Boolean']['output']>;
  /** Arbitrary unique identifier. */
  readonly id: Maybe<Scalars['ID']['output']>;
  readonly integration: Maybe<IIntegration>;
  /**
   * Boolean value for whether or not the restaurant is available
   * This is determined by the restaurant's heartbeat data stored in sanity
   */
  readonly isAvailable: Scalars['Boolean']['output'];
  readonly isDarkKitchen: Maybe<Scalars['Boolean']['output']>;
  readonly isFavorite: Scalars['Boolean']['output'];
  readonly isHalal: Maybe<Scalars['Boolean']['output']>;
  readonly isRecent: Scalars['Boolean']['output'];
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly mobileOrderingStatus: Maybe<OrderingStatus>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly number: Maybe<Scalars['String']['output']>;
  readonly parkingType: Maybe<Scalars['String']['output']>;
  readonly paymentMethods: Maybe<ReadonlyArray<Maybe<IPaymentMethods>>>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly physicalAddress: Maybe<IPhysicalAddress>;
  readonly playgroundType: Maybe<Scalars['String']['output']>;
  readonly pos: Maybe<IRestaurantPos>;
  readonly posRestaurantId: Maybe<Scalars['String']['output']>;
  /** Pos Vendor associated with restaurant */
  readonly posVendor: Maybe<Scalars['String']['output']>;
  readonly restaurantImage: Maybe<ISanityImage>;
  readonly restaurantPosData: Maybe<IRestaurantPosData>;
  readonly showStoreLocatorOffersButton: Maybe<Scalars['Boolean']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
  /**
   * The MDM number identifier. This is the `number` property on the sanity store obj
   * this is NOT the `_id` property
   */
  readonly storeId: Maybe<Scalars['String']['output']>;
  readonly vatNumber: Maybe<Scalars['String']['output']>;
  readonly waitTime: Maybe<IWaitTime>;
}

export interface IRestaurantPos {
  readonly __typename?: 'RestaurantPos';
  readonly vendor: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantPosData {
  readonly __typename?: 'RestaurantPosData';
  readonly _id: Maybe<Scalars['String']['output']>;
}

export enum RestaurantStatus {
  DEV = 'DEV',
  PROD = 'PROD',
}

export interface IRestaurantsConnection extends IConnection {
  readonly __typename?: 'RestaurantsConnection';
  readonly edges: ReadonlyArray<IRestaurantEdge>;
  readonly nodes: ReadonlyArray<IRestaurantNode>;
  readonly pageInfo: IPageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface IRestaurantsInput {
  /**
   * An opaque cursor for pagination. Return results after provided cursor.
   * Used in combination with first field.
   */
  readonly after?: InputMaybe<Scalars['String']['input']>;
  /**
   * An opaque cursor for pagination. Return results before provided cursor.
   * Used in combination with last field.
   */
  readonly before?: InputMaybe<Scalars['String']['input']>;
  /** A coordinates object that sorts results based on location provided. */
  readonly coordinates?: InputMaybe<ICoordinates>;
  /**
   * Filter to a subset of stores
   * Defaults to NEARBY
   */
  readonly filter?: InputMaybe<FilterRestaurantType>;
  /**
   * Return first provided number of edges for pagination query.
   * Used in combination with after field.
   */
  readonly first?: InputMaybe<Scalars['Int']['input']>;
  /** A list of store IDs to filter to */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  /**
   * Return last provided number of edges for pagination query.
   * Used in combination with before field.
   */
  readonly last?: InputMaybe<Scalars['Int']['input']>;
  /**
   * A flag to enable fetching restaurants in parallel,
   * this parameter needs to be removed once TRX-978 A/B test is evaluated
   */
  readonly parallelFlag?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A flag to enable strict radius checking. When enabled, stores will be filtered
   * based on a circle radius. Any stores outside the radius are not included.
   */
  readonly radiusStrictMode?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A list of service modes to search for.
   * Filters list to subset of stores that have one of the service modes available.
   */
  readonly serviceModes?: InputMaybe<ReadonlyArray<ServiceMode>>;
  /**
   * Restaurant operational status.
   * Filters list to subset of stores that have the provided status.
   */
  readonly status?: InputMaybe<OperationalStatus>;
}

/** Resume 3DS sale transaction input */
export interface IResumeThreeDSaleTransaction {
  /** saleId of the transaction */
  readonly saleId: Scalars['String']['input'];
  /** threeDSOptions to resume 3ds flow transaction */
  readonly threeDSOptions: IThreeDsOptions;
}

/** WARNING: Likely to be deprecated in favor of "order" */
export interface ISale {
  readonly __typename?: 'Sale';
  /**
   * First Data sale id. This will be used for any further actions on the sale
   * (eg to void the sale)
   */
  readonly fdSaleId: Scalars['String']['output'];
  /** Id for generated order */
  readonly orderId: Scalars['String']['output'];
  readonly status: Maybe<Scalars['String']['output']>;
}

export interface ISanityImage {
  readonly __typename?: 'SanityImage';
  readonly asset: Maybe<ISanityImageAsset>;
  readonly crop: Maybe<ISanityImageCrop>;
  readonly hotspot: Maybe<ISanityImageHotspot>;
}

export interface ISanityImageAsset {
  readonly __typename?: 'SanityImageAsset';
  readonly _id: Maybe<Scalars['String']['output']>;
  readonly metadata: Maybe<ISanityImageMetaData>;
}

export interface ISanityImageCrop {
  readonly __typename?: 'SanityImageCrop';
  readonly bottom: Maybe<Scalars['Float']['output']>;
  readonly left: Maybe<Scalars['Float']['output']>;
  readonly right: Maybe<Scalars['Float']['output']>;
  readonly top: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageHotspot {
  readonly __typename?: 'SanityImageHotspot';
  readonly height: Maybe<Scalars['Float']['output']>;
  readonly width: Maybe<Scalars['Float']['output']>;
  readonly x: Maybe<Scalars['Float']['output']>;
  readonly y: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageMetaData {
  readonly __typename?: 'SanityImageMetaData';
  readonly lqip: Maybe<Scalars['String']['output']>;
  readonly palette: Maybe<ISanityImagePalette>;
}

export interface ISanityImagePalette {
  readonly __typename?: 'SanityImagePalette';
  readonly dominant: Maybe<ISanityImagePaletteSwatch>;
}

export interface ISanityImagePaletteSwatch {
  readonly __typename?: 'SanityImagePaletteSwatch';
  readonly background: Maybe<Scalars['String']['output']>;
  readonly foreground: Maybe<Scalars['String']['output']>;
}

export enum ServiceMode {
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CATERING_PICKUP = 'CATERING_PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TABLE_SERVICE = 'TABLE_SERVICE',
  TAKEOUT = 'TAKEOUT',
}

export interface ISignInUserInput {
  readonly email: Scalars['String']['input'];
  readonly platform: Platform;
  readonly stage: Stage;
}

export interface ISignUpUserInput {
  readonly country: IsoCountryCode;
  readonly dob?: InputMaybe<Scalars['String']['input']>;
  readonly favoriteStores?: InputMaybe<ReadonlyArray<IFavoriteStoreSignUpInput>>;
  readonly gender?: InputMaybe<Gender>;
  readonly name: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly platform: Platform;
  readonly providerType?: InputMaybe<ProviderType>;
  readonly referralCode?: InputMaybe<Scalars['String']['input']>;
  readonly requiredAcceptanceAgreementInfo?: InputMaybe<
    ReadonlyArray<IRequiredAcceptanceAgreementInfoInput>
  >;
  readonly stage: Stage;
  readonly userName: Scalars['String']['input'];
  readonly wantsPromotionalEmails: Scalars['Boolean']['input'];
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
}

export interface ISizeBasedPlu {
  readonly __typename?: 'SizeBasedPlu';
  readonly comboPlu: Maybe<Scalars['String']['output']>;
  readonly comboSize: Maybe<Scalars['String']['output']>;
}

export interface ISizeBasedPluInput {
  readonly comboPlu?: InputMaybe<Scalars['String']['input']>;
  readonly comboSize?: InputMaybe<Scalars['String']['input']>;
}

export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  LOCALDEV = 'localdev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
}

export interface IStoreAddress {
  readonly __typename?: 'StoreAddress';
  readonly addressLine1: Scalars['String']['output'];
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly phoneNumber: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly zip: Scalars['String']['output'];
}

export interface IStoreAddressInput {
  readonly addressLine1: Scalars['String']['input'];
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly phoneNumber: Scalars['String']['input'];
  readonly state: Scalars['String']['input'];
  readonly zip: Scalars['String']['input'];
}

export interface IStoreDetails {
  readonly __typename?: 'StoreDetails';
  readonly deliveryHours: Maybe<IStoreHours>;
  readonly diningRoomHours: Maybe<IStoreHours>;
  readonly driveThruHours: Maybe<IStoreHours>;
  readonly franchiseGroupId: Maybe<Scalars['Int']['output']>;
  readonly franchiseGroupName: Maybe<Scalars['String']['output']>;
  readonly hasCatering: Maybe<Scalars['Boolean']['output']>;
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  readonly hasDriveThru: Maybe<Scalars['Boolean']['output']>;
  readonly hasMobileOrdering: Maybe<Scalars['Boolean']['output']>;
  readonly hasTableService: Maybe<Scalars['Boolean']['output']>;
  /** Last date time when the store made an order */
  readonly lastActiveDateTime: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly mobileOrderingStatus: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly posVendor: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
  readonly storeNumber: Maybe<Scalars['String']['output']>;
}

export interface IStoreHours {
  readonly __typename?: 'StoreHours';
  readonly friday: Maybe<IDay>;
  readonly monday: Maybe<IDay>;
  readonly saturday: Maybe<IDay>;
  readonly sunday: Maybe<IDay>;
  readonly thursday: Maybe<IDay>;
  readonly tuesday: Maybe<IDay>;
  readonly wednesday: Maybe<IDay>;
}

export interface ISupportCase {
  readonly __typename?: 'SupportCase';
  /** Identifier for the case. Useful for the user to have */
  readonly caseId: Maybe<Scalars['String']['output']>;
  /** The creation date of the ticket */
  readonly created: Maybe<Scalars['String']['output']>;
}

export interface ISupportCaseInput {
  /** Description of the issue the user is having */
  readonly comments: Scalars['String']['input'];
  /** User's email */
  readonly email: Scalars['String']['input'];
  /** Category of the issue */
  readonly issueCategory?: InputMaybe<Scalars['String']['input']>;
  /** Category ID of the issue */
  readonly issueCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId: Scalars['String']['input'];
  /** The status of the issue, i.e. 'OPEN' or 'CLOSED' */
  readonly issueStatus?: InputMaybe<ITicketStatus>;
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** The local time the order associated with the issue was placed */
  readonly localStoreOrderTime?: InputMaybe<Scalars['String']['input']>;
  /** User's name */
  readonly name: Scalars['String']['input'];
  /** The time and date the order associated with the issue was placed */
  readonly orderDateTime?: InputMaybe<Scalars['String']['input']>;
  /** Unique order identifier in RBI */
  readonly rbiOrderId?: InputMaybe<Scalars['String']['input']>;
  /** Service mode of the order associated with the issue */
  readonly serviceMode?: InputMaybe<Scalars['String']['input']>;
  /** Address of the store associated with the issue */
  readonly storeAddress?: InputMaybe<Scalars['String']['input']>;
  /** Store number from MDS */
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  /** Region user signed up in (account region) */
  readonly userAccountRegion?: InputMaybe<Scalars['String']['input']>;
  /** User's region */
  readonly userRegion?: InputMaybe<Scalars['String']['input']>;
}

export interface ISwapInput {
  readonly cartId: Scalars['String']['input'];
  readonly from: Scalars['String']['input'];
  readonly swapType: Scalars['String']['input'];
  readonly to: Scalars['String']['input'];
}

export interface IThreeDsOptions {
  /** challenge response obtained in challengeCallbackUrl to resume challenge flow */
  readonly challengeResponse?: InputMaybe<Scalars['String']['input']>;
  /** transactionId to resume method or challenge flow */
  readonly transactionId: Scalars['String']['input'];
}

export enum TicketCategory {
  GENERAL = 'GENERAL',
}

export interface ITrackEventViaBeInput {
  readonly eventDetails: IEventDetails;
  readonly userIdentities: IUserIdentities;
}

export interface ITransactionListResponse {
  readonly __typename?: 'TransactionListResponse';
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
  readonly transactions: Maybe<ReadonlyArray<Maybe<IPrepaidTransaction>>>;
}

export interface IUnavailableCartEntry {
  readonly __typename?: 'UnavailableCartEntry';
  readonly lineId: Maybe<Scalars['String']['output']>;
  readonly sanityId: Scalars['String']['output'];
}

export interface IUpdateOrderCommitRequestInput {
  /** Commit order request body */
  readonly body: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['ID']['input'];
}

export interface IUpdateOrderInput {
  /** number of seconds from now to inject order */
  readonly fireOrderIn: Scalars['Int']['input'];
  readonly rbiOrderId: Scalars['ID']['input'];
}

export interface IUpdateUserDetailsInput {
  readonly additionalDetails?: InputMaybe<Scalars['String']['input']>;
  readonly autoReloadEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly autoReloadThreshold?: InputMaybe<Scalars['Int']['input']>;
  readonly communicationPreferences?: InputMaybe<
    ReadonlyArray<InputMaybe<ICommunicationPreferenceInput>>
  >;
  readonly defaultAccountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated(reason: "Use `defaultAccountIdentifier`.") */
  readonly defaultFdAccountId?: InputMaybe<Scalars['String']['input']>;
  readonly defaultPaymentAccountId?: InputMaybe<Scalars['String']['input']>;
  readonly defaultReloadAmt?: InputMaybe<Scalars['Int']['input']>;
  readonly defaultScanAndPayAccountIdentifier?: InputMaybe<Scalars['String']['input']>;
  readonly dob?: InputMaybe<Scalars['String']['input']>;
  readonly dobDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly favoriteOffers?: InputMaybe<ReadonlyArray<InputMaybe<IFavoriteOfferInput>>>;
  readonly favoriteStores?: InputMaybe<ReadonlyArray<InputMaybe<IFavoriteStoreInput>>>;
  readonly gender?: InputMaybe<Gender>;
  readonly isoCountryCode?: InputMaybe<IsoCountryCode>;
  readonly loyaltyTier?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly phoneVerified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly promotionalEmails?: InputMaybe<Scalars['Boolean']['input']>;
  readonly requiredAcceptanceAgreementInfo?: InputMaybe<
    ReadonlyArray<InputMaybe<IRequiredAcceptanceAgreementInfoInput>>
  >;
  readonly rutrFailedSkillsTestTimestamp?: InputMaybe<Scalars['String']['input']>;
  readonly rutrPassedSkillsTestTimestamp?: InputMaybe<Scalars['String']['input']>;
  readonly showThLoyaltyOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
}

export interface IUser {
  readonly __typename?: 'User';
  /** Cognito user id */
  readonly cognitoId: Scalars['ID']['output'];
  readonly createdAt: Scalars['String']['output'];
  readonly details: IUserDetails;
  /** loyaltyId used for loyalty API lookup */
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  /** TH Legacy Cogntio User ID */
  readonly thLegacyCognitoId: Maybe<Scalars['String']['output']>;
  readonly uniquePurchasedItems: Maybe<ReadonlyArray<Maybe<ICartEntries>>>;
  readonly updatedAt: Scalars['String']['output'];
}

export interface IUserDetails {
  readonly __typename?: 'UserDetails';
  /** Giftcard can be auto reloaded */
  readonly autoReloadEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Amount in cents that balance must reach for auto reload */
  readonly autoReloadThreshold: Maybe<Scalars['Int']['output']>;
  /** Array of Communication Preferences */
  readonly communicationPreferences: Maybe<ReadonlyArray<Maybe<ICommunicationPreference>>>;
  /** Account (Adyen or First-Data) ID of auto reload default payment method */
  readonly defaultAccountIdentifier: Maybe<Scalars['String']['output']>;
  /**
   * First Data ID of auto reload default payment method
   * @deprecated Use `defaultAccountIdentifier`.
   */
  readonly defaultFdAccountId: Maybe<Scalars['String']['output']>;
  /** Adyen or First Data ID of default payment method */
  readonly defaultPaymentAccountId: Maybe<Scalars['String']['output']>;
  /** Reload amount to default to for user's gift cards */
  readonly defaultReloadAmt: Maybe<Scalars['Int']['output']>;
  /** Default Account Id for Scan & Pay */
  readonly defaultScanAndPayAccountIdentifier: Maybe<Scalars['String']['output']>;
  /**
   * User Adresses and phone number combinations to be used to alllow users
   * to select from multiple saced addresses
   */
  readonly deliveryAddresses: Maybe<ReadonlyArray<Maybe<IDeliveryAddress>>>;
  /** Date of birth (format TBD) */
  readonly dob: Maybe<Scalars['String']['output']>;
  readonly dobDeleted: Maybe<Scalars['Boolean']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  /** Whether the user has verified the email */
  readonly emailVerified: Maybe<Scalars['Boolean']['output']>;
  /** User's saved list of favorite offers */
  readonly favoriteOffers: Maybe<ReadonlyArray<Maybe<IFavoriteOffer>>>;
  /** User's saved list of favorite stores */
  readonly favoriteStores: Maybe<ReadonlyArray<Maybe<IFavoriteStore>>>;
  readonly gender: Maybe<Gender>;
  /** User's ISO Country Code (e.g. USA, CAN) */
  readonly isoCountryCode: Maybe<IsoCountryCode>;
  /** User's selected loyalty tier */
  readonly loyaltyTier: Maybe<Scalars['String']['output']>;
  /** User's name */
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  /** Whether the user has verified the phone number */
  readonly phoneVerified: Maybe<Scalars['Boolean']['output']>;
  /** Whether the user has opted in to promotional emails */
  readonly promotionalEmails: Maybe<Scalars['Boolean']['output']>;
  /** Acceptance Agreement info */
  readonly requiredAcceptanceAgreementInfo: Maybe<
    ReadonlyArray<Maybe<IRequiredAcceptanceAgreementInfo>>
  >;
  /** Last time the user failed the skills tests of RUTR */
  readonly rutrFailedSkillsTestTimestamp: Maybe<Scalars['String']['output']>;
  /** Last time the user passed the skills tests of RUTR */
  readonly rutrPassedSkillsTestTimestamp: Maybe<Scalars['String']['output']>;
  /** Whether the user should be shown the onboarding flow */
  readonly showThLoyaltyOnboarding: Maybe<Scalars['Boolean']['output']>;
  readonly zipcode: Maybe<Scalars['String']['output']>;
}

export interface IUserGeo {
  readonly __typename?: 'UserGeo';
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['String']['output']>;
}

export interface IUserIdentities {
  readonly cognitoId: Scalars['ID']['input'];
  readonly email: Scalars['String']['input'];
  readonly isoCountryCode: IsoCountryCode;
}

export interface IUserPaymentDetails {
  /** Represents the email of the user who owns the requested payment */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** Represents the phone number of the user who owns the requested payment */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IUserRecentAddresses {
  readonly __typename?: 'UserRecentAddresses';
  readonly addresses: Maybe<ReadonlyArray<Maybe<IDeliveryAddress>>>;
  readonly count: Maybe<Scalars['Int']['output']>;
}

export interface IUserReportsMissingOrderInput {
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId?: InputMaybe<Scalars['String']['input']>;
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** List of reported missing items. Undefined if entire order is missing */
  readonly missingItems?: InputMaybe<ReadonlyArray<InputMaybe<IMissingItem>>>;
  /** Order ID that the user is reporting */
  readonly rbiOrderId: Scalars['String']['input'];
  /** Optional user comment as part of the report */
  readonly userComment?: InputMaybe<Scalars['String']['input']>;
}

export interface IUserRequestsRefundInput {
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId?: InputMaybe<Scalars['String']['input']>;
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** List of reported missing items. Undefined if entire order is missing */
  readonly missingItems?: InputMaybe<ReadonlyArray<InputMaybe<IMissingItem>>>;
  /** Order ID that the user is reporting */
  readonly rbiOrderId: Scalars['String']['input'];
  /** Optional user comment as part of the report */
  readonly userComment?: InputMaybe<Scalars['String']['input']>;
}

export interface IValidateCommitOrderInput {
  /** Delivery information */
  readonly delivery?: InputMaybe<ICommitDeliveryInput>;
  /** Takes in a time in seconds to delay the order commit */
  readonly fireOrderIn?: InputMaybe<Scalars['Int']['input']>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['String']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Whether to skip cooling period or not */
  readonly skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IVendor {
  readonly __typename?: 'Vendor';
  readonly vendor: Maybe<Scalars['String']['output']>;
}

export interface IVendorConfig {
  readonly __typename?: 'VendorConfig';
  readonly constantPlu: Maybe<Scalars['String']['output']>;
  readonly discountPlu: Maybe<Scalars['String']['output']>;
  readonly multiConstantPlus: Maybe<ReadonlyArray<Maybe<IQuantityPlu>>>;
  readonly parentChildPlu: Maybe<IParentChildPlu>;
  readonly parentSanityId: Maybe<Scalars['String']['output']>;
  readonly pluType: Scalars['String']['output'];
  readonly pullUpLevels: Maybe<Scalars['Int']['output']>;
  readonly quantityBasedPlu: Maybe<ReadonlyArray<Maybe<IQuantityPlu>>>;
  readonly sizeBasedPlu: Maybe<ISizeBasedPlu>;
}

export interface IVendorConfigInput {
  readonly constantPlu?: InputMaybe<Scalars['String']['input']>;
  readonly discountPlu?: InputMaybe<Scalars['String']['input']>;
  readonly multiConstantPlus?: InputMaybe<ReadonlyArray<InputMaybe<IQuantityPluInput>>>;
  readonly parentChildPlu?: InputMaybe<IParentChildPluInput>;
  readonly parentSanityId?: InputMaybe<Scalars['String']['input']>;
  readonly pluType: Scalars['String']['input'];
  readonly pullUpLevels?: InputMaybe<Scalars['Int']['input']>;
  readonly quantityBasedPlu?: InputMaybe<ReadonlyArray<InputMaybe<IQuantityPluInput>>>;
  readonly sizeBasedPlu?: InputMaybe<ISizeBasedPluInput>;
}

export interface IVendorConfigs {
  readonly __typename?: 'VendorConfigs';
  readonly carrols: Maybe<IVendorConfig>;
  readonly carrolsDelivery: Maybe<IVendorConfig>;
  readonly ncr: Maybe<IVendorConfig>;
  readonly ncrDelivery: Maybe<IVendorConfig>;
  readonly oheics: Maybe<IVendorConfig>;
  readonly oheicsDelivery: Maybe<IVendorConfig>;
  readonly partner: Maybe<IVendorConfig>;
  readonly partnerDelivery: Maybe<IVendorConfig>;
  readonly productNumber: Maybe<IVendorConfig>;
  readonly productNumberDelivery: Maybe<IVendorConfig>;
  readonly qdi: Maybe<IVendorConfig>;
  readonly qdiDelivery: Maybe<IVendorConfig>;
  readonly qst: Maybe<IVendorConfig>;
  readonly qstDelivery: Maybe<IVendorConfig>;
  readonly rpos: Maybe<IVendorConfig>;
  readonly rposDelivery: Maybe<IVendorConfig>;
  readonly sicom: Maybe<IVendorConfig>;
  readonly sicomDelivery: Maybe<IVendorConfig>;
  readonly simplyDelivery: Maybe<IVendorConfig>;
  readonly simplyDeliveryDelivery: Maybe<IVendorConfig>;
  readonly tablet: Maybe<IVendorConfig>;
  readonly tabletDelivery: Maybe<IVendorConfig>;
  readonly toshibaLoyalty: Maybe<IVendorConfig>;
}

export interface IVendorConfigsInput {
  readonly carrols?: InputMaybe<IVendorConfigInput>;
  readonly carrolsDelivery?: InputMaybe<IVendorConfigInput>;
  /** HDX is not currently supported */
  readonly hdx?: InputMaybe<IVendorConfigInput>;
  readonly ncr?: InputMaybe<IVendorConfigInput>;
  readonly ncrDelivery?: InputMaybe<IVendorConfigInput>;
  readonly oheics?: InputMaybe<IVendorConfigInput>;
  readonly oheicsDelivery?: InputMaybe<IVendorConfigInput>;
  readonly partner?: InputMaybe<IVendorConfigInput>;
  readonly partnerDelivery?: InputMaybe<IVendorConfigInput>;
  readonly productNumber?: InputMaybe<IVendorConfigInput>;
  readonly productNumberDelivery?: InputMaybe<IVendorConfigInput>;
  readonly qdi?: InputMaybe<IVendorConfigInput>;
  readonly qdiDelivery?: InputMaybe<IVendorConfigInput>;
  readonly qst?: InputMaybe<IVendorConfigInput>;
  readonly qstDelivery?: InputMaybe<IVendorConfigInput>;
  readonly rpos?: InputMaybe<IVendorConfigInput>;
  readonly rposDelivery?: InputMaybe<IVendorConfigInput>;
  readonly sicom?: InputMaybe<IVendorConfigInput>;
  readonly sicomDelivery?: InputMaybe<IVendorConfigInput>;
  readonly simplyDelivery?: InputMaybe<IVendorConfigInput>;
  readonly simplyDeliveryDelivery?: InputMaybe<IVendorConfigInput>;
  readonly tablet?: InputMaybe<IVendorConfigInput>;
  readonly tabletDelivery?: InputMaybe<IVendorConfigInput>;
  readonly toshibaLoyalty?: InputMaybe<IVendorConfigInput>;
}

export interface IVrPayment {
  /** Represents the PayPal id for the payment */
  readonly billingAgreementId?: InputMaybe<Scalars['String']['input']>;
  /** Device token provided by Braintree for paypal payments */
  readonly deviceToken?: InputMaybe<Scalars['String']['input']>;
  /** Merchant Account */
  readonly merchantAccount: Scalars['String']['input'];
  /** Represents the PSP id for the payment */
  readonly pspReference: Scalars['String']['input'];
  /** Whether to store payment method or not */
  readonly storePaymentMethod: Scalars['Boolean']['input'];
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IVrPaymentCheckoutLink {
  readonly __typename?: 'VrPaymentCheckoutLink';
  readonly link: Scalars['String']['output'];
}

export interface IVrPaymentPayPalFastLink {
  readonly __typename?: 'VrPaymentPayPalFastLink';
  readonly link: Scalars['String']['output'];
}

export interface IVrPaymentVaultLink {
  readonly __typename?: 'VrPaymentVaultLink';
  readonly data: Scalars['String']['output'];
  readonly link: Scalars['String']['output'];
}

export interface IWaitTime {
  readonly __typename?: 'WaitTime';
  readonly firingTimestamp: Maybe<Scalars['String']['output']>;
  readonly queueLength: Maybe<Scalars['Int']['output']>;
}

export interface IWorldpayPayment {
  /** Low Value Token */
  readonly lowValueToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IResendPhoneVerificationInput {
  /** user's current platform */
  readonly platform: Platform;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId?: InputMaybe<Scalars['ID']['input']>;
  /** user's current stage */
  readonly stage: Stage;
}

export interface IResendVerificationEmailInput {
  /** user's current platform */
  readonly platform: Platform;
  /** user's current stage */
  readonly stage: Stage;
}

export interface IValidateAuthJwtInput {
  readonly jwt: Scalars['String']['input'];
}

export interface IVerifyPhoneNumberInput {
  /** The code associated with the user's current OTP, for validation */
  readonly code: Scalars['String']['input'];
  /** user's current platform */
  readonly platform: Platform;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId: Scalars['ID']['input'];
  /** user's current stage */
  readonly stage: Stage;
}

export type IDayFragment = {
  readonly __typename?: 'Day';
  readonly close: string | null;
  readonly open: string | null;
};

export type IStoreHoursFragment = {
  readonly __typename?: 'StoreHours';
  readonly monday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly tuesday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly wednesday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly thursday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly friday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly saturday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly sunday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
};

export type IUserOrderFragment = {
  readonly __typename?: 'Order';
  readonly rbiOrderId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly fireOrderIn: number | null;
  readonly status: RbiOrderStatus;
  readonly orderNumberFormatted: string | null;
  readonly _id: string;
  readonly delivery: {
    readonly __typename?: 'Delivery';
    readonly feeCents: number;
    readonly tipCents: number | null;
    readonly feeDiscountCents: number | null;
    readonly status: DeliveryStatus;
    readonly serviceFeeCents: number | null;
    readonly smallCartFeeCents: number | null;
    readonly geographicalFeeCents: number | null;
    readonly serviceFeeDiscountCents: number | null;
    readonly unavailabilityReason: string | null;
    readonly dropoff: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly state: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
    };
    readonly cancellation: {
      readonly __typename?: 'DeliveryCancellation';
      readonly reason: string | null;
      readonly details: string | null;
    } | null;
  } | null;
  readonly cart: {
    readonly __typename?: 'Cart';
    readonly ticketNumber: string | null;
    readonly storeId: string;
    readonly vatNumber: string | null;
    readonly serviceMode: ServiceMode;
    readonly posVendor: PosVendor;
    readonly cartVersion: number | null;
    readonly subTotalCents: number | null;
    readonly taxCents: number | null;
    readonly totalCents: number | null;
    readonly wasLoyaltyEarned: boolean | null;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'Discount';
      readonly name: DiscountTypes | null;
      readonly value: number | null;
    } | null> | null;
    readonly payment: {
      readonly __typename?: 'Payment';
      readonly paymentType: CartPaymentType | null;
      readonly paymentMethodBrand: PaymentMethodBrand | null;
      readonly paymentRequestTimeLimit: string | null;
    } | null;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'Fees';
      readonly name: string | null;
      readonly priceCents: number | null;
      readonly quantity: number | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly donations: ReadonlyArray<{
      readonly __typename?: 'Donation';
      readonly id: string | null;
      readonly name: string | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly storeAddress: {
      readonly __typename?: 'StoreAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string;
      readonly zip: string;
    };
    readonly storeDetails: {
      readonly __typename?: 'StoreDetails';
      readonly franchiseGroupName: string | null;
      readonly franchiseGroupId: number | null;
      readonly hasCatering: boolean | null;
      readonly hasDelivery: boolean | null;
      readonly hasDriveThru: boolean | null;
      readonly hasMobileOrdering: boolean | null;
      readonly lastActiveDateTime: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly mobileOrderingStatus: string | null;
      readonly phoneNumber: string | null;
      readonly posVendor: string | null;
      readonly status: string | null;
      readonly storeNumber: string | null;
      readonly deliveryHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
      readonly diningRoomHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
      readonly driveThruHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
    } | null;
    readonly cartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly reorderCartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly isInMenu: boolean | null;
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly unavailableCartEntries: ReadonlyArray<{
      readonly __typename?: 'UnavailableCartEntry';
      readonly lineId: string | null;
      readonly sanityId: string;
    } | null> | null;
  };
  readonly fulfillmentDetails: {
    readonly __typename?: 'FulfillmentDetails';
    readonly pickupType: FulfillmentPickupType | null;
    readonly pickupPin: string | null;
    readonly pickupReady: boolean | null;
  } | null;
  readonly order: {
    readonly __typename?: 'RbiOrder';
    readonly refund: {
      readonly __typename?: 'Refund';
      readonly primaryReason: RefundReason;
      readonly secondaryReason: string | null;
      readonly subtotal: string;
      readonly tax: string;
      readonly transactionDateTime: string;
      readonly refundedItems: ReadonlyArray<{
        readonly __typename?: 'RefundedItem';
        readonly refundAmount: string | null;
        readonly refundQuantity: string | null;
        readonly sanityId: string | null;
        readonly type: CartEntryType | null;
        readonly name: string | null;
        readonly plu: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type IDeleteMeMutationVariables = Exact<{ [key: string]: never }>;

export type IDeleteMeMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteMe: boolean | null;
};

export type ICheckRefundEligibilityQueryVariables = Exact<{
  rbiOrderId: Scalars['ID']['input'];
}>;

export type ICheckRefundEligibilityQuery = {
  readonly __typename?: 'Query';
  readonly checkRefundEligibility: {
    readonly __typename?: 'RefundEligibilityResponse';
    readonly eligible: boolean;
    readonly ineligibilityReason: RefundIneligibilityReason | null;
  } | null;
};

export type IDeliveryQuoteQueryVariables = Exact<{
  dropoff: IDeliveryWaypointInput;
  pickup: IDeliveryWaypointInput;
}>;

export type IDeliveryQuoteQuery = {
  readonly __typename?: 'Query';
  readonly deliveryQuote: {
    readonly __typename?: 'Delivery';
    readonly status: DeliveryStatus;
    readonly deliverySurchargeFeeCents: number | null;
  };
};

export type IAbTestFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type IAbTestFlagsQuery = {
  readonly __typename?: 'Query';
  readonly abTestFlags: ReadonlyArray<string>;
};

export type IPutFlavorFlowEventsMutationVariables = Exact<{
  productDetails:
    | ReadonlyArray<InputMaybe<IFlavorFlowItemInput>>
    | InputMaybe<IFlavorFlowItemInput>;
  serviceMode: ServiceMode;
  storeId: Scalars['String']['input'];
  eventType: FlavorFlowType;
}>;

export type IPutFlavorFlowEventsMutation = {
  readonly __typename?: 'Mutation';
  readonly putFlavorFlowEvents: boolean | null;
};

export type IListFlavorFlowItemsQueryVariables = Exact<{
  productDetails:
    | ReadonlyArray<InputMaybe<IFlavorFlowItemInput>>
    | InputMaybe<IFlavorFlowItemInput>;
  serviceMode: ServiceMode;
  storeId: Scalars['String']['input'];
}>;

export type IListFlavorFlowItemsQuery = {
  readonly __typename?: 'Query';
  readonly listFlavorFlowItems: {
    readonly __typename?: 'FlavorFlowItemResult';
    readonly recommender: string | null;
    readonly recommendationToken: string | null;
    readonly results: ReadonlyArray<{
      readonly __typename?: 'FlavorFlowItem';
      readonly id: string;
    } | null> | null;
  } | null;
};

export type IAdditionalTimeSlotFragment = {
  readonly __typename?: 'AdditionalTimeSlot';
  readonly open: string | null;
  readonly close: string | null;
  readonly _type: string | null;
};

export type ICartEntryFragment = {
  readonly __typename?: 'CartEntries';
  readonly lineId: string;
  readonly image: string | null;
  readonly isDonation: boolean | null;
  readonly isExtra: boolean | null;
  readonly name: string | null;
  readonly pickerSelections: string | null;
  readonly price: number | null;
  readonly quantity: number;
  readonly sanityId: string;
  readonly type: CartEntryType;
  readonly url: string | null;
  readonly redeemable: boolean | null;
  readonly _id: string;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
};

export type IDeliveryOrderAmountLimitFragment = {
  readonly __typename?: 'DeliveryOrderAmountLimit';
  readonly deliveryOrderAmountLimit: number | null;
  readonly deliveryOrderAmountLimitEnabled: boolean | null;
  readonly deliveryOrderRepeatedFailureLimitation: boolean | null;
  readonly firstDeliveryOrder: number | null;
  readonly firstDeliveryOrderEnabled: boolean | null;
};

export type IFavoriteFragment = {
  readonly __typename?: 'Favorite';
  readonly favoriteId: string | null;
  readonly name: string | null;
  readonly ref: string | null;
  readonly createdAt: string | null;
  readonly updatedAt: string | null;
  readonly _id: string | null;
  readonly entries: ReadonlyArray<{
    readonly __typename?: 'CartEntries';
    readonly lineId: string;
    readonly image: string | null;
    readonly isDonation: boolean | null;
    readonly isExtra: boolean | null;
    readonly name: string | null;
    readonly pickerSelections: string | null;
    readonly price: number | null;
    readonly quantity: number;
    readonly sanityId: string;
    readonly type: CartEntryType;
    readonly url: string | null;
    readonly redeemable: boolean | null;
    readonly _id: string;
    readonly children: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IReorderFavoriteFragment = {
  readonly __typename?: 'Favorite';
  readonly favoriteId: string | null;
  readonly name: string | null;
  readonly ref: string | null;
  readonly createdAt: string | null;
  readonly updatedAt: string | null;
  readonly _id: string | null;
  readonly entries: ReadonlyArray<{
    readonly __typename?: 'CartEntries';
    readonly isInMenu: boolean | null;
    readonly lineId: string;
    readonly image: string | null;
    readonly isDonation: boolean | null;
    readonly isExtra: boolean | null;
    readonly name: string | null;
    readonly pickerSelections: string | null;
    readonly price: number | null;
    readonly quantity: number;
    readonly sanityId: string;
    readonly type: CartEntryType;
    readonly url: string | null;
    readonly redeemable: boolean | null;
    readonly _id: string;
    readonly children: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly isInMenu: boolean | null;
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    readonly vendorConfigs: {
      readonly __typename?: 'VendorConfigs';
      readonly ncr: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly ncrDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partner: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly partnerDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumber: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly productNumberDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicom: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly sicomDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdi: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly qdiDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rpos: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly rposDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly simplyDeliveryDelivery: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
      readonly toshibaLoyalty: {
        readonly __typename?: 'VendorConfig';
        readonly pluType: string;
        readonly parentSanityId: string | null;
        readonly pullUpLevels: number | null;
        readonly constantPlu: string | null;
        readonly quantityBasedPlu: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly multiConstantPlus: ReadonlyArray<{
          readonly __typename?: 'QuantityPlu';
          readonly quantity: number | null;
          readonly plu: string | null;
          readonly qualifier: string | null;
        } | null> | null;
        readonly parentChildPlu: {
          readonly __typename?: 'ParentChildPlu';
          readonly plu: string | null;
          readonly childPlu: string | null;
        } | null;
        readonly sizeBasedPlu: {
          readonly __typename?: 'SizeBasedPlu';
          readonly comboPlu: string | null;
          readonly comboSize: string | null;
        } | null;
      } | null;
    } | null;
  } | null> | null;
};

export type IOperatingHoursFragment = {
  readonly __typename?: 'OperatingHours';
  readonly friClose: string | null;
  readonly friOpen: string | null;
  readonly monClose: string | null;
  readonly monOpen: string | null;
  readonly satClose: string | null;
  readonly satOpen: string | null;
  readonly sunClose: string | null;
  readonly sunOpen: string | null;
  readonly thrClose: string | null;
  readonly thrOpen: string | null;
  readonly tueClose: string | null;
  readonly tueOpen: string | null;
  readonly wedClose: string | null;
  readonly wedOpen: string | null;
  readonly friAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly monAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly satAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly sunAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly thrAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly tueAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
  readonly wedAdditionalTimeSlot: {
    readonly __typename?: 'AdditionalTimeSlot';
    readonly open: string | null;
    readonly close: string | null;
    readonly _type: string | null;
  } | null;
};

export type IPaymentMethodsFragment = {
  readonly __typename?: 'PaymentMethods';
  readonly name: string | null;
  readonly paymentMethodBrand: string | null;
  readonly state: boolean | null;
  readonly isOnlinePayment: boolean | null;
};

export type IRestaurantNodeFragment = {
  readonly __typename?: 'RestaurantNode';
  readonly _id: string | null;
  readonly storeId: string | null;
  readonly isAvailable: boolean;
  readonly posVendor: string | null;
  readonly chaseMerchantId: string | null;
  readonly cybersourceTransactingId: string | null;
  readonly distanceInMiles: number | null;
  readonly drinkStationType: string | null;
  readonly driveThruLaneType: string | null;
  readonly email: ReadonlyArray<string> | null;
  readonly environment: RestaurantStatus | null;
  readonly franchiseGroupId: number | null;
  readonly franchiseGroupName: string | null;
  readonly frontCounterClosed: boolean | null;
  readonly hasBreakfast: boolean | null;
  readonly hasBurgersForBreakfast: boolean | null;
  readonly hasCatering: boolean | null;
  readonly hasCurbside: boolean | null;
  readonly hideClickAndCollectOrdering: boolean | null;
  readonly hasDelivery: boolean | null;
  readonly hasDineIn: boolean | null;
  readonly hasDriveThru: boolean | null;
  readonly hasTableService: boolean | null;
  readonly hasMobileOrdering: boolean | null;
  readonly hasLateNightMenu: boolean | null;
  readonly hasParking: boolean | null;
  readonly hasPlayground: boolean | null;
  readonly hasTakeOut: boolean | null;
  readonly hasWifi: boolean | null;
  readonly hasLoyalty: boolean | null;
  readonly id: string | null;
  readonly isDarkKitchen: boolean | null;
  readonly isFavorite: boolean;
  readonly isHalal: boolean | null;
  readonly isRecent: boolean;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly mobileOrderingStatus: OrderingStatus | null;
  readonly name: string | null;
  readonly number: string | null;
  readonly parkingType: string | null;
  readonly phoneNumber: string | null;
  readonly playgroundType: string | null;
  readonly posRestaurantId: string | null;
  readonly showStoreLocatorOffersButton: boolean | null;
  readonly status: string | null;
  readonly vatNumber: string | null;
  readonly curbsideHours: {
    readonly __typename?: 'OperatingHours';
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
  } | null;
  readonly deliveryHours: {
    readonly __typename?: 'OperatingHours';
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
  } | null;
  readonly deliveryOrderAmountLimit: {
    readonly __typename?: 'DeliveryOrderAmountLimit';
    readonly deliveryOrderAmountLimit: number | null;
    readonly deliveryOrderAmountLimitEnabled: boolean | null;
    readonly deliveryOrderRepeatedFailureLimitation: boolean | null;
    readonly firstDeliveryOrder: number | null;
    readonly firstDeliveryOrderEnabled: boolean | null;
  } | null;
  readonly diningRoomHours: {
    readonly __typename?: 'OperatingHours';
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
  } | null;
  readonly driveThruHours: {
    readonly __typename?: 'OperatingHours';
    readonly friClose: string | null;
    readonly friOpen: string | null;
    readonly monClose: string | null;
    readonly monOpen: string | null;
    readonly satClose: string | null;
    readonly satOpen: string | null;
    readonly sunClose: string | null;
    readonly sunOpen: string | null;
    readonly thrClose: string | null;
    readonly thrOpen: string | null;
    readonly tueClose: string | null;
    readonly tueOpen: string | null;
    readonly wedClose: string | null;
    readonly wedOpen: string | null;
    readonly friAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly monAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly satAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly sunAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly thrAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly tueAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
    readonly wedAdditionalTimeSlot: {
      readonly __typename?: 'AdditionalTimeSlot';
      readonly open: string | null;
      readonly close: string | null;
      readonly _type: string | null;
    } | null;
  } | null;
  readonly paymentMethods: ReadonlyArray<{
    readonly __typename?: 'PaymentMethods';
    readonly name: string | null;
    readonly paymentMethodBrand: string | null;
    readonly state: boolean | null;
    readonly isOnlinePayment: boolean | null;
  } | null> | null;
  readonly physicalAddress: {
    readonly __typename?: 'PhysicalAddress';
    readonly address1: string | null;
    readonly address2: string | null;
    readonly city: string | null;
    readonly country: string | null;
    readonly postalCode: string | null;
    readonly stateProvince: string | null;
    readonly stateProvinceShort: string | null;
  } | null;
  readonly pos: { readonly __typename?: 'RestaurantPos'; readonly vendor: string | null } | null;
  readonly restaurantImage: {
    readonly __typename?: 'SanityImage';
    readonly asset: {
      readonly __typename?: 'SanityImageAsset';
      readonly _id: string | null;
      readonly metadata: {
        readonly __typename?: 'SanityImageMetaData';
        readonly lqip: string | null;
      } | null;
    } | null;
    readonly crop: {
      readonly __typename?: 'SanityImageCrop';
      readonly top: number | null;
      readonly bottom: number | null;
      readonly left: number | null;
      readonly right: number | null;
    } | null;
    readonly hotspot: {
      readonly __typename?: 'SanityImageHotspot';
      readonly height: number | null;
      readonly width: number | null;
      readonly x: number | null;
      readonly y: number | null;
    } | null;
  } | null;
  readonly restaurantPosData: {
    readonly __typename?: 'RestaurantPosData';
    readonly _id: string | null;
  } | null;
  readonly customerFacingAddress: {
    readonly __typename?: 'CustomerFacingAddress';
    readonly locale: string | null;
  } | null;
  readonly waitTime: {
    readonly __typename?: 'WaitTime';
    readonly queueLength: number | null;
    readonly firingTimestamp: string | null;
  } | null;
  readonly integration: {
    readonly __typename?: 'Integration';
    readonly isPartnerRestaurant: boolean | null;
    readonly partnerGroup: {
      readonly __typename?: 'PartnerGroup';
      readonly name: string | null;
      readonly posIntegration: {
        readonly __typename?: 'PosIntegration';
        readonly _ref: string | null;
        readonly _type: string | null;
      } | null;
    } | null;
  } | null;
};

export type IUserDetailsFragment = {
  readonly __typename?: 'UserDetails';
  readonly name: string | null;
  readonly dob: string | null;
  readonly dobDeleted: boolean | null;
  readonly phoneNumber: string | null;
  readonly phoneVerified: boolean | null;
  readonly email: string | null;
  readonly emailVerified: boolean | null;
  readonly promotionalEmails: boolean | null;
  readonly isoCountryCode: IsoCountryCode | null;
  readonly zipcode: string | null;
  readonly defaultAccountIdentifier: string | null;
  readonly defaultFdAccountId: string | null;
  readonly defaultPaymentAccountId: string | null;
  readonly defaultReloadAmt: number | null;
  readonly autoReloadEnabled: boolean | null;
  readonly autoReloadThreshold: number | null;
  readonly loyaltyTier: string | null;
  readonly deliveryAddresses: ReadonlyArray<{
    readonly __typename?: 'DeliveryAddress';
    readonly addressLine1: string;
    readonly addressLine2: string | null;
    readonly city: string;
    readonly alias: string | null;
    readonly country: IsoCountryCode | null;
    readonly phoneNumber: string;
    readonly route: string | null;
    readonly state: string | null;
    readonly streetNumber: string | null;
    readonly zip: string | null;
  } | null> | null;
  readonly communicationPreferences: ReadonlyArray<{
    readonly __typename?: 'CommunicationPreference';
    readonly id: string | null;
    readonly value: string | null;
  } | null> | null;
  readonly favoriteStores: ReadonlyArray<{
    readonly __typename?: 'FavoriteStore';
    readonly storeId: string | null;
    readonly storeNumber: string | null;
  } | null> | null;
  readonly favoriteOffers: ReadonlyArray<{
    readonly __typename?: 'FavoriteOffer';
    readonly id: string | null;
  } | null> | null;
  readonly requiredAcceptanceAgreementInfo: ReadonlyArray<{
    readonly __typename?: 'RequiredAcceptanceAgreementInfo';
    readonly id: string | null;
    readonly updatedAt: string | null;
  } | null> | null;
};

export type IUserGeoFragment = {
  readonly __typename?: 'UserGeo';
  readonly city: string | null;
  readonly state: string | null;
  readonly country: string | null;
};

export type IVendorConfigFragment = {
  readonly __typename?: 'VendorConfig';
  readonly pluType: string;
  readonly parentSanityId: string | null;
  readonly pullUpLevels: number | null;
  readonly constantPlu: string | null;
  readonly quantityBasedPlu: ReadonlyArray<{
    readonly __typename?: 'QuantityPlu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly multiConstantPlus: ReadonlyArray<{
    readonly __typename?: 'QuantityPlu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly parentChildPlu: {
    readonly __typename?: 'ParentChildPlu';
    readonly plu: string | null;
    readonly childPlu: string | null;
  } | null;
  readonly sizeBasedPlu: {
    readonly __typename?: 'SizeBasedPlu';
    readonly comboPlu: string | null;
    readonly comboSize: string | null;
  } | null;
};

export type IVendorConfigsFragment = {
  readonly __typename?: 'VendorConfigs';
  readonly ncr: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly ncrDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partner: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partnerDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumber: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumberDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicom: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicomDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdi: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdiDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rpos: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rposDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDeliveryDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly toshibaLoyalty: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
};

export type IGetRestaurantQueryVariables = Exact<{
  storeId?: InputMaybe<Scalars['String']['input']>;
  storeNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGetRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly available: boolean;
    readonly curbsideHours: {
      readonly __typename?: 'OperatingHours';
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
    } | null;
    readonly deliveryHours: {
      readonly __typename?: 'OperatingHours';
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
    } | null;
    readonly diningRoomHours: {
      readonly __typename?: 'OperatingHours';
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
    } | null;
    readonly driveThruHours: {
      readonly __typename?: 'OperatingHours';
      readonly friClose: string | null;
      readonly friOpen: string | null;
      readonly monClose: string | null;
      readonly monOpen: string | null;
      readonly satClose: string | null;
      readonly satOpen: string | null;
      readonly sunClose: string | null;
      readonly sunOpen: string | null;
      readonly thrClose: string | null;
      readonly thrOpen: string | null;
      readonly tueClose: string | null;
      readonly tueOpen: string | null;
      readonly wedClose: string | null;
      readonly wedOpen: string | null;
      readonly friAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly monAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly satAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly sunAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly thrAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly tueAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
      readonly wedAdditionalTimeSlot: {
        readonly __typename?: 'AdditionalTimeSlot';
        readonly open: string | null;
        readonly close: string | null;
        readonly _type: string | null;
      } | null;
    } | null;
  };
};

export type IGetInstagramImageFeedQueryVariables = Exact<{
  input: Scalars['Int']['input'];
}>;

export type IGetInstagramImageFeedQuery = {
  readonly __typename?: 'Query';
  readonly getImageFeed: ReadonlyArray<{
    readonly __typename?: 'InstagramImage';
    readonly media_url: string;
    readonly username: string;
    readonly timestamp: string;
  }>;
};

export type ICreateSupportCaseMutationVariables = Exact<{
  caseInfo: ISupportCaseInput;
}>;

export type ICreateSupportCaseMutation = {
  readonly __typename?: 'Mutation';
  readonly createSupportCase: {
    readonly __typename?: 'SupportCase';
    readonly caseId: string | null;
  } | null;
};

export type IGetEncryptionDetailsMutationVariables = Exact<{ [key: string]: never }>;

export type IGetEncryptionDetailsMutation = {
  readonly __typename?: 'Mutation';
  readonly encryptionDetails: {
    readonly __typename?: 'EncryptionDetails';
    readonly fdPublicKey: string;
    readonly fdAccessToken: string;
    readonly fdPublicKeyExpiresInSeconds: number;
    readonly fdApiKey: string;
    readonly fdCustomerId: string | null;
    readonly fdtokenizationUrl: string;
  };
};

export type IAddCreditAccountMutationVariables = Exact<{
  input: IAddCreditAccountInput;
}>;

export type IAddCreditAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly addCreditAccount: {
    readonly __typename?: 'Account';
    readonly accountIdentifier: string | null;
    readonly fdAccountId: string | null;
    readonly chaseProfileId: string | null;
    readonly credit: {
      readonly __typename?: 'CreditCard';
      readonly alias: string;
      readonly cardType: string;
      readonly expiryMonth: string;
      readonly expiryYear: string;
      readonly panToken: string | null;
      readonly billingAddress: {
        readonly __typename?: 'BillingAddress';
        readonly locality: string;
        readonly postalCode: string;
        readonly region: string;
        readonly streetAddress: string;
        readonly unitNumber: string | null;
      } | null;
    } | null;
  };
};

export type IDeleteAccountMutationVariables = Exact<{
  input: IDeleteAccountInput;
}>;

export type IDeleteAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAccount: boolean | null;
};

export type IUserAccountsQueryVariables = Exact<{
  feCountryCode: IsoCountryCode;
}>;

export type IUserAccountsQuery = {
  readonly __typename?: 'Query';
  readonly userAccounts: {
    readonly __typename?: 'AccountListResponse';
    readonly count: number;
    readonly paymentProcessor: PaymentProcessor | null;
    readonly accounts: ReadonlyArray<{
      readonly __typename?: 'Account';
      readonly accountIdentifier: string | null;
      readonly fdAccountId: string | null;
      readonly chaseProfileId: string | null;
      readonly paypal: boolean | null;
      readonly paypalIdentifier: string | null;
      readonly credit: {
        readonly __typename?: 'CreditCard';
        readonly alias: string;
        readonly cardType: string;
        readonly expiryYear: string;
        readonly expiryMonth: string;
        readonly panToken: string | null;
        readonly billingAddress: {
          readonly __typename?: 'BillingAddress';
          readonly locality: string;
          readonly country: string | null;
          readonly postalCode: string;
          readonly region: string;
          readonly streetAddress: string;
          readonly unitNumber: string | null;
        } | null;
      } | null;
      readonly prepaid: {
        readonly __typename?: 'GiftCard';
        readonly alias: string;
        readonly currentBalance: number | null;
        readonly cardNumber: string;
        readonly expiryMonth: string;
        readonly expiryYear: string;
        readonly feFormattedCurrentBalance: number | null;
        readonly shuffledCardNumber: string;
      } | null;
    } | null> | null;
  } | null;
};

export type ILogPaymentFlowStartedEventMutationVariables = Exact<{ [key: string]: never }>;

export type ILogPaymentFlowStartedEventMutation = {
  readonly __typename?: 'Mutation';
  readonly logPaymentFlowStartedEvent: boolean;
};

export type IInitiatePaymentMutationVariables = Exact<{
  input: IInitiatePaymentInput;
}>;

export type IInitiatePaymentMutation = {
  readonly __typename?: 'Mutation';
  readonly initiatePayment: {
    readonly __typename?: 'InitiatePaymentResponse';
    readonly cybersourcePaSetup: {
      readonly __typename?: 'CybersourcePaSetup';
      readonly rbiOrderId: string;
      readonly accessToken: string;
      readonly referenceId: string;
      readonly deviceDataCollectionUrl: string;
    } | null;
    readonly cybersourceCaptureContext: {
      readonly __typename?: 'CybersourceCaptureContext';
      readonly context: string;
    } | null;
    readonly paycometCaptureContext: {
      readonly __typename?: 'PaycometCaptureContextResponse';
      readonly amount: number | null;
      readonly challengeUrl: string | null;
      readonly currency: string | null;
      readonly errorCode: number;
      readonly methodId: number | null;
      readonly order: string | null;
      readonly paymentRequestTimeLimit: string | null;
    } | null;
  };
};

export type IGenerateIdealCheckoutLinkMutationVariables = Exact<{
  input: IGenerateIdealCheckoutLinkInput;
}>;

export type IGenerateIdealCheckoutLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateIdealCheckoutLink: {
    readonly __typename?: 'IdealCheckoutLink';
    readonly result: string;
    readonly action: {
      readonly __typename?: 'RedirectAction';
      readonly method: string;
      readonly paymentMethodType: string;
      readonly type: string;
      readonly url: string;
    };
  };
};

export type IPluDataQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
}>;

export type IPluDataQuery = {
  readonly __typename?: 'Query';
  readonly pluData: {
    readonly __typename?: 'PluData';
    readonly plus: string | null;
    readonly deliveryPlus: string | null;
    readonly updatedAt: string | null;
  } | null;
};

export type IGetPrepaidsBalanceMutationVariables = Exact<{
  cardNumber: Scalars['String']['input'];
  feCountryCode: IsoCountryCode;
}>;

export type IGetPrepaidsBalanceMutation = {
  readonly __typename?: 'Mutation';
  readonly prepaidsBalance: { readonly __typename?: 'Balance'; readonly currentBalance: number };
};

export type IPrepaidsMergeMutationVariables = Exact<{
  input: IPrepaidsMergeInput;
}>;

export type IPrepaidsMergeMutation = {
  readonly __typename?: 'Mutation';
  readonly prepaidsMerge: {
    readonly __typename?: 'Merge';
    readonly currentBalance: number;
    readonly fdAccountId: string | null;
  };
};

export type IPrepaidsReloadMutationVariables = Exact<{
  input: IPrepaidsReloadInput;
}>;

export type IPrepaidsReloadMutation = {
  readonly __typename?: 'Mutation';
  readonly prepaidsReload: {
    readonly __typename?: 'Reload';
    readonly currentBalance: number;
    readonly fdAccountId: string;
    readonly fdCorrelationId: string | null;
    readonly transactionId: string;
  };
};

export type IAddPrepaidCardMutationVariables = Exact<{
  input: IAddGiftAccountInput;
}>;

export type IAddPrepaidCardMutation = {
  readonly __typename?: 'Mutation';
  readonly addGiftAccount: {
    readonly __typename?: 'GiftAccount';
    readonly fdAccountId: string;
    readonly prepaid: { readonly __typename?: 'GiftCard'; readonly currentBalance: number | null };
  };
};

export type IPrepaidTransactionCurrencyCodesQueryVariables = Exact<{
  cardNumber: Scalars['String']['input'];
}>;

export type IPrepaidTransactionCurrencyCodesQuery = {
  readonly __typename?: 'Query';
  readonly prepaidsTransactions: {
    readonly __typename?: 'TransactionListResponse';
    readonly transactions: ReadonlyArray<{
      readonly __typename?: 'PrepaidTransaction';
      readonly currencyCode: { readonly __typename?: 'CurrencyCode'; readonly number: number };
    } | null> | null;
  } | null;
};

export type IGetRefillDrinkQrCodeQueryVariables = Exact<{
  orderUpdateAt: Scalars['String']['input'];
  storeNumber: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
}>;

export type IGetRefillDrinkQrCodeQuery = {
  readonly __typename?: 'Query';
  readonly getRefillDrinkQRCode: {
    readonly __typename?: 'RefillDrinkQRCodeInfo';
    readonly qrCodeInfo: string | null;
  };
};

export type IRequestRefundMutationVariables = Exact<{
  input: IUserRequestsRefundInput;
}>;

export type IRequestRefundMutation = {
  readonly __typename?: 'Mutation';
  readonly userRequestsRefund: {
    readonly __typename?: 'RefundRequestResponse';
    readonly refundRequestAmountCents: number;
    readonly requestDateTime: string | null;
  };
};

export type IRequestMyInfoMutationVariables = Exact<{ [key: string]: never }>;

export type IRequestMyInfoMutation = {
  readonly __typename?: 'Mutation';
  readonly requestMyInfo: boolean | null;
};

export type IGetRestaurantsQueryVariables = Exact<{
  input?: InputMaybe<IRestaurantsInput>;
}>;

export type IGetRestaurantsQuery = {
  readonly __typename?: 'Query';
  readonly restaurants: {
    readonly __typename?: 'RestaurantsConnection';
    readonly totalCount: number | null;
    readonly pageInfo: {
      readonly __typename?: 'PageInfo';
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
    readonly nodes: ReadonlyArray<{
      readonly __typename?: 'RestaurantNode';
      readonly _id: string | null;
      readonly storeId: string | null;
      readonly isAvailable: boolean;
      readonly posVendor: string | null;
      readonly chaseMerchantId: string | null;
      readonly cybersourceTransactingId: string | null;
      readonly distanceInMiles: number | null;
      readonly drinkStationType: string | null;
      readonly driveThruLaneType: string | null;
      readonly email: ReadonlyArray<string> | null;
      readonly environment: RestaurantStatus | null;
      readonly franchiseGroupId: number | null;
      readonly franchiseGroupName: string | null;
      readonly frontCounterClosed: boolean | null;
      readonly hasBreakfast: boolean | null;
      readonly hasBurgersForBreakfast: boolean | null;
      readonly hasCatering: boolean | null;
      readonly hasCurbside: boolean | null;
      readonly hideClickAndCollectOrdering: boolean | null;
      readonly hasDelivery: boolean | null;
      readonly hasDineIn: boolean | null;
      readonly hasDriveThru: boolean | null;
      readonly hasTableService: boolean | null;
      readonly hasMobileOrdering: boolean | null;
      readonly hasLateNightMenu: boolean | null;
      readonly hasParking: boolean | null;
      readonly hasPlayground: boolean | null;
      readonly hasTakeOut: boolean | null;
      readonly hasWifi: boolean | null;
      readonly hasLoyalty: boolean | null;
      readonly id: string | null;
      readonly isDarkKitchen: boolean | null;
      readonly isFavorite: boolean;
      readonly isHalal: boolean | null;
      readonly isRecent: boolean;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly mobileOrderingStatus: OrderingStatus | null;
      readonly name: string | null;
      readonly number: string | null;
      readonly parkingType: string | null;
      readonly phoneNumber: string | null;
      readonly playgroundType: string | null;
      readonly posRestaurantId: string | null;
      readonly showStoreLocatorOffersButton: boolean | null;
      readonly status: string | null;
      readonly vatNumber: string | null;
      readonly curbsideHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly deliveryHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly deliveryOrderAmountLimit: {
        readonly __typename?: 'DeliveryOrderAmountLimit';
        readonly deliveryOrderAmountLimit: number | null;
        readonly deliveryOrderAmountLimitEnabled: boolean | null;
        readonly deliveryOrderRepeatedFailureLimitation: boolean | null;
        readonly firstDeliveryOrder: number | null;
        readonly firstDeliveryOrderEnabled: boolean | null;
      } | null;
      readonly diningRoomHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly driveThruHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly paymentMethods: ReadonlyArray<{
        readonly __typename?: 'PaymentMethods';
        readonly name: string | null;
        readonly paymentMethodBrand: string | null;
        readonly state: boolean | null;
        readonly isOnlinePayment: boolean | null;
      } | null> | null;
      readonly physicalAddress: {
        readonly __typename?: 'PhysicalAddress';
        readonly address1: string | null;
        readonly address2: string | null;
        readonly city: string | null;
        readonly country: string | null;
        readonly postalCode: string | null;
        readonly stateProvince: string | null;
        readonly stateProvinceShort: string | null;
      } | null;
      readonly pos: {
        readonly __typename?: 'RestaurantPos';
        readonly vendor: string | null;
      } | null;
      readonly restaurantImage: {
        readonly __typename?: 'SanityImage';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string | null;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetaData';
            readonly lqip: string | null;
          } | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly height: number | null;
          readonly width: number | null;
          readonly x: number | null;
          readonly y: number | null;
        } | null;
      } | null;
      readonly restaurantPosData: {
        readonly __typename?: 'RestaurantPosData';
        readonly _id: string | null;
      } | null;
      readonly customerFacingAddress: {
        readonly __typename?: 'CustomerFacingAddress';
        readonly locale: string | null;
      } | null;
      readonly waitTime: {
        readonly __typename?: 'WaitTime';
        readonly queueLength: number | null;
        readonly firingTimestamp: string | null;
      } | null;
      readonly integration: {
        readonly __typename?: 'Integration';
        readonly isPartnerRestaurant: boolean | null;
        readonly partnerGroup: {
          readonly __typename?: 'PartnerGroup';
          readonly name: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _ref: string | null;
            readonly _type: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type IDeliveryRestaurantQueryVariables = Exact<{
  dropoff: IDeliveryWaypointInput;
  searchRadius: Scalars['Float']['input'];
  platform: Platform;
}>;

export type IDeliveryRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly deliveryRestaurant: {
    readonly __typename?: 'DeliveryRestaurant';
    readonly storeStatus: DeliveryStoreStatus;
    readonly quote: DeliveryStatus | null;
    readonly nextEarliestOpen: string | null;
    readonly deliverySurchargeFeeCents: number | null;
    readonly quoteId: string | null;
    readonly unavailabilityReason: string | null;
    readonly restaurant: {
      readonly __typename?: 'RestaurantNode';
      readonly _id: string | null;
      readonly storeId: string | null;
      readonly isAvailable: boolean;
      readonly posVendor: string | null;
      readonly chaseMerchantId: string | null;
      readonly cybersourceTransactingId: string | null;
      readonly distanceInMiles: number | null;
      readonly drinkStationType: string | null;
      readonly driveThruLaneType: string | null;
      readonly email: ReadonlyArray<string> | null;
      readonly environment: RestaurantStatus | null;
      readonly franchiseGroupId: number | null;
      readonly franchiseGroupName: string | null;
      readonly frontCounterClosed: boolean | null;
      readonly hasBreakfast: boolean | null;
      readonly hasBurgersForBreakfast: boolean | null;
      readonly hasCatering: boolean | null;
      readonly hasCurbside: boolean | null;
      readonly hideClickAndCollectOrdering: boolean | null;
      readonly hasDelivery: boolean | null;
      readonly hasDineIn: boolean | null;
      readonly hasDriveThru: boolean | null;
      readonly hasTableService: boolean | null;
      readonly hasMobileOrdering: boolean | null;
      readonly hasLateNightMenu: boolean | null;
      readonly hasParking: boolean | null;
      readonly hasPlayground: boolean | null;
      readonly hasTakeOut: boolean | null;
      readonly hasWifi: boolean | null;
      readonly hasLoyalty: boolean | null;
      readonly id: string | null;
      readonly isDarkKitchen: boolean | null;
      readonly isFavorite: boolean;
      readonly isHalal: boolean | null;
      readonly isRecent: boolean;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly mobileOrderingStatus: OrderingStatus | null;
      readonly name: string | null;
      readonly number: string | null;
      readonly parkingType: string | null;
      readonly phoneNumber: string | null;
      readonly playgroundType: string | null;
      readonly posRestaurantId: string | null;
      readonly showStoreLocatorOffersButton: boolean | null;
      readonly status: string | null;
      readonly vatNumber: string | null;
      readonly curbsideHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly deliveryHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly deliveryOrderAmountLimit: {
        readonly __typename?: 'DeliveryOrderAmountLimit';
        readonly deliveryOrderAmountLimit: number | null;
        readonly deliveryOrderAmountLimitEnabled: boolean | null;
        readonly deliveryOrderRepeatedFailureLimitation: boolean | null;
        readonly firstDeliveryOrder: number | null;
        readonly firstDeliveryOrderEnabled: boolean | null;
      } | null;
      readonly diningRoomHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly driveThruHours: {
        readonly __typename?: 'OperatingHours';
        readonly friClose: string | null;
        readonly friOpen: string | null;
        readonly monClose: string | null;
        readonly monOpen: string | null;
        readonly satClose: string | null;
        readonly satOpen: string | null;
        readonly sunClose: string | null;
        readonly sunOpen: string | null;
        readonly thrClose: string | null;
        readonly thrOpen: string | null;
        readonly tueClose: string | null;
        readonly tueOpen: string | null;
        readonly wedClose: string | null;
        readonly wedOpen: string | null;
        readonly friAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly monAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly satAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly sunAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly thrAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly tueAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
        readonly wedAdditionalTimeSlot: {
          readonly __typename?: 'AdditionalTimeSlot';
          readonly open: string | null;
          readonly close: string | null;
          readonly _type: string | null;
        } | null;
      } | null;
      readonly paymentMethods: ReadonlyArray<{
        readonly __typename?: 'PaymentMethods';
        readonly name: string | null;
        readonly paymentMethodBrand: string | null;
        readonly state: boolean | null;
        readonly isOnlinePayment: boolean | null;
      } | null> | null;
      readonly physicalAddress: {
        readonly __typename?: 'PhysicalAddress';
        readonly address1: string | null;
        readonly address2: string | null;
        readonly city: string | null;
        readonly country: string | null;
        readonly postalCode: string | null;
        readonly stateProvince: string | null;
        readonly stateProvinceShort: string | null;
      } | null;
      readonly pos: {
        readonly __typename?: 'RestaurantPos';
        readonly vendor: string | null;
      } | null;
      readonly restaurantImage: {
        readonly __typename?: 'SanityImage';
        readonly asset: {
          readonly __typename?: 'SanityImageAsset';
          readonly _id: string | null;
          readonly metadata: {
            readonly __typename?: 'SanityImageMetaData';
            readonly lqip: string | null;
          } | null;
        } | null;
        readonly crop: {
          readonly __typename?: 'SanityImageCrop';
          readonly top: number | null;
          readonly bottom: number | null;
          readonly left: number | null;
          readonly right: number | null;
        } | null;
        readonly hotspot: {
          readonly __typename?: 'SanityImageHotspot';
          readonly height: number | null;
          readonly width: number | null;
          readonly x: number | null;
          readonly y: number | null;
        } | null;
      } | null;
      readonly restaurantPosData: {
        readonly __typename?: 'RestaurantPosData';
        readonly _id: string | null;
      } | null;
      readonly customerFacingAddress: {
        readonly __typename?: 'CustomerFacingAddress';
        readonly locale: string | null;
      } | null;
      readonly waitTime: {
        readonly __typename?: 'WaitTime';
        readonly queueLength: number | null;
        readonly firingTimestamp: string | null;
      } | null;
      readonly integration: {
        readonly __typename?: 'Integration';
        readonly isPartnerRestaurant: boolean | null;
        readonly partnerGroup: {
          readonly __typename?: 'PartnerGroup';
          readonly name: string | null;
          readonly posIntegration: {
            readonly __typename?: 'PosIntegration';
            readonly _ref: string | null;
            readonly _type: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    readonly preOrderTimeSlots: ReadonlyArray<{
      readonly __typename?: 'PreOrderTimeSlot';
      readonly start: string;
      readonly end: string;
    } | null> | null;
  };
};

export type ITrackEventViaBeMutationVariables = Exact<{
  input: ITrackEventViaBeInput;
}>;

export type ITrackEventViaBeMutation = {
  readonly __typename?: 'Mutation';
  readonly trackEventViaBE: boolean | null;
};

export type IGetUniqueStoresQueryVariables = Exact<{
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus> | RbiOrderStatus>;
}>;

export type IGetUniqueStoresQuery = {
  readonly __typename?: 'Query';
  readonly uniqueStores: {
    readonly __typename?: 'OrderListResponse';
    readonly orders: ReadonlyArray<{
      readonly __typename?: 'Order';
      readonly cart: { readonly __typename?: 'Cart'; readonly storeId: string };
    } | null> | null;
  };
};

export type IGetUserFavoritesRefsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGetUserFavoritesRefsQuery = {
  readonly __typename?: 'Query';
  readonly userFavorites: {
    readonly __typename?: 'FavoriteList';
    readonly count: number | null;
    readonly resumeAfter: string | null;
    readonly favorites: ReadonlyArray<{
      readonly __typename?: 'Favorite';
      readonly ref: string | null;
      readonly _id: string | null;
    } | null> | null;
  };
};

export type IGetUserFavoriteQueryVariables = Exact<{
  favoriteId: Scalars['String']['input'];
}>;

export type IGetUserFavoriteQuery = {
  readonly __typename?: 'Query';
  readonly userFavorite: {
    readonly __typename?: 'Favorite';
    readonly favoriteId: string | null;
    readonly name: string | null;
    readonly ref: string | null;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly _id: string | null;
    readonly entries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type IGetUserFavoritesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGetUserFavoritesQuery = {
  readonly __typename?: 'Query';
  readonly userFavorites: {
    readonly __typename?: 'FavoriteList';
    readonly count: number | null;
    readonly resumeAfter: string | null;
    readonly favorites: ReadonlyArray<{
      readonly __typename?: 'Favorite';
      readonly favoriteId: string | null;
      readonly name: string | null;
      readonly ref: string | null;
      readonly createdAt: string | null;
      readonly updatedAt: string | null;
      readonly _id: string | null;
      readonly entries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};

export type IGetUserReorderFavoritesQueryVariables = Exact<{ [key: string]: never }>;

export type IGetUserReorderFavoritesQuery = {
  readonly __typename?: 'Query';
  readonly userFavorites: {
    readonly __typename?: 'FavoriteList';
    readonly count: number | null;
    readonly favorites: ReadonlyArray<{
      readonly __typename?: 'Favorite';
      readonly favoriteId: string | null;
      readonly name: string | null;
      readonly ref: string | null;
      readonly createdAt: string | null;
      readonly updatedAt: string | null;
      readonly _id: string | null;
      readonly entries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly isInMenu: boolean | null;
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};

export type ICreateFavoriteMutationVariables = Exact<{
  input: IFavoriteInput;
}>;

export type ICreateFavoriteMutation = {
  readonly __typename?: 'Mutation';
  readonly createFavorite: {
    readonly __typename?: 'Favorite';
    readonly favoriteId: string | null;
    readonly name: string | null;
    readonly ref: string | null;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly _id: string | null;
    readonly entries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type IUpdateFavoriteMutationVariables = Exact<{
  favoriteId: Scalars['String']['input'];
  input: IFavoriteInput;
}>;

export type IUpdateFavoriteMutation = {
  readonly __typename?: 'Mutation';
  readonly updateFavorite: {
    readonly __typename?: 'Favorite';
    readonly favoriteId: string | null;
    readonly name: string | null;
    readonly ref: string | null;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly _id: string | null;
    readonly entries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type IDeleteFavoriteMutationVariables = Exact<{
  favoriteId: Scalars['String']['input'];
}>;

export type IDeleteFavoriteMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteFavorite: {
    readonly __typename?: 'Favorite';
    readonly favoriteId: string | null;
    readonly name: string | null;
    readonly ref: string | null;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly _id: string | null;
    readonly entries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type ICommitOrderMutationVariables = Exact<{
  input: ICommitOrderInput;
  delivery?: InputMaybe<ICommitDeliveryInput>;
  skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ICommitOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly commitOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
  };
};

export type IUpdateOrderMutationVariables = Exact<{
  input: IUpdateOrderInput;
}>;

export type IUpdateOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly updateOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly fireOrderIn: number | null;
  };
};

export type IGetUserOrdersQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus> | RbiOrderStatus>;
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  shouldFilterByRegion?: InputMaybe<Scalars['Boolean']['input']>;
  serviceModes?: InputMaybe<ReadonlyArray<InputMaybe<ServiceMode>> | InputMaybe<ServiceMode>>;
}>;

export type IGetUserOrdersQuery = {
  readonly __typename?: 'Query';
  readonly userOrders: {
    readonly __typename?: 'OrderListResponse';
    readonly count: number;
    readonly resumeAfter: string | null;
    readonly orders: ReadonlyArray<{
      readonly __typename?: 'Order';
      readonly rbiOrderId: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly fireOrderIn: number | null;
      readonly status: RbiOrderStatus;
      readonly orderNumberFormatted: string | null;
      readonly _id: string;
      readonly delivery: {
        readonly __typename?: 'Delivery';
        readonly feeCents: number;
        readonly tipCents: number | null;
        readonly feeDiscountCents: number | null;
        readonly status: DeliveryStatus;
        readonly serviceFeeCents: number | null;
        readonly smallCartFeeCents: number | null;
        readonly geographicalFeeCents: number | null;
        readonly serviceFeeDiscountCents: number | null;
        readonly unavailabilityReason: string | null;
        readonly dropoff: {
          readonly __typename?: 'DeliveryWaypoint';
          readonly state: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        };
        readonly cancellation: {
          readonly __typename?: 'DeliveryCancellation';
          readonly reason: string | null;
          readonly details: string | null;
        } | null;
      } | null;
      readonly cart: {
        readonly __typename?: 'Cart';
        readonly ticketNumber: string | null;
        readonly storeId: string;
        readonly vatNumber: string | null;
        readonly serviceMode: ServiceMode;
        readonly posVendor: PosVendor;
        readonly cartVersion: number | null;
        readonly subTotalCents: number | null;
        readonly taxCents: number | null;
        readonly totalCents: number | null;
        readonly wasLoyaltyEarned: boolean | null;
        readonly discounts: ReadonlyArray<{
          readonly __typename?: 'Discount';
          readonly name: DiscountTypes | null;
          readonly value: number | null;
        } | null> | null;
        readonly payment: {
          readonly __typename?: 'Payment';
          readonly paymentType: CartPaymentType | null;
          readonly paymentMethodBrand: PaymentMethodBrand | null;
          readonly paymentRequestTimeLimit: string | null;
        } | null;
        readonly fees: ReadonlyArray<{
          readonly __typename?: 'Fees';
          readonly name: string | null;
          readonly priceCents: number | null;
          readonly quantity: number | null;
          readonly totalCents: number | null;
        } | null> | null;
        readonly donations: ReadonlyArray<{
          readonly __typename?: 'Donation';
          readonly id: string | null;
          readonly name: string | null;
          readonly totalCents: number | null;
        } | null> | null;
        readonly storeAddress: {
          readonly __typename?: 'StoreAddress';
          readonly addressLine1: string;
          readonly addressLine2: string | null;
          readonly city: string;
          readonly state: string;
          readonly zip: string;
        };
        readonly storeDetails: {
          readonly __typename?: 'StoreDetails';
          readonly franchiseGroupName: string | null;
          readonly franchiseGroupId: number | null;
          readonly hasCatering: boolean | null;
          readonly hasDelivery: boolean | null;
          readonly hasDriveThru: boolean | null;
          readonly hasMobileOrdering: boolean | null;
          readonly lastActiveDateTime: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
          readonly mobileOrderingStatus: string | null;
          readonly phoneNumber: string | null;
          readonly posVendor: string | null;
          readonly status: string | null;
          readonly storeNumber: string | null;
          readonly deliveryHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
          readonly diningRoomHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
          readonly driveThruHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
        } | null;
        readonly cartEntries: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly children: ReadonlyArray<{
                    readonly __typename?: 'CartEntries';
                    readonly lineId: string;
                    readonly image: string | null;
                    readonly isDonation: boolean | null;
                    readonly isExtra: boolean | null;
                    readonly name: string | null;
                    readonly pickerSelections: string | null;
                    readonly price: number | null;
                    readonly quantity: number;
                    readonly sanityId: string;
                    readonly type: CartEntryType;
                    readonly url: string | null;
                    readonly redeemable: boolean | null;
                    readonly _id: string;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        readonly reorderCartEntries: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly isInMenu: boolean | null;
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly children: ReadonlyArray<{
                    readonly __typename?: 'CartEntries';
                    readonly isInMenu: boolean | null;
                    readonly lineId: string;
                    readonly image: string | null;
                    readonly isDonation: boolean | null;
                    readonly isExtra: boolean | null;
                    readonly name: string | null;
                    readonly pickerSelections: string | null;
                    readonly price: number | null;
                    readonly quantity: number;
                    readonly sanityId: string;
                    readonly type: CartEntryType;
                    readonly url: string | null;
                    readonly redeemable: boolean | null;
                    readonly _id: string;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        readonly unavailableCartEntries: ReadonlyArray<{
          readonly __typename?: 'UnavailableCartEntry';
          readonly lineId: string | null;
          readonly sanityId: string;
        } | null> | null;
      };
      readonly fulfillmentDetails: {
        readonly __typename?: 'FulfillmentDetails';
        readonly pickupType: FulfillmentPickupType | null;
        readonly pickupPin: string | null;
        readonly pickupReady: boolean | null;
      } | null;
      readonly order: {
        readonly __typename?: 'RbiOrder';
        readonly refund: {
          readonly __typename?: 'Refund';
          readonly primaryReason: RefundReason;
          readonly secondaryReason: string | null;
          readonly subtotal: string;
          readonly tax: string;
          readonly transactionDateTime: string;
          readonly refundedItems: ReadonlyArray<{
            readonly __typename?: 'RefundedItem';
            readonly refundAmount: string | null;
            readonly refundQuantity: string | null;
            readonly sanityId: string | null;
            readonly type: CartEntryType | null;
            readonly name: string | null;
            readonly plu: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type IGetUserOrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type IGetUserOrderQuery = {
  readonly __typename?: 'Query';
  readonly order: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly fireOrderIn: number | null;
    readonly status: RbiOrderStatus;
    readonly orderNumberFormatted: string | null;
    readonly _id: string;
    readonly delivery: {
      readonly __typename?: 'Delivery';
      readonly feeCents: number;
      readonly tipCents: number | null;
      readonly feeDiscountCents: number | null;
      readonly status: DeliveryStatus;
      readonly serviceFeeCents: number | null;
      readonly smallCartFeeCents: number | null;
      readonly geographicalFeeCents: number | null;
      readonly serviceFeeDiscountCents: number | null;
      readonly unavailabilityReason: string | null;
      readonly dropoff: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly state: string | null;
        readonly latitude: number | null;
        readonly longitude: number | null;
      };
      readonly cancellation: {
        readonly __typename?: 'DeliveryCancellation';
        readonly reason: string | null;
        readonly details: string | null;
      } | null;
    } | null;
    readonly cart: {
      readonly __typename?: 'Cart';
      readonly ticketNumber: string | null;
      readonly storeId: string;
      readonly vatNumber: string | null;
      readonly serviceMode: ServiceMode;
      readonly posVendor: PosVendor;
      readonly cartVersion: number | null;
      readonly subTotalCents: number | null;
      readonly taxCents: number | null;
      readonly totalCents: number | null;
      readonly wasLoyaltyEarned: boolean | null;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'Discount';
        readonly name: DiscountTypes | null;
        readonly value: number | null;
      } | null> | null;
      readonly payment: {
        readonly __typename?: 'Payment';
        readonly paymentType: CartPaymentType | null;
        readonly paymentMethodBrand: PaymentMethodBrand | null;
        readonly paymentRequestTimeLimit: string | null;
      } | null;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'Fees';
        readonly name: string | null;
        readonly priceCents: number | null;
        readonly quantity: number | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly donations: ReadonlyArray<{
        readonly __typename?: 'Donation';
        readonly id: string | null;
        readonly name: string | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly storeAddress: {
        readonly __typename?: 'StoreAddress';
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string;
        readonly zip: string;
      };
      readonly storeDetails: {
        readonly __typename?: 'StoreDetails';
        readonly franchiseGroupName: string | null;
        readonly franchiseGroupId: number | null;
        readonly hasCatering: boolean | null;
        readonly hasDelivery: boolean | null;
        readonly hasDriveThru: boolean | null;
        readonly hasMobileOrdering: boolean | null;
        readonly lastActiveDateTime: string | null;
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly mobileOrderingStatus: string | null;
        readonly phoneNumber: string | null;
        readonly posVendor: string | null;
        readonly status: string | null;
        readonly storeNumber: string | null;
        readonly deliveryHours: {
          readonly __typename?: 'StoreHours';
          readonly monday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly tuesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly wednesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly thursday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly friday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly saturday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly sunday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
        } | null;
        readonly diningRoomHours: {
          readonly __typename?: 'StoreHours';
          readonly monday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly tuesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly wednesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly thursday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly friday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly saturday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly sunday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
        } | null;
        readonly driveThruHours: {
          readonly __typename?: 'StoreHours';
          readonly monday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly tuesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly wednesday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly thursday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly friday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly saturday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
          readonly sunday: {
            readonly __typename?: 'Day';
            readonly close: string | null;
            readonly open: string | null;
          } | null;
        } | null;
      } | null;
      readonly cartEntries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
      readonly reorderCartEntries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly isInMenu: boolean | null;
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
      readonly unavailableCartEntries: ReadonlyArray<{
        readonly __typename?: 'UnavailableCartEntry';
        readonly lineId: string | null;
        readonly sanityId: string;
      } | null> | null;
    };
    readonly fulfillmentDetails: {
      readonly __typename?: 'FulfillmentDetails';
      readonly pickupType: FulfillmentPickupType | null;
      readonly pickupPin: string | null;
      readonly pickupReady: boolean | null;
    } | null;
    readonly order: {
      readonly __typename?: 'RbiOrder';
      readonly refund: {
        readonly __typename?: 'Refund';
        readonly primaryReason: RefundReason;
        readonly secondaryReason: string | null;
        readonly subtotal: string;
        readonly tax: string;
        readonly transactionDateTime: string;
        readonly refundedItems: ReadonlyArray<{
          readonly __typename?: 'RefundedItem';
          readonly refundAmount: string | null;
          readonly refundQuantity: string | null;
          readonly sanityId: string | null;
          readonly type: CartEntryType | null;
          readonly name: string | null;
          readonly plu: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type IUpdateMeMutationVariables = Exact<{
  input: IUpdateUserDetailsInput;
}>;

export type IUpdateMeMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMe: {
    readonly __typename?: 'User';
    readonly details: {
      readonly __typename?: 'UserDetails';
      readonly name: string | null;
      readonly dob: string | null;
      readonly dobDeleted: boolean | null;
      readonly phoneNumber: string | null;
      readonly phoneVerified: boolean | null;
      readonly email: string | null;
      readonly emailVerified: boolean | null;
      readonly promotionalEmails: boolean | null;
      readonly isoCountryCode: IsoCountryCode | null;
      readonly zipcode: string | null;
      readonly defaultAccountIdentifier: string | null;
      readonly defaultFdAccountId: string | null;
      readonly defaultPaymentAccountId: string | null;
      readonly defaultReloadAmt: number | null;
      readonly autoReloadEnabled: boolean | null;
      readonly autoReloadThreshold: number | null;
      readonly loyaltyTier: string | null;
      readonly deliveryAddresses: ReadonlyArray<{
        readonly __typename?: 'DeliveryAddress';
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly alias: string | null;
        readonly country: IsoCountryCode | null;
        readonly phoneNumber: string;
        readonly route: string | null;
        readonly state: string | null;
        readonly streetNumber: string | null;
        readonly zip: string | null;
      } | null> | null;
      readonly communicationPreferences: ReadonlyArray<{
        readonly __typename?: 'CommunicationPreference';
        readonly id: string | null;
        readonly value: string | null;
      } | null> | null;
      readonly favoriteStores: ReadonlyArray<{
        readonly __typename?: 'FavoriteStore';
        readonly storeId: string | null;
        readonly storeNumber: string | null;
      } | null> | null;
      readonly favoriteOffers: ReadonlyArray<{
        readonly __typename?: 'FavoriteOffer';
        readonly id: string | null;
      } | null> | null;
      readonly requiredAcceptanceAgreementInfo: ReadonlyArray<{
        readonly __typename?: 'RequiredAcceptanceAgreementInfo';
        readonly id: string | null;
        readonly updatedAt: string | null;
      } | null> | null;
    };
  };
};

export type ICreateLoyaltyUserMutationVariables = Exact<{
  cognitoId: Scalars['String']['input'];
}>;

export type ICreateLoyaltyUserMutation = {
  readonly __typename?: 'Mutation';
  readonly createLoyaltyUser: { readonly __typename?: 'User'; readonly loyaltyId: string | null };
};

export type IUserGeoQueryVariables = Exact<{ [key: string]: never }>;

export type IUserGeoQuery = {
  readonly __typename?: 'Query';
  readonly userGeo: {
    readonly __typename?: 'UserGeo';
    readonly city: string | null;
    readonly state: string | null;
    readonly country: string | null;
  };
};

export type IAutoEnrollUserInLoyaltyMutationVariables = Exact<{ [key: string]: never }>;

export type IAutoEnrollUserInLoyaltyMutation = {
  readonly __typename?: 'Mutation';
  readonly autoEnrollUserInLoyalty: {
    readonly __typename?: 'User';
    readonly loyaltyId: string | null;
  };
};

export type IAssociateInvitationCodeToLoyaltyUserMutationVariables = Exact<{
  loyaltyId: Scalars['String']['input'];
}>;

export type IAssociateInvitationCodeToLoyaltyUserMutation = {
  readonly __typename?: 'Mutation';
  readonly associateInvitationCodeToLoyaltyUser: {
    readonly __typename?: 'InvitationCode';
    readonly code: string | null;
  };
};

export type IOrderFragment = {
  readonly __typename?: 'Order';
  readonly createdAt: string;
  readonly posOrderId: string | null;
  readonly rbiOrderId: string;
  readonly status: RbiOrderStatus;
  readonly fireOrderIn: number | null;
  readonly orderNumberFormatted: string | null;
  readonly _id: string;
  readonly orderErrors: {
    readonly __typename?: 'OrderErrors';
    readonly orderInjectionErrors: {
      readonly __typename?: 'OrderInjectionErrors';
      readonly isConfigurationError: boolean;
      readonly isMenuError: boolean;
      readonly isStoreAvailabilityError: boolean;
      readonly isTimeoutError: boolean;
      readonly isUnknownError: boolean;
    };
    readonly posErrors: ReadonlyArray<{
      readonly __typename?: 'RbiError';
      readonly errorCode: string | null;
      readonly errorName: string | null;
      readonly message: string | null;
      readonly rbiErrorCode: string | null;
      readonly rbiErrorDomain: RbiErrorDomain | null;
    } | null> | null;
  } | null;
  readonly fulfillmentDetails: {
    readonly __typename?: 'FulfillmentDetails';
    readonly pickupType: FulfillmentPickupType | null;
    readonly pickupPin: string | null;
    readonly pickupReady: boolean | null;
  } | null;
  readonly cart: {
    readonly __typename?: 'Cart';
    readonly customerName: string;
    readonly cartVersion: number | null;
    readonly guestId: string | null;
    readonly posVendor: PosVendor;
    readonly serviceMode: ServiceMode;
    readonly subTotalCents: number | null;
    readonly taxCents: number | null;
    readonly ticketNumber: string | null;
    readonly totalCents: number | null;
    readonly wasLoyaltyEarned: boolean | null;
    readonly vatNumber: string | null;
    readonly appliedOffers: ReadonlyArray<{
      readonly __typename?: 'CartAppliedOffer';
      readonly sanityId: string | null;
    } | null> | null;
    readonly cartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'Discount';
      readonly name: DiscountTypes | null;
      readonly value: number | null;
    } | null> | null;
    readonly donations: ReadonlyArray<{
      readonly __typename?: 'Donation';
      readonly id: string | null;
      readonly name: string | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'Fees';
      readonly name: string | null;
      readonly priceCents: number | null;
      readonly quantity: number | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly guestDetails: {
      readonly __typename?: 'GuestDetails';
      readonly isoCountryCode: string | null;
    } | null;
    readonly payment: {
      readonly __typename?: 'Payment';
      readonly cardType: string | null;
      readonly ccLast4: string | null;
      readonly panToken: string | null;
      readonly paymentType: CartPaymentType | null;
      readonly paymentMethodBrand: PaymentMethodBrand | null;
      readonly paymentRequestTimeLimit: string | null;
    } | null;
    readonly rewardsApplied: ReadonlyArray<{
      readonly __typename?: 'CartAppliedReward';
      readonly cartId: string;
      readonly rewardId: string;
      readonly timesApplied: number;
    } | null> | null;
    readonly storeAddress: {
      readonly __typename?: 'StoreAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string;
      readonly zip: string;
    };
    readonly storeDetails: {
      readonly __typename?: 'StoreDetails';
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly storeNumber: string | null;
    } | null;
    readonly unavailableCartEntries: ReadonlyArray<{
      readonly __typename?: 'UnavailableCartEntry';
      readonly lineId: string | null;
      readonly sanityId: string;
    } | null> | null;
  };
  readonly delivery: {
    readonly __typename?: 'Delivery';
    readonly feeCents: number;
    readonly feeDiscountCents: number | null;
    readonly instructions: string | null;
    readonly status: DeliveryStatus;
    readonly tipCents: number | null;
    readonly serviceFeeCents: number | null;
    readonly smallCartFeeCents: number | null;
    readonly geographicalFeeCents: number | null;
    readonly quotedFeeCents: number | null;
    readonly baseDeliveryFeeCents: number | null;
    readonly deliverySurchargeFeeCents: number | null;
    readonly serviceFeeDiscountCents: number | null;
    readonly unavailabilityReason: string | null;
    readonly pickup: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly latitude: number | null;
      readonly longitude: number | null;
    };
    readonly dropoff: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly arrivalTime: string | null;
      readonly beforeTime: string | null;
      readonly afterTime: string | null;
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string | null;
      readonly zip: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly phoneNumber: string;
    };
    readonly driver: {
      readonly __typename?: 'DeliveryDriver';
      readonly name: string | null;
      readonly profileImageUrl: string | null;
      readonly phoneNumber: string | null;
    } | null;
  } | null;
  readonly loyaltyTransaction: {
    readonly __typename?: 'LoyaltyTransaction';
    readonly id: string;
    readonly pointsEarned: number;
    readonly bonusPointsEarned: number;
    readonly pointsUsed: number;
    readonly pointsBalance: number;
    readonly rewardsUsed: number;
  } | null;
};

export type IPriceOrderMutationVariables = Exact<{
  input: IPriceOrderInput;
  delivery?: InputMaybe<IPriceDeliveryInput>;
}>;

export type IPriceOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly priceOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
  };
};

export type ICancelOrderMutationVariables = Exact<{
  input: ICancelOrderInput;
}>;

export type ICancelOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelOrder: { readonly __typename?: 'Order'; readonly rbiOrderId: string };
};

export type IGetOrderQueryVariables = Exact<{
  rbiOrderId: Scalars['ID']['input'];
  handleCommitError?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type IGetOrderQuery = {
  readonly __typename?: 'Query';
  readonly order: {
    readonly __typename?: 'Order';
    readonly createdAt: string;
    readonly posOrderId: string | null;
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
    readonly fireOrderIn: number | null;
    readonly orderNumberFormatted: string | null;
    readonly _id: string;
    readonly orderErrors: {
      readonly __typename?: 'OrderErrors';
      readonly orderInjectionErrors: {
        readonly __typename?: 'OrderInjectionErrors';
        readonly isConfigurationError: boolean;
        readonly isMenuError: boolean;
        readonly isStoreAvailabilityError: boolean;
        readonly isTimeoutError: boolean;
        readonly isUnknownError: boolean;
      };
      readonly posErrors: ReadonlyArray<{
        readonly __typename?: 'RbiError';
        readonly errorCode: string | null;
        readonly errorName: string | null;
        readonly message: string | null;
        readonly rbiErrorCode: string | null;
        readonly rbiErrorDomain: RbiErrorDomain | null;
      } | null> | null;
    } | null;
    readonly fulfillmentDetails: {
      readonly __typename?: 'FulfillmentDetails';
      readonly pickupType: FulfillmentPickupType | null;
      readonly pickupPin: string | null;
      readonly pickupReady: boolean | null;
    } | null;
    readonly cart: {
      readonly __typename?: 'Cart';
      readonly customerName: string;
      readonly cartVersion: number | null;
      readonly guestId: string | null;
      readonly posVendor: PosVendor;
      readonly serviceMode: ServiceMode;
      readonly subTotalCents: number | null;
      readonly taxCents: number | null;
      readonly ticketNumber: string | null;
      readonly totalCents: number | null;
      readonly wasLoyaltyEarned: boolean | null;
      readonly vatNumber: string | null;
      readonly appliedOffers: ReadonlyArray<{
        readonly __typename?: 'CartAppliedOffer';
        readonly sanityId: string | null;
      } | null> | null;
      readonly cartEntries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'Discount';
        readonly name: DiscountTypes | null;
        readonly value: number | null;
      } | null> | null;
      readonly donations: ReadonlyArray<{
        readonly __typename?: 'Donation';
        readonly id: string | null;
        readonly name: string | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'Fees';
        readonly name: string | null;
        readonly priceCents: number | null;
        readonly quantity: number | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly guestDetails: {
        readonly __typename?: 'GuestDetails';
        readonly isoCountryCode: string | null;
      } | null;
      readonly payment: {
        readonly __typename?: 'Payment';
        readonly cardType: string | null;
        readonly ccLast4: string | null;
        readonly panToken: string | null;
        readonly paymentType: CartPaymentType | null;
        readonly paymentMethodBrand: PaymentMethodBrand | null;
        readonly paymentRequestTimeLimit: string | null;
      } | null;
      readonly rewardsApplied: ReadonlyArray<{
        readonly __typename?: 'CartAppliedReward';
        readonly cartId: string;
        readonly rewardId: string;
        readonly timesApplied: number;
      } | null> | null;
      readonly storeAddress: {
        readonly __typename?: 'StoreAddress';
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string;
        readonly zip: string;
      };
      readonly storeDetails: {
        readonly __typename?: 'StoreDetails';
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly storeNumber: string | null;
      } | null;
      readonly unavailableCartEntries: ReadonlyArray<{
        readonly __typename?: 'UnavailableCartEntry';
        readonly lineId: string | null;
        readonly sanityId: string;
      } | null> | null;
    };
    readonly delivery: {
      readonly __typename?: 'Delivery';
      readonly feeCents: number;
      readonly feeDiscountCents: number | null;
      readonly instructions: string | null;
      readonly status: DeliveryStatus;
      readonly tipCents: number | null;
      readonly serviceFeeCents: number | null;
      readonly smallCartFeeCents: number | null;
      readonly geographicalFeeCents: number | null;
      readonly quotedFeeCents: number | null;
      readonly baseDeliveryFeeCents: number | null;
      readonly deliverySurchargeFeeCents: number | null;
      readonly serviceFeeDiscountCents: number | null;
      readonly unavailabilityReason: string | null;
      readonly pickup: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly latitude: number | null;
        readonly longitude: number | null;
      };
      readonly dropoff: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly arrivalTime: string | null;
        readonly beforeTime: string | null;
        readonly afterTime: string | null;
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string | null;
        readonly zip: string | null;
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly phoneNumber: string;
      };
      readonly driver: {
        readonly __typename?: 'DeliveryDriver';
        readonly name: string | null;
        readonly profileImageUrl: string | null;
        readonly phoneNumber: string | null;
      } | null;
    } | null;
    readonly loyaltyTransaction: {
      readonly __typename?: 'LoyaltyTransaction';
      readonly id: string;
      readonly pointsEarned: number;
      readonly bonusPointsEarned: number;
      readonly pointsUsed: number;
      readonly pointsBalance: number;
      readonly rewardsUsed: number;
    } | null;
  } | null;
};

export type IDeliveryOrderStatusQueryVariables = Exact<{
  rbiOrderId: Scalars['ID']['input'];
}>;

export type IDeliveryOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly order: {
    readonly __typename?: 'Order';
    readonly status: RbiOrderStatus;
    readonly delivery: {
      readonly __typename?: 'Delivery';
      readonly status: DeliveryStatus;
      readonly shareUuid: string | null;
      readonly pincode: string | null;
      readonly dropoff: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly arrivalTime: string | null;
        readonly beforeTime: string | null;
        readonly afterTime: string | null;
      };
      readonly driver: {
        readonly __typename?: 'DeliveryDriver';
        readonly name: string | null;
        readonly phoneNumber: string | null;
        readonly profileImageUrl: string | null;
        readonly location: {
          readonly __typename?: 'Location';
          readonly latitude: number | null;
          readonly longitude: number | null;
        } | null;
      } | null;
      readonly vendorProperties: {
        readonly __typename?: 'Vendor';
        readonly vendor: string | null;
      } | null;
      readonly task: {
        readonly __typename?: 'DeliveryTaskDetails';
        readonly uuid: string | null;
      } | null;
    } | null;
  } | null;
};

export type IGenerateFirstpayVaultedLinkMutationVariables = Exact<{
  storeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGenerateFirstpayVaultedLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateFirstpayVaultedLink: {
    readonly __typename?: 'FirstpayVaultedLink';
    readonly link: string;
  };
};

export type IGenerateFirstpayCheckoutLinkMutationVariables = Exact<{
  rbiOrderId: Scalars['String']['input'];
}>;

export type IGenerateFirstpayCheckoutLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateFirstpayCheckoutLink: {
    readonly __typename?: 'FirstpayCheckoutLink';
    readonly link: string;
  };
};

export type IGenerateFirstDataHostedPageLinkMutationVariables = Exact<{
  rbiOrderId: Scalars['String']['input'];
  parentUri: Scalars['String']['input'];
  typePayment: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
}>;

export type IGenerateFirstDataHostedPageLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateFirstDataHostedPageLink: {
    readonly __typename?: 'FirstDataHostedPageLink';
    readonly chargetotal: number;
    readonly checkoutoption: string;
    readonly currency: string;
    readonly customParam_storeId: string;
    readonly hash_algorithm: string;
    readonly language: string;
    readonly merchantTransactionId: string;
    readonly parentUri: string;
    readonly paymentMethod: string;
    readonly responseFailURL: string;
    readonly responseSuccessURL: string;
    readonly storename: string;
    readonly timezone: string;
    readonly txndatetime: string;
    readonly txntype: string;
    readonly hashExtended: string;
    readonly host: string;
  };
};

export type IGeneratePaymarkCheckoutLinkMutationVariables = Exact<{
  rbiOrderId: Scalars['String']['input'];
}>;

export type IGeneratePaymarkCheckoutLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generatePaymarkCheckoutLink: {
    readonly __typename?: 'PayMarkCheckoutLink';
    readonly link: string;
  };
};

export type IGeneratePaycometCheckoutLinkMutationVariables = Exact<{
  rbiOrderId: Scalars['String']['input'];
  typePayment: Scalars['String']['input'];
}>;

export type IGeneratePaycometCheckoutLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generatePaycometCheckoutLink: {
    readonly __typename?: 'PaycometCheckoutLink';
    readonly iframeUrl: string | null;
    readonly jetId: string | null;
  };
};

export type ICreatePaycometPreAuthorizationMutationVariables = Exact<{
  input: ICreatePaycometPreAuthorizationInput;
}>;

export type ICreatePaycometPreAuthorizationMutation = {
  readonly __typename?: 'Mutation';
  readonly createPaycometPreAuthorization: {
    readonly __typename?: 'PaycometCreatePreAuthorization';
    readonly merchantAccount: string;
    readonly pspReference: string;
    readonly type: string;
    readonly paymentMethod: {
      readonly __typename?: 'PaycometPaymentMethod';
      readonly bin: string;
      readonly brand: string;
      readonly cardholderName: string;
      readonly expiryMonth: string;
      readonly expiryYear: string;
      readonly last4: string;
      readonly source: string;
      readonly storePaymentMethod: boolean | null;
      readonly storedPaymentMethodId: string;
      readonly url3ds: string;
    };
  };
};

export type IPaycometCardInfoMutationVariables = Exact<{
  input: IPaycometCardInfoInput;
}>;

export type IPaycometCardInfoMutation = {
  readonly __typename?: 'Mutation';
  readonly paycometCardInfo: {
    readonly __typename?: 'PaycometCardInfoResponse';
    readonly merchantAccount: string;
    readonly pspReference: string;
    readonly type: string;
    readonly cardInfo: {
      readonly __typename?: 'PaycometGetCardInfo';
      readonly brand: string;
      readonly bin: string;
      readonly last4: string;
      readonly storedPaymentMethodId: string;
      readonly expiryYear: string;
      readonly expiryMonth: string;
      readonly paytpvToken: string;
    };
  };
};

export type IGenerateVrPaymentCheckoutLinkMutationVariables = Exact<{
  rbiOrderId: Scalars['String']['input'];
  guestEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGenerateVrPaymentCheckoutLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateVrPaymentCheckoutLink: {
    readonly __typename?: 'VrPaymentCheckoutLink';
    readonly link: string;
  };
};

export type IGenerateVrPaymentVaultLinkMutationVariables = Exact<{
  input: IGenerateVrPaymentVaultLinkInput;
}>;

export type IGenerateVrPaymentVaultLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateVrPaymentVaultLink: {
    readonly __typename?: 'VrPaymentVaultLink';
    readonly data: string;
    readonly link: string;
  };
};

export type IGenerateVrPaymentPayPalFastLinkMutationVariables = Exact<{
  input: IGenerateVrPaymentPayPalFastLinkInput;
}>;

export type IGenerateVrPaymentPayPalFastLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly generateVrPaymentPayPalFastLink: {
    readonly __typename?: 'VrPaymentPayPalFastLink';
    readonly link: string;
  };
};

export type IValidateCommitOrderMutationVariables = Exact<{
  input: IValidateCommitOrderInput;
}>;

export type IValidateCommitOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly validateCommitOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
  } | null;
};

export type IDetailsFragment = {
  readonly __typename?: 'UserDetails';
  readonly name: string | null;
  readonly dob: string | null;
  readonly dobDeleted: boolean | null;
  readonly phoneNumber: string | null;
  readonly phoneVerified: boolean | null;
  readonly email: string | null;
  readonly emailVerified: boolean | null;
  readonly promotionalEmails: boolean | null;
  readonly isoCountryCode: IsoCountryCode | null;
  readonly zipcode: string | null;
  readonly defaultAccountIdentifier: string | null;
  readonly defaultFdAccountId: string | null;
  readonly defaultPaymentAccountId: string | null;
  readonly defaultReloadAmt: number | null;
  readonly autoReloadEnabled: boolean | null;
  readonly autoReloadThreshold: number | null;
  readonly loyaltyTier: string | null;
  readonly deliveryAddresses: ReadonlyArray<{
    readonly __typename?: 'DeliveryAddress';
    readonly addressLine1: string;
    readonly addressLine2: string | null;
    readonly city: string;
    readonly alias: string | null;
    readonly country: IsoCountryCode | null;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly phoneNumber: string;
    readonly route: string | null;
    readonly state: string | null;
    readonly streetNumber: string | null;
    readonly zip: string | null;
  } | null> | null;
  readonly communicationPreferences: ReadonlyArray<{
    readonly __typename?: 'CommunicationPreference';
    readonly id: string | null;
    readonly value: string | null;
  } | null> | null;
  readonly favoriteStores: ReadonlyArray<{
    readonly __typename?: 'FavoriteStore';
    readonly storeId: string | null;
    readonly storeNumber: string | null;
  } | null> | null;
  readonly favoriteOffers: ReadonlyArray<{
    readonly __typename?: 'FavoriteOffer';
    readonly id: string | null;
  } | null> | null;
  readonly requiredAcceptanceAgreementInfo: ReadonlyArray<{
    readonly __typename?: 'RequiredAcceptanceAgreementInfo';
    readonly id: string | null;
    readonly updatedAt: string | null;
  } | null> | null;
};

export type IGetMeQueryVariables = Exact<{
  numUniquePurchasedItems?: InputMaybe<Scalars['Int']['input']>;
  customInput?: InputMaybe<IMeInput>;
}>;

export type IGetMeQuery = {
  readonly __typename?: 'Query';
  readonly me: {
    readonly __typename?: 'User';
    readonly thLegacyCognitoId: string | null;
    readonly cognitoId: string;
    readonly loyaltyId: string | null;
    readonly details: {
      readonly __typename?: 'UserDetails';
      readonly name: string | null;
      readonly dob: string | null;
      readonly dobDeleted: boolean | null;
      readonly phoneNumber: string | null;
      readonly phoneVerified: boolean | null;
      readonly email: string | null;
      readonly emailVerified: boolean | null;
      readonly promotionalEmails: boolean | null;
      readonly isoCountryCode: IsoCountryCode | null;
      readonly zipcode: string | null;
      readonly defaultAccountIdentifier: string | null;
      readonly defaultFdAccountId: string | null;
      readonly defaultPaymentAccountId: string | null;
      readonly defaultReloadAmt: number | null;
      readonly autoReloadEnabled: boolean | null;
      readonly autoReloadThreshold: number | null;
      readonly loyaltyTier: string | null;
      readonly deliveryAddresses: ReadonlyArray<{
        readonly __typename?: 'DeliveryAddress';
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly alias: string | null;
        readonly country: IsoCountryCode | null;
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly phoneNumber: string;
        readonly route: string | null;
        readonly state: string | null;
        readonly streetNumber: string | null;
        readonly zip: string | null;
      } | null> | null;
      readonly communicationPreferences: ReadonlyArray<{
        readonly __typename?: 'CommunicationPreference';
        readonly id: string | null;
        readonly value: string | null;
      } | null> | null;
      readonly favoriteStores: ReadonlyArray<{
        readonly __typename?: 'FavoriteStore';
        readonly storeId: string | null;
        readonly storeNumber: string | null;
      } | null> | null;
      readonly favoriteOffers: ReadonlyArray<{
        readonly __typename?: 'FavoriteOffer';
        readonly id: string | null;
      } | null> | null;
      readonly requiredAcceptanceAgreementInfo: ReadonlyArray<{
        readonly __typename?: 'RequiredAcceptanceAgreementInfo';
        readonly id: string | null;
        readonly updatedAt: string | null;
      } | null> | null;
    };
    readonly uniquePurchasedItems: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly name: string | null;
      readonly image: string | null;
      readonly isExtra: boolean | null;
      readonly isDonation: boolean | null;
      readonly lineId: string;
      readonly sanityId: string;
      readonly quantity: number;
      readonly type: CartEntryType;
      readonly price: number | null;
      readonly url: string | null;
      readonly pickerSelections: string | null;
      readonly isInMenu: boolean | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly isInMenu: boolean | null;
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};

export type IUserRecentAddressesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type IUserRecentAddressesQuery = {
  readonly __typename?: 'Query';
  readonly userRecentAddresses: {
    readonly __typename?: 'UserRecentAddresses';
    readonly count: number | null;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'DeliveryAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly alias: string | null;
      readonly country: IsoCountryCode | null;
      readonly phoneNumber: string;
      readonly state: string | null;
      readonly zip: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly route: string | null;
      readonly streetNumber: string | null;
    } | null> | null;
  } | null;
};

export const DayFragmentDoc = gql`
  fragment DayFragment on Day {
    close
    open
  }
`;
export const StoreHoursFragmentDoc = gql`
  fragment StoreHoursFragment on StoreHours {
    monday {
      ...DayFragment
    }
    tuesday {
      ...DayFragment
    }
    wednesday {
      ...DayFragment
    }
    thursday {
      ...DayFragment
    }
    friday {
      ...DayFragment
    }
    saturday {
      ...DayFragment
    }
    sunday {
      ...DayFragment
    }
  }
  ${DayFragmentDoc}
`;
export const VendorConfigFragmentDoc = gql`
  fragment VendorConfigFragment on VendorConfig {
    pluType
    parentSanityId
    pullUpLevels
    constantPlu
    quantityBasedPlu {
      quantity
      plu
      qualifier
    }
    multiConstantPlus {
      quantity
      plu
      qualifier
    }
    parentChildPlu {
      plu
      childPlu
    }
    sizeBasedPlu {
      comboPlu
      comboSize
    }
  }
`;
export const VendorConfigsFragmentDoc = gql`
  fragment VendorConfigsFragment on VendorConfigs {
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    partner {
      ...VendorConfigFragment
    }
    partnerDelivery {
      ...VendorConfigFragment
    }
    productNumber {
      ...VendorConfigFragment
    }
    productNumberDelivery {
      ...VendorConfigFragment
    }
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    qdi {
      ...VendorConfigFragment
    }
    qdiDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
    simplyDelivery {
      ...VendorConfigFragment
    }
    simplyDeliveryDelivery {
      ...VendorConfigFragment
    }
    toshibaLoyalty {
      ...VendorConfigFragment
    }
  }
  ${VendorConfigFragmentDoc}
`;
export const CartEntryFragmentDoc = gql`
  fragment CartEntryFragment on CartEntries {
    _id: lineId
    lineId
    image
    isDonation
    isExtra
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    redeemable
    vendorConfigs {
      ...VendorConfigsFragment
    }
  }
  ${VendorConfigsFragmentDoc}
`;
export const UserOrderFragmentDoc = gql`
  fragment UserOrderFragment on Order {
    _id: rbiOrderId
    rbiOrderId
    createdAt
    updatedAt
    fireOrderIn
    status
    orderNumberFormatted
    delivery {
      dropoff {
        state
        latitude
        longitude
      }
      cancellation {
        reason
        details
      }
      feeCents
      tipCents
      feeDiscountCents
      status
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      serviceFeeDiscountCents
      unavailabilityReason
    }
    cart {
      discounts {
        name
        value
      }
      payment {
        paymentType
        paymentMethodBrand
        paymentRequestTimeLimit
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      ticketNumber
      storeId
      vatNumber
      serviceMode
      posVendor
      cartVersion
      donations {
        id
        name
        totalCents
      }
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        deliveryHours {
          ...StoreHoursFragment
        }
        diningRoomHours {
          ...StoreHoursFragment
        }
        driveThruHours {
          ...StoreHoursFragment
        }
        franchiseGroupName
        franchiseGroupId
        hasCatering
        hasDelivery
        hasDriveThru
        hasMobileOrdering
        lastActiveDateTime
        latitude
        longitude
        mobileOrderingStatus
        phoneNumber
        posVendor
        status
        storeNumber
      }
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      reorderCartEntries {
        ...CartEntryFragment
        isInMenu
        children {
          ...CartEntryFragment
          isInMenu
          children {
            ...CartEntryFragment
            isInMenu
            children {
              ...CartEntryFragment
              isInMenu
              children {
                ...CartEntryFragment
                isInMenu
                children {
                  ...CartEntryFragment
                  isInMenu
                }
              }
            }
          }
        }
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    fulfillmentDetails {
      pickupType
      pickupPin
      pickupReady
    }
    order {
      refund {
        primaryReason
        refundedItems {
          refundAmount
          refundQuantity
          sanityId
          type
          name
          plu
        }
        secondaryReason
        subtotal
        tax
        transactionDateTime
      }
    }
  }
  ${StoreHoursFragmentDoc}
  ${CartEntryFragmentDoc}
`;
export const FavoriteFragmentDoc = gql`
  fragment FavoriteFragment on Favorite {
    _id: favoriteId
    favoriteId
    name
    ref
    createdAt
    updatedAt
    entries {
      ...CartEntryFragment
      children {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
              }
            }
          }
        }
      }
    }
  }
  ${CartEntryFragmentDoc}
`;
export const ReorderFavoriteFragmentDoc = gql`
  fragment ReorderFavoriteFragment on Favorite {
    ...FavoriteFragment
    entries {
      ...CartEntryFragment
      isInMenu
      children {
        ...CartEntryFragment
        isInMenu
        children {
          ...CartEntryFragment
          isInMenu
          children {
            ...CartEntryFragment
            isInMenu
            children {
              ...CartEntryFragment
              isInMenu
              children {
                ...CartEntryFragment
                isInMenu
              }
            }
          }
        }
      }
    }
  }
  ${FavoriteFragmentDoc}
  ${CartEntryFragmentDoc}
`;
export const AdditionalTimeSlotFragmentDoc = gql`
  fragment AdditionalTimeSlotFragment on AdditionalTimeSlot {
    open
    close
    _type
  }
`;
export const OperatingHoursFragmentDoc = gql`
  fragment OperatingHoursFragment on OperatingHours {
    friClose
    friOpen
    friAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    monClose
    monOpen
    monAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    satClose
    satOpen
    satAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    sunClose
    sunOpen
    sunAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    thrClose
    thrOpen
    thrAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    tueClose
    tueOpen
    tueAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
    wedClose
    wedOpen
    wedAdditionalTimeSlot {
      ...AdditionalTimeSlotFragment
    }
  }
  ${AdditionalTimeSlotFragmentDoc}
`;
export const DeliveryOrderAmountLimitFragmentDoc = gql`
  fragment DeliveryOrderAmountLimitFragment on DeliveryOrderAmountLimit {
    deliveryOrderAmountLimit
    deliveryOrderAmountLimitEnabled
    deliveryOrderRepeatedFailureLimitation
    firstDeliveryOrder
    firstDeliveryOrderEnabled
  }
`;
export const PaymentMethodsFragmentDoc = gql`
  fragment PaymentMethodsFragment on PaymentMethods {
    name
    paymentMethodBrand
    state
    isOnlinePayment
  }
`;
export const RestaurantNodeFragmentDoc = gql`
  fragment RestaurantNodeFragment on RestaurantNode {
    _id
    storeId
    isAvailable
    posVendor
    chaseMerchantId
    curbsideHours {
      ...OperatingHoursFragment
    }
    cybersourceTransactingId
    deliveryHours {
      ...OperatingHoursFragment
    }
    deliveryOrderAmountLimit {
      ...DeliveryOrderAmountLimitFragment
    }
    diningRoomHours {
      ...OperatingHoursFragment
    }
    distanceInMiles
    drinkStationType
    driveThruHours {
      ...OperatingHoursFragment
    }
    driveThruLaneType
    email
    environment
    franchiseGroupId
    franchiseGroupName
    frontCounterClosed
    hasBreakfast
    hasBurgersForBreakfast
    hasCatering
    hasCurbside
    hideClickAndCollectOrdering
    hasDelivery
    hasDineIn
    hasDriveThru
    hasTableService
    hasMobileOrdering
    hasLateNightMenu
    hasParking
    hasPlayground
    hasTakeOut
    hasWifi
    hasLoyalty
    id
    isDarkKitchen
    isFavorite
    isHalal
    isRecent
    latitude
    longitude
    mobileOrderingStatus
    name
    number
    parkingType
    paymentMethods {
      ...PaymentMethodsFragment
    }
    phoneNumber
    physicalAddress {
      address1
      address2
      city
      country
      postalCode
      stateProvince
      stateProvinceShort
    }
    playgroundType
    pos {
      vendor
    }
    posRestaurantId
    restaurantImage {
      asset {
        _id
        metadata {
          lqip
        }
      }
      crop {
        top
        bottom
        left
        right
      }
      hotspot {
        height
        width
        x
        y
      }
    }
    restaurantPosData {
      _id
    }
    showStoreLocatorOffersButton
    status
    vatNumber
    customerFacingAddress {
      locale
    }
    waitTime {
      queueLength
      firingTimestamp
    }
    integration {
      isPartnerRestaurant
      partnerGroup {
        name
        posIntegration {
          _ref
          _type
        }
      }
    }
  }
  ${OperatingHoursFragmentDoc}
  ${DeliveryOrderAmountLimitFragmentDoc}
  ${PaymentMethodsFragmentDoc}
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetailsFragment on UserDetails {
    name
    dob
    dobDeleted
    phoneNumber
    phoneVerified
    email
    emailVerified
    promotionalEmails
    isoCountryCode
    zipcode
    defaultAccountIdentifier
    defaultFdAccountId
    defaultPaymentAccountId
    defaultReloadAmt
    autoReloadEnabled
    autoReloadThreshold
    deliveryAddresses {
      addressLine1
      addressLine2
      city
      alias
      country
      phoneNumber
      route
      state
      streetNumber
      zip
    }
    communicationPreferences {
      id
      value
    }
    favoriteStores {
      storeId
      storeNumber
    }
    favoriteOffers {
      id
    }
    loyaltyTier
    requiredAcceptanceAgreementInfo {
      id
      updatedAt
    }
  }
`;
export const UserGeoFragmentDoc = gql`
  fragment UserGeoFragment on UserGeo {
    city
    state
    country
  }
`;
export const OrderFragmentDoc = gql`
  fragment OrderFragment on Order {
    _id: rbiOrderId
    createdAt
    posOrderId
    rbiOrderId
    status
    fireOrderIn
    orderNumberFormatted
    orderErrors {
      orderInjectionErrors {
        isConfigurationError
        isMenuError
        isStoreAvailabilityError
        isTimeoutError
        isUnknownError
      }
      posErrors {
        errorCode
        errorName
        message
        rbiErrorCode
        rbiErrorDomain
      }
    }
    fulfillmentDetails {
      pickupType
      pickupPin
      pickupReady
    }
    cart {
      appliedOffers {
        sanityId
      }
      customerName
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      cartVersion
      discounts {
        name
        value
      }
      donations {
        id
        name
        totalCents
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      guestDetails {
        isoCountryCode
      }
      guestId
      payment {
        cardType
        ccLast4
        panToken
        paymentType
        paymentMethodBrand
        paymentRequestTimeLimit
      }
      posVendor
      rewardsApplied {
        cartId
        rewardId
        timesApplied
      }
      serviceMode
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        latitude
        longitude
        storeNumber
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    delivery {
      pickup {
        latitude
        longitude
      }
      dropoff {
        arrivalTime
        beforeTime
        afterTime
        addressLine1
        addressLine2
        city
        state
        zip
        latitude
        longitude
        phoneNumber
      }
      driver {
        name
        profileImageUrl
        phoneNumber
      }
      feeCents
      feeDiscountCents
      instructions
      status
      tipCents
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      quotedFeeCents
      baseDeliveryFeeCents
      deliverySurchargeFeeCents
      serviceFeeDiscountCents
      unavailabilityReason
    }
    loyaltyTransaction {
      id
      pointsEarned
      bonusPointsEarned
      pointsUsed
      pointsBalance
      rewardsUsed
    }
  }
  ${CartEntryFragmentDoc}
`;
export const DetailsFragmentDoc = gql`
  fragment DetailsFragment on UserDetails {
    name
    dob
    dobDeleted
    phoneNumber
    phoneVerified
    email
    emailVerified
    promotionalEmails
    isoCountryCode
    zipcode
    defaultAccountIdentifier
    defaultFdAccountId
    defaultPaymentAccountId
    defaultReloadAmt
    autoReloadEnabled
    autoReloadThreshold
    deliveryAddresses {
      addressLine1
      addressLine2
      city
      alias
      country
      latitude
      longitude
      phoneNumber
      route
      state
      streetNumber
      zip
    }
    communicationPreferences {
      id
      value
    }
    favoriteStores {
      storeId
      storeNumber
    }
    favoriteOffers {
      id
    }
    loyaltyTier
    requiredAcceptanceAgreementInfo {
      id
      updatedAt
    }
  }
`;
export const DeleteMeDocument = gql`
  mutation DeleteMe @authRequired @gateway(flag: "enable-gateway-deleteMe") {
    deleteMe
  }
`;
export type IDeleteMeMutationFn = Apollo.MutationFunction<
  IDeleteMeMutation,
  IDeleteMeMutationVariables
>;

/**
 * __useDeleteMeMutation__
 *
 * To run a mutation, you first call `useDeleteMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeMutation, { data, loading, error }] = useDeleteMeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMeMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteMeMutation, IDeleteMeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteMeMutation, IDeleteMeMutationVariables>(
    DeleteMeDocument,
    options
  );
}
export type DeleteMeMutationHookResult = ReturnType<typeof useDeleteMeMutation>;
export type DeleteMeMutationResult = Apollo.MutationResult<IDeleteMeMutation>;
export type DeleteMeMutationOptions = Apollo.BaseMutationOptions<
  IDeleteMeMutation,
  IDeleteMeMutationVariables
>;
export const CheckRefundEligibilityDocument = gql`
  query CheckRefundEligibility($rbiOrderId: ID!)
  @authRequired
  @gateway(flag: "enable-gateway-checkRefundEligibility") {
    checkRefundEligibility(rbiOrderId: $rbiOrderId) {
      eligible
      ineligibilityReason
    }
  }
`;

/**
 * __useCheckRefundEligibilityQuery__
 *
 * To run a query within a React component, call `useCheckRefundEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRefundEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRefundEligibilityQuery({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *   },
 * });
 */
export function useCheckRefundEligibilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICheckRefundEligibilityQuery,
    ICheckRefundEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ICheckRefundEligibilityQuery, ICheckRefundEligibilityQueryVariables>(
    CheckRefundEligibilityDocument,
    options
  );
}
export function useCheckRefundEligibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICheckRefundEligibilityQuery,
    ICheckRefundEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ICheckRefundEligibilityQuery, ICheckRefundEligibilityQueryVariables>(
    CheckRefundEligibilityDocument,
    options
  );
}
export type CheckRefundEligibilityQueryHookResult = ReturnType<
  typeof useCheckRefundEligibilityQuery
>;
export type CheckRefundEligibilityLazyQueryHookResult = ReturnType<
  typeof useCheckRefundEligibilityLazyQuery
>;
export type CheckRefundEligibilityQueryResult = Apollo.QueryResult<
  ICheckRefundEligibilityQuery,
  ICheckRefundEligibilityQueryVariables
>;
export const DeliveryQuoteDocument = gql`
  query DeliveryQuote($dropoff: DeliveryWaypointInput!, $pickup: DeliveryWaypointInput!)
  @gateway(flag: "enable-gateway-deliveryQuote") {
    deliveryQuote(dropoff: $dropoff, pickup: $pickup) {
      status
      deliverySurchargeFeeCents
    }
  }
`;

/**
 * __useDeliveryQuoteQuery__
 *
 * To run a query within a React component, call `useDeliveryQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryQuoteQuery({
 *   variables: {
 *      dropoff: // value for 'dropoff'
 *      pickup: // value for 'pickup'
 *   },
 * });
 */
export function useDeliveryQuoteQuery(
  baseOptions: Apollo.QueryHookOptions<IDeliveryQuoteQuery, IDeliveryQuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IDeliveryQuoteQuery, IDeliveryQuoteQueryVariables>(
    DeliveryQuoteDocument,
    options
  );
}
export function useDeliveryQuoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IDeliveryQuoteQuery, IDeliveryQuoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IDeliveryQuoteQuery, IDeliveryQuoteQueryVariables>(
    DeliveryQuoteDocument,
    options
  );
}
export type DeliveryQuoteQueryHookResult = ReturnType<typeof useDeliveryQuoteQuery>;
export type DeliveryQuoteLazyQueryHookResult = ReturnType<typeof useDeliveryQuoteLazyQuery>;
export type DeliveryQuoteQueryResult = Apollo.QueryResult<
  IDeliveryQuoteQuery,
  IDeliveryQuoteQueryVariables
>;
export const AbTestFlagsDocument = gql`
  query AbTestFlags {
    abTestFlags
  }
`;

/**
 * __useAbTestFlagsQuery__
 *
 * To run a query within a React component, call `useAbTestFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbTestFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbTestFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAbTestFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAbTestFlagsQuery, IAbTestFlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IAbTestFlagsQuery, IAbTestFlagsQueryVariables>(
    AbTestFlagsDocument,
    options
  );
}
export function useAbTestFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAbTestFlagsQuery, IAbTestFlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IAbTestFlagsQuery, IAbTestFlagsQueryVariables>(
    AbTestFlagsDocument,
    options
  );
}
export type AbTestFlagsQueryHookResult = ReturnType<typeof useAbTestFlagsQuery>;
export type AbTestFlagsLazyQueryHookResult = ReturnType<typeof useAbTestFlagsLazyQuery>;
export type AbTestFlagsQueryResult = Apollo.QueryResult<
  IAbTestFlagsQuery,
  IAbTestFlagsQueryVariables
>;
export const PutFlavorFlowEventsDocument = gql`
  mutation PutFlavorFlowEvents(
    $productDetails: [FlavorFlowItemInput]!
    $serviceMode: ServiceMode!
    $storeId: String!
    $eventType: FlavorFlowType!
  ) {
    putFlavorFlowEvents(
      productDetails: $productDetails
      serviceMode: $serviceMode
      storeId: $storeId
      eventType: $eventType
    )
  }
`;
export type IPutFlavorFlowEventsMutationFn = Apollo.MutationFunction<
  IPutFlavorFlowEventsMutation,
  IPutFlavorFlowEventsMutationVariables
>;

/**
 * __usePutFlavorFlowEventsMutation__
 *
 * To run a mutation, you first call `usePutFlavorFlowEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutFlavorFlowEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putFlavorFlowEventsMutation, { data, loading, error }] = usePutFlavorFlowEventsMutation({
 *   variables: {
 *      productDetails: // value for 'productDetails'
 *      serviceMode: // value for 'serviceMode'
 *      storeId: // value for 'storeId'
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function usePutFlavorFlowEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPutFlavorFlowEventsMutation,
    IPutFlavorFlowEventsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPutFlavorFlowEventsMutation, IPutFlavorFlowEventsMutationVariables>(
    PutFlavorFlowEventsDocument,
    options
  );
}
export type PutFlavorFlowEventsMutationHookResult = ReturnType<
  typeof usePutFlavorFlowEventsMutation
>;
export type PutFlavorFlowEventsMutationResult = Apollo.MutationResult<IPutFlavorFlowEventsMutation>;
export type PutFlavorFlowEventsMutationOptions = Apollo.BaseMutationOptions<
  IPutFlavorFlowEventsMutation,
  IPutFlavorFlowEventsMutationVariables
>;
export const ListFlavorFlowItemsDocument = gql`
  query ListFlavorFlowItems(
    $productDetails: [FlavorFlowItemInput]!
    $serviceMode: ServiceMode!
    $storeId: String!
  ) @gateway(flag: "enable-gateway-flavor-flow") {
    listFlavorFlowItems(
      productDetails: $productDetails
      serviceMode: $serviceMode
      storeId: $storeId
    ) {
      recommender
      recommendationToken
      results {
        id
      }
    }
  }
`;

/**
 * __useListFlavorFlowItemsQuery__
 *
 * To run a query within a React component, call `useListFlavorFlowItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFlavorFlowItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFlavorFlowItemsQuery({
 *   variables: {
 *      productDetails: // value for 'productDetails'
 *      serviceMode: // value for 'serviceMode'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useListFlavorFlowItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IListFlavorFlowItemsQuery,
    IListFlavorFlowItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IListFlavorFlowItemsQuery, IListFlavorFlowItemsQueryVariables>(
    ListFlavorFlowItemsDocument,
    options
  );
}
export function useListFlavorFlowItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IListFlavorFlowItemsQuery,
    IListFlavorFlowItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IListFlavorFlowItemsQuery, IListFlavorFlowItemsQueryVariables>(
    ListFlavorFlowItemsDocument,
    options
  );
}
export type ListFlavorFlowItemsQueryHookResult = ReturnType<typeof useListFlavorFlowItemsQuery>;
export type ListFlavorFlowItemsLazyQueryHookResult = ReturnType<
  typeof useListFlavorFlowItemsLazyQuery
>;
export type ListFlavorFlowItemsQueryResult = Apollo.QueryResult<
  IListFlavorFlowItemsQuery,
  IListFlavorFlowItemsQueryVariables
>;
export const GetRestaurantDocument = gql`
  query GetRestaurant($storeId: String, $storeNumber: String)
  @gateway(flag: "enable-gateway-restaurant") {
    restaurant(storeId: $storeId, storeNumber: $storeNumber) {
      available
      curbsideHours {
        ...OperatingHoursFragment
      }
      deliveryHours {
        ...OperatingHoursFragment
      }
      diningRoomHours {
        ...OperatingHoursFragment
      }
      driveThruHours {
        ...OperatingHoursFragment
      }
    }
  }
  ${OperatingHoursFragmentDoc}
`;

/**
 * __useGetRestaurantQuery__
 *
 * To run a query within a React component, call `useGetRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      storeNumber: // value for 'storeNumber'
 *   },
 * });
 */
export function useGetRestaurantQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetRestaurantQuery, IGetRestaurantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetRestaurantQuery, IGetRestaurantQueryVariables>(
    GetRestaurantDocument,
    options
  );
}
export function useGetRestaurantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRestaurantQuery, IGetRestaurantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetRestaurantQuery, IGetRestaurantQueryVariables>(
    GetRestaurantDocument,
    options
  );
}
export type GetRestaurantQueryHookResult = ReturnType<typeof useGetRestaurantQuery>;
export type GetRestaurantLazyQueryHookResult = ReturnType<typeof useGetRestaurantLazyQuery>;
export type GetRestaurantQueryResult = Apollo.QueryResult<
  IGetRestaurantQuery,
  IGetRestaurantQueryVariables
>;
export const GetInstagramImageFeedDocument = gql`
  query GetInstagramImageFeed($input: Int!) {
    getImageFeed(input: $input) {
      media_url
      username
      timestamp
    }
  }
`;

/**
 * __useGetInstagramImageFeedQuery__
 *
 * To run a query within a React component, call `useGetInstagramImageFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstagramImageFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstagramImageFeedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInstagramImageFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetInstagramImageFeedQuery,
    IGetInstagramImageFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetInstagramImageFeedQuery, IGetInstagramImageFeedQueryVariables>(
    GetInstagramImageFeedDocument,
    options
  );
}
export function useGetInstagramImageFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetInstagramImageFeedQuery,
    IGetInstagramImageFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetInstagramImageFeedQuery, IGetInstagramImageFeedQueryVariables>(
    GetInstagramImageFeedDocument,
    options
  );
}
export type GetInstagramImageFeedQueryHookResult = ReturnType<typeof useGetInstagramImageFeedQuery>;
export type GetInstagramImageFeedLazyQueryHookResult = ReturnType<
  typeof useGetInstagramImageFeedLazyQuery
>;
export type GetInstagramImageFeedQueryResult = Apollo.QueryResult<
  IGetInstagramImageFeedQuery,
  IGetInstagramImageFeedQueryVariables
>;
export const CreateSupportCaseDocument = gql`
  mutation CreateSupportCase($caseInfo: SupportCaseInput!)
  @gateway(flag: "enable-gateway-createSupportCase") {
    createSupportCase(caseInfo: $caseInfo) {
      caseId
    }
  }
`;
export type ICreateSupportCaseMutationFn = Apollo.MutationFunction<
  ICreateSupportCaseMutation,
  ICreateSupportCaseMutationVariables
>;

/**
 * __useCreateSupportCaseMutation__
 *
 * To run a mutation, you first call `useCreateSupportCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportCaseMutation, { data, loading, error }] = useCreateSupportCaseMutation({
 *   variables: {
 *      caseInfo: // value for 'caseInfo'
 *   },
 * });
 */
export function useCreateSupportCaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateSupportCaseMutation,
    ICreateSupportCaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateSupportCaseMutation, ICreateSupportCaseMutationVariables>(
    CreateSupportCaseDocument,
    options
  );
}
export type CreateSupportCaseMutationHookResult = ReturnType<typeof useCreateSupportCaseMutation>;
export type CreateSupportCaseMutationResult = Apollo.MutationResult<ICreateSupportCaseMutation>;
export type CreateSupportCaseMutationOptions = Apollo.BaseMutationOptions<
  ICreateSupportCaseMutation,
  ICreateSupportCaseMutationVariables
>;
export const GetEncryptionDetailsDocument = gql`
  mutation GetEncryptionDetails @gateway(flag: "enable-gateway-payments") {
    encryptionDetails {
      fdPublicKey
      fdAccessToken
      fdPublicKeyExpiresInSeconds
      fdApiKey
      fdCustomerId
      fdtokenizationUrl
    }
  }
`;
export type IGetEncryptionDetailsMutationFn = Apollo.MutationFunction<
  IGetEncryptionDetailsMutation,
  IGetEncryptionDetailsMutationVariables
>;

/**
 * __useGetEncryptionDetailsMutation__
 *
 * To run a mutation, you first call `useGetEncryptionDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEncryptionDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEncryptionDetailsMutation, { data, loading, error }] = useGetEncryptionDetailsMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetEncryptionDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGetEncryptionDetailsMutation,
    IGetEncryptionDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IGetEncryptionDetailsMutation, IGetEncryptionDetailsMutationVariables>(
    GetEncryptionDetailsDocument,
    options
  );
}
export type GetEncryptionDetailsMutationHookResult = ReturnType<
  typeof useGetEncryptionDetailsMutation
>;
export type GetEncryptionDetailsMutationResult =
  Apollo.MutationResult<IGetEncryptionDetailsMutation>;
export type GetEncryptionDetailsMutationOptions = Apollo.BaseMutationOptions<
  IGetEncryptionDetailsMutation,
  IGetEncryptionDetailsMutationVariables
>;
export const AddCreditAccountDocument = gql`
  mutation AddCreditAccount($input: AddCreditAccountInput!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    addCreditAccount(input: $input) {
      accountIdentifier
      fdAccountId
      chaseProfileId
      credit {
        alias
        billingAddress {
          locality
          postalCode
          region
          streetAddress
          unitNumber
        }
        cardType
        expiryMonth
        expiryYear
        panToken
      }
    }
  }
`;
export type IAddCreditAccountMutationFn = Apollo.MutationFunction<
  IAddCreditAccountMutation,
  IAddCreditAccountMutationVariables
>;

/**
 * __useAddCreditAccountMutation__
 *
 * To run a mutation, you first call `useAddCreditAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditAccountMutation, { data, loading, error }] = useAddCreditAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreditAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddCreditAccountMutation,
    IAddCreditAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAddCreditAccountMutation, IAddCreditAccountMutationVariables>(
    AddCreditAccountDocument,
    options
  );
}
export type AddCreditAccountMutationHookResult = ReturnType<typeof useAddCreditAccountMutation>;
export type AddCreditAccountMutationResult = Apollo.MutationResult<IAddCreditAccountMutation>;
export type AddCreditAccountMutationOptions = Apollo.BaseMutationOptions<
  IAddCreditAccountMutation,
  IAddCreditAccountMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($input: DeleteAccountInput!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    deleteAccount(input: $input)
  }
`;
export type IDeleteAccountMutationFn = Apollo.MutationFunction<
  IDeleteAccountMutation,
  IDeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteAccountMutation, IDeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteAccountMutation, IDeleteAccountMutationVariables>(
    DeleteAccountDocument,
    options
  );
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<IDeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  IDeleteAccountMutation,
  IDeleteAccountMutationVariables
>;
export const UserAccountsDocument = gql`
  query UserAccounts($feCountryCode: IsoCountryCode!)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-payments") {
    userAccounts(feCountryCode: $feCountryCode) {
      count
      accounts {
        accountIdentifier
        fdAccountId
        chaseProfileId
        credit {
          alias
          cardType
          expiryYear
          expiryMonth
          panToken
          billingAddress {
            locality
            country
            postalCode
            region
            streetAddress
            unitNumber
          }
        }
        prepaid {
          alias
          currentBalance
          cardNumber
          expiryMonth
          expiryYear
          feFormattedCurrentBalance
          shuffledCardNumber
        }
        paypal
        paypalIdentifier
      }
      paymentProcessor
    }
  }
`;

/**
 * __useUserAccountsQuery__
 *
 * To run a query within a React component, call `useUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountsQuery({
 *   variables: {
 *      feCountryCode: // value for 'feCountryCode'
 *   },
 * });
 */
export function useUserAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<IUserAccountsQuery, IUserAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserAccountsQuery, IUserAccountsQueryVariables>(
    UserAccountsDocument,
    options
  );
}
export function useUserAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserAccountsQuery, IUserAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserAccountsQuery, IUserAccountsQueryVariables>(
    UserAccountsDocument,
    options
  );
}
export type UserAccountsQueryHookResult = ReturnType<typeof useUserAccountsQuery>;
export type UserAccountsLazyQueryHookResult = ReturnType<typeof useUserAccountsLazyQuery>;
export type UserAccountsQueryResult = Apollo.QueryResult<
  IUserAccountsQuery,
  IUserAccountsQueryVariables
>;
export const LogPaymentFlowStartedEventDocument = gql`
  mutation LogPaymentFlowStartedEvent
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-payments") {
    logPaymentFlowStartedEvent
  }
`;
export type ILogPaymentFlowStartedEventMutationFn = Apollo.MutationFunction<
  ILogPaymentFlowStartedEventMutation,
  ILogPaymentFlowStartedEventMutationVariables
>;

/**
 * __useLogPaymentFlowStartedEventMutation__
 *
 * To run a mutation, you first call `useLogPaymentFlowStartedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogPaymentFlowStartedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logPaymentFlowStartedEventMutation, { data, loading, error }] = useLogPaymentFlowStartedEventMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogPaymentFlowStartedEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILogPaymentFlowStartedEventMutation,
    ILogPaymentFlowStartedEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ILogPaymentFlowStartedEventMutation,
    ILogPaymentFlowStartedEventMutationVariables
  >(LogPaymentFlowStartedEventDocument, options);
}
export type LogPaymentFlowStartedEventMutationHookResult = ReturnType<
  typeof useLogPaymentFlowStartedEventMutation
>;
export type LogPaymentFlowStartedEventMutationResult =
  Apollo.MutationResult<ILogPaymentFlowStartedEventMutation>;
export type LogPaymentFlowStartedEventMutationOptions = Apollo.BaseMutationOptions<
  ILogPaymentFlowStartedEventMutation,
  ILogPaymentFlowStartedEventMutationVariables
>;
export const InitiatePaymentDocument = gql`
  mutation InitiatePayment($input: InitiatePaymentInput!) @authRequired @authGuestAllowed {
    initiatePayment(input: $input) {
      cybersourcePaSetup {
        rbiOrderId
        accessToken
        referenceId
        deviceDataCollectionUrl
      }
      cybersourceCaptureContext {
        context
      }
      paycometCaptureContext {
        amount
        challengeUrl
        currency
        errorCode
        methodId
        order
        paymentRequestTimeLimit
      }
    }
  }
`;
export type IInitiatePaymentMutationFn = Apollo.MutationFunction<
  IInitiatePaymentMutation,
  IInitiatePaymentMutationVariables
>;

/**
 * __useInitiatePaymentMutation__
 *
 * To run a mutation, you first call `useInitiatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePaymentMutation, { data, loading, error }] = useInitiatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IInitiatePaymentMutation,
    IInitiatePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IInitiatePaymentMutation, IInitiatePaymentMutationVariables>(
    InitiatePaymentDocument,
    options
  );
}
export type InitiatePaymentMutationHookResult = ReturnType<typeof useInitiatePaymentMutation>;
export type InitiatePaymentMutationResult = Apollo.MutationResult<IInitiatePaymentMutation>;
export type InitiatePaymentMutationOptions = Apollo.BaseMutationOptions<
  IInitiatePaymentMutation,
  IInitiatePaymentMutationVariables
>;
export const GenerateIdealCheckoutLinkDocument = gql`
  mutation GenerateIdealCheckoutLink($input: GenerateIdealCheckoutLinkInput!) @authRequired {
    generateIdealCheckoutLink(input: $input) {
      result
      action {
        method
        paymentMethodType
        type
        url
      }
    }
  }
`;
export type IGenerateIdealCheckoutLinkMutationFn = Apollo.MutationFunction<
  IGenerateIdealCheckoutLinkMutation,
  IGenerateIdealCheckoutLinkMutationVariables
>;

/**
 * __useGenerateIdealCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useGenerateIdealCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateIdealCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateIdealCheckoutLinkMutation, { data, loading, error }] = useGenerateIdealCheckoutLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateIdealCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateIdealCheckoutLinkMutation,
    IGenerateIdealCheckoutLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateIdealCheckoutLinkMutation,
    IGenerateIdealCheckoutLinkMutationVariables
  >(GenerateIdealCheckoutLinkDocument, options);
}
export type GenerateIdealCheckoutLinkMutationHookResult = ReturnType<
  typeof useGenerateIdealCheckoutLinkMutation
>;
export type GenerateIdealCheckoutLinkMutationResult =
  Apollo.MutationResult<IGenerateIdealCheckoutLinkMutation>;
export type GenerateIdealCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateIdealCheckoutLinkMutation,
  IGenerateIdealCheckoutLinkMutationVariables
>;
export const PluDataDocument = gql`
  query pluData($storeId: String!) @gateway(flag: "enable-gateway-pluData") {
    pluData(storeId: $storeId) {
      plus
      deliveryPlus
      updatedAt
    }
  }
`;

/**
 * __usePluDataQuery__
 *
 * To run a query within a React component, call `usePluDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePluDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePluDataQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePluDataQuery(
  baseOptions: Apollo.QueryHookOptions<IPluDataQuery, IPluDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPluDataQuery, IPluDataQueryVariables>(PluDataDocument, options);
}
export function usePluDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPluDataQuery, IPluDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPluDataQuery, IPluDataQueryVariables>(PluDataDocument, options);
}
export type PluDataQueryHookResult = ReturnType<typeof usePluDataQuery>;
export type PluDataLazyQueryHookResult = ReturnType<typeof usePluDataLazyQuery>;
export type PluDataQueryResult = Apollo.QueryResult<IPluDataQuery, IPluDataQueryVariables>;
export const GetPrepaidsBalanceDocument = gql`
  mutation GetPrepaidsBalance($cardNumber: String!, $feCountryCode: IsoCountryCode!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    prepaidsBalance(cardNumber: $cardNumber, feCountryCode: $feCountryCode) {
      currentBalance
    }
  }
`;
export type IGetPrepaidsBalanceMutationFn = Apollo.MutationFunction<
  IGetPrepaidsBalanceMutation,
  IGetPrepaidsBalanceMutationVariables
>;

/**
 * __useGetPrepaidsBalanceMutation__
 *
 * To run a mutation, you first call `useGetPrepaidsBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPrepaidsBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPrepaidsBalanceMutation, { data, loading, error }] = useGetPrepaidsBalanceMutation({
 *   variables: {
 *      cardNumber: // value for 'cardNumber'
 *      feCountryCode: // value for 'feCountryCode'
 *   },
 * });
 */
export function useGetPrepaidsBalanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGetPrepaidsBalanceMutation,
    IGetPrepaidsBalanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IGetPrepaidsBalanceMutation, IGetPrepaidsBalanceMutationVariables>(
    GetPrepaidsBalanceDocument,
    options
  );
}
export type GetPrepaidsBalanceMutationHookResult = ReturnType<typeof useGetPrepaidsBalanceMutation>;
export type GetPrepaidsBalanceMutationResult = Apollo.MutationResult<IGetPrepaidsBalanceMutation>;
export type GetPrepaidsBalanceMutationOptions = Apollo.BaseMutationOptions<
  IGetPrepaidsBalanceMutation,
  IGetPrepaidsBalanceMutationVariables
>;
export const PrepaidsMergeDocument = gql`
  mutation PrepaidsMerge($input: PrepaidsMergeInput!) @gateway(flag: "enable-gateway-payments") {
    prepaidsMerge(input: $input) {
      currentBalance
      fdAccountId
    }
  }
`;
export type IPrepaidsMergeMutationFn = Apollo.MutationFunction<
  IPrepaidsMergeMutation,
  IPrepaidsMergeMutationVariables
>;

/**
 * __usePrepaidsMergeMutation__
 *
 * To run a mutation, you first call `usePrepaidsMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepaidsMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepaidsMergeMutation, { data, loading, error }] = usePrepaidsMergeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepaidsMergeMutation(
  baseOptions?: Apollo.MutationHookOptions<IPrepaidsMergeMutation, IPrepaidsMergeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPrepaidsMergeMutation, IPrepaidsMergeMutationVariables>(
    PrepaidsMergeDocument,
    options
  );
}
export type PrepaidsMergeMutationHookResult = ReturnType<typeof usePrepaidsMergeMutation>;
export type PrepaidsMergeMutationResult = Apollo.MutationResult<IPrepaidsMergeMutation>;
export type PrepaidsMergeMutationOptions = Apollo.BaseMutationOptions<
  IPrepaidsMergeMutation,
  IPrepaidsMergeMutationVariables
>;
export const PrepaidsReloadDocument = gql`
  mutation PrepaidsReload($input: PrepaidsReloadInput!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    prepaidsReload(input: $input) {
      currentBalance
      fdAccountId
      fdCorrelationId
      transactionId
    }
  }
`;
export type IPrepaidsReloadMutationFn = Apollo.MutationFunction<
  IPrepaidsReloadMutation,
  IPrepaidsReloadMutationVariables
>;

/**
 * __usePrepaidsReloadMutation__
 *
 * To run a mutation, you first call `usePrepaidsReloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepaidsReloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepaidsReloadMutation, { data, loading, error }] = usePrepaidsReloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepaidsReloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPrepaidsReloadMutation,
    IPrepaidsReloadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPrepaidsReloadMutation, IPrepaidsReloadMutationVariables>(
    PrepaidsReloadDocument,
    options
  );
}
export type PrepaidsReloadMutationHookResult = ReturnType<typeof usePrepaidsReloadMutation>;
export type PrepaidsReloadMutationResult = Apollo.MutationResult<IPrepaidsReloadMutation>;
export type PrepaidsReloadMutationOptions = Apollo.BaseMutationOptions<
  IPrepaidsReloadMutation,
  IPrepaidsReloadMutationVariables
>;
export const AddPrepaidCardDocument = gql`
  mutation AddPrepaidCard($input: AddGiftAccountInput!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    addGiftAccount(input: $input) {
      fdAccountId
      prepaid {
        currentBalance
      }
    }
  }
`;
export type IAddPrepaidCardMutationFn = Apollo.MutationFunction<
  IAddPrepaidCardMutation,
  IAddPrepaidCardMutationVariables
>;

/**
 * __useAddPrepaidCardMutation__
 *
 * To run a mutation, you first call `useAddPrepaidCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrepaidCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrepaidCardMutation, { data, loading, error }] = useAddPrepaidCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPrepaidCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddPrepaidCardMutation,
    IAddPrepaidCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IAddPrepaidCardMutation, IAddPrepaidCardMutationVariables>(
    AddPrepaidCardDocument,
    options
  );
}
export type AddPrepaidCardMutationHookResult = ReturnType<typeof useAddPrepaidCardMutation>;
export type AddPrepaidCardMutationResult = Apollo.MutationResult<IAddPrepaidCardMutation>;
export type AddPrepaidCardMutationOptions = Apollo.BaseMutationOptions<
  IAddPrepaidCardMutation,
  IAddPrepaidCardMutationVariables
>;
export const PrepaidTransactionCurrencyCodesDocument = gql`
  query prepaidTransactionCurrencyCodes($cardNumber: String!)
  @authRequired
  @gateway(flag: "enable-gateway-payments") {
    prepaidsTransactions(cardNumber: $cardNumber) {
      transactions {
        currencyCode {
          number
        }
      }
    }
  }
`;

/**
 * __usePrepaidTransactionCurrencyCodesQuery__
 *
 * To run a query within a React component, call `usePrepaidTransactionCurrencyCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrepaidTransactionCurrencyCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrepaidTransactionCurrencyCodesQuery({
 *   variables: {
 *      cardNumber: // value for 'cardNumber'
 *   },
 * });
 */
export function usePrepaidTransactionCurrencyCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IPrepaidTransactionCurrencyCodesQuery,
    IPrepaidTransactionCurrencyCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IPrepaidTransactionCurrencyCodesQuery,
    IPrepaidTransactionCurrencyCodesQueryVariables
  >(PrepaidTransactionCurrencyCodesDocument, options);
}
export function usePrepaidTransactionCurrencyCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPrepaidTransactionCurrencyCodesQuery,
    IPrepaidTransactionCurrencyCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IPrepaidTransactionCurrencyCodesQuery,
    IPrepaidTransactionCurrencyCodesQueryVariables
  >(PrepaidTransactionCurrencyCodesDocument, options);
}
export type PrepaidTransactionCurrencyCodesQueryHookResult = ReturnType<
  typeof usePrepaidTransactionCurrencyCodesQuery
>;
export type PrepaidTransactionCurrencyCodesLazyQueryHookResult = ReturnType<
  typeof usePrepaidTransactionCurrencyCodesLazyQuery
>;
export type PrepaidTransactionCurrencyCodesQueryResult = Apollo.QueryResult<
  IPrepaidTransactionCurrencyCodesQuery,
  IPrepaidTransactionCurrencyCodesQueryVariables
>;
export const GetRefillDrinkQrCodeDocument = gql`
  query GetRefillDrinkQRCode(
    $orderUpdateAt: String!
    $storeNumber: String!
    $transactionId: String!
  ) {
    getRefillDrinkQRCode(
      orderUpdateAt: $orderUpdateAt
      storeNumber: $storeNumber
      transactionId: $transactionId
    ) {
      qrCodeInfo
    }
  }
`;

/**
 * __useGetRefillDrinkQrCodeQuery__
 *
 * To run a query within a React component, call `useGetRefillDrinkQrCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefillDrinkQrCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefillDrinkQrCodeQuery({
 *   variables: {
 *      orderUpdateAt: // value for 'orderUpdateAt'
 *      storeNumber: // value for 'storeNumber'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetRefillDrinkQrCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetRefillDrinkQrCodeQuery,
    IGetRefillDrinkQrCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetRefillDrinkQrCodeQuery, IGetRefillDrinkQrCodeQueryVariables>(
    GetRefillDrinkQrCodeDocument,
    options
  );
}
export function useGetRefillDrinkQrCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetRefillDrinkQrCodeQuery,
    IGetRefillDrinkQrCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetRefillDrinkQrCodeQuery, IGetRefillDrinkQrCodeQueryVariables>(
    GetRefillDrinkQrCodeDocument,
    options
  );
}
export type GetRefillDrinkQrCodeQueryHookResult = ReturnType<typeof useGetRefillDrinkQrCodeQuery>;
export type GetRefillDrinkQrCodeLazyQueryHookResult = ReturnType<
  typeof useGetRefillDrinkQrCodeLazyQuery
>;
export type GetRefillDrinkQrCodeQueryResult = Apollo.QueryResult<
  IGetRefillDrinkQrCodeQuery,
  IGetRefillDrinkQrCodeQueryVariables
>;
export const RequestRefundDocument = gql`
  mutation RequestRefund($input: UserRequestsRefundInput!)
  @authRequired
  @gateway(flag: "enable-gateway-userRequestsRefund") {
    userRequestsRefund(input: $input) {
      refundRequestAmountCents
      requestDateTime
    }
  }
`;
export type IRequestRefundMutationFn = Apollo.MutationFunction<
  IRequestRefundMutation,
  IRequestRefundMutationVariables
>;

/**
 * __useRequestRefundMutation__
 *
 * To run a mutation, you first call `useRequestRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRefundMutation, { data, loading, error }] = useRequestRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<IRequestRefundMutation, IRequestRefundMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRequestRefundMutation, IRequestRefundMutationVariables>(
    RequestRefundDocument,
    options
  );
}
export type RequestRefundMutationHookResult = ReturnType<typeof useRequestRefundMutation>;
export type RequestRefundMutationResult = Apollo.MutationResult<IRequestRefundMutation>;
export type RequestRefundMutationOptions = Apollo.BaseMutationOptions<
  IRequestRefundMutation,
  IRequestRefundMutationVariables
>;
export const RequestMyInfoDocument = gql`
  mutation RequestMyInfo @authRequired @gateway {
    requestMyInfo
  }
`;
export type IRequestMyInfoMutationFn = Apollo.MutationFunction<
  IRequestMyInfoMutation,
  IRequestMyInfoMutationVariables
>;

/**
 * __useRequestMyInfoMutation__
 *
 * To run a mutation, you first call `useRequestMyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMyInfoMutation, { data, loading, error }] = useRequestMyInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestMyInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<IRequestMyInfoMutation, IRequestMyInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IRequestMyInfoMutation, IRequestMyInfoMutationVariables>(
    RequestMyInfoDocument,
    options
  );
}
export type RequestMyInfoMutationHookResult = ReturnType<typeof useRequestMyInfoMutation>;
export type RequestMyInfoMutationResult = Apollo.MutationResult<IRequestMyInfoMutation>;
export type RequestMyInfoMutationOptions = Apollo.BaseMutationOptions<
  IRequestMyInfoMutation,
  IRequestMyInfoMutationVariables
>;
export const GetRestaurantsDocument = gql`
  query GetRestaurants($input: RestaurantsInput) {
    restaurants(input: $input) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      nodes {
        ...RestaurantNodeFragment
      }
    }
  }
  ${RestaurantNodeFragmentDoc}
`;

/**
 * __useGetRestaurantsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRestaurantsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export function useGetRestaurantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetRestaurantsQuery, IGetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export type GetRestaurantsQueryHookResult = ReturnType<typeof useGetRestaurantsQuery>;
export type GetRestaurantsLazyQueryHookResult = ReturnType<typeof useGetRestaurantsLazyQuery>;
export type GetRestaurantsQueryResult = Apollo.QueryResult<
  IGetRestaurantsQuery,
  IGetRestaurantsQueryVariables
>;
export const DeliveryRestaurantDocument = gql`
  query DeliveryRestaurant(
    $dropoff: DeliveryWaypointInput!
    $searchRadius: Float!
    $platform: Platform!
  ) {
    deliveryRestaurant(dropoff: $dropoff, searchRadius: $searchRadius, platform: $platform) {
      storeStatus
      quote
      nextEarliestOpen
      restaurant {
        ...RestaurantNodeFragment
      }
      deliverySurchargeFeeCents
      quoteId
      unavailabilityReason
      preOrderTimeSlots {
        start
        end
      }
    }
  }
  ${RestaurantNodeFragmentDoc}
`;

/**
 * __useDeliveryRestaurantQuery__
 *
 * To run a query within a React component, call `useDeliveryRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryRestaurantQuery({
 *   variables: {
 *      dropoff: // value for 'dropoff'
 *      searchRadius: // value for 'searchRadius'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useDeliveryRestaurantQuery(
  baseOptions: Apollo.QueryHookOptions<IDeliveryRestaurantQuery, IDeliveryRestaurantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IDeliveryRestaurantQuery, IDeliveryRestaurantQueryVariables>(
    DeliveryRestaurantDocument,
    options
  );
}
export function useDeliveryRestaurantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDeliveryRestaurantQuery,
    IDeliveryRestaurantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IDeliveryRestaurantQuery, IDeliveryRestaurantQueryVariables>(
    DeliveryRestaurantDocument,
    options
  );
}
export type DeliveryRestaurantQueryHookResult = ReturnType<typeof useDeliveryRestaurantQuery>;
export type DeliveryRestaurantLazyQueryHookResult = ReturnType<
  typeof useDeliveryRestaurantLazyQuery
>;
export type DeliveryRestaurantQueryResult = Apollo.QueryResult<
  IDeliveryRestaurantQuery,
  IDeliveryRestaurantQueryVariables
>;
export const TrackEventViaBeDocument = gql`
  mutation TrackEventViaBE($input: TrackEventViaBEInput!)
  @authRequired
  @gateway(flag: "enable-gateway-trackEventViaBE") {
    trackEventViaBE(input: $input)
  }
`;
export type ITrackEventViaBeMutationFn = Apollo.MutationFunction<
  ITrackEventViaBeMutation,
  ITrackEventViaBeMutationVariables
>;

/**
 * __useTrackEventViaBeMutation__
 *
 * To run a mutation, you first call `useTrackEventViaBeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventViaBeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventViaBeMutation, { data, loading, error }] = useTrackEventViaBeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackEventViaBeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ITrackEventViaBeMutation,
    ITrackEventViaBeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ITrackEventViaBeMutation, ITrackEventViaBeMutationVariables>(
    TrackEventViaBeDocument,
    options
  );
}
export type TrackEventViaBeMutationHookResult = ReturnType<typeof useTrackEventViaBeMutation>;
export type TrackEventViaBeMutationResult = Apollo.MutationResult<ITrackEventViaBeMutation>;
export type TrackEventViaBeMutationOptions = Apollo.BaseMutationOptions<
  ITrackEventViaBeMutation,
  ITrackEventViaBeMutationVariables
>;
export const GetUniqueStoresDocument = gql`
  query GetUniqueStores($orderStatuses: [RbiOrderStatus!])
  @authRequired
  @gateway(flag: "enable-gateway-uniqueStores") {
    uniqueStores(orderStatuses: $orderStatuses) {
      orders {
        cart {
          storeId
        }
      }
    }
  }
`;

/**
 * __useGetUniqueStoresQuery__
 *
 * To run a query within a React component, call `useGetUniqueStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueStoresQuery({
 *   variables: {
 *      orderStatuses: // value for 'orderStatuses'
 *   },
 * });
 */
export function useGetUniqueStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetUniqueStoresQuery, IGetUniqueStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUniqueStoresQuery, IGetUniqueStoresQueryVariables>(
    GetUniqueStoresDocument,
    options
  );
}
export function useGetUniqueStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUniqueStoresQuery, IGetUniqueStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUniqueStoresQuery, IGetUniqueStoresQueryVariables>(
    GetUniqueStoresDocument,
    options
  );
}
export type GetUniqueStoresQueryHookResult = ReturnType<typeof useGetUniqueStoresQuery>;
export type GetUniqueStoresLazyQueryHookResult = ReturnType<typeof useGetUniqueStoresLazyQuery>;
export type GetUniqueStoresQueryResult = Apollo.QueryResult<
  IGetUniqueStoresQuery,
  IGetUniqueStoresQueryVariables
>;
export const GetUserFavoritesRefsDocument = gql`
  query GetUserFavoritesRefs($limit: Int, $resumeAfter: String)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    userFavorites(limit: $limit, resumeAfter: $resumeAfter) {
      count
      resumeAfter
      favorites {
        _id: favoriteId
        ref
      }
    }
  }
`;

/**
 * __useGetUserFavoritesRefsQuery__
 *
 * To run a query within a React component, call `useGetUserFavoritesRefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavoritesRefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavoritesRefsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      resumeAfter: // value for 'resumeAfter'
 *   },
 * });
 */
export function useGetUserFavoritesRefsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IGetUserFavoritesRefsQuery,
    IGetUserFavoritesRefsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserFavoritesRefsQuery, IGetUserFavoritesRefsQueryVariables>(
    GetUserFavoritesRefsDocument,
    options
  );
}
export function useGetUserFavoritesRefsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetUserFavoritesRefsQuery,
    IGetUserFavoritesRefsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserFavoritesRefsQuery, IGetUserFavoritesRefsQueryVariables>(
    GetUserFavoritesRefsDocument,
    options
  );
}
export type GetUserFavoritesRefsQueryHookResult = ReturnType<typeof useGetUserFavoritesRefsQuery>;
export type GetUserFavoritesRefsLazyQueryHookResult = ReturnType<
  typeof useGetUserFavoritesRefsLazyQuery
>;
export type GetUserFavoritesRefsQueryResult = Apollo.QueryResult<
  IGetUserFavoritesRefsQuery,
  IGetUserFavoritesRefsQueryVariables
>;
export const GetUserFavoriteDocument = gql`
  query GetUserFavorite($favoriteId: String!)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    userFavorite(favoriteId: $favoriteId) {
      ...FavoriteFragment
    }
  }
  ${FavoriteFragmentDoc}
`;

/**
 * __useGetUserFavoriteQuery__
 *
 * To run a query within a React component, call `useGetUserFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavoriteQuery({
 *   variables: {
 *      favoriteId: // value for 'favoriteId'
 *   },
 * });
 */
export function useGetUserFavoriteQuery(
  baseOptions: Apollo.QueryHookOptions<IGetUserFavoriteQuery, IGetUserFavoriteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserFavoriteQuery, IGetUserFavoriteQueryVariables>(
    GetUserFavoriteDocument,
    options
  );
}
export function useGetUserFavoriteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserFavoriteQuery, IGetUserFavoriteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserFavoriteQuery, IGetUserFavoriteQueryVariables>(
    GetUserFavoriteDocument,
    options
  );
}
export type GetUserFavoriteQueryHookResult = ReturnType<typeof useGetUserFavoriteQuery>;
export type GetUserFavoriteLazyQueryHookResult = ReturnType<typeof useGetUserFavoriteLazyQuery>;
export type GetUserFavoriteQueryResult = Apollo.QueryResult<
  IGetUserFavoriteQuery,
  IGetUserFavoriteQueryVariables
>;
export const GetUserFavoritesDocument = gql`
  query GetUserFavorites($limit: Int, $resumeAfter: String)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    userFavorites(limit: $limit, resumeAfter: $resumeAfter) {
      count
      resumeAfter
      favorites {
        ...FavoriteFragment
      }
    }
  }
  ${FavoriteFragmentDoc}
`;

/**
 * __useGetUserFavoritesQuery__
 *
 * To run a query within a React component, call `useGetUserFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavoritesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      resumeAfter: // value for 'resumeAfter'
 *   },
 * });
 */
export function useGetUserFavoritesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetUserFavoritesQuery, IGetUserFavoritesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserFavoritesQuery, IGetUserFavoritesQueryVariables>(
    GetUserFavoritesDocument,
    options
  );
}
export function useGetUserFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserFavoritesQuery, IGetUserFavoritesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserFavoritesQuery, IGetUserFavoritesQueryVariables>(
    GetUserFavoritesDocument,
    options
  );
}
export type GetUserFavoritesQueryHookResult = ReturnType<typeof useGetUserFavoritesQuery>;
export type GetUserFavoritesLazyQueryHookResult = ReturnType<typeof useGetUserFavoritesLazyQuery>;
export type GetUserFavoritesQueryResult = Apollo.QueryResult<
  IGetUserFavoritesQuery,
  IGetUserFavoritesQueryVariables
>;
export const GetUserReorderFavoritesDocument = gql`
  query GetUserReorderFavorites @authRequired @gateway(flag: "enable-gateway-userFavorites") {
    userFavorites {
      count
      favorites {
        ...ReorderFavoriteFragment
      }
    }
  }
  ${ReorderFavoriteFragmentDoc}
`;

/**
 * __useGetUserReorderFavoritesQuery__
 *
 * To run a query within a React component, call `useGetUserReorderFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReorderFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReorderFavoritesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserReorderFavoritesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IGetUserReorderFavoritesQuery,
    IGetUserReorderFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserReorderFavoritesQuery, IGetUserReorderFavoritesQueryVariables>(
    GetUserReorderFavoritesDocument,
    options
  );
}
export function useGetUserReorderFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetUserReorderFavoritesQuery,
    IGetUserReorderFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserReorderFavoritesQuery, IGetUserReorderFavoritesQueryVariables>(
    GetUserReorderFavoritesDocument,
    options
  );
}
export type GetUserReorderFavoritesQueryHookResult = ReturnType<
  typeof useGetUserReorderFavoritesQuery
>;
export type GetUserReorderFavoritesLazyQueryHookResult = ReturnType<
  typeof useGetUserReorderFavoritesLazyQuery
>;
export type GetUserReorderFavoritesQueryResult = Apollo.QueryResult<
  IGetUserReorderFavoritesQuery,
  IGetUserReorderFavoritesQueryVariables
>;
export const CreateFavoriteDocument = gql`
  mutation CreateFavorite($input: FavoriteInput!)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    createFavorite(input: $input) {
      ...FavoriteFragment
    }
  }
  ${FavoriteFragmentDoc}
`;
export type ICreateFavoriteMutationFn = Apollo.MutationFunction<
  ICreateFavoriteMutation,
  ICreateFavoriteMutationVariables
>;

/**
 * __useCreateFavoriteMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteMutation, { data, loading, error }] = useCreateFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateFavoriteMutation,
    ICreateFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateFavoriteMutation, ICreateFavoriteMutationVariables>(
    CreateFavoriteDocument,
    options
  );
}
export type CreateFavoriteMutationHookResult = ReturnType<typeof useCreateFavoriteMutation>;
export type CreateFavoriteMutationResult = Apollo.MutationResult<ICreateFavoriteMutation>;
export type CreateFavoriteMutationOptions = Apollo.BaseMutationOptions<
  ICreateFavoriteMutation,
  ICreateFavoriteMutationVariables
>;
export const UpdateFavoriteDocument = gql`
  mutation UpdateFavorite($favoriteId: String!, $input: FavoriteInput!)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    updateFavorite(favoriteId: $favoriteId, input: $input) {
      ...FavoriteFragment
    }
  }
  ${FavoriteFragmentDoc}
`;
export type IUpdateFavoriteMutationFn = Apollo.MutationFunction<
  IUpdateFavoriteMutation,
  IUpdateFavoriteMutationVariables
>;

/**
 * __useUpdateFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteMutation, { data, loading, error }] = useUpdateFavoriteMutation({
 *   variables: {
 *      favoriteId: // value for 'favoriteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateFavoriteMutation,
    IUpdateFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateFavoriteMutation, IUpdateFavoriteMutationVariables>(
    UpdateFavoriteDocument,
    options
  );
}
export type UpdateFavoriteMutationHookResult = ReturnType<typeof useUpdateFavoriteMutation>;
export type UpdateFavoriteMutationResult = Apollo.MutationResult<IUpdateFavoriteMutation>;
export type UpdateFavoriteMutationOptions = Apollo.BaseMutationOptions<
  IUpdateFavoriteMutation,
  IUpdateFavoriteMutationVariables
>;
export const DeleteFavoriteDocument = gql`
  mutation DeleteFavorite($favoriteId: String!)
  @authRequired
  @gateway(flag: "enable-gateway-userFavorites") {
    deleteFavorite(favoriteId: $favoriteId) {
      ...FavoriteFragment
    }
  }
  ${FavoriteFragmentDoc}
`;
export type IDeleteFavoriteMutationFn = Apollo.MutationFunction<
  IDeleteFavoriteMutation,
  IDeleteFavoriteMutationVariables
>;

/**
 * __useDeleteFavoriteMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteMutation, { data, loading, error }] = useDeleteFavoriteMutation({
 *   variables: {
 *      favoriteId: // value for 'favoriteId'
 *   },
 * });
 */
export function useDeleteFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteFavoriteMutation,
    IDeleteFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IDeleteFavoriteMutation, IDeleteFavoriteMutationVariables>(
    DeleteFavoriteDocument,
    options
  );
}
export type DeleteFavoriteMutationHookResult = ReturnType<typeof useDeleteFavoriteMutation>;
export type DeleteFavoriteMutationResult = Apollo.MutationResult<IDeleteFavoriteMutation>;
export type DeleteFavoriteMutationOptions = Apollo.BaseMutationOptions<
  IDeleteFavoriteMutation,
  IDeleteFavoriteMutationVariables
>;
export const CommitOrderDocument = gql`
  mutation CommitOrder(
    $input: CommitOrderInput!
    $delivery: CommitDeliveryInput
    $skipCoolingPeriod: Boolean
  ) @authRequired @authGuestAllowed @gateway(flag: "enable-gateway-commitOrder") {
    commitOrder(input: $input, delivery: $delivery, skipCoolingPeriod: $skipCoolingPeriod) {
      rbiOrderId
      status
    }
  }
`;
export type ICommitOrderMutationFn = Apollo.MutationFunction<
  ICommitOrderMutation,
  ICommitOrderMutationVariables
>;

/**
 * __useCommitOrderMutation__
 *
 * To run a mutation, you first call `useCommitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitOrderMutation, { data, loading, error }] = useCommitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      delivery: // value for 'delivery'
 *      skipCoolingPeriod: // value for 'skipCoolingPeriod'
 *   },
 * });
 */
export function useCommitOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ICommitOrderMutation, ICommitOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICommitOrderMutation, ICommitOrderMutationVariables>(
    CommitOrderDocument,
    options
  );
}
export type CommitOrderMutationHookResult = ReturnType<typeof useCommitOrderMutation>;
export type CommitOrderMutationResult = Apollo.MutationResult<ICommitOrderMutation>;
export type CommitOrderMutationOptions = Apollo.BaseMutationOptions<
  ICommitOrderMutation,
  ICommitOrderMutationVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder($input: UpdateOrderInput!)
  @authRequired
  @gateway(flag: "enable-gateway-updateOrder") {
    updateOrder(input: $input) {
      rbiOrderId
      fireOrderIn
    }
  }
`;
export type IUpdateOrderMutationFn = Apollo.MutationFunction<
  IUpdateOrderMutation,
  IUpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateOrderMutation, IUpdateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateOrderMutation, IUpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options
  );
}
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<IUpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  IUpdateOrderMutation,
  IUpdateOrderMutationVariables
>;
export const GetUserOrdersDocument = gql`
  query GetUserOrders(
    $userId: ID
    $limit: Int
    $resumeAfter: String
    $orderStatuses: [RbiOrderStatus!]
    $createdAfter: String
    $shouldFilterByRegion: Boolean
    $serviceModes: [ServiceMode]
  ) @authRequired @authGuestAllowed @gateway(flag: "enable-gateway-userOrders") {
    userOrders(
      userId: $userId
      limit: $limit
      resumeAfter: $resumeAfter
      orderStatuses: $orderStatuses
      createdAfter: $createdAfter
      shouldFilterByRegion: $shouldFilterByRegion
      serviceModes: $serviceModes
    ) {
      count
      resumeAfter
      orders {
        ...UserOrderFragment
      }
    }
  }
  ${UserOrderFragmentDoc}
`;

/**
 * __useGetUserOrdersQuery__
 *
 * To run a query within a React component, call `useGetUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrdersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      resumeAfter: // value for 'resumeAfter'
 *      orderStatuses: // value for 'orderStatuses'
 *      createdAfter: // value for 'createdAfter'
 *      shouldFilterByRegion: // value for 'shouldFilterByRegion'
 *      serviceModes: // value for 'serviceModes'
 *   },
 * });
 */
export function useGetUserOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>(
    GetUserOrdersDocument,
    options
  );
}
export function useGetUserOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>(
    GetUserOrdersDocument,
    options
  );
}
export type GetUserOrdersQueryHookResult = ReturnType<typeof useGetUserOrdersQuery>;
export type GetUserOrdersLazyQueryHookResult = ReturnType<typeof useGetUserOrdersLazyQuery>;
export type GetUserOrdersQueryResult = Apollo.QueryResult<
  IGetUserOrdersQuery,
  IGetUserOrdersQueryVariables
>;
export const GetUserOrderDocument = gql`
  query GetUserOrder($id: ID!) @authRequired @gateway(flag: "enable-gateway-order") {
    order(rbiOrderId: $id) {
      ...UserOrderFragment
    }
  }
  ${UserOrderFragmentDoc}
`;

/**
 * __useGetUserOrderQuery__
 *
 * To run a query within a React component, call `useGetUserOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserOrderQuery(
  baseOptions: Apollo.QueryHookOptions<IGetUserOrderQuery, IGetUserOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserOrderQuery, IGetUserOrderQueryVariables>(
    GetUserOrderDocument,
    options
  );
}
export function useGetUserOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserOrderQuery, IGetUserOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserOrderQuery, IGetUserOrderQueryVariables>(
    GetUserOrderDocument,
    options
  );
}
export type GetUserOrderQueryHookResult = ReturnType<typeof useGetUserOrderQuery>;
export type GetUserOrderLazyQueryHookResult = ReturnType<typeof useGetUserOrderLazyQuery>;
export type GetUserOrderQueryResult = Apollo.QueryResult<
  IGetUserOrderQuery,
  IGetUserOrderQueryVariables
>;
export const UpdateMeDocument = gql`
  mutation UpdateMe($input: UpdateUserDetailsInput!)
  @authRequired
  @gateway(flag: "enable-gateway-updateMe") {
    updateMe(details: $input) {
      details {
        ...UserDetailsFragment
      }
    }
  }
  ${UserDetailsFragmentDoc}
`;
export type IUpdateMeMutationFn = Apollo.MutationFunction<
  IUpdateMeMutation,
  IUpdateMeMutationVariables
>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateMeMutation, IUpdateMeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IUpdateMeMutation, IUpdateMeMutationVariables>(
    UpdateMeDocument,
    options
  );
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<IUpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  IUpdateMeMutation,
  IUpdateMeMutationVariables
>;
export const CreateLoyaltyUserDocument = gql`
  mutation CreateLoyaltyUser($cognitoId: String!)
  @gateway(flag: "enable-gateway-createLoyaltyUser") {
    createLoyaltyUser(cognitoId: $cognitoId) {
      loyaltyId
    }
  }
`;
export type ICreateLoyaltyUserMutationFn = Apollo.MutationFunction<
  ICreateLoyaltyUserMutation,
  ICreateLoyaltyUserMutationVariables
>;

/**
 * __useCreateLoyaltyUserMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyUserMutation, { data, loading, error }] = useCreateLoyaltyUserMutation({
 *   variables: {
 *      cognitoId: // value for 'cognitoId'
 *   },
 * });
 */
export function useCreateLoyaltyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateLoyaltyUserMutation,
    ICreateLoyaltyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateLoyaltyUserMutation, ICreateLoyaltyUserMutationVariables>(
    CreateLoyaltyUserDocument,
    options
  );
}
export type CreateLoyaltyUserMutationHookResult = ReturnType<typeof useCreateLoyaltyUserMutation>;
export type CreateLoyaltyUserMutationResult = Apollo.MutationResult<ICreateLoyaltyUserMutation>;
export type CreateLoyaltyUserMutationOptions = Apollo.BaseMutationOptions<
  ICreateLoyaltyUserMutation,
  ICreateLoyaltyUserMutationVariables
>;
export const UserGeoDocument = gql`
  query UserGeo @gateway {
    userGeo {
      ...UserGeoFragment
    }
  }
  ${UserGeoFragmentDoc}
`;

/**
 * __useUserGeoQuery__
 *
 * To run a query within a React component, call `useUserGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGeoQuery(
  baseOptions?: Apollo.QueryHookOptions<IUserGeoQuery, IUserGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserGeoQuery, IUserGeoQueryVariables>(UserGeoDocument, options);
}
export function useUserGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserGeoQuery, IUserGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserGeoQuery, IUserGeoQueryVariables>(UserGeoDocument, options);
}
export type UserGeoQueryHookResult = ReturnType<typeof useUserGeoQuery>;
export type UserGeoLazyQueryHookResult = ReturnType<typeof useUserGeoLazyQuery>;
export type UserGeoQueryResult = Apollo.QueryResult<IUserGeoQuery, IUserGeoQueryVariables>;
export const AutoEnrollUserInLoyaltyDocument = gql`
  mutation AutoEnrollUserInLoyalty @authRequired {
    autoEnrollUserInLoyalty {
      loyaltyId
    }
  }
`;
export type IAutoEnrollUserInLoyaltyMutationFn = Apollo.MutationFunction<
  IAutoEnrollUserInLoyaltyMutation,
  IAutoEnrollUserInLoyaltyMutationVariables
>;

/**
 * __useAutoEnrollUserInLoyaltyMutation__
 *
 * To run a mutation, you first call `useAutoEnrollUserInLoyaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoEnrollUserInLoyaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoEnrollUserInLoyaltyMutation, { data, loading, error }] = useAutoEnrollUserInLoyaltyMutation({
 *   variables: {
 *   },
 * });
 */
export function useAutoEnrollUserInLoyaltyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAutoEnrollUserInLoyaltyMutation,
    IAutoEnrollUserInLoyaltyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IAutoEnrollUserInLoyaltyMutation,
    IAutoEnrollUserInLoyaltyMutationVariables
  >(AutoEnrollUserInLoyaltyDocument, options);
}
export type AutoEnrollUserInLoyaltyMutationHookResult = ReturnType<
  typeof useAutoEnrollUserInLoyaltyMutation
>;
export type AutoEnrollUserInLoyaltyMutationResult =
  Apollo.MutationResult<IAutoEnrollUserInLoyaltyMutation>;
export type AutoEnrollUserInLoyaltyMutationOptions = Apollo.BaseMutationOptions<
  IAutoEnrollUserInLoyaltyMutation,
  IAutoEnrollUserInLoyaltyMutationVariables
>;
export const AssociateInvitationCodeToLoyaltyUserDocument = gql`
  mutation AssociateInvitationCodeToLoyaltyUser($loyaltyId: String!) @authRequired {
    associateInvitationCodeToLoyaltyUser(loyaltyId: $loyaltyId) {
      code
    }
  }
`;
export type IAssociateInvitationCodeToLoyaltyUserMutationFn = Apollo.MutationFunction<
  IAssociateInvitationCodeToLoyaltyUserMutation,
  IAssociateInvitationCodeToLoyaltyUserMutationVariables
>;

/**
 * __useAssociateInvitationCodeToLoyaltyUserMutation__
 *
 * To run a mutation, you first call `useAssociateInvitationCodeToLoyaltyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateInvitationCodeToLoyaltyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateInvitationCodeToLoyaltyUserMutation, { data, loading, error }] = useAssociateInvitationCodeToLoyaltyUserMutation({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *   },
 * });
 */
export function useAssociateInvitationCodeToLoyaltyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAssociateInvitationCodeToLoyaltyUserMutation,
    IAssociateInvitationCodeToLoyaltyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IAssociateInvitationCodeToLoyaltyUserMutation,
    IAssociateInvitationCodeToLoyaltyUserMutationVariables
  >(AssociateInvitationCodeToLoyaltyUserDocument, options);
}
export type AssociateInvitationCodeToLoyaltyUserMutationHookResult = ReturnType<
  typeof useAssociateInvitationCodeToLoyaltyUserMutation
>;
export type AssociateInvitationCodeToLoyaltyUserMutationResult =
  Apollo.MutationResult<IAssociateInvitationCodeToLoyaltyUserMutation>;
export type AssociateInvitationCodeToLoyaltyUserMutationOptions = Apollo.BaseMutationOptions<
  IAssociateInvitationCodeToLoyaltyUserMutation,
  IAssociateInvitationCodeToLoyaltyUserMutationVariables
>;
export const PriceOrderDocument = gql`
  mutation PriceOrder($input: PriceOrderInput!, $delivery: PriceDeliveryInput)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-priceOrder") {
    priceOrder(input: $input, delivery: $delivery) {
      rbiOrderId
      status
    }
  }
`;
export type IPriceOrderMutationFn = Apollo.MutationFunction<
  IPriceOrderMutation,
  IPriceOrderMutationVariables
>;

/**
 * __usePriceOrderMutation__
 *
 * To run a mutation, you first call `usePriceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePriceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [priceOrderMutation, { data, loading, error }] = usePriceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      delivery: // value for 'delivery'
 *   },
 * });
 */
export function usePriceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<IPriceOrderMutation, IPriceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPriceOrderMutation, IPriceOrderMutationVariables>(
    PriceOrderDocument,
    options
  );
}
export type PriceOrderMutationHookResult = ReturnType<typeof usePriceOrderMutation>;
export type PriceOrderMutationResult = Apollo.MutationResult<IPriceOrderMutation>;
export type PriceOrderMutationOptions = Apollo.BaseMutationOptions<
  IPriceOrderMutation,
  IPriceOrderMutationVariables
>;
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-cancelOrder") {
    cancelOrder(input: $input) {
      rbiOrderId
    }
  }
`;
export type ICancelOrderMutationFn = Apollo.MutationFunction<
  ICancelOrderMutation,
  ICancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ICancelOrderMutation, ICancelOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICancelOrderMutation, ICancelOrderMutationVariables>(
    CancelOrderDocument,
    options
  );
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<ICancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  ICancelOrderMutation,
  ICancelOrderMutationVariables
>;
export const GetOrderDocument = gql`
  query GetOrder($rbiOrderId: ID!, $handleCommitError: Boolean)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-order") {
    order(rbiOrderId: $rbiOrderId, handleCommitError: $handleCommitError) {
      ...OrderFragment
    }
  }
  ${OrderFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *      handleCommitError: // value for 'handleCommitError'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<IGetOrderQuery, IGetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetOrderQuery, IGetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOrderQuery, IGetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetOrderQuery, IGetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<IGetOrderQuery, IGetOrderQueryVariables>;
export const DeliveryOrderStatusDocument = gql`
  query DeliveryOrderStatus($rbiOrderId: ID!) {
    order(rbiOrderId: $rbiOrderId) {
      delivery {
        status
        shareUuid
        dropoff {
          arrivalTime
          beforeTime
          afterTime
        }
        driver {
          name
          phoneNumber
          profileImageUrl
          location {
            latitude
            longitude
          }
        }
        pincode
        vendorProperties {
          vendor
        }
        task {
          uuid
        }
      }
      status
    }
  }
`;

/**
 * __useDeliveryOrderStatusQuery__
 *
 * To run a query within a React component, call `useDeliveryOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryOrderStatusQuery({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *   },
 * });
 */
export function useDeliveryOrderStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    IDeliveryOrderStatusQuery,
    IDeliveryOrderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IDeliveryOrderStatusQuery, IDeliveryOrderStatusQueryVariables>(
    DeliveryOrderStatusDocument,
    options
  );
}
export function useDeliveryOrderStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IDeliveryOrderStatusQuery,
    IDeliveryOrderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IDeliveryOrderStatusQuery, IDeliveryOrderStatusQueryVariables>(
    DeliveryOrderStatusDocument,
    options
  );
}
export type DeliveryOrderStatusQueryHookResult = ReturnType<typeof useDeliveryOrderStatusQuery>;
export type DeliveryOrderStatusLazyQueryHookResult = ReturnType<
  typeof useDeliveryOrderStatusLazyQuery
>;
export type DeliveryOrderStatusQueryResult = Apollo.QueryResult<
  IDeliveryOrderStatusQuery,
  IDeliveryOrderStatusQueryVariables
>;
export const GenerateFirstpayVaultedLinkDocument = gql`
  mutation GenerateFirstpayVaultedLink($storeId: String) @authRequired @authGuestAllowed {
    generateFirstpayVaultedLink(storeId: $storeId) {
      link
    }
  }
`;
export type IGenerateFirstpayVaultedLinkMutationFn = Apollo.MutationFunction<
  IGenerateFirstpayVaultedLinkMutation,
  IGenerateFirstpayVaultedLinkMutationVariables
>;

/**
 * __useGenerateFirstpayVaultedLinkMutation__
 *
 * To run a mutation, you first call `useGenerateFirstpayVaultedLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFirstpayVaultedLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFirstpayVaultedLinkMutation, { data, loading, error }] = useGenerateFirstpayVaultedLinkMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGenerateFirstpayVaultedLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateFirstpayVaultedLinkMutation,
    IGenerateFirstpayVaultedLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateFirstpayVaultedLinkMutation,
    IGenerateFirstpayVaultedLinkMutationVariables
  >(GenerateFirstpayVaultedLinkDocument, options);
}
export type GenerateFirstpayVaultedLinkMutationHookResult = ReturnType<
  typeof useGenerateFirstpayVaultedLinkMutation
>;
export type GenerateFirstpayVaultedLinkMutationResult =
  Apollo.MutationResult<IGenerateFirstpayVaultedLinkMutation>;
export type GenerateFirstpayVaultedLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateFirstpayVaultedLinkMutation,
  IGenerateFirstpayVaultedLinkMutationVariables
>;
export const GenerateFirstpayCheckoutLinkDocument = gql`
  mutation generateFirstpayCheckoutLink($rbiOrderId: String!) @authRequired @authGuestAllowed {
    generateFirstpayCheckoutLink(rbiOrderId: $rbiOrderId) {
      link
    }
  }
`;
export type IGenerateFirstpayCheckoutLinkMutationFn = Apollo.MutationFunction<
  IGenerateFirstpayCheckoutLinkMutation,
  IGenerateFirstpayCheckoutLinkMutationVariables
>;

/**
 * __useGenerateFirstpayCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useGenerateFirstpayCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFirstpayCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFirstpayCheckoutLinkMutation, { data, loading, error }] = useGenerateFirstpayCheckoutLinkMutation({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *   },
 * });
 */
export function useGenerateFirstpayCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateFirstpayCheckoutLinkMutation,
    IGenerateFirstpayCheckoutLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateFirstpayCheckoutLinkMutation,
    IGenerateFirstpayCheckoutLinkMutationVariables
  >(GenerateFirstpayCheckoutLinkDocument, options);
}
export type GenerateFirstpayCheckoutLinkMutationHookResult = ReturnType<
  typeof useGenerateFirstpayCheckoutLinkMutation
>;
export type GenerateFirstpayCheckoutLinkMutationResult =
  Apollo.MutationResult<IGenerateFirstpayCheckoutLinkMutation>;
export type GenerateFirstpayCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateFirstpayCheckoutLinkMutation,
  IGenerateFirstpayCheckoutLinkMutationVariables
>;
export const GenerateFirstDataHostedPageLinkDocument = gql`
  mutation generateFirstDataHostedPageLink(
    $rbiOrderId: String!
    $parentUri: String!
    $typePayment: String!
    $callbackUrl: String!
  ) @authRequired @authGuestAllowed {
    generateFirstDataHostedPageLink(
      rbiOrderId: $rbiOrderId
      parentUri: $parentUri
      typePayment: $typePayment
      callbackUrl: $callbackUrl
    ) {
      chargetotal
      checkoutoption
      currency
      customParam_storeId
      hash_algorithm
      language
      merchantTransactionId
      parentUri
      paymentMethod
      responseFailURL
      responseSuccessURL
      storename
      timezone
      txndatetime
      txntype
      hashExtended
      host
    }
  }
`;
export type IGenerateFirstDataHostedPageLinkMutationFn = Apollo.MutationFunction<
  IGenerateFirstDataHostedPageLinkMutation,
  IGenerateFirstDataHostedPageLinkMutationVariables
>;

/**
 * __useGenerateFirstDataHostedPageLinkMutation__
 *
 * To run a mutation, you first call `useGenerateFirstDataHostedPageLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFirstDataHostedPageLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFirstDataHostedPageLinkMutation, { data, loading, error }] = useGenerateFirstDataHostedPageLinkMutation({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *      parentUri: // value for 'parentUri'
 *      typePayment: // value for 'typePayment'
 *      callbackUrl: // value for 'callbackUrl'
 *   },
 * });
 */
export function useGenerateFirstDataHostedPageLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateFirstDataHostedPageLinkMutation,
    IGenerateFirstDataHostedPageLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateFirstDataHostedPageLinkMutation,
    IGenerateFirstDataHostedPageLinkMutationVariables
  >(GenerateFirstDataHostedPageLinkDocument, options);
}
export type GenerateFirstDataHostedPageLinkMutationHookResult = ReturnType<
  typeof useGenerateFirstDataHostedPageLinkMutation
>;
export type GenerateFirstDataHostedPageLinkMutationResult =
  Apollo.MutationResult<IGenerateFirstDataHostedPageLinkMutation>;
export type GenerateFirstDataHostedPageLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateFirstDataHostedPageLinkMutation,
  IGenerateFirstDataHostedPageLinkMutationVariables
>;
export const GeneratePaymarkCheckoutLinkDocument = gql`
  mutation generatePaymarkCheckoutLink($rbiOrderId: String!) @authRequired @authGuestAllowed {
    generatePaymarkCheckoutLink(rbiOrderId: $rbiOrderId) {
      link
    }
  }
`;
export type IGeneratePaymarkCheckoutLinkMutationFn = Apollo.MutationFunction<
  IGeneratePaymarkCheckoutLinkMutation,
  IGeneratePaymarkCheckoutLinkMutationVariables
>;

/**
 * __useGeneratePaymarkCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useGeneratePaymarkCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePaymarkCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePaymarkCheckoutLinkMutation, { data, loading, error }] = useGeneratePaymarkCheckoutLinkMutation({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *   },
 * });
 */
export function useGeneratePaymarkCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGeneratePaymarkCheckoutLinkMutation,
    IGeneratePaymarkCheckoutLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGeneratePaymarkCheckoutLinkMutation,
    IGeneratePaymarkCheckoutLinkMutationVariables
  >(GeneratePaymarkCheckoutLinkDocument, options);
}
export type GeneratePaymarkCheckoutLinkMutationHookResult = ReturnType<
  typeof useGeneratePaymarkCheckoutLinkMutation
>;
export type GeneratePaymarkCheckoutLinkMutationResult =
  Apollo.MutationResult<IGeneratePaymarkCheckoutLinkMutation>;
export type GeneratePaymarkCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  IGeneratePaymarkCheckoutLinkMutation,
  IGeneratePaymarkCheckoutLinkMutationVariables
>;
export const GeneratePaycometCheckoutLinkDocument = gql`
  mutation GeneratePaycometCheckoutLink($rbiOrderId: String!, $typePayment: String!) {
    generatePaycometCheckoutLink(rbiOrderId: $rbiOrderId, typePayment: $typePayment) {
      iframeUrl
      jetId
    }
  }
`;
export type IGeneratePaycometCheckoutLinkMutationFn = Apollo.MutationFunction<
  IGeneratePaycometCheckoutLinkMutation,
  IGeneratePaycometCheckoutLinkMutationVariables
>;

/**
 * __useGeneratePaycometCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useGeneratePaycometCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePaycometCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePaycometCheckoutLinkMutation, { data, loading, error }] = useGeneratePaycometCheckoutLinkMutation({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *      typePayment: // value for 'typePayment'
 *   },
 * });
 */
export function useGeneratePaycometCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGeneratePaycometCheckoutLinkMutation,
    IGeneratePaycometCheckoutLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGeneratePaycometCheckoutLinkMutation,
    IGeneratePaycometCheckoutLinkMutationVariables
  >(GeneratePaycometCheckoutLinkDocument, options);
}
export type GeneratePaycometCheckoutLinkMutationHookResult = ReturnType<
  typeof useGeneratePaycometCheckoutLinkMutation
>;
export type GeneratePaycometCheckoutLinkMutationResult =
  Apollo.MutationResult<IGeneratePaycometCheckoutLinkMutation>;
export type GeneratePaycometCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  IGeneratePaycometCheckoutLinkMutation,
  IGeneratePaycometCheckoutLinkMutationVariables
>;
export const CreatePaycometPreAuthorizationDocument = gql`
  mutation CreatePaycometPreAuthorization($input: CreatePaycometPreAuthorizationInput!) {
    createPaycometPreAuthorization(input: $input) {
      merchantAccount
      paymentMethod {
        bin
        brand
        cardholderName
        expiryMonth
        expiryYear
        last4
        source
        storePaymentMethod
        storedPaymentMethodId
        url3ds
      }
      pspReference
      type
    }
  }
`;
export type ICreatePaycometPreAuthorizationMutationFn = Apollo.MutationFunction<
  ICreatePaycometPreAuthorizationMutation,
  ICreatePaycometPreAuthorizationMutationVariables
>;

/**
 * __useCreatePaycometPreAuthorizationMutation__
 *
 * To run a mutation, you first call `useCreatePaycometPreAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaycometPreAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaycometPreAuthorizationMutation, { data, loading, error }] = useCreatePaycometPreAuthorizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaycometPreAuthorizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreatePaycometPreAuthorizationMutation,
    ICreatePaycometPreAuthorizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ICreatePaycometPreAuthorizationMutation,
    ICreatePaycometPreAuthorizationMutationVariables
  >(CreatePaycometPreAuthorizationDocument, options);
}
export type CreatePaycometPreAuthorizationMutationHookResult = ReturnType<
  typeof useCreatePaycometPreAuthorizationMutation
>;
export type CreatePaycometPreAuthorizationMutationResult =
  Apollo.MutationResult<ICreatePaycometPreAuthorizationMutation>;
export type CreatePaycometPreAuthorizationMutationOptions = Apollo.BaseMutationOptions<
  ICreatePaycometPreAuthorizationMutation,
  ICreatePaycometPreAuthorizationMutationVariables
>;
export const PaycometCardInfoDocument = gql`
  mutation PaycometCardInfo($input: PaycometCardInfoInput!) {
    paycometCardInfo(input: $input) {
      merchantAccount
      pspReference
      cardInfo {
        brand
        bin
        last4
        storedPaymentMethodId
        expiryYear
        expiryMonth
        paytpvToken
      }
      type
    }
  }
`;
export type IPaycometCardInfoMutationFn = Apollo.MutationFunction<
  IPaycometCardInfoMutation,
  IPaycometCardInfoMutationVariables
>;

/**
 * __usePaycometCardInfoMutation__
 *
 * To run a mutation, you first call `usePaycometCardInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaycometCardInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paycometCardInfoMutation, { data, loading, error }] = usePaycometCardInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaycometCardInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPaycometCardInfoMutation,
    IPaycometCardInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPaycometCardInfoMutation, IPaycometCardInfoMutationVariables>(
    PaycometCardInfoDocument,
    options
  );
}
export type PaycometCardInfoMutationHookResult = ReturnType<typeof usePaycometCardInfoMutation>;
export type PaycometCardInfoMutationResult = Apollo.MutationResult<IPaycometCardInfoMutation>;
export type PaycometCardInfoMutationOptions = Apollo.BaseMutationOptions<
  IPaycometCardInfoMutation,
  IPaycometCardInfoMutationVariables
>;
export const GenerateVrPaymentCheckoutLinkDocument = gql`
  mutation GenerateVrPaymentCheckoutLink($rbiOrderId: String!, $guestEmail: String)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentCheckoutLink(rbiOrderId: $rbiOrderId, guestEmail: $guestEmail) {
      link
    }
  }
`;
export type IGenerateVrPaymentCheckoutLinkMutationFn = Apollo.MutationFunction<
  IGenerateVrPaymentCheckoutLinkMutation,
  IGenerateVrPaymentCheckoutLinkMutationVariables
>;

/**
 * __useGenerateVrPaymentCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useGenerateVrPaymentCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateVrPaymentCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateVrPaymentCheckoutLinkMutation, { data, loading, error }] = useGenerateVrPaymentCheckoutLinkMutation({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *      guestEmail: // value for 'guestEmail'
 *   },
 * });
 */
export function useGenerateVrPaymentCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateVrPaymentCheckoutLinkMutation,
    IGenerateVrPaymentCheckoutLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateVrPaymentCheckoutLinkMutation,
    IGenerateVrPaymentCheckoutLinkMutationVariables
  >(GenerateVrPaymentCheckoutLinkDocument, options);
}
export type GenerateVrPaymentCheckoutLinkMutationHookResult = ReturnType<
  typeof useGenerateVrPaymentCheckoutLinkMutation
>;
export type GenerateVrPaymentCheckoutLinkMutationResult =
  Apollo.MutationResult<IGenerateVrPaymentCheckoutLinkMutation>;
export type GenerateVrPaymentCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateVrPaymentCheckoutLinkMutation,
  IGenerateVrPaymentCheckoutLinkMutationVariables
>;
export const GenerateVrPaymentVaultLinkDocument = gql`
  mutation GenerateVrPaymentVaultLink($input: GenerateVrPaymentVaultLinkInput!)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentVaultLink(input: $input) {
      data
      link
    }
  }
`;
export type IGenerateVrPaymentVaultLinkMutationFn = Apollo.MutationFunction<
  IGenerateVrPaymentVaultLinkMutation,
  IGenerateVrPaymentVaultLinkMutationVariables
>;

/**
 * __useGenerateVrPaymentVaultLinkMutation__
 *
 * To run a mutation, you first call `useGenerateVrPaymentVaultLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateVrPaymentVaultLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateVrPaymentVaultLinkMutation, { data, loading, error }] = useGenerateVrPaymentVaultLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateVrPaymentVaultLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateVrPaymentVaultLinkMutation,
    IGenerateVrPaymentVaultLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateVrPaymentVaultLinkMutation,
    IGenerateVrPaymentVaultLinkMutationVariables
  >(GenerateVrPaymentVaultLinkDocument, options);
}
export type GenerateVrPaymentVaultLinkMutationHookResult = ReturnType<
  typeof useGenerateVrPaymentVaultLinkMutation
>;
export type GenerateVrPaymentVaultLinkMutationResult =
  Apollo.MutationResult<IGenerateVrPaymentVaultLinkMutation>;
export type GenerateVrPaymentVaultLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateVrPaymentVaultLinkMutation,
  IGenerateVrPaymentVaultLinkMutationVariables
>;
export const GenerateVrPaymentPayPalFastLinkDocument = gql`
  mutation GenerateVrPaymentPayPalFastLink($input: GenerateVrPaymentPayPalFastLinkInput!)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentPayPalFastLink(input: $input) {
      link
    }
  }
`;
export type IGenerateVrPaymentPayPalFastLinkMutationFn = Apollo.MutationFunction<
  IGenerateVrPaymentPayPalFastLinkMutation,
  IGenerateVrPaymentPayPalFastLinkMutationVariables
>;

/**
 * __useGenerateVrPaymentPayPalFastLinkMutation__
 *
 * To run a mutation, you first call `useGenerateVrPaymentPayPalFastLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateVrPaymentPayPalFastLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateVrPaymentPayPalFastLinkMutation, { data, loading, error }] = useGenerateVrPaymentPayPalFastLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateVrPaymentPayPalFastLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGenerateVrPaymentPayPalFastLinkMutation,
    IGenerateVrPaymentPayPalFastLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IGenerateVrPaymentPayPalFastLinkMutation,
    IGenerateVrPaymentPayPalFastLinkMutationVariables
  >(GenerateVrPaymentPayPalFastLinkDocument, options);
}
export type GenerateVrPaymentPayPalFastLinkMutationHookResult = ReturnType<
  typeof useGenerateVrPaymentPayPalFastLinkMutation
>;
export type GenerateVrPaymentPayPalFastLinkMutationResult =
  Apollo.MutationResult<IGenerateVrPaymentPayPalFastLinkMutation>;
export type GenerateVrPaymentPayPalFastLinkMutationOptions = Apollo.BaseMutationOptions<
  IGenerateVrPaymentPayPalFastLinkMutation,
  IGenerateVrPaymentPayPalFastLinkMutationVariables
>;
export const ValidateCommitOrderDocument = gql`
  mutation ValidateCommitOrder($input: ValidateCommitOrderInput!) @authRequired @authGuestAllowed {
    validateCommitOrder(input: $input) {
      rbiOrderId
      status
    }
  }
`;
export type IValidateCommitOrderMutationFn = Apollo.MutationFunction<
  IValidateCommitOrderMutation,
  IValidateCommitOrderMutationVariables
>;

/**
 * __useValidateCommitOrderMutation__
 *
 * To run a mutation, you first call `useValidateCommitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCommitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCommitOrderMutation, { data, loading, error }] = useValidateCommitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCommitOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IValidateCommitOrderMutation,
    IValidateCommitOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IValidateCommitOrderMutation, IValidateCommitOrderMutationVariables>(
    ValidateCommitOrderDocument,
    options
  );
}
export type ValidateCommitOrderMutationHookResult = ReturnType<
  typeof useValidateCommitOrderMutation
>;
export type ValidateCommitOrderMutationResult = Apollo.MutationResult<IValidateCommitOrderMutation>;
export type ValidateCommitOrderMutationOptions = Apollo.BaseMutationOptions<
  IValidateCommitOrderMutation,
  IValidateCommitOrderMutationVariables
>;
export const GetMeDocument = gql`
  query GetMe($numUniquePurchasedItems: Int, $customInput: MeInput)
  @authRequired
  @gateway(flag: "enable-gateway-me") {
    me(numUniquePurchasedItems: $numUniquePurchasedItems, customInput: $customInput) {
      thLegacyCognitoId
      cognitoId
      details {
        ...DetailsFragment
      }
      loyaltyId
      uniquePurchasedItems {
        name
        image
        isExtra
        isDonation
        lineId
        sanityId
        quantity
        type
        price
        url
        pickerSelections
        isInMenu
        vendorConfigs {
          ...VendorConfigsFragment
        }
        children {
          ...CartEntryFragment
          isInMenu
          children {
            ...CartEntryFragment
            isInMenu
            children {
              ...CartEntryFragment
              isInMenu
              children {
                ...CartEntryFragment
                isInMenu
                children {
                  ...CartEntryFragment
                  isInMenu
                  children {
                    ...CartEntryFragment
                    isInMenu
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${DetailsFragmentDoc}
  ${VendorConfigsFragmentDoc}
  ${CartEntryFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      numUniquePurchasedItems: // value for 'numUniquePurchasedItems'
 *      customInput: // value for 'customInput'
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetMeQuery, IGetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetMeQuery, IGetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetMeQuery, IGetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetMeQuery, IGetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<IGetMeQuery, IGetMeQueryVariables>;
export const UserRecentAddressesDocument = gql`
  query userRecentAddresses($limit: Int, $countryCode: String)
  @authRequired
  @gateway(flag: "enable-gateway-me") {
    userRecentAddresses(limit: $limit, countryCode: $countryCode) {
      count
      addresses {
        addressLine1
        addressLine2
        city
        alias
        country
        phoneNumber
        state
        zip
        latitude
        longitude
        route
        streetNumber
      }
    }
  }
`;

/**
 * __useUserRecentAddressesQuery__
 *
 * To run a query within a React component, call `useUserRecentAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecentAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecentAddressesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUserRecentAddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IUserRecentAddressesQuery,
    IUserRecentAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserRecentAddressesQuery, IUserRecentAddressesQueryVariables>(
    UserRecentAddressesDocument,
    options
  );
}
export function useUserRecentAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserRecentAddressesQuery,
    IUserRecentAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserRecentAddressesQuery, IUserRecentAddressesQueryVariables>(
    UserRecentAddressesDocument,
    options
  );
}
export type UserRecentAddressesQueryHookResult = ReturnType<typeof useUserRecentAddressesQuery>;
export type UserRecentAddressesLazyQueryHookResult = ReturnType<
  typeof useUserRecentAddressesLazyQuery
>;
export type UserRecentAddressesQueryResult = Apollo.QueryResult<
  IUserRecentAddressesQuery,
  IUserRecentAddressesQueryVariables
>;
import { disableFragmentWarnings } from '@apollo/client';
disableFragmentWarnings();
