import React from 'react';

import { useMediaQuery } from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import { DesktopHeader } from './desktop-header';
import { MobileHeader } from './mobile-header';

const routesWithoutHeader = {
  mobile: [
    routes.storeLocator,
    routes.membershipDetails,
    routes.blueLightCard,
    routes.waitingPayment,
    routes.cartAddPaymentMethod,
  ],
  desktop: [
    routes.cart,
    routes.signIn,
    routes.signUp,
    routes.orderConfirmation,
    routes.createAccount,
    routes.waitingPayment,
    routes.cartAddPaymentMethod,
  ],
};

const isPathInRouteList = (routeList: string[], pathname: string) =>
  routeList.some(route => pathname.startsWith(route));

export const AppHeader: React.FC = () => {
  const isMobile = useMediaQuery('headerMobile');
  const { isAuthenticated } = useAuthContext();
  const enableLoyaltyQRCodeHeaderButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON
  );

  const {
    location: { pathname },
  } = useLocationContext();

  // Hide header on Loyalty My Code page with flag
  const routesWithoutHeaderMobile =
    isAuthenticated() && enableLoyaltyQRCodeHeaderButton
      ? [...routesWithoutHeader.mobile, routes.redeem]
      : routesWithoutHeader.mobile;

  if (
    (isMobile && isPathInRouteList(routesWithoutHeaderMobile, pathname)) ||
    (!isMobile && isPathInRouteList(routesWithoutHeader.desktop, pathname))
  ) {
    return null;
  }

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};
