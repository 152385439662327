import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useMemoWithCompare } from 'hooks/use-memo-with-compare';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode';
import type { TLocalizationKey } from 'types/i18n';
import { isServiceModeAvailable } from 'utils/service-mode';

export const serviceModeToIntlId: Record<ServiceMode, TLocalizationKey> = {
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'takeOut',
  [ServiceMode.TABLE_SERVICE]: 'tableService',
};

// Store the order and which service modes will display in the text.
const serviceModeOrder: ServiceMode[] = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.EAT_IN,
  ServiceMode.CURBSIDE,
  ServiceMode.TABLE_SERVICE,
];

interface UseServiceModeTextOptions {
  restaurant: IRestaurantNode;
}

export const getAvailableServiceModes = (serviceModeStatus: ServiceModeStatus) => {
  return serviceModeOrder.filter(isServiceModeAvailable(serviceModeStatus));
};

export const useServiceModeText = ({
  restaurant,
}: UseServiceModeTextOptions): string | undefined => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  const serviceModes = serviceModeOrder.filter(isServiceModeAvailable(serviceModeStatus));

  return useMemoWithCompare(() => {
    if (!serviceModes.length) {
      return;
    }

    return serviceModes
      .map(serviceMode => formatMessage({ id: serviceModeToIntlId[serviceMode] }))
      .join(', ');
  }, [serviceModes]);
};
