import { FC } from 'react';

import { useIdCounter } from '../../hooks';

import { SwitchButton } from './switch-button';
import { SwitchLabel } from './switch-label';
import { StyledButtonAndLabelWrapper } from './switch.styled';
import { SwitchProps } from './types';

/**
 * toggles the state of a single item to on or off
 */
export const Switch: FC<SwitchProps> = ({
  checked = false,
  disabled = false,
  id: propsId,
  label,
  onClick,
  'data-testid': dataTestId,
}) => {
  const generatedId = useIdCounter('switch');
  const id = propsId ?? generatedId;
  return (
    <StyledButtonAndLabelWrapper>
      <SwitchLabel disabled={disabled} htmlFor={id}>
        {label}
      </SwitchLabel>
      <SwitchButton
        data-testid={dataTestId}
        checked={checked}
        disabled={disabled}
        id={id}
        onClick={onClick}
      />
    </StyledButtonAndLabelWrapper>
  );
};
