import { IDeliveryAddress } from 'generated/rbi-graphql';
import { IPriceOrderParams } from 'hooks/price-order/types';
import { IPriceOrderDeliveryInput } from 'remote/queries/order';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { ServiceMode } from 'state/order';
import { logger } from 'utils/logger';

export const buildPriceDeliveryInput = (
  order: Pick<
    IPriceOrderParams,
    'deliveryAddress' | 'orderPhoneNumber' | 'serviceMode' | 'tipCents' | 'deliveryInstructions'
  >,
  user: UserDetails | null,
  quoteId: string
): IPriceOrderDeliveryInput | null => {
  if (order.serviceMode !== ServiceMode.DELIVERY || !order.deliveryAddress) {
    return null;
  }

  const deliveryAddress = order.deliveryAddress as IDeliveryAddress | undefined;

  if (deliveryAddress && (!deliveryAddress.latitude || !deliveryAddress.longitude)) {
    logger.error({
      message: 'missing delivery address lat/lng in buildPriceDeliveryInput',
      order,
      bypassSampling: true,
    });
  }

  const usersPhoneNumber = order.orderPhoneNumber || user?.details.phoneNumber || '';
  const phoneNumber = usersPhoneNumber;
  return {
    dropoff: {
      ...order.deliveryAddress,
      phoneNumber,
    },
    instructions: order.deliveryInstructions,
    quoteId,
    tipCents: order.tipCents,
  };
};
