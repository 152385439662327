import { getIso3, getSubRegions } from '@rbilabs/intl';

import {
  administrativeAreasByCountry,
  administrativeAreasByCountryExtended,
} from 'components/administrative-area-input/constants';
import { ISOs } from 'utils/form/constants';

import { getCountry as currentMarketCountry } from '../environment';

import { IPlaceAddress, IPlaceAddressComponent } from './types';

export type PlaceAddressParserOptions = {
  useISOCountry?: boolean;
  streetNameFirst?: boolean;
};

/**
 * parsePlaceAddress() turns a Google Place API response's `address_components`
 * into a standardized object with address properties
 */
export function parsePlaceAddress(
  placeAddressComponents?: IPlaceAddressComponent[],
  options?: PlaceAddressParserOptions
): IPlaceAddress {
  if (!placeAddressComponents) {
    return {
      addressLine1: '',
      addressLine2: '',
      city: '',
      route: '',
      state: '',
      streetNumber: '',
      zip: '',
      country: '',
    };
  }

  return {
    addressLine1: getAddressLine1(placeAddressComponents, options?.streetNameFirst),
    addressLine2: getAddressLine2(),
    city: getCity(placeAddressComponents),
    route: getRoute(placeAddressComponents),
    state: getState(placeAddressComponents),
    streetNumber: getStreetNumber(placeAddressComponents),
    zip: getZip(placeAddressComponents),
    country: getCountry(placeAddressComponents, options?.useISOCountry),
  };
}

function getAddressLine1(addressComponents: IPlaceAddressComponent[], streetNameFirst?: boolean) {
  const streetNumber = getStreetNumber(addressComponents);
  const streetName = getRoute(addressComponents);

  if (!streetNumber || !streetName) {
    return '';
  }

  if (streetNameFirst) {
    return [streetName, streetNumber].join(' ');
  }

  return [streetNumber, streetName].join(' ');
}

function getRoute(addressComponents: IPlaceAddressComponent[]) {
  return findComponentWithType(addressComponents, 'route').long_name;
}

function getStreetNumber(addressComponents: IPlaceAddressComponent[]) {
  return findComponentWithType(addressComponents, 'street_number').long_name;
}

// I don't see a way for the Places response to include line 2 data...
function getAddressLine2() {
  return '';
}

function getCity(addressComponents: IPlaceAddressComponent[]) {
  return (
    findComponentWithType(addressComponents, 'locality').long_name ||
    findComponentWithType(addressComponents, 'sublocality').long_name ||
    findComponentWithType(addressComponents, 'postal_town').long_name ||
    findComponentWithType(addressComponents, 'administrative_area_level_2').long_name ||
    findComponentWithType(addressComponents, 'administrative_area_level_3').long_name
  );
}

function getState(addressComponents: IPlaceAddressComponent[]) {
  return findComponentWithType(addressComponents, 'administrative_area_level_1').short_name;
}

function getZip(addressComponents: IPlaceAddressComponent[]) {
  return findComponentWithType(addressComponents, 'postal_code').long_name;
}

function getCountry(addressComponents: IPlaceAddressComponent[], useISOCountry?: boolean) {
  /**
   * Gibraltar and Andorra are small countries near Spain. The Spain FZ controls the operations of the restaurants
   * in these two countries and wants to serve the BK ES app in Gibraltar and Andorra.
   * Hence, we consider Gibraltar and Andorra subregions of Spain.
   */

  const shortName = getParentRegion(
    findComponentWithType(addressComponents, 'country').short_name,
    currentMarketCountry().toUpperCase()
  );
  return useISOCountry ? getIso3({ iso2: shortName }) : shortName;
}

function findComponentWithType(
  addressComponents: IPlaceAddressComponent[],
  type: string
): IPlaceAddressComponent {
  return (
    addressComponents.find(comp => comp.types.includes(type)) || {
      long_name: '',
      short_name: '',
      types: [],
    }
  );
}

function isSubRegionOf(maybeParentRegion: string, region: string) {
  const subRegions = getSubRegions([maybeParentRegion]);
  return subRegions.find(item => item.iso2 === region);
}

/**
 * Returns the parent region (if any) or the region itself
 */
const getParentRegion = (region: string, maybeParentRegion: string) =>
  region !== maybeParentRegion && isSubRegionOf(maybeParentRegion, region)
    ? maybeParentRegion
    : region;

/**
 * This function checks if the given state is an acronym or a full name.
 * If it is an acronym, the function returns it directly.
 * If it is a full name, the function returns the corresponding acronym from the mapping.
 *
 * @param {string} state - The state name (e.g., "Porto") or acronym (e.g., "PR").
 * @param {keyof typeof ISOs} countryISO - The ISO code of the country (e.g., ISOs.PT).
 * @returns {string} - The corresponding state code (e.g., "PR") or the state received if no mapping is found.
 */
export const getStateCode = (state: string, countryISO: keyof typeof ISOs): string => {
  const acronymMapping = administrativeAreasByCountry[countryISO];
  if (acronymMapping?.[state]) {
    return state;
  }

  const extendedMapping = administrativeAreasByCountryExtended[countryISO];
  return extendedMapping?.[state] ?? state;
};
