import { FC } from 'react';

import styled from 'styled-components';

import { mediaQuery } from '../..';

import { IFlexProps } from './types';

export const StyledFlex = styled.div<IFlexProps>`
  display: flex;

  ${(props) => ({
    flexDirection: props.flexDirection,
    flexWrap: props.flexWrap,
    justifyContent: props.justifyContent,
    justifyItems: props.justifyItems,
    alignContent: props.alignContent,
    alignItems: props.alignItems,
  })}

  @media ${mediaQuery.mobileTiny} {
    ${(props) => ({
      flexDirection: props.flexDirectionMobileTiny,
      flexWrap: props.flexWrapMobileTiny,
      justifyContent: props.justifyContentMobileTiny,
      justifyItems: props.justifyItemsMobileTiny,
      alignContent: props.alignContentMobileTiny,
      alignItems: props.alignItemsMobileTiny,
    })}
  }

  @media ${mediaQuery.tablet} {
    ${(props) => ({
      flexDirection: props.flexDirectionTablet,
      flexWrap: props.flexWrapTablet,
      justifyContent: props.justifyContentTablet,
      justifyItems: props.justifyItemsTablet,
      alignContent: props.alignContentTablet,
      alignItems: props.alignItemsTablet,
    })}
  }

  @media ${mediaQuery.desktopSmall} {
    ${(props) => ({
      flexDirection: props.flexDirectionDesktopSmall,
      flexWrap: props.flexWrapDesktopSmall,
      justifyContent: props.justifyContentDesktopSmall,
      justifyItems: props.justifyItemsDesktopSmall,
      alignContent: props.alignContentDesktopSmall,
      alignItems: props.alignItemsDesktopSmall,
    })}
  }

  @media ${mediaQuery.desktopLarge} {
    ${(props) => ({
      flexDirection: props.flexDirectionDesktopLarge,
      flexWrap: props.flexWrapDesktopLarge,
      justifyContent: props.justifyContentDesktopLarge,
      justifyItems: props.justifyItemsDesktopLarge,
      alignContent: props.alignContentDesktopLarge,
      alignItems: props.alignItemsDesktopLarge,
    })}
  }
` as FC<IFlexProps>;
