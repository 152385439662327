import { MouseEventHandler, forwardRef, useCallback } from 'react';

import { StyledSwitchButton } from './switch.styled';
import { SwitchButtonProps } from './types';
/**
 * Toggle the state of a single item to on or off.
 * This component MUST be paired with a label to work (use the exported SwitchLabel).
 * Only use this if you need custom styling that is different from the exported 'Switch' component.
 */
export const SwitchButton = forwardRef<HTMLButtonElement, SwitchButtonProps>(
  ({ disabled = false, checked = false, onClick, id, 'data-testid': dataTestId }, forwardedRef) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (e) => {
        onClick(e);
      },
      [onClick],
    );

    return (
      <StyledSwitchButton
        ref={forwardedRef}
        data-testid={dataTestId}
        type="button"
        role="switch"
        aria-checked={checked}
        disabled={disabled}
        id={id}
        onClick={handleClick}
      />
    );
  },
);
