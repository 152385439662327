import { gql } from '@apollo/client';

import { FireOrderIn, IAddress, IBackendCartEntries, IServerOrder } from '@rbi-ctg/menu';
import {
  ICheckoutDotComPayment,
  ICybersourcePayment,
  IEvertecPayment,
} from 'generated/graphql-gateway';
import {
  IAdyenPayment,
  IAppliedOffer,
  IFirstDataPayment,
  IFirstpayPayment,
  IOrbitalPayment,
  IPayMarkPayment,
  IPaycometPayment,
  IPreOrderTimeSlotInput,
  IVrPayment,
} from 'generated/rbi-graphql';
import { IParsedLoyaltyReward } from 'state/loyalty/hooks/types';
import { OrderStatus } from 'state/order';
import { CartPaymentType, PaymentFlowType } from 'state/order/types';
import { CardTypes, IApplePayDetails, IGooglePayDetails } from 'state/payment/types';
import { ServiceMode } from 'state/service-mode';
import { POSVendor } from 'state/store/hooks/enums';
import { RBIPlatform } from 'utils/environment';
import { BrowserInformation } from 'utils/payment';

import { OrderFragment } from './fragments/order';

export interface IOrderAddressInput extends IAddress {
  country: string | null;
  phoneNumber: string;
  shouldSave?: boolean | null;
}

export interface IPriceOrderInput {
  appliedOffers?: IAppliedOffer[];
  rewardsApplied?: IParsedLoyaltyReward[] | null;
  brand: string;
  cartEntries: IBackendCartEntries[];
  cartVersion?: number | null;
  customerLocale?: string | null;
  customerName?: string | null;
  paymentMethod?: CardTypes | null;
  platform: RBIPlatform;
  posVendor: POSVendor | null;
  redeemedOn?: string | null;
  requestedAmountCents: number;
  serviceMode: ServiceMode;
  storeAddress: IOrderAddressInput;
  storeId: string;
  storePosId?: string | null;
  vatNumber?: string | null;
}

export interface ICancelOrderInput {
  rbiOrderId: string;
}

export interface IPriceOrderDeliveryInput {
  dropoff: IOrderAddressInput;
  instructions?: string;
  tipCents?: number;
  quoteId?: string;
}

export interface IPriceOrderMutationResponse {
  priceOrder: {
    rbiOrderId: string;
    status: OrderStatus;
  };
}

export interface IPriceOrderMutationVariables {
  input: IPriceOrderInput;
  delivery?: IPriceOrderDeliveryInput | null;
}

export const PriceOrderMutation = gql`
  mutation PriceOrder($input: PriceOrderInput!, $delivery: PriceDeliveryInput)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-priceOrder") {
    priceOrder(input: $input, delivery: $delivery) {
      rbiOrderId
      status
    }
  }
`;

export const CancelOrderMutation = gql`
  mutation CancelOrder($input: CancelOrderInput!)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-cancelOrder") {
    cancelOrder(input: $input) {
      rbiOrderId
    }
  }
`;

export interface IGetOrderQueryResponse {
  order: IServerOrder;
}

export interface IGetOrderQueryVariables {
  rbiOrderId: string;
  handleCommitError?: boolean;
}

export const GetOrderQuery = gql`
  query GetOrder($rbiOrderId: ID!, $handleCommitError: Boolean)
  @authRequired
  @authGuestAllowed
  @gateway(flag: "enable-gateway-order") {
    order(rbiOrderId: $rbiOrderId, handleCommitError: $handleCommitError) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export interface IThreeDsOptions {
  /** challenge response obtained in challengeCallbackUrl to resume challenge flow */
  challengeResponse?: string | null;
  /** transactionId to resume method or challenge flow */
  transactionId: string;
}

/** Resume 3DS sale transaction input */
export interface IResumeThreeDSaleTransaction {
  /** saleId of the transaction */
  saleId: string;
  /** threeDSOptions to resume 3ds flow transaction */
  threeDSOptions: IThreeDsOptions;
}

export interface ICommitOrderMutationInput {
  additionalDetails?: string;
  billingAddress?: {
    locality: string | null;
    postalCode: string;
    region: string | null;
    streetAddress: string | null;
    unitNumber?: string | null;
  };
  ccBin?: string | null;
  ccExpiryMonth?: string | null;
  ccExpiryYear?: string | null;
  ccLast4?: string | null;
  creditType?: string | null;
  paymentMethodBrand?: string | null;
  fdAccessToken?: string;
  fdAccountId?: string;
  fdNonce?: string;
  fireOrderIn?: FireOrderIn;
  fullName?: string;
  rbiOrderId: string;
  payment?: IPayment;
  storeEmail?: string;
  guestEmail?: string;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
  chaseProfileId?: string;
  threeDSDetails?: IResumeThreeDSaleTransaction;
  saveCommitOrderOnly?: boolean;
  storeId?: string;
  deviceSessionId?: string;
}

export interface ICommitOrderMutationDeliveryInput {
  instructions?: string;
  tipCents?: number;
  pushNotification?: boolean;
  // the user's location to deliver to
  dropoff?: Partial<IOrderAddressInput>;
  selectedPreOrderTimeSlot?: IPreOrderTimeSlotInput;
}

export interface ICommitOrderMutationVariables {
  input: ICommitOrderMutationInput;
  delivery: ICommitOrderMutationDeliveryInput | null;
  skipCoolingPeriod?: boolean;
}

export interface ICommitOrderMutationResponse {
  commitOrder: {
    rbiOrderId: string;
    status: string;
  };
}

export interface IPayment {
  /** Adyen payment data */
  adyenInput?: IAdyenPayment;
  /** Details from Apple Pay for payment. */
  applePayDetails?: IApplePayDetails;
  /** Billing address associated to card */
  billingAddress?: {
    locality: string | null;
    postalCode: string;
    region: string | null;
    streetAddress: string | null;
    unitNumber?: string | null;
    country?: string | null;
  };
  /** Billing customer */
  billingCustomer?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  blikCode?: string | null;
  browserInformation?: BrowserInformation;
  /** Contains supplemental data for CC transactions */
  ccMetadata?: {
    ccBin?: string | null;
    ccExpiryMonth?: string;
    ccExpiryYear?: string;
    ccLast4?: string | null;
  };
  /** chase id from TH endpoint to trigger direct payment */
  chaseProfileId?: string;
  /** First Data payment data */
  firstDataInput?: IFirstDataPayment;
  /** Name on credit card */
  fullName: string;
  /** Details from Google Pay for payment. */
  googlePayDetails?: IGooglePayDetails;
  paymentType?: CartPaymentType;
  cashPayment?: boolean;
  paymentMethodBrand?: string | null;
  vrPaymentInput?: IVrPayment;
  orbitalInput?: IOrbitalPayment;
  payMarkInput?: IPayMarkPayment;
  paycometInput?: IPaycometPayment;
  firstPayInput?: IFirstpayPayment;
  checkoutDotComInput?: ICheckoutDotComPayment;
  cybersourceInput?: ICybersourcePayment;
  evertecInput?: IEvertecPayment;
  paymentFlowType?: PaymentFlowType;
}

export const DeliveryStatusQuery = gql`
  query DeliveryOrderStatus($rbiOrderId: ID!) {
    order(rbiOrderId: $rbiOrderId) {
      delivery {
        status
        shareUuid
        dropoff {
          arrivalTime
          beforeTime
          afterTime
        }
        driver {
          name
          phoneNumber
          profileImageUrl
          location {
            latitude
            longitude
          }
        }
        pincode
        vendorProperties {
          vendor
        }
        task {
          uuid
        }
      }
      status
    }
  }
`;

// Generates the URL needed to view the Firstpay credit card form for checkout
export const FirstpayVaultedLinkMutation = gql`
  mutation GenerateFirstpayVaultedLink($storeId: String) @authRequired @authGuestAllowed {
    generateFirstpayVaultedLink(storeId: $storeId) {
      link
    }
  }
`;

// Generates the URL needed to view the Firstpay credit card form for checkout
export const FirstpayLinkMutation = gql`
  mutation generateFirstpayCheckoutLink($rbiOrderId: String!) @authRequired @authGuestAllowed {
    generateFirstpayCheckoutLink(rbiOrderId: $rbiOrderId) {
      link
    }
  }
`;

// Generates the URL needed to view the FirstData hosted page for checkout
export const FirstDataHostedPageLinkMutation = gql`
  mutation generateFirstDataHostedPageLink(
    $rbiOrderId: String!
    $parentUri: String!
    $typePayment: String!
    $callbackUrl: String!
  ) @authRequired @authGuestAllowed {
    generateFirstDataHostedPageLink(
      rbiOrderId: $rbiOrderId
      parentUri: $parentUri
      typePayment: $typePayment
      callbackUrl: $callbackUrl
    ) {
      chargetotal
      checkoutoption
      currency
      customParam_storeId
      hash_algorithm
      language
      merchantTransactionId
      parentUri
      paymentMethod
      responseFailURL
      responseSuccessURL
      storename
      timezone
      txndatetime
      txntype
      hashExtended
      host
    }
  }
`;

// Generates the URL needed to view the PayMark credit card form for checkout
export const PaymarkLinkMutation = gql`
  mutation generatePaymarkCheckoutLink($rbiOrderId: String!) @authRequired @authGuestAllowed {
    generatePaymarkCheckoutLink(rbiOrderId: $rbiOrderId) {
      link
    }
  }
`;

// Generates the URL needed to view the Paycomet credit card form for checkout
export const PaycometLinkMutation = gql`
  mutation GeneratePaycometCheckoutLink($rbiOrderId: String!, $typePayment: String!) {
    generatePaycometCheckoutLink(rbiOrderId: $rbiOrderId, typePayment: $typePayment) {
      iframeUrl
      jetId
    }
  }
`;

export const CreatePaycometPreAuthorizationMutation = gql`
  mutation CreatePaycometPreAuthorization($input: CreatePaycometPreAuthorizationInput!) {
    createPaycometPreAuthorization(input: $input) {
      merchantAccount
      paymentMethod {
        bin
        brand
        cardholderName
        expiryMonth
        expiryYear
        last4
        source
        storePaymentMethod
        storedPaymentMethodId
        url3ds
      }
      pspReference
      type
    }
  }
`;

// Generates the URL needed to view the Paycomet credit card form for checkout
export const PaycometCardInfoMutation = gql`
  mutation PaycometCardInfo($input: PaycometCardInfoInput!) {
    paycometCardInfo(input: $input) {
      merchantAccount
      pspReference
      cardInfo {
        brand
        bin
        last4
        storedPaymentMethodId
        expiryYear
        expiryMonth
        paytpvToken
      }
      type
    }
  }
`;

// Generates the URL needed to view the VR Payment credit card form for checkout
export const VrPaymentLinkMutation = gql`
  mutation GenerateVrPaymentCheckoutLink($rbiOrderId: String!, $guestEmail: String)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentCheckoutLink(rbiOrderId: $rbiOrderId, guestEmail: $guestEmail) {
      link
    }
  }
`;

// Generates the payment URL for a vaulted credit card
export const VrPaymentVaultLinkMutation = gql`
  mutation GenerateVrPaymentVaultLink($input: GenerateVrPaymentVaultLinkInput!)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentVaultLink(input: $input) {
      data
      link
    }
  }
`;

export const VrPaymentPayPalFastLinkMutation = gql`
  mutation GenerateVrPaymentPayPalFastLink($input: GenerateVrPaymentPayPalFastLinkInput!)
  @authRequired
  @authGuestAllowed {
    generateVrPaymentPayPalFastLink(input: $input) {
      link
    }
  }
`;

export const ValidateCommitOrderMutation = gql`
  mutation ValidateCommitOrder($input: ValidateCommitOrderInput!) @authRequired @authGuestAllowed {
    validateCommitOrder(input: $input) {
      rbiOrderId
      status
    }
  }
`;
